import {
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  SetupIntent,
  StripeError,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { forwardRef, useImperativeHandle } from 'react';
import { useSetDefaultPaymentMethod } from '~/services/api/auth';

export interface StripeCardElementProps {
  onBeforeConfirm?: () => void;
  onSuccess?: (data: any) => void;
  onConfirmSuccess?: (setupIntent: SetupIntent) => void;
  onError?: (error: StripeError) => void;
  onSetDefaultPaymentError?: (data: any) => void;
  onReady?: (element: StripePaymentElement) => void;
  onChange?: (event: StripePaymentElementChangeEvent) => void;
}

export const StripeCardElement = forwardRef(
  (props: StripeCardElementProps, ref: React.Ref<any>) => {
    const {
      onSuccess = () => {},
      onError = () => {},
      onSetDefaultPaymentError = () => {},
      onBeforeConfirm = () => {},
      onReady = () => {},
      onChange = () => {},
      onConfirmSuccess = () => {},
    } = props;
    const stripe = useStripe();
    const elements = useElements();
    const { mutate: setDefaultMutation } = useSetDefaultPaymentMethod({
      onSuccess,
      onError: onSetDefaultPaymentError,
    });

    useImperativeHandle(
      ref,
      () => ({
        confirmStripeSetup: async () => {
          if (!stripe || !elements) {
            return;
          }
          onBeforeConfirm();
          const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: window.location.href,
            },
            redirect: 'if_required',
          });

          if (error) {
            onError(error);
          }

          if (setupIntent?.id) {
            onConfirmSuccess(setupIntent);
            setDefaultMutation({ setup_intent: setupIntent?.id });
          }
        },
      }),
      [stripe, elements],
    );

    return (
      <>
        <PaymentElement
          id='payment-element'
          onChange={onChange}
          onReady={onReady}
          options={{
            terms: {
              card: 'never',
            },
          }}
        />
        <AddressElement options={{ mode: 'billing' }} />
      </>
    );
  },
);
