/* eslint-disable no-nested-ternary */
import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { AddZoneSchema } from '~/utils/schema/integration';
import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import themes from '~/themes';
import {
  useCreateHubZonesMutation,
  useUpdateHubZonesMutation,
} from '~/services/api/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { useRef, useState } from 'react';
import { Zone } from '~/models/stores';
import { alertParams, showAlert } from '~/components/common/Alert';
import { DeliveryZoneSets } from '../../types';
import { DialogAddZoneForm } from './DrawAddZoneForm';

interface DialogAddZoneIProps {
  open: boolean;
  onClose: () => void;
  zoneSelected?: Zone;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
}

export const FeeTypeElement = ({ type, open }) => (
  <>
    <Typography color={themes.color.violet900}>
      {type === 'fixed_amount' ? '$' : '%'}
    </Typography>
    {open ? (
      <KeyboardArrowDownIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    ) : (
      <KeyboardArrowUpIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    )}
  </>
);

export const DialogAddZone: React.FC<DialogAddZoneIProps> = ({
  open,
  onClose,
  zoneSelected,
  defaultCenter,
}) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const isEdit = !!zoneSelected;
  const drawnZoneMapRef = useRef(null);

  const [missCoordinateErr, setMissCoordinateErr] = useState('');
  const {
    mutate: createHubZonesMutation,
    isLoading: isLoadingCreateHubZonesMutation,
  } = useCreateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const {
    mutate: updateHubZonesMutation,
    isLoading: isLoadingUpdateHubZonesMutation,
  } = useUpdateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const onSubmit = (values: any) => {
    const {
      name,
      delivery_fee_type,
      long_lat,
      delivery_fee,
      delivery_minimum,
    } = values;
    const longLat = isEdit ? drawnZoneMapRef.current.getPaths() : long_lat;

    if (!longLat.length || !long_lat.length) {
      return setMissCoordinateErr(
        'Please complete drawing your delivery zone.',
      );
    }

    setMissCoordinateErr('');

    const params = {
      action: isEdit && zoneSelected.id ? 'update' : 'create',
      parameters: {
        integration_id: search.id,
        name,
        enable: true,
        delivery_fee,
        delivery_fee_type,
        delivery_minimum,
        area_type: DeliveryZoneSets.DrawnZones,
        long_lat: longLat || [],
        ...(isEdit && {
          _id: zoneSelected.id,
        }),
      },
    };
    if (isEdit) {
      return updateHubZonesMutation(params);
    }
    return createHubZonesMutation(params);
  };

  const confirmClose = () => {
    showAlert({
      ...alertParams.warnDark,
      title: 'Warning',
      description:
        'If you leave this page, unsaved changes will be lost.\nAre you sure you want to continue?',
      onOk: () => onClose(),
    });
  };

  const title =
    isEdit && zoneSelected.id
      ? 'Edit Zone'
      : isEdit
      ? 'Duplicate Zone'
      : 'Add Zone';

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => confirmClose()}
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create-zone',
          loading:
            isLoadingCreateHubZonesMutation || isLoadingUpdateHubZonesMutation,
        },
      ]}
      PaperProps={{
        sx: {
          maxWidth: 690,
        },
      }}
    >
      <Formik
        initialValues={
          isEdit
            ? zoneSelected
            : {
                name: '',
                delivery_fee: 0,
                delivery_minimum: 0,
                delivery_fee_type: 'fixed_amount',
                long_lat: [],
              }
        }
        onSubmit={onSubmit}
        validationSchema={AddZoneSchema}
        enableReinitialize
      >
        {({ values: { delivery_fee_type, long_lat }, setFieldValue }) => (
          <Form id='create-zone'>
            <DialogAddZoneForm
              defaultCenter={defaultCenter}
              delivery_fee_type={delivery_fee_type}
              long_lat={long_lat}
              setFieldValue={setFieldValue}
              drawnZoneMapRef={drawnZoneMapRef}
              missCoordinateErr={missCoordinateErr}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
