import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import themes, { styled } from '~/themes';
import { Icon } from '~/components/common/Icon';

type ComparePlansProps = {};

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.8),
  fontWeight: 'bold',
  width: 140,
  [theme.breakpoints.down('sm')]: {
    width: 80,
  },
  [theme.breakpoints.between('sm', 'ss')]: {
    width: 100,
  },
}));

export const StyledTableHeaderFirstCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.8),
  fontWeight: 'bold',
  width: 400,
  [theme.breakpoints.down('sm')]: {
    width: 80,
  },
  [theme.breakpoints.between('sm', 'ss')]: {
    width: 150,
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: theme.bg.midPurple,
  padding: 0,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.8),
}));

export const StyledCheckIcon = styled(Icon)(({ theme }) => ({
  width: `${theme.spacing(1.4)} !important`,
  height: `${theme.spacing(1)}!important`,
}));

export const ComparePlans: React.FC<ComparePlansProps> = () => {
  const createData = (
    name: string,
    started: string,
    standard: string,
    advanced: string,
  ) => ({ name, started, standard, advanced });

  const planInfoRows = [
    createData(
      'Included tasks',
      '50 tasks/mo',
      '2000 tasks/mo',
      '6000 tasks/mo',
    ),
    createData('Additional tasks', 'Not available', '$0.08/task', '$0.06/task'),
    createData('Number of drivers', '1', 'Unlimited', 'Unlimited'),
    createData('Number of users', '1', 'Unlimited', 'Unlimited'),
  ];

  const featureRows = [
    createData('eCommerce integration', '', '', ''),
    createData('Route optimization', '', '', ''),
    createData('Real-time delivery tracking', '', '', ''),
    createData('Booking & ordering forms', '', '', ''),
    createData('Shipping labels', '', '', ''),
    createData('POD - photo and signature capture', '', '', ''),
    createData('Automatic dispatcher notifications', '', '', ''),
    createData('Automatic task assignment', '', '', ''),
    createData('Task scheduling', '-', '', ''),
    createData('Barcode scanning', '-', '', ''),
    createData('Automatic customer notifications', '-', '', ''),
    createData('Realtime ETA notifications', '-', '', ''),
    createData('Geo-fencing and delivery zone management', '-', '', ''),
    createData('Advanced driver management', '-', '-', ''),
  ];

  const customerSupportRows = [
    createData('Email support', '', '', ''),
    createData('Chat support', '', '', ''),
    createData('Scheduled phone support', '-', '', ''),
  ];
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeaderFirstCell />
            <StyledTableHeaderCell align='center'>
              Starter
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align='center'>
              Standard
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align='center'>
              Advanced
            </StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {planInfoRows.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell align='center'>{row.started}</StyledTableCell>
              <StyledTableCell align='center'>{row.standard}</StyledTableCell>
              <StyledTableCell align='center'>{row.advanced}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Table sx={{ mt: 3 }}>
        <StyledTableHead>
          <TableRow>
            <StyledTableHeaderFirstCell>Features</StyledTableHeaderFirstCell>
            <StyledTableHeaderCell align='center' />
            <StyledTableHeaderCell align='center' />
            <StyledTableHeaderCell align='center' />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {featureRows.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell align='center'>
                {row.started.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.standard.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.advanced.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Table sx={{ mt: 3 }}>
        <StyledTableHead>
          <TableRow>
            <StyledTableHeaderFirstCell>
              Customer Support
            </StyledTableHeaderFirstCell>
            <StyledTableHeaderCell align='center' />
            <StyledTableHeaderCell align='center' />
            <StyledTableHeaderCell align='center' />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {customerSupportRows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component='th' scope='row'>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.started.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.standard.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.advanced.length ? (
                  <Typography
                    fontWeight='bold'
                    color={themes.color.gray900}
                    ml={0.5}
                  >
                    -
                  </Typography>
                ) : (
                  <StyledCheckIcon name='check' useBackgroundImg />
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
