import {
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import BreadCrumbs from '~/components/common/BreadCrumbs';
import { IBreadCrumbItem } from '~/components/common/BreadCrumbs/BreadCrumbs';
import { styled } from '~/themes';

type PageLayoutProps = {
  headerTitle?: string | React.ReactNode;
  isFistLoading?: boolean;
  isShowEmpty?: boolean;
  renderEmpty?: React.ReactNode;
  renderHeaderButtons?: React.ReactNode;
  renderTabs?: React.ReactNode;
  renderFirstLoading?: React.ReactNode;
  breadcrumbs?: IBreadCrumbItem[] | undefined;
  sxContainer?: SxProps;
  sxHeaderContainer?: SxProps;
  sxHeaderLeftContainer?: SxProps;
  sxContentContainer?: SxProps;
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    height: '100%',
  },
}));

const StyledHeaderContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  boxShadow: '0px 1px 6px #160E4D14',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: theme.zIndex.drawer - 1,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1.5),
  },
}));

const StyledHeaderLeftContainer = styled(Box)(() => ({}));

export const StyledContentContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  height: '100%',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1.5),
  },
}));

export const StyledFirstLoadingContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledFirstLoading = styled(CircularProgress)(() => ({}));

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const {
    renderHeaderButtons,
    renderEmpty,
    renderFirstLoading,
    renderTabs,
    headerTitle,
    breadcrumbs,
    isShowEmpty = false,
    isFistLoading = false,
    sxContainer,
    sxHeaderContainer,
    sxHeaderLeftContainer,
    sxContentContainer,
    children,
  } = props;

  const renderContent = useMemo(() => {
    if (isFistLoading) {
      return (
        <StyledFirstLoadingContainer>
          {renderFirstLoading || <StyledFirstLoading />}
        </StyledFirstLoadingContainer>
      );
    }

    if (isShowEmpty) {
      return renderEmpty || <Typography>No Data</Typography>;
    }
    return (
      <StyledContentContainer
        className='customized-scrollbar'
        sx={{ ...sxContentContainer }}
      >
        {children}
      </StyledContentContainer>
    );
  }, [isFistLoading, isShowEmpty, renderEmpty, children]);
  return (
    <StyledContainer sx={{ ...sxContainer }}>
      <StyledHeaderContainer
        sx={{
          ...(renderTabs && {
            paddingBottom: 0,
          }),
          ...sxHeaderContainer,
        }}
      >
        <StyledHeaderLeftContainer sx={{ ...sxHeaderLeftContainer }}>
          {breadcrumbs ? <BreadCrumbs list={breadcrumbs} /> : null}
          {typeof headerTitle === 'string' ? (
            <Typography variant='h2'>{headerTitle}</Typography>
          ) : (
            headerTitle
          )}
          {renderTabs}
        </StyledHeaderLeftContainer>
        {renderHeaderButtons}
      </StyledHeaderContainer>
      {renderContent}
    </StyledContainer>
  );
};
