export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Dispatcher = 'dispatcher',
  Driver = 'driver',
  ApiKey = 'api_key',
}

export enum UserExpand {
  Live = 'live', // to get live fields: tasks, alerting
  Team = 'team',
}

export enum UserStatus {
  Pending = 'pending',
  Active = 'active',
  Deactivated = 'deactivated',
}
