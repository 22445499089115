import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import theme from '~/themes';
import CustomAlert, { IAlert } from './Alert';

const WrapperAlert = (props: IAlert) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [props]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CustomAlert open={open} onClose={onClose} {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const container = document.getElementById('alert');
const root = createRoot(container);
const showAlert = (props: IAlert) => root.render(<WrapperAlert {...props} />);

export default showAlert;
