function supportsLocalStorage() {
  return typeof Storage !== 'undefined';
}

export const LOCAL_STORAGE_KEYS = {
  SHOPIFY_REDIRECT_STORE_URL: 'shopify_redirect_store_url',
  FCM_REGISTRATION_ID: 'fcm_registration_id',
  SAVE_TASK_FORM: 'save_task_form',
};

export const set = (key: string, value: any) => {
  if (supportsLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export function clearItem(key: string) {
  window.localStorage.removeItem(key);
}

export const get = (key: string) => {
  if (supportsLocalStorage()) {
    try {
      const value = JSON.parse(window.localStorage.getItem(key) || '');
      return value;
    } catch (e) {
      clearItem(key);
      return null;
    }
  }
  return undefined;
};

export const clearAll = () => window.localStorage.clear();

export const KEYS = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  ORG_ID: 'org_id',
  ITEMS_PER_PAGE: 'items_per_page',
  TIMEZONE: 'timezone',
  ADMIN_ID: 'id',
  SSO: 'sso',
};

let orgId;
let idToken;
let countOfShowFailPaymentDialog = 0;
export const memoryStorage = {
  getOrgId: () => orgId,
  setOrgId: (v) => {
    orgId = v;
  },
  getIdToken: () => idToken,
  setIdToken: (v) => {
    idToken = v;
  },
  setCountOfShowFailPaymentDialog: () => {
    countOfShowFailPaymentDialog += 1;
  },
  getCountOfShowFailPaymentDialog: () => countOfShowFailPaymentDialog,
};
