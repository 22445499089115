import { createStyles } from '@mui/styles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: theme.spacing(2),
      margin: theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(4)})`,
      color: theme.color.black,
    },
    rootDialog: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      margin: 0,
      padding: theme.spacing(2),
      color: theme.color.violet900,
      borderBottom: `1px solid ${theme.color.violet300}`,
      marginBottom: theme.spacing(2),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      'width': '50%',
      'margin': 0,
      'borderRadius': 0,
      'height': '100%',
      'fontSize': 14,
      '& .MuiButton-root': {
        maxHeight: 'none',
      },
    },
    content: {
      padding: theme.spacing(0, 2, 3, 2),
      position: 'relative',
    },
    actions: {
      minHeight: 50,
      padding: 0,
      flex: 0,
      alignItems: 'inherit',
    },
    containerActions: {
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2.5),
    },
    closeIcon: {
      width: theme.spacing(1.4),
      height: theme.spacing(1.4),
      color: theme.color.violet900,
    },
    fullButton: {
      'width': '100%',
      'margin': 0,
      'borderRadius': 0,
      'height': '100%',
      'fontSize': 14,
      '& .MuiButton-root': {
        maxHeight: 'none',
      },
    },
    subTitle: {
      marginTop: theme.spacing(0.5),
    },
  });

export default style;
