import { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  generateCustomToken,
  generateCustomTokenSSO,
  logRequestSignIn,
  useVerifyAccount,
} from '~/services/api/auth';
import PublicLayout from '~/layout/PublicLayout';
import { fromQueryString } from '~/utils/queryString';
import RegisterStepper from '~/components/common/RegisterStepper';
import { styled } from '~/themes';
import { getCurrentUser, signInWCustomToken } from '~/services/firebase';
import Cookies from 'js-cookie';
import {
  CookieOptions,
  CUSTOM_TOKEN_COOKIES_KEY,
  RF_CUSTOM_TOKEN_COOKIES_KEY,
} from '~/constants/auth';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9,
  background: 'rgba(256, 256, 256, 0.7)',
}));

export const EmailVerified = () => {
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = fromQueryString(location.search);
  const [loading, setLoading] = useState(true);

  const { mutate: verifyAccountMutation, isError } = useVerifyAccount({
    onSuccess: async ({ custom_token }) => {
      await signInWCustomToken(custom_token);
      const user: any = await getCurrentUser();

      await logRequestSignIn();

      if (isNewSSO) await generateCustomTokenSSO();
      else {
        const stsTokenManager = user?.stsTokenManager || {};
        const tokenResult = await generateCustomToken(
          stsTokenManager.refreshToken,
        );
        Cookies.set(CUSTOM_TOKEN_COOKIES_KEY, tokenResult?.custom_token, {
          ...CookieOptions,
        });
        Cookies.set(
          RF_CUSTOM_TOKEN_COOKIES_KEY,
          tokenResult?.refresh_token,
          CookieOptions,
        );
      }

      setLoading(false);
      channel.postMessage({
        type: BroadcastEvents.RefreshOtherPageWhenLogged,
      });
      setTimeout(() => {
        window.location.href = '/user-onboarding';
      }, 5000);
    },
    onError: () => {
      navigate('/login');
    },
  });

  useEffect(() => {
    if (code) {
      verifyAccountMutation(code as string);
    }
  }, [code]);

  if (loading || isError)
    return (
      <PublicLayout>
        <Grid container columnSpacing={6} spacing={1.5} mt={3}>
          <StyledLoading>
            <CircularProgress size={30} />
          </StyledLoading>
          <Grid item xs={12} md={10}>
            <RegisterStepper activeStep={1} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h1'>Email Verifying</Typography>
            <Typography variant='body1' mt={3}>
              Please wait while we take you to the next step.
            </Typography>
          </Grid>
        </Grid>
      </PublicLayout>
    );

  return (
    <PublicLayout>
      <Grid container columnSpacing={6} spacing={1.5} mt={3}>
        <Grid item xs={12} md={10}>
          <RegisterStepper activeStep={1} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h1'>Email Verified</Typography>
          <Typography variant='body1' mt={3}>
            Please wait while we take you to the next step.
          </Typography>
          <Typography variant='body1' mt={3}>
            If your browser doesn&apos;t automatically refresh,{' '}
            <a href='/user-onboarding'>click here</a>.
          </Typography>
        </Grid>
      </Grid>
    </PublicLayout>
  );
};
