import { getIn } from 'formik';
import React from 'react';

import { IFormProps } from '~/models/common';
import DateSelectorRaw, {
  IDateSelectorProps,
} from '~/components/common/DateSelector/DateSelector';

export interface IFieldDateSelector
  extends IFormProps,
    Omit<IDateSelectorProps, 'onApply'> {
  disabled?: boolean;
  showIcon?: boolean;
  legend?: string;
  invalidDays?: string[];
  placeholder?: string;
  dateFormat?: boolean;
  timeFormat?: boolean;
  disablePastDate?: boolean;
  onApply?: (value?: any, val?: any) => void;
}

const FieldDateSelector: React.FC<IFieldDateSelector> = ({
  field,
  form,
  disabled = false,
  showIcon = false,
  placeholder,
  legend,
  dateFormat,
  timeFormat,
  className,
  disablePastDate,
  invalidDays,
  onApply,
}) => (
  <DateSelectorRaw
    id={field.name}
    className={className}
    disabled={disabled}
    showIcon={showIcon}
    legend={legend}
    placeholder={placeholder}
    dateFormat={dateFormat}
    timeFormat={timeFormat}
    disablePastDate={disablePastDate}
    invalidDays={invalidDays}
    onApply={(val) => {
      form.setFieldValue(field.name, val);
      setTimeout(
        () => form.setTouched({ ...form.touched, [field.name]: true }),
        0,
      );
      if (onApply) {
        onApply(form.values, val);
      }
    }}
    error={
      !!getIn(form.touched, field.name) && !!getIn(form.errors, field.name)
    }
    helperText={
      Boolean(getIn(form.touched, field.name)) && getIn(form.errors, field.name)
    }
    formValue={getIn(form.values, field.name)}
  />
);

export default FieldDateSelector;
