import React from 'react';
import { getIn } from 'formik';
import {
  TimeSelector as TimeSelectorRaw,
  TimeSelectorProps,
} from '~/components/common/TimeSelector';
import { IFormProps } from '~/models/common';

export interface IFormTimeSelector
  extends IFormProps,
    Omit<TimeSelectorProps, 'name' | 'field' | 'form'> {}

const TimeSelector: React.FC<IFormTimeSelector> = ({
  field,
  form,
  error,
  helperText,
  onChange,
  ...props
}) => (
  <TimeSelectorRaw
    id={field.name}
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      helperText ||
      (Boolean(getIn(form.touched, field.name)) &&
        getIn(form.errors, field.name))
    }
    value={field.value ?? ''}
    onChange={(value: any) => {
      form.setFieldValue(field.name, value);
      if (onChange) {
        onChange(value);
      }
    }}
    {...props}
  />
);

export default TimeSelector;
