import { useContext, createContext, useMemo, useState, useEffect } from 'react';
import { IOrg } from '~/models/org';
import { IPlan, IPlanTier } from '~/models/plan';
import { useGetOrgQuery } from '~/services/api/org';
import { useGetPlans } from '~/services/api/plan';
import { PENDO_KEY } from '~/config';
import { initializePendo } from '~/utils/pendo';
import { useAuth } from './AuthProvider';
import { growthbook } from './AppProvider';

export interface PlanContextProps {
  org: IOrg;
  plans: IPlan[];
  plansOrigin: IPlan[];
  currentPlan: IPlan;
  isFetchingOrg: boolean;
  isFetchingPlans: boolean;
  isStandardPlan: boolean;
  isAdvancePlan: boolean;
  isStarterPlan: boolean;
  isStandardOrAdvancePlan: boolean;
  isStarterOrStandardPlan: boolean;
  isOpenUpSellDialog: boolean;
  additionalTask: number;
  setIsOpenUpSellDialog: (v: boolean) => void;
  isBundlePlan: boolean;
}
export const PaymentContext = createContext({} as PlanContextProps);

const PaymentProvider = ({ children }) => {
  const { account } = useAuth();
  const [isOpenUpSellDialog, setIsOpenUpSellDialog] = useState(false);
  const { data: org, isFetching: isFetchingOrg } = useGetOrgQuery({
    enabled: true,
  });
  const { data: plans, isFetching: isFetchingPlans } = useGetPlans({});
  const plansByCountry = plans.filter((p) => {
    const countryOfOrg = org.country;
    return p.tier === IPlanTier.Free || p.country === countryOfOrg;
  });

  const currentPlan: IPlan = useMemo(() => {
    if (org?.price_id && plansByCountry.length > 1) {
      const plan = plansByCountry.find((p) => p.price_id === org?.price_id);
      return plan || { tier: 0 };
    }
    return plans.find((p) => p.tier === IPlanTier.Free);
  }, [plansByCountry, org]);

  const isStandardPlan = currentPlan.tier === IPlanTier.Standard;
  const isAdvancePlan = currentPlan.tier === IPlanTier.Advance;
  const isStandardOrAdvancePlan =
    currentPlan.tier === IPlanTier.Standard ||
    currentPlan.tier === IPlanTier.Advance;
  const isStarterOrStandardPlan =
    currentPlan.tier === IPlanTier.Standard ||
    currentPlan.tier === IPlanTier.Free;
  const isStarterPlan = currentPlan.tier === IPlanTier.Free;
  const isBundlePlan =
    currentPlan.tier === IPlanTier.Advance && !org?.subscription_id;

  const additionalTask: number = useMemo(() => {
    if (
      !org.monthly_created_task ||
      org.monthly_created_task - org.limit_task < 0
    )
      return 0;
    return org.monthly_created_task - org.limit_task;
  }, [org]);

  useEffect(() => {
    // growthBook - set user attributes for targeting
    growthbook.setAttributes({
      id: account?.id,
      email: account?.email,
      role: account?.role,
      orgId: account?.org_id,
      currentPlanState: org.grace_period ? 'closed' : 'ongoing',
      currentPlanName: currentPlan.name,
    });

    if (PENDO_KEY && org.id) {
      // only run on production
      initializePendo(account, org, currentPlan);
    }
  }, [account, org]);

  const values = useMemo(
    () => ({
      org,
      plansOrigin: plans,
      plans: plansByCountry,
      currentPlan,
      isFetchingOrg,
      isFetchingPlans,
      isStandardPlan,
      isAdvancePlan,
      isStarterPlan,
      isStandardOrAdvancePlan,
      isStarterOrStandardPlan,
      isOpenUpSellDialog,
      additionalTask,
      setIsOpenUpSellDialog,
      isBundlePlan,
    }),
    [
      org,
      plans,
      plansByCountry,
      currentPlan,
      isFetchingOrg,
      isFetchingPlans,
      isStandardPlan,
      isAdvancePlan,
      isStarterPlan,
      isStandardOrAdvancePlan,
      isStarterOrStandardPlan,
      isOpenUpSellDialog,
      additionalTask,
      setIsOpenUpSellDialog,
      isBundlePlan,
    ],
  );

  return (
    <PaymentContext.Provider value={values}>{children}</PaymentContext.Provider>
  );
};

const usePaymentInfo = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error(
      'Components cannot be rendered outside the Payment Context',
    );
  }
  return context;
};

export { PaymentProvider, usePaymentInfo };
