import { useContext } from 'react';
import { LiveMapContext } from '../context/LiveMapContext';

export const useLiveMapContext = () => {
  const context = useContext(LiveMapContext);
  if (context === undefined) {
    throw new Error('Component should be in LiveMapContext ');
  }
  return context;
};
