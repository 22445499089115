import { Moment } from 'moment';
import { IntegrationType } from '~/models/stores';
import { UserStatus } from '../AdminOptions/Users/types';

export enum MarkerType {
  DropOff = 'dropOff',
  Driver = 'driver',
  Store = 'store',
  Return = 'return',
}

export type MapBoundsPadding = {
  DEFAULT: google.maps.Padding;
  DRIVER_ROUTES: google.maps.Padding;
  TASK_ROUTES: google.maps.Padding;
};

export interface LocationInfo {
  id: string;
  task_id: string;
  task_name: string;
  marker_type: MarkerType;
  name?: string;
  phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  country?: string;
  postcode?: string;
  email?: string;
  lng?: number;
  lat?: number;
  country_code?: string;
}

export interface WaybillAddress
  extends Omit<LocationInfo, 'id' | 'task_id' | 'task_name' | 'marker_type'> {
  postal_code?: string;
  phone_number?: string;
}

export enum TaskStatus {
  PendingPickup = 'pending_pickup',
  Delivering = 'delivering',
  Returning = 'returning',
  Completed = 'completed',
  Returned = 'returned',
  Cancelled = 'cancelled',
  Unassigned = 'unassigned',
}

export interface Task {
  returnLabelInfo?: WaybillAddress;
  expected_delivery_before?: string;
  expected_delivery_after?: string;
  pickup_hub_id?: string;
  pickup?: LocationInfo;
  delivery?: LocationInfo;
  barcode?: string;
  note?: string;
  delivery_index?: number;
  pickup_index?: number;
  return_index?: null;
  skip_pickup?: boolean;
  contactless_delivery?: boolean;
  executor_type?: string;
  executor_id?: string;
  executor_org_id?: string;
  assigned_at?: string;
  assigned_by?: string;
  first_assigned_at?: string;
  first_assigned_by?: string;
  created_org_id?: string;
  merchant_org_id?: string;
  number?: number;
  name?: string;
  tracking_url?: string;
  created_at?: string;
  created_by?: string;
  feed_back?: string;
  updated_at?: string;
  status?: TaskStatus;
  clean_pickup_hub_id?: boolean;
  is_alerted?: boolean;
  pickup_arr_time?: number;
  delivery_arr_time?: number;
  return_arr_time?: number;
  out_for_delivery_time?: string;
  rating?: number;
  manual_route?: boolean;
  executor?: Executor;
  id?: string;
  isDivider?: boolean;
  integration_type?: IntegrationType;
  picked_up_details?: {
    photos: string[];
    barcode_scanned?: boolean;
    signature?: string;
  };
  returned_details?: {
    photos: string[];
    barcode_scanned?: boolean;
    signature?: string;
  };
  delivered_details?: {
    photos: string[];
    barcode_scanned?: boolean;
    signature?: string;
  };
  estimated_delivery_time?: number;
  delivered_at?: string;
  metadata?: {
    bs_order_id?: string;
    package_weight?: number;
    return_address?: WaybillAddress;
    waybill_address?: WaybillAddress;
  };
  require_pickup_proofs?: boolean;
  require_delivery_capture?: boolean;
  require_delivery_signature?: boolean;
  is_active?: boolean;
  picked_up_at?: string;
  completed_at?: string;
  returned_at?: string;
}

export interface Executor {
  display_name?: string;
  phone?: string;
  driver_status?: DriverStatus;
  avatar?: string;
  lat?: number;
  lng?: number;
  id?: string;
}

export type ITaskResponse = {
  items: Task[];
  total_items?: number;
  total_pages?: number;
  not_done_list?: number;
  done_list?: number;
};

export enum DriverStatus {
  Idle = 'idle',
  Online = 'online',
  Offline = 'offline',
}

export interface Driver {
  first_name?: string;
  last_name?: string;
  display_name?: string;
  phone?: string;
  email?: string;
  username?: string;
  org_id?: string;
  role?: string;
  default?: boolean;
  status?: UserStatus;
  timezone?: string;
  driver_status?: DriverStatus;
  invited_by?: string;
  invited_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  accepted_invitation_at?: Date;
  vehicle?: DriverVehicle;
  avatar?: string;
  avatar_type?: string;
  last_location_at?: Date;
  lat?: number;
  lng?: number;
  address?: DriverAddress;
  customer_support?: string;
  dob?: string;
  driver_first_time_login?: boolean;
  migrate_to_bs?: boolean;
  username_old_fb?: string;
  tasks?: any[];
  total_task?: number;
  in_hand_task?: number;
  is_alerted?: boolean;
  eta_alerted?: boolean;
  id?: string;
  idle_at?: string;
}

export interface DriverAddress {
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  country?: string;
  postcode?: string;
}

export interface DriverVehicle {
  type?: string;
  make_model?: string;
  color?: string;
  licence_plate?: string;
  model?: string;
  year?: string;
  insurance_country?: string;
  insurance_state?: string;
}

export interface ITaskLog {
  task_id?: string;
  type?: string;
  created_at?: string;
  created_by?: TaskLogCreatedBy;
  log?: string;
  id?: string;
  is_created_by_system?: boolean;
}

export interface TaskLogCreatedBy {
  display_name?: string;
  avatar_type?: string;
  role?: string;
  avatar?: string;
  id?: string;
}

export enum TaskStatusDeliveringLongTime {
  Delivering = 'delivering-long-time',
}

export interface IDriverRouteResponse {
  pickup?: LocationInfo | null;
  delivery?: LocationInfo;
  delivery_index?: number;
  pickup_index?: number | null;
  return_index?: null;
  name?: string;
  tracking_url?: string;
  status?: string;
  delivery_arr_time?: number;
  id?: string;
  expected_delivery_after?: Date | string;
  expected_delivery_before?: Date | string;
  pickup_arr_time?: number;
  return_arr_time?: number;
  estimated_delivery_time?: number;
  out_for_delivery_time?: Date;
  pickup_alerted?: boolean;
  delivery_alerted?: boolean;
  arr_time?: number;
  is_active?: boolean;
}

export interface IDriverRoute extends LocationInfo {
  expected_delivery_after?: string;
  expected_delivery_before?: string;
  sort_id?: number;
  is_alerted?: boolean;
  update_sort_id?: number;
  eta_time?: number;
  task_status: TaskStatus;
  is_disabled?: boolean;
}

export interface IDriverRouteMap {
  infoWindow: google.maps.InfoWindow;
  marker?: google.maps.marker.AdvancedMarkerElement;
  routes: IDriverRoute[];
  waypoints: google.maps.DirectionsWaypoint;
  position: google.maps.LatLngLiteral;
  waypointSortId: number;
  isExpanded?: boolean;
}

export interface ITaskAddress
  extends Omit<LocationInfo, 'id' | 'task_id' | 'task_name' | 'marker_type'> {
  country_code?: string;
  save_to_address_book?: boolean;
  address_book?: any;
}
export interface IAddTaskData {
  id?: string;
  pickup: ITaskAddress;
  delivery: ITaskAddress;
  skip_pickup: boolean;
  note?: string;
  require_pickup_proofs: boolean;
  require_delivery_capture: boolean;
  require_delivery_signature: boolean;
  expected_delivery_after?: Moment | string | null;
  expected_delivery_before?: Moment | string | null;
  expected_delivery_date?: string;
}

export interface IAllAddressBook extends LocationInfo {
  store_name?: string;
  metadata?: any;
  email?: string;
}
