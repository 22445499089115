/* eslint-disable react/no-unescaped-entities */
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { Stack, Typography } from '@mui/material';
import BackgroundImage from '~/assets/images/background/setup-task.png';
import Button from '~/components/common/Button';

const style = (theme: Theme) =>
  createStyles({
    containerClass: {
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      '& > img:first-child': {
        maxHeight: 200,
      },
    },
    title: {
      marginTop: theme.spacing(3),
      fontSize: 22,
      paddingTop: 0,
    },

    desc: {
      color: theme.color.violet900,
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      containerClass: {
        '& > img:first-child': {
          maxWidth: '100%',
        },
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  setOpenCreateTask?: (value: boolean) => void;
  setOpenImportTask?: (value: boolean) => void;
}

const SetupYourTask: React.FC<IProps> = ({
  classes,
  setOpenCreateTask,
  setOpenImportTask,
}) => (
  <div className={classes.containerClass}>
    <img src={BackgroundImage} alt='setup-your-store' />
    <Typography variant='h1' className={classes.title}>
      Create Delivery Task
    </Typography>
    <Typography className={classes.desc}>
      Easily create a delivery task and assign it to your driver! You can then
      monitor and
      <br />
      manage the entire delivery process until the task is successfully
      completed.
    </Typography>
    <Stack direction='row' spacing={1} mt={2}>
      <Button
        buttonType='default'
        onClick={() => {
          setOpenImportTask(true);
        }}
      >
        Import Tasks
      </Button>
      <Button buttonType='primary' onClick={() => setOpenCreateTask(true)}>
        Create Task
      </Button>
    </Stack>
  </div>
);

export default withStyles(style)(SetupYourTask);
