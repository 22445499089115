import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { toQueryString, cleanDeep } from '~/utils/queryString';
import { IAddressResponse } from '~/models/address';
import { queryClient } from '~/lib/react-query';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getAddresses = (params: {
  [key: string]: any;
}): Promise<IAddressResponse> => {
  cleanDeep(params);
  return httpClient.json.get(
    `${endpoints.address.address}?${toQueryString(params, {
      arrayFormat: 'none',
    })}`,
  );
};

export const createAddress = (params) =>
  httpClient.json.post(endpoints.address.address, params);

export const bulkCreateAddress = (params) =>
  httpClient.json.post(endpoints.address.bulkCreateAddress, params);

export const updateAddress = (params) =>
  httpClient.json.put(`${endpoints.address.address}/${params.id}`, params);
export const deleteAddress = (id: string) =>
  httpClient.json.delete(`${endpoints.address.address}/${id}`);

export const useGetAddressQuery = ({
  params,
  onSuccess,
  enabled = true,
}: any) =>
  useQuery({
    queryKey: [queryKeys.address.getAddress, params],
    queryFn: () => getAddresses(params),
    initialData: () =>
      queryClient.getQueryData([
        queryKeys.address.getAddress,
        params,
      ]) as IAddressResponse,
    keepPreviousData: true,
    cacheTime: 1 * 10 * 1000,
    onSuccess,
    enabled,
  });

export const useCreateAddressMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: createAddress,
    onSuccess,
    onError,
  });

export const useUpdateAddressMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateAddress,
    onSuccess,
    onError,
  });

export const useDeleteAddressMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: deleteAddress,
    onSuccess,
    onError,
  });

export const useBulkCreateAddressMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: bulkCreateAddress,
    onSuccess,
    onError,
  });
