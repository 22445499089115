/**
 * HttpClient is a wrapper around axios that provides easier interceptor management
 * It follows axios API so you can do all things axios does.
 *
 * Usage:
 * - Lets say you want to sent a JSON request
 * ```
 * httpClient.json.get(url)
 * httpClient.json.post(url, data)
 * ```
 *
 * In case you want to overwrite some config like `baseURL`
 * ```
 * httpClient.json.get(url, { baseURL: 'https://your-custom-base-url' })
 * ```
 *
 * Do the same for formData request
 * ```
 * httpClient.formData.post(url, formData)
 * ```
 *
 * Why need separate `formData` and `json` into classes.
 * It's because we could add util helpers layer on top each Content-Type
 * such as providing a formData conversion utils. See example below
 * ```
 * // You could add a utils like this to formData
 * const formDataRequest = (req) => {
 *   const formData = new FormData()
 *   Object.entries(req).reduce((data, [key, val]) => {
 *     data.append(key, value)
 *     return data
 *   })
 * }
 *
 * // With that we could pass data request to formData request lke normal object
 * httpClient.formData.post(url, { name: 'Nam', sex: 'Male' })
 * ```
 */
import HttpJson from '../http-client/json';
import HttpFormData from '../http-client/form-data';
import HttpFullRes from '../http-client/full-res';

const httpJson = HttpJson.create();
const httpFormData = HttpFormData.create();
const httpFullRes = HttpFullRes.create();

const httpClient = {
  json: httpJson,
  formData: httpFormData,
  fullRes: httpFullRes,
};

export default httpClient;
