import { LocationInfo } from '../types';

export interface IBaseMarker {
  id: string;
  marker: google.maps.marker.AdvancedMarkerElement | null;
  locations?: LocationInfo[];
}

export class BaseMarkers {
  private markers: {
    id: string;
    locations?: LocationInfo[];
    marker: google.maps.marker.AdvancedMarkerElement | null;
  }[];

  private map: google.maps.Map | null;

  constructor(map: google.maps.Map | null) {
    this.markers = [];
    this.map = map;
  }

  add(data: IBaseMarker) {
    this.markers = [...this.markers, data];
  }

  get(id: string) {
    return this.markers.find((e) => e.id === id);
  }

  getAll() {
    return this.markers;
  }

  hideAll() {
    this.markers.forEach(({ marker }) => {
      marker.map = null;
    });
  }

  hideById(id: string) {
    const baseMarker = this.markers.find((e) => e.id === id);
    if (baseMarker) {
      baseMarker.marker.map = null;
    }
  }

  showAll() {
    this.markers.forEach(({ marker }) => {
      marker.map = this.map;
    });
  }

  update(id: string, updateData: IBaseMarker) {
    return this.markers.map((e) => (e.id === id ? updateData : e));
  }

  updateContent(id: string, content: Node, isShow = true) {
    const { marker } = this.get(id);
    marker.content = content;
    marker.map = isShow ? this.map : null;
  }

  // bulkUpdate() {}

  remove(id: string) {
    this.hideById(id);
    this.markers = this.markers.filter((e) => e.id !== id);
    return this.markers;
  }

  removeAll() {
    this.markers = [];
  }
}
