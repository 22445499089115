import { styled } from '@mui/material';
import { Form, Formik } from 'formik';
import { Field } from '~/components/common/Formik';
import Modal from '~/components/common/Modal';
import Tooltip, { TooltipPasswordContent } from '~/components/common/Tooltip';
import { ChangePwdSchema } from '~/utils/schema/auth';
import InfoIcon from '~/assets/images/icons/info.svg';
import { Stack } from '@mui/system';
import { LinkButton } from '~/components/common/Button';
import { useChangePwd, useLogout } from '~/services/api/auth';
import { useAuth } from '~/providers/AuthProvider';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { formatFirebaseError } from '~/services/firebase';

const StyledModal = styled(Modal)(() => ({
  maxWidth: 370,
}));

const StyledImage = styled('img')(({ theme }) => ({
  marginTop: 30,
  marginRight: theme.spacing(-3),
  marginLeft: theme.spacing(1),
}));

const StyledForm = styled(Form)(({ theme }) => ({
  'width': 'calc(100% - 30px)',
  '& > div': {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

const useStyles = makeStyles(() => ({
  tooltipContent: {
    width: 220,
  },
}));
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePwdModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { account } = useAuth();
  const classes = useStyles();

  const formikIndex = isOpen ? 1 : 0;

  const { mutate: logoutMutation } = useLogout();

  const {
    mutate: inviteUserMutation,
    isLoading,
    isError,
    error,
  } = useChangePwd({
    onSuccess: () => {
      onClose();
      logoutMutation(null, {
        onSuccess: () => {
          window.location.reload();
        },
      });
    },
    onError: () => {},
  });

  const errorMsg = useMemo(() => {
    if (error) return formatFirebaseError(error);
    return '';
  }, [error]);

  const handleForgotPassword = () => {
    logoutMutation(null, {
      onSuccess: () => {
        window.location.href = '/reset-password';
      },
    });
  };

  const onSubmit = async (formData) => {
    inviteUserMutation({
      ...formData,
      email: account?.email || '',
    });
  };

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        newPassword: '',
      }}
      onSubmit={onSubmit}
      validationSchema={ChangePwdSchema}
      key={formikIndex}
    >
      {({ isValid, dirty }) => (
        <StyledModal
          title='Change Password'
          open={isOpen}
          onClose={onClose}
          disableCloseOutside
          actions={[
            {
              title: 'Cancel',
              onClick: () => onClose(),
              buttonType: 'default',
            },
            {
              title: 'Save',
              buttonType: 'primary',
              type: 'submit',
              form: 'change-pwd',
              loading: isLoading,
              disabled: !isValid || !dirty,
            },
          ]}
        >
          <StyledForm
            id='change-pwd'
            onKeyPress={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <Field.TextInput
              name='password'
              legend='Current Password'
              type='password'
              error={isError}
              helperText={errorMsg || ''}
            />
            <Stack width='100%' alignItems='flex-end' mt={-1}>
              <LinkButton onClick={handleForgotPassword}>
                Forgot Password?
              </LinkButton>
            </Stack>
            <Field.TextInput
              name='newPassword'
              legend='New Password'
              type='password'
            />
            <Tooltip
              title={<TooltipPasswordContent />}
              sx={{
                cursor: 'pointer',
              }}
              classes={{ tooltip: classes.tooltipContent }}
            >
              <StyledImage width={20} height={20} src={InfoIcon} alt='info' />
            </Tooltip>
            <Field.TextInput
              name='confirmPassword'
              legend='Confirm New Password'
              type='password'
            />
          </StyledForm>
        </StyledModal>
      )}
    </Formik>
  );
};

export default ChangePwdModal;
