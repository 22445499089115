import { Form, Formik } from 'formik';
import { Theme, styled } from '~/themes';
import {
  GENERAL_SETTING_DEFAULT,
  TIMEZONE_SETTING,
} from '~/constants/adminOptions';
import Loading from '~/components/common/CirularLoading';
import Button from '~/components/common/Button';
import { IAccount } from '~/models/adminOptions';
import { alertParams, showAlert } from '~/components/common/Alert';
import { useAuth } from '~/providers/AuthProvider';
import { useGetTimezoneQuery } from '~/services/api/userManagement';
import { useUpdateGeneralSettingsMutation } from '~/services/api/shared';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { isObjectEqual } from '~/utils/common';
import omit from 'lodash/omit';
import { Stack } from '@mui/material';
import { ChangeAccountSchema } from '~/utils/schema/user';
import { GeneralContent } from './components/GeneralContent';

const Container = styled(Stack)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  position: 'relative',
  boxShadow: '0px 1px 6px #160E4D1A',
  borderRadius: theme.spacing(2),
  background: theme.color.white,
}));

const FormContainer = styled(Form)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(5, 5, 5, 5),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(3, 3, 3, 3),
  },
}));

const SaveButton = styled(Button)(() => ({
  width: 150,
}));

const ButtonContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  paddingTop: theme.spacing(3),
  background: theme.bg.white,
  borderTop: `1px solid ${theme.bg.midPurple}`,
}));

type IProps = {};

const General: React.FC<IProps> = () => {
  const { account, isFetchingAccounts } = useAuth();
  const { data: dataTimezone, isLoading: isLoadingTimezone } =
    useGetTimezoneQuery();

  const { mutate: updateGeneralSettings, isLoading: loadingUpdate } =
    useUpdateGeneralSettingsMutation({
      onSuccess: () => {
        showAlert({
          ...alertParams.success,
          closeOnClick: true,
        });
      },
      onError: (err) => {
        showAlert(errorAlert(err));
      },
    });

  const onSubmit = ({
    id,
    first_name: _,
    last_name: __,
    timezoneType,
    timezone,
    ...values
  }: IAccount) => {
    delete values.tooltip;
    updateGeneralSettings({
      body: {
        ...values,
        timezone:
          timezoneType === TIMEZONE_SETTING.AUTO_DETECT
            ? TIMEZONE_SETTING.AUTO_DETECT
            : timezone,
      },
    });
  };

  return (
    <Container>
      <Formik
        initialValues={
          account
            ? {
                ...account,
                timezoneType:
                  account?.timezone === TIMEZONE_SETTING.AUTO_DETECT
                    ? TIMEZONE_SETTING.AUTO_DETECT
                    : TIMEZONE_SETTING.MANUALLY,
              }
            : GENERAL_SETTING_DEFAULT
        }
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={ChangeAccountSchema}
      >
        {({ values }) => (
          <FormContainer>
            <Loading loading={isFetchingAccounts} />
            <GeneralContent
              dataTimezone={dataTimezone}
              isLoadingTimezone={isLoadingTimezone}
            />
            <ButtonContainer>
              <SaveButton
                buttonType='primary'
                type='submit'
                loading={loadingUpdate}
                disabled={isObjectEqual(
                  omit(values, ['avatar']),
                  omit(account || GENERAL_SETTING_DEFAULT, ['avatar']),
                )}
              >
                Save
              </SaveButton>
            </ButtonContainer>
          </FormContainer>
        )}
      </Formik>
    </Container>
  );
};

export default General;
