/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Box,
  Menu,
  MenuItem as MuiMenuItem,
  ThemeProvider,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  NavList,
  SideBar,
  MenuItem,
  MenuList,
  HeaderMobile,
  NavItem,
  MenuSelect as UnifiedMenuSelect,
  Avatar as UnifiedAvatar,
  theme as unifiedTheme,
} from 'bs-unified-ui';
import { useCallback, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  LOCAL_STORAGE_KEYS,
  get,
  clearItem,
  memoryStorage,
} from '~/utils/localStorage';
import { useLogout } from '~/services/api/auth';
import {
  removeDefaultOrg,
  updateDefaultOrg,
  useCreateNotiSubscribeMutation,
} from '~/services/api/shared';
import { popoverClasses } from '@mui/material/Popover';
import { useAuth } from '~/providers/AuthProvider';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { styled } from '~/themes';
import LogoutIcon from '~/assets/images/icons/logout.svg';
import { Stack } from '@mui/system';
import { convertShortString } from '~/utils/formatter';
import TickIcon from '~/assets/images/icons/selected-menu.svg';
import AddIcon from '~/assets/images/icons/add-workspace.svg';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TierUsage } from './components';

type UnifiedNavbarProps = {};

export const StyledWsMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  'height': theme.spacing(4),
  'borderRadius': '6px',
  '&.MuiMenuItem-root.Mui-selected': {
    background: '#E4E8F0',
  },
  '&.Mui-selected:hover': {
    background: '#E4E8F0 !important',
  },
}));

export const UnifiedNavbar: React.FC<UnifiedNavbarProps> = () => {
  const navigate = useNavigate();
  const { accounts, account, unifiedNavigation } = useAuth();
  const isCreateWorkspace = useFeatureIsOn('cf-create-workspace-internally');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutate: logoutMutation } = useLogout({
    onSuccess: () => {
      window.location.href = '/login';
    },
  });

  const orgs = useMemo(
    () =>
      accounts && accounts.length
        ? accounts
            .map((acc) => ({
              name: acc?.org?.name || '',
              id: acc?.org?.id,
            }))
            .sort((a, b) =>
              a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
            )
        : [],
    [accounts],
  );

  const { mutate: updateSubscription } = useCreateNotiSubscribeMutation({
    onSuccess: () => {
      logoutMutation();
      clearItem(LOCAL_STORAGE_KEYS.FCM_REGISTRATION_ID);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transformNavLink = (link: string) => {
    switch (link) {
      case 'canfleet_live_tracking':
        return '/';
      case 'canfleet_task_list':
        return '/tasks';
      case 'canfleet_address_book':
        return '/address';
      case 'canfleet_insight':
        return '/insight';
      case 'canfleet_setting':
        return '/setting';
      case 'canfleet_help_center':
        return 'https://canfleetsupport.breadstack.com/portal/en/home';
      default:
        return '/';
    }
  };

  const handleLogout = useCallback(() => {
    // unsubscribe firebase cloud message
    const unsubscribeNoti = async () => {
      try {
        const token = get(LOCAL_STORAGE_KEYS.FCM_REGISTRATION_ID);
        if (token) {
          updateSubscription({
            active: false,
            type: 'web',
            registration_id: token,
          });
        } else {
          logoutMutation();
        }
      } catch (err) {
        logoutMutation();
      }
    };
    unsubscribeNoti();
  }, []);
  const handleClick = (event) => {
    if (orgs.length < 1) return;
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateDefaultOrg = async (orgId) => {
    const currentOrgId = memoryStorage.getOrgId();
    try {
      await removeDefaultOrg();
      memoryStorage.setOrgId(orgId);
      await updateDefaultOrg();
      channel.postMessage({
        type: BroadcastEvents.RefreshOtherPageWhenChangedOrg,
      });
      window.location.replace(window.location.pathname);
    } catch (err) {
      showAlert(errorAlert(err));
      memoryStorage.setOrgId(currentOrgId);
    }
  };

  const handleCloseSideBarMobile = () => {
    const element = document.getElementsByClassName(
      'bs-unified__side-bar-container',
    )[0] as HTMLElement;
    element?.classList.remove('bs-unified__side-bar-container-open');
  };

  const renderWsMenu = useMemo(
    () => (
      <Menu
        id='orgs-menu'
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        classes={{
          paper: 'customized-scrollbar',
        }}
        slotProps={{
          paper: {
            sx: {
              'marginTop': '-20px',
              'marginLeft': '-60px',
              'paddingBottom': isCreateWorkspace ? '40px' : 0,
              'boxShadow': '0px 5px 10px #0000001A',
              'maxHeight': 460,
              'backgroundColor': '#33363D',
              'color': 'white',
              'borderRadius': '2px',
              '& .MuiList-padding': {
                padding: 0,
                marginBottom: isCreateWorkspace ? '-40px' : 0,
              },
              '& .MuiMenuItem-root': {
                'borderRadius': 0,
                'padding': '8px',
                '&:hover': {
                  backgroundColor: '#2D91FC !important',
                },
                '&:first-child': {
                  'backgroundColor': '#33363D !important',
                  '&:hover': {
                    backgroundColor: '#2D91FC !important',
                  },
                },
              },
            },
          },
        }}
        MenuListProps={{
          sx: {},
          style: { pointerEvents: 'auto' },
        }}
        sx={{
          [`&.${popoverClasses.root}`]: {
            pointerEvents: 'none',
          },
        }}
      >
        {orgs.map((org) => (
          <StyledWsMenuItem
            value={org.id}
            key={org.id}
            data-value={org.id}
            data-testid='option-org'
            onClick={() => {
              if (org.id !== account?.org_id) {
                handleUpdateDefaultOrg(org.id);
              }
            }}
          >
            {org.id === account?.org_id ? (
              <img src={TickIcon} alt='tick' style={{ marginRight: 8 }} />
            ) : (
              <div style={{ width: 17 }} />
            )}
            {org?.name.length > 30 ? `${org?.name.slice(0, 30)}...` : org?.name}
          </StyledWsMenuItem>
        ))}
        {isCreateWorkspace && (
          <StyledWsMenuItem
            value='create_org'
            selected={false}
            key='create_org'
            data-value='create_org'
            data-testid='create_org'
            onClick={() => {
              navigate('/create-workspace');
            }}
            sx={{
              position: 'sticky',
              bottom: '-40px',
              backgroundColor: '#33363D',
            }}
          >
            <img src={AddIcon} alt='tick' style={{ marginRight: 8 }} />
            Create workspace
          </StyledWsMenuItem>
        )}
      </Menu>
    ),
    [open, isCreateWorkspace],
  );

  const renderNavBottom = useMemo(() => {
    if (unifiedNavigation?.section3?.length) {
      return unifiedNavigation.section3.map(
        ({ id, url, icon, popup_items }) => {
          if (id === 'settings') {
            return (
              <a key={id} href={url}>
                <NavItem key={id} id={icon} />
              </a>
            );
          }
          if (id === 'profile_photo') {
            const avatarOptions = popup_items.length
              ? popup_items.map((item) => ({
                  value: item.id,
                  label: item.caption,
                  url: item.url,
                  icon: item.icon,
                }))
              : [];
            return (
              <Box
                key={id}
                sx={{
                  'textAlign': 'center',
                  '& > div': {
                    minWidth: 'unset',
                  },
                }}
              >
                <UnifiedMenuSelect
                  options={avatarOptions}
                  onSelect={(_, v) => {
                    if (v.value === 'log_out') {
                      handleLogout();
                    } else {
                      window.location.href = v.url;
                    }
                  }}
                  renderElement={
                    <UnifiedAvatar sx={{ width: 30, height: 30 }} src={url} />
                  }
                />
              </Box>
            );
          }

          if (id === 'log_out') {
            return (
              <button key={id} type='button' onClick={handleLogout}>
                <img width={20} height={20} src={LogoutIcon} alt='logout' />
              </button>
            );
          }
          return '';
        },
      );
    }
    return (
      <button key='log-out' type='button' onClick={handleLogout}>
        <img width={20} height={20} src={LogoutIcon} alt='logout' />
      </button>
    );
  }, [unifiedNavigation]);

  const renderMenuBottom = (
    <>
      <TierUsage />
      {unifiedNavigation.section4
        .filter((e) => e.id === 'canfleet_help_center')
        .map((item) => (
          <NavLink
            to={item.url ? item.url : transformNavLink(item.id)}
            key={item.id}
            target='_blank'
            rel='noreferrer'
            onClick={handleCloseSideBarMobile}
          >
            <MenuItem icon='help_center'>Help Center</MenuItem>
          </NavLink>
        ))}
      {orgs.length >= 1 && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box onClick={handleClick}>
            <MenuItem key='workspace' icon='workspace'>
              Workspace
            </MenuItem>
          </Box>
        </ClickAwayListener>
      )}

      {unifiedNavigation.section4
        .filter((e) => e.id === 'canfleet_setting')
        .map((item) => (
          <NavLink
            to={item.url ? item.url : transformNavLink(item.id)}
            key={item.id}
            onClick={handleCloseSideBarMobile}
          >
            {({ isActive }) => (
              <MenuItem active={isActive} icon={item.icon}>
                {item.caption}
              </MenuItem>
            )}
          </NavLink>
        ))}
    </>
  );

  return (
    <>
      <HeaderMobile />
      <SideBar
        renderHeader={
          <Stack p={1.2} mb={0.5}>
            {accounts.length >= 1 && (
              <Typography
                fontSize={12}
                color={unifiedTheme.foreground.black}
                sx={{ opacity: 0.4 }}
                fontFamily='Lato'
              >
                {convertShortString(account?.org?.name, 22)}
              </Typography>
            )}
            <Typography
              fontSize={14}
              fontWeight='bold'
              fontFamily='Lato'
              color={unifiedTheme.foreground.black}
            >
              Delivery
            </Typography>
          </Stack>
        }
        renderMenu={
          <MenuList renderMenuBottom={renderMenuBottom}>
            {unifiedNavigation.section2.map((item) => (
              <NavLink
                to={item.url ? item.url : transformNavLink(item.id)}
                key={item.id}
                onClick={handleCloseSideBarMobile}
              >
                {({ isActive }) => (
                  <MenuItem icon={item.icon} active={isActive}>
                    {item.caption}
                  </MenuItem>
                )}
              </NavLink>
            ))}
          </MenuList>
        }
      >
        <ThemeProvider theme={unifiedTheme}>
          <NavList renderBottom={renderNavBottom}>
            {unifiedNavigation?.section1 ? (
              unifiedNavigation?.section1?.map(({ id, icon, url }) => {
                if (id === 'canfleet') {
                  return <NavItem key={id} id={id} active />;
                }
                return (
                  <a key={id} href={url}>
                    <NavItem key={id} id={icon} active={false} />
                  </a>
                );
              })
            ) : (
              <NavItem key='canfleet' id='canfleet' active />
            )}
          </NavList>
        </ThemeProvider>
        {renderWsMenu}
      </SideBar>
    </>
  );
};
