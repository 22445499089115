import { IUser } from '~/models/user';

export const DEFAULT_USER_DATA: IUser = {
  id: '',
  created_at: '',
  updated_at: '',
  // org_id: '',
  first_name: '',
  last_name: '',
  display_name: '',
  phone: '',
  email: '',
  role: '',
  status: '',
  items_per_page: 25,
};

export const USER_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

export const USER_ROLE = {
  OWNER: 'owner',
  ADMIN: 'admin',
  DISPATCHER: 'dispatcher',
  DRIVER: 'driver',
  API_KEY: 'api_key',
};

export const USER_EXPAND = {
  LIVE: 'live', // to get live fields: tasks, alerting
  TEAM: 'team',
};

export const USER_ROLE_OPTION = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'dispatcher',
    label: 'Dispatcher',
  },
];
