import { createStyles } from '@mui/styles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    labelRoot: {
      'padding': theme.spacing(0.5, 1.5),
      'backgroundColor': theme.bg.lightPurple,
      'marginLeft': 0,
      'marginRight': 0,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    label: {
      'display': 'flex',
      'alignItems': 'center',
      'color': theme.color.violet900,
      'fontSize': 16,
      'fontWeight': 600,
      '& > img': {
        marginRight: theme.spacing(0.8),
        width: 34,
        height: 34,
      },
    },

    detailItem: {
      fontSize: 14,
      fontWeight: 400,
    },
    detailItemLabel: {
      color: theme.color.black,
    },
    detailItemDesc: {
      color: theme.color.gray900,
    },
  });

export default style;
