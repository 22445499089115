import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import style from './style';

interface IRadioOption {
  value: string;
  label: string;
  desc?: string;
  icon?: any;
  disabled?: boolean;
}

export interface IRadioButton
  extends WithStyles<typeof style>,
    Omit<RadioGroupProps, 'classes'> {
  options: IRadioOption[];
  error?: boolean;
  helperText?: string;
  labelRootClassName?: string;
  radioRootClassName?: string;
  rootClassName?: string;
}

const RadioButton: React.FC<IRadioButton> = ({
  classes,
  onChange,
  value,
  name,
  options,
  error,
  helperText,
  labelRootClassName,
  radioRootClassName,
  rootClassName,
}) => (
  <FormControl
    classes={{
      root: clsx(classes.root, rootClassName),
    }}
  >
    <RadioGroup value={value} name={name} onChange={onChange}>
      {options.map((item) => (
        <FormControlLabel
          data-value={`${item.value}-radio-option-value`}
          data-testid={`${name}-radio-option`}
          classes={{
            root: clsx(classes.labelRoot, labelRootClassName),
            label: classes.label,
          }}
          key={item.value}
          value={item.value}
          label={
            // eslint-disable-next-line no-nested-ternary
            item.icon ? (
              <>
                <img alt={item.label} src={item.icon} />
                {item.label}
              </>
            ) : item.desc ? (
              <div className={classes.detailItem}>
                <div className={classes.detailItemLabel}>{item.label}</div>
                <div className={classes.detailItemDesc}>{item.desc}</div>
              </div>
            ) : (
              item.label
            )
          }
          control={
            <Radio
              disabled={item?.disabled || false}
              color='secondary'
              classes={{ root: radioRootClassName }}
            />
          }
        />
      ))}
    </RadioGroup>
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

export default withStyles(style)(RadioButton);
