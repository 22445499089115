import React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { LinearProgress } from '@mui/material';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
      position: 'relative',
    },
    root: {
      backgroundColor: '#EBEBEB',
      height: 4,
    },
    bar: {
      backgroundColor: '#121142',
      height: 4,
    },
    divider: {
      position: 'absolute',
      width: 5,
      height: 7,
      top: -1,
      backgroundColor: theme.bg.white,
    },
  });

interface ITrackProgress extends WithStyles<typeof style> {
  progress: number;
}
const TrackProgress: React.FC<ITrackProgress> = ({ progress, classes }) => (
  <div className={classes.container}>
    <LinearProgress
      classes={{
        root: classes.root,
        bar: classes.bar,
      }}
      variant='determinate'
      value={progress === 90 ? 87.5 : progress}
    />
    {Array(4)
      .fill(0)
      .map((_, index) => (
        <div
          className={classes.divider}
          style={{ left: `calc(${(index + 1) * 25}% - 2.5px)` }}
        />
      ))}
  </div>
);

export default withStyles(style)(TrackProgress);
