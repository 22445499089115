import { convertShortString, formatPhoneNumber } from '~/utils/formatter';
import Barcode from 'react-barcode';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Task } from '~/pages/LiveTracking/types';
import { styled } from '~/themes';
import LogoIcon from '~/assets/images/logo/logo-black.svg';

type LabelSizeProps = {
  task: Task;
  orgName: string;
};

const Container = styled('div')({
  height: '100vh',
  width: 400,
  margin: '0 !important',
  padding: '0 !important',
  overflow: 'hidden',
});

const Body = styled('div')({
  fontSize: 11,
  color: '#000',
  fontWeight: 400,
  position: 'relative',
  padding: '20px 8px',
  margin: '5px auto',
  justifyContent: 'center',
  background: '#fff',
});

const Waybill = styled('div')({
  border: '1px solid #707070',
});

const BillHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '12px 0px',
  borderBottom: '1px solid #707070',
});

const BillHeaderIcon = styled('img')({
  height: 40,
  width: 148,
  marginLeft: 'auto',
  marginRight: 12,
});

const BillItem = styled('div')({
  padding: '8px 15px',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #707070',
});

const BillId = styled('div')({
  fontSize: 36,
  lineHeight: '40px',
  fontWeight: 600,
});

const BillBarcode = styled('div')({
  display: 'flex',
  width: 100,
  height: 60,
  position: 'relative',
});

const BillItemContainer = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'margin': 0,

  '&:first-of-type': {
    marginRight: '0.5rem',
  },
});

const BillItemBlock = styled('div')({
  marginBottom: 3,
});

const BillLabel = styled('div')({
  fontSize: 18,
  fontWeight: 600,
  marginBottom: 5,
});

const BillValue = styled('div')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
});

const BillDelivery = styled('div')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '20px',
});

const BillDeliveryName = styled('div')({
  fontSize: 16,
  marginBottom: 5,
});

const BillLabelSmall = styled('div')({
  fontSize: 14,
  fontWeight: 600,
});

const BillNoteLabel = styled('div')({
  fontSize: 12,
  fontWeight: 600,
  marginTop: 20,
});

const BillNoteContent = styled('div')({
  fontSize: 12,
});

const BorderBillItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #707070',
});

const CornerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% + 2px)',
  marginLeft: '-1px',
});

const BillCorner = styled('div')({
  width: 16,
  height: 16,
  borderStyle: 'solid',
  borderColor: 'black',
});

const BorderBillContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 12px',
  margin: '-8px 0 -8px 0',
});

export const WaybillLabelSize = forwardRef(
  (props: LabelSizeProps, ref: React.Ref<any>) => {
    const containerRef = useRef();
    const { task, orgName } = props;
    const { delivery, metadata } = task;

    const returnLabelInfo = task.returnLabelInfo || {
      city: '',
      address_1: '',
      address_2: '',
      postcode: '',
      state: '',
      country: '',
      phone: '',
    };

    const waybillAddress = metadata?.waybill_address || {
      city: '',
      address_1: '',
      address_2: '',
      postal_code: '',
      state: '',
      country: '',
      phone_number: '',
    };

    useImperativeHandle(
      ref,
      () => ({
        getRef: () => containerRef.current,
      }),
      [],
    );
    const packageWeight = useMemo(() => {
      if (task?.metadata?.package_weight) {
        return `${task?.metadata?.package_weight} kg`;
      }
      return '-';
    }, [task]);

    return (
      <Container ref={containerRef}>
        <Body>
          <Waybill>
            <BillHeader>
              <BillHeaderIcon src={LogoIcon} alt='logo' />
            </BillHeader>
            <BillItem>
              <div>
                <BillLabel style={{ marginBottom: 0 }}>Task ID:</BillLabel>
                <BillId>{task.name}</BillId>
              </div>
              <BillBarcode
                style={
                  task.name && task.name.length >= 5
                    ? { width: 140 }
                    : { width: 100 }
                }
              >
                <Barcode
                  value={task.name}
                  height={45}
                  width={2}
                  margin={0}
                  fontSize={14}
                />
              </BillBarcode>
            </BillItem>
            <BillItem style={{ borderBottom: 'none', marginBottom: -15 }}>
              <BillLabel>Shipper: {orgName}</BillLabel>
            </BillItem>
            <BillItem style={{ borderBottom: 'none' }}>
              <BillItemContainer style={{ flexBasis: '50%' }}>
                <BillItemBlock>
                  <BillLabel>From:</BillLabel>
                  <BillValue>
                    {`${convertShortString(waybillAddress.address_1, 22)},`}
                    <br />
                    {convertShortString(
                      `${waybillAddress.city}, ${waybillAddress?.state}, ${waybillAddress?.country}`,
                      40,
                    )}
                    <br />
                    {waybillAddress?.postal_code}
                  </BillValue>
                </BillItemBlock>
              </BillItemContainer>
              <BillItemContainer style={{ flexBasis: '50%' }}>
                <BillItemBlock style={{ minHeight: 79 }}>
                  <BillLabel>Return:</BillLabel>
                  {returnLabelInfo?.address_1 ? (
                    <BillValue>
                      {convertShortString(returnLabelInfo?.address_1, 22)}
                      <br />
                      {convertShortString(
                        `${returnLabelInfo.city}, ${returnLabelInfo.state}, ${returnLabelInfo.country}`,
                        22,
                      )}
                      <br />
                      {returnLabelInfo.postcode}
                    </BillValue>
                  ) : (
                    ''
                  )}
                </BillItemBlock>
              </BillItemContainer>
            </BillItem>

            <BillItem style={{ borderTop: 'none', paddingTop: 0 }}>
              <BillItemContainer style={{ flexBasis: '50%' }}>
                <BillItemBlock>
                  <BillLabelSmall>Contact:</BillLabelSmall>
                  <BillValue>
                    {formatPhoneNumber(waybillAddress.phone_number)}
                  </BillValue>
                </BillItemBlock>
              </BillItemContainer>
              <BillItemContainer style={{ flexBasis: '50%' }}>
                <BillItemBlock>
                  <BillLabelSmall>Contact:</BillLabelSmall>
                  <BillValue>
                    {formatPhoneNumber(returnLabelInfo.phone || '')}
                  </BillValue>
                </BillItemBlock>
              </BillItemContainer>
            </BillItem>
            <BorderBillItem>
              <CornerContainer>
                <BillCorner style={{ borderWidth: '4px 0 0 4px' }} />
                <BillCorner style={{ borderWidth: '4px 4px 0 0' }} />
              </CornerContainer>
              <BorderBillContent>
                <BillItemContainer style={{ flexBasis: '50%' }}>
                  <BillLabel>To:</BillLabel>
                  <BillDeliveryName>
                    {convertShortString(delivery.name, 20)}
                  </BillDeliveryName>
                  <BillDelivery>
                    {convertShortString(delivery.address_1, 18)}
                    <br />
                    {convertShortString(
                      `${delivery.city}, ${delivery.state}, ${delivery.country}`,
                      20,
                    )}
                    <br />
                    {delivery.postcode}
                  </BillDelivery>
                </BillItemContainer>
                <BillItemContainer style={{ flexBasis: '50%' }}>
                  <BillLabel>Contact:</BillLabel>
                  <BillValue>{formatPhoneNumber(delivery.phone)}</BillValue>
                  <BillNoteLabel>Note:</BillNoteLabel>
                  <BillNoteContent>{task.note}</BillNoteContent>
                </BillItemContainer>
              </BorderBillContent>
              <CornerContainer>
                <BillCorner style={{ borderWidth: '0 0 4px 4px' }} />
                <BillCorner style={{ borderWidth: '0 4px 4px 0' }} />
              </CornerContainer>
            </BorderBillItem>
            <BillItem style={{ borderBottom: 'none' }}>
              <BillItemContainer>
                <BillLabel>Package Weight:</BillLabel>
                <BillValue>{packageWeight}</BillValue>
              </BillItemContainer>
            </BillItem>
          </Waybill>
        </Body>
      </Container>
    );
  },
);
