import {
  IOptionItem,
  CountryListItem,
  CountryListResponseItem,
  AddressItem,
} from '~/models/common';

export const convertCountryList = (
  list: CountryListResponseItem[],
): CountryListItem[] =>
  list
    .sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
    .map(
      ({ name, code, states }: CountryListResponseItem): CountryListItem => ({
        value: code,
        label: name,
        states: states.map(
          ({
            name: stateName,
            code: stateCode,
            is_suggestion_delivery,
            is_suggestion_pickup,
          }: AddressItem): IOptionItem => ({
            value: stateCode,
            label: stateName,
            is_suggestion_pickup: !!is_suggestion_pickup,
            is_suggestion_delivery: !!is_suggestion_delivery,
          }),
        ),
      }),
    );
