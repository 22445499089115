import { useEffect, useState } from 'react';
import { useAuth } from '~/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { DialogUpdatePayment } from '~/pages/AdminOptions/Billing/DialogUpdatePayment';
import { alertParams, showAlert } from '~/components/common/Alert';
import { useLogout } from '~/services/api/auth';
import { memoryStorage } from '~/utils/localStorage';
import { DialogAccountLocked } from './DialogAccountLocked';
import { DialogFailPayment } from './DialogFailPayment';
import { FailPaymentBanner } from './FailPaymentBanner';

export const FailPaymentController = () => {
  const { account } = useAuth();
  const { org } = usePaymentInfo();
  const navigate = useNavigate();
  const { mutate: logoutMutation } = useLogout({
    onSuccess: () => navigate('/login'),
  });
  const [openAccountLocked, setIsOpenAccountLocked] = useState(false);
  const [openUpdatePayment, setIsOpenUpdatePayment] = useState(false);
  const [openFailPaymentWarn, setIsOpenFailPaymentWarn] = useState(false);

  useEffect(() => {
    if (!org?.id && account.status === 'deactivated') {
      setIsOpenAccountLocked(true);
    }

    if (
      org?.grace_period &&
      memoryStorage.getCountOfShowFailPaymentDialog() === 0
    ) {
      setTimeout(() => {
        setIsOpenFailPaymentWarn(true);
      }, 200);
    }
  }, [account, org]);
  return (
    <>
      <FailPaymentBanner />
      {openAccountLocked && (
        <DialogAccountLocked
          open={openAccountLocked}
          onClose={() => setIsOpenAccountLocked(false)}
          onOkClick={() => {
            setIsOpenAccountLocked(false);
            setIsOpenUpdatePayment(true);
          }}
        />
      )}

      {openUpdatePayment && (
        <DialogUpdatePayment
          open={openUpdatePayment}
          onClose={() => {
            logoutMutation();
          }}
          onCancelClick={() => logoutMutation()}
          onUpdatePaymentSuccess={() => {
            setIsOpenUpdatePayment(false);
            showAlert({
              ...alertParams.success,
              title: 'Update Successfully',
              description: 'Your subscription has been activated.',
              onOk: () => navigate(0),
              onCancel: () => navigate(0),
              closeOnClick: false,
            });
          }}
        />
      )}
      {openFailPaymentWarn && (
        <DialogFailPayment
          open={openFailPaymentWarn}
          onClose={() => {
            memoryStorage.setCountOfShowFailPaymentDialog();
            setIsOpenFailPaymentWarn(false);
          }}
        />
      )}
    </>
  );
};
