import { useMutation, useQuery } from '@tanstack/react-query';
import { CountryListResponseItem } from '~/models/common';
import httpClient from '~/lib/client/httpClient';
import { convertCountryList } from '~/utils/share';
import { IHub, INote, ITeam } from '~/models/shared';
import { cleanDeep, toQueryString } from '~/utils/queryString';
import { IInvitation, IUnifiedNavigationData } from '~/models/auth';
import { IAccount } from '~/models/adminOptions';
import { queryClient } from '~/lib/react-query';
import { showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { IAddress } from '~/models/address';
import { DEFAULT_UNIFIED_NAVIGATION } from '~/constants/auth';
import { setAccountQueryData } from '~/utils/reactQuery';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getCountries = (): Promise<CountryListResponseItem[]> =>
  httpClient.json.get(endpoints.share.countries);

export const getHubList = (params): Promise<IHub[]> => {
  cleanDeep(params);
  return httpClient.json.get(
    `${endpoints.share.hubs}?${toQueryString(params)}`,
  );
};

export const getTeams = (params): Promise<ITeam[]> => {
  cleanDeep(params);
  return httpClient.json.get(
    `${endpoints.share.team}?${toQueryString(params)}`,
  );
};

export const updateGeneralSettings = (params): Promise<IAccount> =>
  httpClient.json.put(endpoints.user.user, params.body);

export const updateDefaultOrg = (): Promise<IAccount> =>
  httpClient.json.put(endpoints.user.user, {
    default: true,
  });

export const removeDefaultOrg = (): Promise<IAccount> =>
  httpClient.json.put(endpoints.user.user, {
    default: false,
  });

export const updateUserAvatar = (params): Promise<any> =>
  httpClient.formData.post(endpoints.user.avatar, params.body);

export const removeUserAvatar = ({ body }: { body: any }): Promise<any> =>
  httpClient.json.put('user/avatar', body);

export const getNote = (params): Promise<INote[]> =>
  httpClient.json.get(`tasks/${params.taskId}/notes`);

export const createNote = (params) =>
  httpClient.json.post(`tasks/${params.taskId}/notes`, params.body);
export const deleteNote = (params) =>
  httpClient.json.delete(`tasks/${params.taskId}/notes/${params.noteId}`);

export const updateTaskImage = (body) =>
  httpClient.formData.post(`tasks/image`, body);

export const createNotiSubscription = (body) =>
  httpClient.json.post(`subscriptions`, body);

export const getStoreLocations = (): Promise<IAddress[]> =>
  httpClient.json.get('common_address/search');

export const getUnifiedNavigation = (
  params,
): Promise<{
  data: IUnifiedNavigationData;
}> =>
  httpClient.json.get(`unification/navigation`, {
    params,
  });

export const useGetCountriesQuery = (params) => {
  const { onSuccess = () => {}, onError = () => {}, enabled } = params;
  return useQuery({
    queryKey: [queryKeys.shared.getCountries],
    initialData: [],
    enabled,
    queryFn: getCountries,
    select: (data) => convertCountryList(data),
    onSuccess,
    onError,
  });
};

export const useGetHubListQuery = (
  {
    params,
    onSuccess,
    onError,
  }: {
    params: any;
    onSuccess?: () => void;
    onError?: () => void;
  } = {
    params: {},
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useQuery({
    queryKey: [queryKeys.shared.getHubList, params.search],
    queryFn: () => getHubList(params),
    initialData: [],
    onSuccess,
    onError,
  });

export const useGetTeamsQuery = (
  {
    enabled,
    params,
    onSuccess,
    onError,
  }: {
    enabled?: boolean;
    params: any;
    onSuccess?: () => void;
    onError?: () => void;
  } = {
    enabled: true,
    params: {},
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useQuery({
    queryKey: [queryKeys.shared.getTeamList, params.search, params.hub_id],
    queryFn: () => getTeams(params),
    initialData: null,
    enabled,
    onSuccess,
    onError,
  });

const getInvitation = ({ code }: { code?: string }): Promise<IInvitation> =>
  httpClient.json.get(endpoints.user.invitation(code));

const validateInvitation = ({ code }: { code?: string }) =>
  httpClient.json.post(endpoints.user.validate(code));

export const unSubscription = ({
  registration_id,
}: {
  registration_id?: string;
}) => httpClient.json.post('unsubscriptions', { registration_id });

export const useGetInvitationQuery = (
  {
    params,
  }: {
    params: { code?: string };
  } = {
    params: {},
  },
) =>
  useQuery({
    queryKey: ['getInvitation', params],
    queryFn: () => getInvitation(params),
    initialData: null,
    enabled: !!params.code,
  });

export const useValidateInvitationMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: validateInvitation,
    onError,
    onSuccess,
  });

export const useUpdateGeneralSettingsMutation = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateGeneralSettings,
    onSuccess: (resp) => {
      setAccountQueryData(resp);
      onSuccess?.(resp);
    },
    onError,
  });
};

export const useUpdateDefaultOrgMutation = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateDefaultOrg,
    onSuccess,
    onError,
  });
};

export const useRemoveDefaultOrgMutation = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: removeDefaultOrg,
    onSuccess,
    onError,
  });
};

export const useUpdateUserAvatar = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateUserAvatar,
    onSuccess: (resp) => {
      setAccountQueryData(resp);
      onSuccess?.();
    },
    onError,
  });
};

export const useRemoveUserAvatar = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: removeUserAvatar,
    onSuccess,
    onError,
  });
};

export const useGetNotesQuery = (params) => {
  const { onSuccess, onError, taskId } = params;
  return useQuery({
    queryKey: [queryKeys.shared.getNoteList, taskId],
    queryFn: () => getNote({ taskId }),
    onSuccess,
    onError,
    enabled: !!taskId,
    initialData: [],
  });
};

export const useCreateNoteMutation = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: createNote,
    onSuccess: (resp) => {
      queryClient.invalidateQueries([queryKeys.shared.getNoteList]);
      onSuccess?.(resp);
    },
    onError: (err) => {
      showAlert(errorAlert(err));
      onError?.(err);
    },
  });
};

export const useDeleteNoteMutation = () =>
  useMutation({
    mutationFn: deleteNote,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.shared.getNoteList]);
    },
    onError: (err) => {
      showAlert(errorAlert(err));
    },
  });

export const useUpdateTaskImageMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: updateTaskImage,
    onSuccess,
    onError,
  });
};

export const useCreateNotiSubscribeMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: createNotiSubscription,
    onSuccess,
    onError,
  });
};

export const useGetAllStoreLocations = (data) => {
  const { onSuccess, onError } = data;
  return useQuery({
    queryKey: ['useGetAllStoreLocations'],
    queryFn: getStoreLocations,
    initialData: [],
    onSuccess,
    onError,
  });
};

export const useGetUnifiedNavigation = (data) => {
  const { onSuccess, onError, enabled = true, params } = data;
  return useQuery({
    queryKey: ['useGetUnifiedNavigation', params],
    queryFn: () => getUnifiedNavigation(params),
    initialData: { data: DEFAULT_UNIFIED_NAVIGATION },
    enabled,
    onSuccess,
    onError,
  });
};
