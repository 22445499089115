import MuiRating, { RatingProps as _RatingProps } from '@mui/material/Rating';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import { styled } from '~/themes';
import StarIcon from '~/assets/images/icons/star.svg';
import EmptyStarIcon from '~/assets/images/icons/empty-star.svg';

interface RatingProps extends _RatingProps {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  precision?: number;
  value?: number;
  customSize?: number;
  onChange?: (event: React.SyntheticEvent, value: number | null) => void;
  onChangeActive?: (event: React.SyntheticEvent, value: number | null) => void;
  customStar?: any;
  customEmptyStar?: any;
}
const StyledIconItem = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
}));
export const Rating: React.FC<RatingProps> = ({
  value = 0,
  precision = 1,
  readOnly = true,
  className,
  onChange,
  onChangeActive,
  customSize = 17,
  customStar,
  customEmptyStar,
  ...rest
}) => (
  <Box
    className={className}
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
    }}
  >
    <MuiRating
      {...rest}
      value={value || 0}
      precision={precision}
      onChange={onChange}
      readOnly={
        typeof onChange === 'function' || typeof onChangeActive === 'function'
          ? false
          : readOnly
      }
      icon={
        <StyledIconItem
          alt='empty-star'
          size={customSize}
          src={customStar || StarIcon}
        />
      }
      emptyIcon={
        <StyledIconItem
          alt='empty-star'
          size={customSize}
          src={customEmptyStar || EmptyStarIcon}
        />
      }
      sx={{
        '& .MuiRating-icon': {
          width: `${customSize}px`,
          mx: '3px',
        },
        '& .MuiRating-iconActive': {
          transform: 'scale(1)',
        },
        '.filled': {
          zIndex: 1,
        },
      }}
    />

    {/* <Typography className='rating-value'>
      {value ? Math.round(value * 100) / 100 : 0}
    </Typography> */}
  </Box>
);
