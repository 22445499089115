import { makeStyles } from '@mui/styles';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { Theme } from '~/themes';

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  item: {
    '&.MuiAccordion-root.Mui-expanded': {
      boxShadow: '0px 1px 6px #160E4D14',
      backgroundColor: theme.bg.lightPurple,
      marginBottom: theme.spacing(0.5),
    },
    '&.MuiAccordion-root': {
      'marginTop': theme.spacing(1),
      '&:first-child': {
        marginTop: 0,
      },
      '&.Mui-expanded': {
        marginBottom: theme.spacing(1),
        background: theme.color.white,
        borderRadius: '10px !important',
      },
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 45,
      fontWeight: '500',
      borderRadius: '10px !important',
    },
  },
  itemContent: {
    padding: theme.spacing(1, 2),
  },
  driverIcon: {
    marginRight: theme.spacing(0.5),
  },
  loading: {
    maxHeight: 'unset',
  },
  filterContainer: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'gap': theme.spacing(1, 2),
    'marginBottom': theme.spacing(1),
    '& > *': {
      '&:last-child': {
        marginLeft: 'auto',
      },
    },
    '& > form': {
      minWidth: 300,
    },
  },
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    containerClass: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    filterContainer: {
      'gap': theme.spacing(1, 1),
      '& > form': {
        minWidth: 0,
        display: 'flex',
        flex: 1,
      },
    },
  },
}));

export default useStyles;
