import httpClient from '~/lib/client/httpClient';
import { toQueryString } from '~/utils/queryString';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { IInvoiceResponse } from './types';

export enum SettingBillingsKeys {
  GET_INVOICES = 'SettingBillings.GET_INVOICES',
}

export const SettingBillingsQueryKeys = {
  getInvoices: (params?: any) =>
    [SettingBillingsKeys.GET_INVOICES, params].filter(Boolean),
};

export const fetchInvoices = (afterId?: string): Promise<IInvoiceResponse> => {
  const qs = toQueryString(
    { starting_after: afterId, limit: 10 },
    { skipNull: true },
  );
  return httpClient.json.get(`/invoices?${qs}`);
};

export const useGetInvoicesQuery = (
  options?: UseInfiniteQueryOptions<IInvoiceResponse>,
) =>
  useInfiniteQuery({
    queryKey: [SettingBillingsQueryKeys.getInvoices()],
    queryFn: async ({ pageParam = null }) => fetchInvoices(pageParam),
    initialData: { pages: [], pageParams: [] },
    getNextPageParam: (lastPage) =>
      lastPage?.has_more ? lastPage.items.slice(-1)[0].id : null,
    ...options,
  });
