const endpoints = {
  auth: {
    login: '/auth/admin/login',
    logout: '/auth/admin/logout',
    refreshToken: '/auth/admin/refresh',
    passwordReset: '/auth/admin/password/reset',
    passwordResetConfirm: '/auth/admin/password/reset/confirm',
    passwordResetDriver: '/auth/password/reset',
    passwordResetConfirmDriver: '/auth/password/reset/confirm',
    activate: '/auth/activate',
    activationResend: '/auth/activation/resend',
    register: '/invitation/users/invite/verify',
    validate: '/invitation/users/invite/token-validate',
    signup: '/accounts:signup',
    signIn: '/accounts:signin',
    signOut: '/accounts:signout',
    verifyAccount: '/accounts:verify',
    activeAccount: '/accounts:active',
    verifyEmail: '/accounts:verify_acc',
    generateCustomToken: '/accounts:generate_custom_token',
    resendEmail: '/accounts:resend_email',
    billingInfo: '/billing:create_setup_intent',
    logRequestSignIn: '/accounts:log_signin_request',
    setCustomClaim: '/accounts:set_custom_claim',
    verifyReCaptcha: '/accounts:verify_recaptcha',
    setDefaultPayment: '/billing:set_default_payment_method',
    sendResetPasswordEmail: '/accounts:admin_change_password',
    setNewPassword: '/accounts:accept_admin_change_password',
    verifyAdminChangePassword: '/accounts:verify_admin_change_password',
  },
  sso: {
    auth: '/auth',
    logout: '/clear-session',
    checkAuth: '/check-session',
  },
  driver: {
    drivers: '/users',
    log: (id: string) => `/driver_log/${id}`,
    doc: (id: string) => `/user/license_photos/${id}`,
    docDetail: (id: string, docId: string) => `/users/${id}/documents/${docId}`,
  },
  task: {
    common: 'tasks',
    cancelTask: 'tasks:cancel',
    photo: (task_id: string, id: string) => `/tasks/${task_id}/documents/${id}`,
    log: (task_id: string) => `/tasks/${task_id}/notes`,
  },
  org: {
    org: '/org',
    create: '/orgs',
  },
  billing: {
    invoices: '/invoices',
  },
  share: {
    countries: 'countries',
    hubs: 'hubs',
    team: 'teams',
  },

  user: {
    detail: (id: string) => `/users/${id}`,
    update: (id: string) => `/users/${id}`,
    status: (id: string) => `/invitation/users/${id}/active`,
    user: '/user',
    avatar: '/user/avatar',
    users: '/users',
    invite: (role: string) => `/users:invite_${role}`,
    createAPIKey: '/users:create_api_key',
    resendInvitation: (id: string) => `/users/${id}/invitation:resend`,
    rotateAPIKey: (id: string) => `/users/${id}/api_key:rotate`,
    invitation: (code: string) => `/invitations/${code}`,
    validate: (code: string) => `/invitations/${code}/accept`,
    changePwd: '/users/change-password',
  },

  timezone: {
    timezone: '/timezones',
  },

  track: {
    track: '/tracking',
    trackRating: (id: string) => `/tasks:rating/${id}`,
  },

  integration: {
    integration: '/integrations',
    checkConnectionOnboarding: '/check_connection_in_onboarding',
    breadStackStores: '/breadstack_stores',
  },
  dashboard: {
    dashboard: '/dashboard',
  },
  address: {
    address: '/address_books',
    bulkCreateAddress: '/bulk_create_address_book',
  },
};

export default endpoints;
