import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Field } from '~/components/common/Formik';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';

interface ISearchFormikProps {
  field?: string;
  placeholder?: string;
  searchSuggestion?: string;
  clearIds?: string[];
  onSubmit?: (values: any) => void;
  onClear?: () => void;
}

export const SearchFormik: React.FC<ISearchFormikProps> = ({
  field = 'search',
  placeholder = 'Enter 3 or more characters',
  searchSuggestion = 'Enter 3 or more characters',
  clearIds = [],
  onSubmit,
  onClear,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queries = fromQueryString(location.search);
  const initialSearchValue = queries[field] || '';

  const handleSubmit = useCallback(
    (values) => {
      const clearSearchId = !values[field] ? [field] : [];
      const searchValue = omitFromQueryString(
        addToQueryString(location.search, values),
        [...clearSearchId, ...clearIds],
      );

      navigate({
        pathname: location.pathname,
        search: searchValue,
      });
    },
    [location, clearIds, field],
  );

  const handleClear = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, [field, ...clearIds]),
    });
  }, [location, field]);

  return (
    <Formik
      initialValues={{ [field]: initialSearchValue }}
      onSubmit={onSubmit || handleSubmit}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form>
          <Field.SearchInput
            searchSuggestion={searchSuggestion}
            placeholder={placeholder}
            name={field}
            onClear={() => {
              if (onClear) {
                onClear();
              } else {
                handleClear();
              }
              setFieldValue(field, '');
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
