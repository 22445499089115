import { Box, Grid, Stack, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import { Icon } from '~/components/common/Icon';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { IPlanTier } from '~/models/plan';
import Tooltip from '~/components/common/Tooltip';
import { StyledPlanItemContainer } from '../style';

type TaskQuotaProps = {};

export const StyledTextValue = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: 16,
}));

export const TaskQuota: React.FC<TaskQuotaProps> = () => {
  const { additionalTask, org, currentPlan } = usePaymentInfo();

  return (
    <StyledPlanItemContainer>
      <Stack direction='row' spacing={0.5}>
        <Typography variant='h4'>Task Quota</Typography>
        <Tooltip title='A task is a delivery job.' arrow>
          <Icon name='help' useBackgroundImg />
        </Tooltip>
      </Stack>

      <Grid
        container
        sx={{
          border: '1px solid #E9E7F6',
          p: 2,
          borderRadius: '10px',
          mt: 2,
        }}
      >
        <Grid item xs={12} sm={6}>
          <Stack direction='row'>
            <Box>
              <Typography>Included tasks</Typography>
              <StyledTextValue>{org.limit_task}</StyledTextValue>
            </Box>
            <Box sx={{ ml: 10 }}>
              <Typography>Additional tasks</Typography>
              {currentPlan.tier === IPlanTier.Free ? (
                <Typography color={themes.color.gray600} fontWeight='bold'>
                  Not available
                </Typography>
              ) : (
                <StyledTextValue>{additionalTask || '0'}</StyledTextValue>
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack
            sx={{ ml: 'auto', marginTop: '10px' }}
            direction='row'
            spacing={0.5}
          >
            <Icon
              name={
                currentPlan.tier === IPlanTier.Free ? 'info-yellow' : 'info'
              }
              useBackgroundImg
              color='inherit'
            />
            {currentPlan.tier === IPlanTier.Free ? (
              <Typography color={themes.color.yellow} fontWeight='bold'>
                Upgrade your plan to increase your task limit.
              </Typography>
            ) : (
              <Typography color={themes.color.violet600} fontWeight='bold'>
                Additional tasks will be charged at the end of the billing cycle
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </StyledPlanItemContainer>
  );
};
