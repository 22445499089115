import React from 'react';
import { getIn } from 'formik';
import CheckBox from '~/components/common/CheckBox';
import { ICheckBox } from '~/components/common/CheckBox/CheckBox';
import { IFormProps } from '~/models/common';

export interface IFieldCheckBox
  extends IFormProps,
    Omit<ICheckBox, 'name' | 'form' | 'error'> {
  error?: string;
}

const FieldCheckBox = ({ field, form, error, ...props }: IFieldCheckBox) => (
  <CheckBox
    error={
      !!error ||
      (getIn(form.touched, field.name) && getIn(form.errors, field.name))
    }
    helperText={
      error ||
      (getIn(form.touched, field.name) ? getIn(form.errors, field.name) : '')
    }
    id={field.name}
    checked={!!field.value}
    onChange={field.onChange}
    {...props}
  />
);

export default FieldCheckBox;
