import {
  BaseTextFieldProps,
  IconButton,
  InputAdornment,
  InputBaseProps,
  SxProps,
} from '@mui/material';
import { TextField, Icon, theme as unifiedTheme } from 'bs-unified-ui';
import { useState } from 'react';
import { Theme } from '~/themes';

export interface UnifiedTextFieldProps extends BaseTextFieldProps {
  label?: string | React.ReactNode;
  dataTestId?: string;
  startAdornment?: string | React.ReactNode;
  endAdornment?: string | React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  value?: string;
  error?: boolean;
  multiline?: boolean;
  helperText?: string | React.ReactNode | '';
  inputProps?: InputBaseProps['inputProps'];
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url' | 'time';
  placeholder?: string;
  id?: string;
  name?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  rows?: number;
  fullWidth?: boolean;
}

export const UnifiedTextField: React.FC<UnifiedTextFieldProps> = ({
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const passwordEndAdornment = (
    <InputAdornment position='end' variant='filled'>
      <IconButton
        sx={{ p: 0, pb: 0.5, mr: -1 }}
        aria-label='toggle password visibility'
        disableRipple
        onClick={(event) => {
          setShowPassword(!showPassword);
          event.preventDefault();
        }}
        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
        }}
      >
        {showPassword ? (
          <Icon
            size={16}
            name='eye'
            type='fill'
            color={unifiedTheme.background.outlineDivider}
          />
        ) : (
          <Icon
            size={16}
            name='eye-off'
            type='fill'
            color={unifiedTheme.background.outlineDivider}
          />
        )}
      </IconButton>
    </InputAdornment>
  );

  if (props.type === 'password') {
    return (
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        endAdornment={passwordEndAdornment}
      />
    );
  }

  return <TextField {...props} />;
};
