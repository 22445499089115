const TO_RADIANS = Math.PI / 180;

export const rotateImage = (image: HTMLImageElement, type?: string) => {
  const canvas = document.createElement('canvas');
  const { naturalHeight: height, naturalWidth: width } = image;

  canvas.width = height;
  canvas.height = width;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d ctx');
  }

  ctx.clearRect(0, 0, width, height);
  ctx.translate(height / 2, width / 2);
  ctx.rotate(-90 * TO_RADIANS);
  ctx.drawImage(image, -width / 2, -height / 2);
  return canvas.toDataURL(type, 0.8);
};
