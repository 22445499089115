/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { WithStyles } from '@mui/styles';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { alertParams, showAlert } from '~/components/common/Alert';
import Table from '~/components/common/Table';
import { TColumn } from '~/components/common/Table/Table';
import { UserStatus, IUser } from '~/models/user';
import { Theme } from '~/themes';
import {
  convertedSortParams,
  getNewSort,
  transformTypeToTitle,
} from '~/utils/common';
import { USER_ROLE, USER_STATUS } from '~/constants/userManagement';
import { updateLocationSearchParams } from '~/utils/formatter';
import { IAlert } from '~/components/common/Alert/Alert';
import {
  useResendInvitationMutation,
  useDeleteUserMutation,
} from '~/services/api/userManagement';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { useAuth } from '~/providers/AuthProvider';
import { LinkButton } from '~/components/common/Button';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { fromQueryString } from '~/utils/queryString';

const style = (theme: Theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    avatar: {
      borderRadius: '50%',
    },
    status: {
      '&.pending': {
        color: theme.color.yellow,
      },
      '&.active': {
        color: theme.color.green,
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  data: IUser[];
  isLoading: boolean;
}

const UserTable: React.FC<IProps> = ({ classes, data, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAuth();
  const sourceType =
    useParams<{ sourceType: string; source: string }>()?.sourceType ||
    UserStatus.Active;
  const { sort, sort_by } = fromQueryString(location.search);

  const { mutate: deleteUser, isLoading: loadingUpdate } =
    useDeleteUserMutation({
      onSuccess: () => {
        showAlert({
          ...alertParams.success,
          title: 'User was deleted successfully',
        });
        queryClient.invalidateQueries([queryKeys.userManagement.getUsers]);
      },
      onError: (error) => {
        showAlert(errorAlert(error));
      },
    });

  const { mutate: resendInvitation, isLoading: loadingResent } =
    useResendInvitationMutation({
      onSuccess: () => {
        showAlert({
          ...alertParams.success,
          title: 'Resent Invitation Successfully',
          description:
            'The invitation link has been resent to the user’s email.',
        });
      },
      onError: (error) => {
        showAlert(errorAlert(error));
      },
    });

  const handleSort = useCallback(
    (sortId: string, nextArrange: number) => {
      const sortParams = getNewSort(sortId, nextArrange);
      navigate({
        pathname: location.pathname,
        search: `?${updateLocationSearchParams(location, sortParams)}`,
      });
    },
    [location],
  );

  const onDeleteAlert = useCallback(
    (id: string) => {
      let params: IAlert = {
        ...alertParams.warning,
        title: 'Warning',
        cancelText: 'Cancel',
        okText: 'Yes, Delete',
        onOk: () => deleteUser(id),
      };
      params = {
        ...params,
        description:
          'You are about to delete this user.\nAre you sure you want to continue?',
      };
      showAlert(params);
    },
    [sourceType],
  );

  const renderAction = useCallback(
    ({ id, status, email, role }: IUser) => {
      if (role === USER_ROLE.OWNER || id === account?.id) {
        return '';
      }

      if (status === UserStatus.Pending) {
        return (
          <>
            <LinkButton
              data-value={email}
              onClick={(e) => {
                resendInvitation(id);
                e.stopPropagation();
              }}
            >
              Resend Invitation
            </LinkButton>
            <span role='presentation'>{'  |  '}</span>
            <LinkButton
              data-value={email}
              onClick={(e) => {
                onDeleteAlert(id || '');
                e.stopPropagation();
              }}
            >
              Delete
            </LinkButton>
          </>
        );
      }

      return (
        <LinkButton
          data-value={email}
          onClick={(e) => {
            onDeleteAlert(id || '');
            e.stopPropagation();
          }}
        >
          Delete
        </LinkButton>
      );
    },
    [sourceType, account?.id],
  );

  const renderStatus = useCallback(
    ({ status }: IUser) => (
      <span
        role='presentation'
        className={clsx(
          classes.status,
          status === USER_STATUS.PENDING && status,
          status === USER_STATUS.ACTIVE && status,
        )}
      >
        {transformTypeToTitle(status)}
      </span>
    ),
    [sourceType],
  );

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'avatar',
        title: 'Image',
        cellStyle: { width: 40 },
        renderComponent: (item: IUser) => (
          <DriverAvatar
            size={40}
            avatar={item.avatar}
            name={item.display_name}
          />
        ),
      },
      {
        id: 'display_name',
        title: 'Name',
        cellStyle: { minWidth: 150 },
        sortable: true,
        renderComponent: ({ display_name }: IUser) => (
          <ToolTipText text={display_name} maxLength={30} />
        ),
      },
      {
        id: 'role',
        title: 'Role',
        cellStyle: { minWidth: 150 },
        sortable: true,
        renderComponent: ({ role }: IUser) => transformTypeToTitle(role),
      },
      {
        id: 'email',
        title: 'Email',
        cellStyle: { minWidth: 120 },
        sortable: true,
      },
      {
        id: 'status',
        title: 'Status',
        cellStyle: { minWidth: 120 },
        sortable: true,
        renderComponent: renderStatus,
      },
      {
        id: 'action',
        title: 'Action',
        cellStyle: {
          minWidth: 120,
          textAlign: 'right',
        },
        renderComponent: renderAction,
      },
    ],
    [sourceType, account?.id],
  );

  return (
    <Table
      columns={columns}
      dataProps={data}
      noHoverInteraction
      loading={isLoading || loadingUpdate || loadingResent}
      sortBy={convertedSortParams(sort, sort_by)}
      onSort={handleSort}
    />
  );
};

export default withStyles(style)(UserTable);
