import themes, { styled } from '~/themes';
import { Stack, Typography } from '@mui/material';
import BackgroundImage from '~/assets/images/background/setup-address.png';
import Button from '~/components/common/Button';

const StyledContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  height: '100%',
  [theme.breakpoints.down('md')]: {
    containerClass: {
      '& > img:first-child': {
        maxWidth: '100%',
      },
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: 22,
  paddingTop: 0,
}));

const StyledDesc = styled(Typography)(({ theme }) => ({
  color: theme.color.violet900,
  textAlign: 'center',
  marginTop: theme.spacing(1),
  whiteSpace: 'break-spaces',
}));

interface IEmptyStateProps {
  setOpen?: (value: boolean) => void;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({ setOpen }) => (
  <StyledContainer>
    <img
      src={BackgroundImage}
      alt='setup-your-address'
      style={{ maxHeight: 200 }}
    />
    <StyledTitle variant='h2'>Efficient Contact Management</StyledTitle>
    <StyledDesc>
      {
        "Create and store your essential contacts, and when it's time to create a task, simply\nselect your saved contacts for swift, hassle-free deliveries."
      }
    </StyledDesc>
    <Button
      sx={{
        marginTop: themes.spacing(3),
      }}
      buttonType='primary'
      onClick={() => setOpen(true)}
    >
      Add Contact
    </Button>
  </StyledContainer>
);
