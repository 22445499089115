import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

export interface Props extends TooltipProps {
  className?: string;
}

const TooltipStyled = ({ className, children, ...props }: Props) => {
  const renderChildren =
    typeof children !== 'string' ? children : <span>{children}</span>;

  return (
    <Tooltip className={className} placement='right' {...props}>
      {renderChildren}
    </Tooltip>
  );
};

export default TooltipStyled;
