/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Container } from '@mui/system';
import Logo from '~/assets/images/logo/canfleet.svg';
import { bgColors, colors } from '~/themes';
import { Link } from 'react-scroll';
import './index.scss';
import { agreeTermsURL } from '~/constants/auth';

const styles = () => ({
  linkItem: {
    '& > a': {
      display: 'block',
      padding: '10px 12px',
      borderRadius: 10,
    },
    '& .active': {
      color: colors.primaryOrange,
      background: bgColors.lightOrange,
      fontWeight: 'bold',
    },
  },
  loginButton: {
    'width': 'auto',
    'color': '#ED6808',
    'background': 'white',
    'padding': '10px 20px',
    'fontFamily': 'Poppins',
    'fontWeight': 400,
    'boxShadow': '0px 5px 10px #0000001A',
    'fontSize': 16,
    'borderRadius': 10,
    '&:hover': {
      background: '#e0e0e0',
    },
  },
});

const links = [
  'Data Collection and Use',
  'Disclosure of Personal Info',
  'Service and Support',
];

const sections = [
  <>
    <h1>Privacy Policy</h1>
    <p style={{ marginTop: -10, marginBottom: 36 }}>
      Last Update: June 23, 2023
    </p>
    <h4>1. Data Collection and Use of Personal Information</h4>
    <p style={{ marginTop: 16, marginBottom: 36 }}>
      <b>
        In order to maintain and facilitate services, CanFleet collects the
        following personal information:
      </b>
    </p>
    <h5>1-1. User profile information</h5>
    <p>
      We collect your first and last names, date of birth, profile picture,
      phone number, address, and vehicle information to create and update your
      driver account. We also collect payment and banking information to issue
      paystub invoices.
    </p>
    <h5>1-2. Background information</h5>
    <p>
      We collect background information, such as your social insurance number,
      driver's license, and driver's abstract, to verify your identity,
      eligibility to work, and previous driving records. Prior to the time of
      hire, background checks are conducted to ensure maximum safety and
      security for the parties involved.
    </p>
    <h5>1-3. User-generated content</h5>
    <p>
      We collect user-generated content in the form of photographs or other
      forms of media that are submitted to us through customer inquiries and
      feedback. Sources include CanFleet customer service messages, and messages
      and images corresponding to delivery tasks. This information is used to
      enhance support and services for CanFleet customers.
    </p>
    <h5>1-4. Data created during the use of the Service</h5>
    <p>
      At any point during which CanFleet is used, the following information may
      be collected to facilitate in-app features, including:{' '}
    </p>
    <ul>
      <li>
        <b>Location data:</b>
        &nbsp;We collect the Driver's approximate location when CanFleet Driver
        App is open in the foreground or background. We use location data to
        create in-app navigation systems, dispatch tasks by proximity, and track
        deliveries in real-time. The current location information of Drivers is
        also provided to Senders to improve pick-up and delivery estimates for
        Senders.
      </li>
      <li>
        <b>Device data:</b>
        &nbsp;Includes device model ID, device IP address, network data, and
        other unique device identifiers. We use device data to analyze user
        performance, diagnose bugs, and improve usability for future interaction
        designs.
      </li>
      <li>
        <b>Transaction data:</b>
        &nbsp;Includes order details, time and date of the service, delivery
        information, distance traveled, and the total amount charged and earned.
      </li>
    </ul>
    <h5>1-5. Third-Party</h5>
    <p>
      First-time CanFleet users will have the opportunity to create an account
      through third-party services, including Facebook and Gmail.
    </p>
  </>,
  <>
    <h4>2. Disclosure of Personal Information</h4>
    <p>
      All collected information is held on our servers in Canada and Vietnam.
      Personal information may be accessed by our staff for the sole purpose of
      providing the Services defined in the terms of use.{' '}
    </p>
    <p>CanFleet shares the following data to provide a service:</p>
    <ul>
      <li>
        Delivery recipient's name and pickup and dropoff locations, shared with
        the driver
      </li>
      <li>
        Drivers' name, photo, and vehicle information shared with delivery
        recipients to verify personnel
      </li>
      <li>
        Order receipts including relevant invoice information shared with both
        driver(s) and the delivery recipient(s)
      </li>
    </ul>
  </>,
  <>
    <Divider style={{ marginTop: 36, marginBottom: 32 }} />
    <h4>Customer Service and Support</h4>
    <p>
      CanFleet shall remain contactable for all inquiries, whether from the
      Sender or its Recipients, through its email or its call centre from 8 am
      to 8 pm every day, with the exception of Sundays and Public Holidays.
    </p>
    <p>
      Notwithstanding Clause 5.a., CanFleet and the Sender shall agree on the
      following communication channels:
    </p>
    <p>
      <b>For Recipients:</b>
    </p>
    <ul>
      <li>Email: support@canteccouriers.com</li>
      <li>Phone : 604-306-5216</li>
      <li>Between 8 am to 8 pm from Monday to Friday</li>
    </ul>
    <p>
      <b>For Senders:</b>
    </p>
    <ul>
      <li>Email: support@canteccouriers.com</li>
      <li>Phone: 604-306-5216</li>
      <li>Between 8 am to 6 pm from Monday to Friday</li>
    </ul>
    <p>
      <b>For potential sales matters:</b>
    </p>
    <ul>
      <li>Email: sales@canteccouriers.com</li>
      <li>Between 10 am to 6 pm from Monday to Friday</li>
    </ul>
  </>,
];

const Privacy = ({ classes }) => {
  const renderNavbar = () => (
    <div className='nav'>
      <nav>
        <ul>
          {links.map((label, idx) => (
            <li key={label + idx} className={classes.linkItem}>
              <Link
                key={idx}
                activeClass='active'
                to={`section${idx}`}
                spy
                smooth
                offset={-120}
                duration={400}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
  return (
    <div className='container'>
      <Container maxWidth='md'>
        <div className='header'>
          <a href='/' className='text-link'>
            <img className='logo' alt='Logo' src={Logo} />
            <span className='logo__text'>CanFleet</span>
          </a>
        </div>
        {renderNavbar()}
        {sections.map((section, index) => (
          <div
            key={`section${index}`}
            className='section'
            id={`section${index}`}
          >
            {section}
          </div>
        ))}

        <Divider style={{ marginTop: 95, marginBottom: 40 }} />
        <div className='footer'>
          <img className='footer__logo' src={Logo} alt='logo' />
          <div>
            <div className='footer_links'>
              <a href={agreeTermsURL}>
                <span>Terms and Conditions</span>
              </a>
              <a href='/privacy-policy'>
                <span>Privacy Policy</span>
              </a>
            </div>
            <div className='footer__trademark'>
              © 2024 Breadstack Delivery. All rights reserved.
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Privacy);
