import { Box, Stack, styled } from '@mui/system';
import themes from '~/themes';

export const StyledContainer = styled(Stack)(() => ({
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '20px',
}));

export const StyledTitle = styled(Box)(() => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 'bold',
  color: themes.color.violet900,
}));

export const StyledContent = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
}));
