import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import clsx from 'clsx';
import { PaperProps, PopoverOrigin } from '@mui/material';

interface IMenuItem {
  label?: string;
  value: string | null | any;
  disabled?: boolean;
}

export interface ElementSelectProps {
  className?: string;
  iconButton?: string;
  options: Array<IMenuItem>;
  value?: string | null | any;
  optionIconClassName?: string;
  onChange?: (option: IMenuItem) => void;
  elementSelect?: (open?: boolean) => React.ReactNode;
  iconButtonProps?: IconButtonProps;
  paperProps?: PaperProps;
  anchorOrigin?: PopoverOrigin;
}

export const ElementSelect: React.FC<ElementSelectProps> = (props) => {
  const {
    className = '',
    options,
    value = null,
    onChange,
    elementSelect = null,
    iconButtonProps = {},
    paperProps,
    anchorOrigin,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: IMenuItem) => {
    handleClose();
    onChange(option);
  };

  return (
    <>
      <IconButton
        data-testid='menu-button'
        onClick={handleClick}
        {...iconButtonProps}
      >
        {elementSelect(open)}
      </IconButton>
      <Menu
        id='menu'
        className={className}
        classes={{ paper: clsx('customized-scrollbar') }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={value}
            selected={option.value === value}
            onClick={() => handleMenuItemClick(option)}
            disabled={!!option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
