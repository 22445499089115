import { CircularProgress, Stack } from '@mui/material';
import themes from '~/themes';

type FirstLoadingProps = {};

export const FirstLoading: React.FC<FirstLoadingProps> = () => (
  <Stack justifyContent='center' alignItems='center' height='100%'>
    <CircularProgress
      size={40}
      sx={{ color: themes.color.violet900 }}
      thickness={5}
    />
  </Stack>
);
