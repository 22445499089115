import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { Theme } from '~/themes';
import { makeStyles } from '@mui/styles';

export type IconProps = {
  id?: string;
  name: string;
  size?: 'small' | 'medium' | 'large' | number;
  color?: string;
  className?: string;
  useBackgroundImg?: boolean;
  style?: CSSProperties;
  baseUrl?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    '&.small': {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
    '&.medium': {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    '&.large': {
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
  },
}));

export const Icon: React.FC<IconProps> = (props) => {
  const {
    id,
    name,
    color = 'inherit',
    size = 'small',
    className = '',
    useBackgroundImg = false,
    style,
    baseUrl = 'icons',
  } = props;
  const classes = useStyles();

  return (
    <div
      id={id}
      className={clsx(classes.icon, size, className)}
      style={{
        color,
        maskImage: `url(/static/media/${baseUrl}/${name}.svg)`,
        WebkitMaskImage: `url(/static/media/${baseUrl}/${name}.svg)`,
        backgroundColor: color,
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        maskPosition: 'center',
        WebkitMaskPosition: 'center',
        display: 'inline-block',
        width: typeof size === 'number' && size,
        height: typeof size === 'number' && size,
        ...(useBackgroundImg && {
          backgroundImage: `url(/static/media/${baseUrl}/${name}.svg)`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }),
        ...style,
      }}
    />
  );
};
