import { Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import Button from '~/components/common/Button';
import SwitchButton from '~/components/common/SwitchButton';
import Table, { TColumn } from '~/components/common/Table/Table';
import Tooltip from '~/components/common/Tooltip';
import { getNumberWithCurrency } from '~/utils/formatter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import themes from '~/themes';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import {
  useGetListHubZone,
  useUpdateHubZonesMutation,
  useExportPostalCodes,
} from '~/services/api/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { Zone } from '~/models/stores';
import { alertParams, showAlert } from '~/components/common/Alert';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { DialogAddPostalCode } from './DialogAddPostalCode';
import { ImportPostalCode } from './DialogImportPostalCode';
import { DeliveryZoneSets } from '../../types';

type PostalCodeProps = {};

export const PostalCode: React.FC<PostalCodeProps> = () => {
  const [openPostalCodeDialog, setOpenPostalCodeDialog] = useState(false);
  const [zoneSelected, setZoneSelected] = useState<Zone>(null);
  const location = useLocation();
  const search = fromQueryString(location.search);
  const [openImportDialog, setOpenImportDialog] = useState(false);

  const { mutate: updateHubZonesMutation } = useUpdateHubZonesMutation({
    onSuccess: () => {},
  });

  const { mutate: exportPostalCodeMutation, isLoading: loadingExport } =
    useExportPostalCodes();

  const { data: zones, isFetching } = useGetListHubZone({
    action: 'list_all',
    parameters: {
      area_type: DeliveryZoneSets.PostalCode,
      integration_id: search.id,
    },
  });

  const handleExportPostalCode = () => {
    exportPostalCodeMutation(search.id, {
      onSuccess: (response) => {
        let filename = 'Postal_zone_list.xlsx';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (filenameMatch && filenameMatch.length === 2) {
            [, filename] = filenameMatch;
          }
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
    });
  };

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'name',
        title: 'Zone',
        cellStyle: { textAlign: 'left' },
        renderComponent: (item) => (
          <Typography>
            <SwitchButton
              name='update-postcode'
              checked={item.enable}
              noText
              onChange={(e) => {
                updateHubZonesMutation({
                  action: 'update',
                  parameters: {
                    integration_id: search.id,
                    enable: e.target.checked,
                    _id: item.id,
                  },
                });
              }}
            />
            <ToolTipText
              text={item.name}
              maxLength={10}
              textNode={(v) => <span>{v}</span>}
            />
          </Typography>
        ),
      },
      {
        id: 'delivery_fee',
        title: 'Delivery Fee',
        renderComponent: (item) =>
          item.delivery_fee_type === 'percentage' ? (
            `${Number(item.delivery_fee || 0).toFixed(2)}%`
          ) : (
            <Tooltip
              title={getNumberWithCurrency(item.delivery_fee || 0).tooltip}
            >
              <span>
                {getNumberWithCurrency(item.delivery_fee || 0).display}
              </span>
            </Tooltip>
          ),
      },
      {
        id: 'delivery_minimum',
        title: 'Order Minimum',
        renderComponent: (item) => (
          <Tooltip
            style={{ maxWidth: 120 }}
            title={getNumberWithCurrency(item.delivery_minimum || 0).tooltip}
          >
            <span>
              {getNumberWithCurrency(item.delivery_minimum || 0).display}
            </span>
          </Tooltip>
        ),
        cellStyle: { textAlign: 'left' },
      },
      {
        id: 'actions',
        title: '',
        cellStyle: { textAlign: 'left' },
        renderComponent: (item) => (
          <ElementSelect
            paperProps={{
              sx: {
                width: 160,
              },
            }}
            elementSelect={() => (
              <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
            )}
            onChange={(o) => {
              if (o.value === 'edit') {
                setOpenPostalCodeDialog(true);
                setZoneSelected(item);
              }
              if (o.value === 'delete') {
                showAlert({
                  ...alertParams.warning,
                  description:
                    'Once deleted, this zone cannot be recovered.\nAre you sure you want to continue?',
                  okText: 'Yes, Delete',
                  cancelText: 'Cancel',
                  onOk: () => {
                    updateHubZonesMutation({
                      action: 'delete',
                      parameters: {
                        _id: item.id,
                      },
                    });
                  },
                });
              }
            }}
            options={[
              {
                label: 'Edit',
                value: 'edit',
              },
              {
                label: 'Delete',
                value: 'delete',
              },
            ]}
          />
        ),
      },
    ],
    [search],
  );

  return (
    <Stack spacing={0.5}>
      {zones.length ? (
        <Table
          columns={columns}
          dataProps={zones}
          loading={isFetching}
          borderRadiusHeader={false}
        />
      ) : (
        ''
      )}

      <Stack
        direction='row'
        spacing={1}
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '10px 0',
        }}
      >
        <Button
          buttonType='default'
          noRounder
          onClick={() => setOpenPostalCodeDialog(true)}
        >
          Add Postal Code
        </Button>
        <Button
          buttonType='default'
          noRounder
          onClick={() => {
            showAlert({
              ...alertParams.warnDark,
              title:
                'Importing zones will overwrite all the existing zones in this store.',
              onOk: () => {
                setOpenImportDialog(true);
              },
              cancelText: 'Cancel',
              sx: {
                '& .MuiPaper-root': { maxWidth: 480 },
              },
              sxButton: {
                borderRadius: '20px !important',
                width: '125px',
              },
            });
          }}
        >
          Import Postal Code
        </Button>
        <Button
          buttonType='default'
          noRounder
          onClick={handleExportPostalCode}
          loading={loadingExport}
        >
          Export List
        </Button>
      </Stack>
      {openPostalCodeDialog && (
        <DialogAddPostalCode
          open={openPostalCodeDialog}
          zoneSelected={zoneSelected}
          onClose={() => {
            setOpenPostalCodeDialog(false);
            setZoneSelected(null);
          }}
        />
      )}
      {openImportDialog && (
        <ImportPostalCode
          open={openImportDialog}
          setOpen={setOpenImportDialog}
        />
      )}
    </Stack>
  );
};
