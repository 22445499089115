import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { alertParams, showAlert } from '~/components/common/Alert';
import Modal from '~/components/common/Modal';
import { StripeCardForm } from '~/components/shared/StripeCardForm';
import themes from '~/themes';

type DialogUpdatePaymentProps = {
  open: boolean;
  onClose: () => void;
  onUpdatePaymentSuccess?: () => void;
  onCancelClick?: () => void;
};

export const DialogUpdatePayment: React.FC<DialogUpdatePaymentProps> = ({
  open,
  onClose,
  onUpdatePaymentSuccess,
  onCancelClick,
}) => {
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updatePaymentRef = useRef(null);
  return (
    <Modal
      maxWidth={false}
      open={open}
      onClose={onClose}
      title='Update Payment'
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => {
            onCancelClick?.();
            onClose();
          },
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          loading: loadingUpdate,
          onClick: () => {
            updatePaymentRef.current.confirmStripeSetup();
          },
        },
      ]}
      PaperProps={{
        sx: {
          'minHeight': 550,
          'maxWidth': 480,
          '& .MuiDialogContent-root': {
            background: themes.bg.lightPurple,
            padding: `${themes.spacing(2, 5)} !important`,
          },
        },
      }}
    >
      <Box
        p={2}
        sx={{
          background: themes.bg.white,
          borderRadius: '10px',
          height: '100%',
        }}
      >
        <StripeCardForm
          ref={updatePaymentRef}
          onBeforeConfirm={() => setLoadingUpdate(true)}
          onError={(error) => {
            setLoadingUpdate(false);
            if (error?.type !== 'validation_error') {
              showAlert({
                ...alertParams.warning,
                title: 'Warning',
                description:
                  'Could not initialize payment info, please try again.',
                okText: 'Close',
              });
            }
          }}
          onSuccess={() => {
            setLoadingUpdate(false);
            onUpdatePaymentSuccess();
          }}
        />
      </Box>
    </Modal>
  );
};
