import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '~/components/common/Icon';
import Modal from '~/components/common/Modal';
import {
  ChangeBilled,
  ComparePlans,
  SubscriptionPlans,
} from '~/components/shared/Subscriptions';
import { IBillingFrequency } from '~/models/plan';
import themes from '~/themes';
import { addToQueryString, fromQueryString } from '~/utils/queryString';

type DialogSelectPlanProps = {
  open: boolean;
  onClose: () => void;
  onChangePlan?: (data: any) => void;
};
export const DialogSelectPlan: React.FC<DialogSelectPlanProps> = ({
  open,
  onClose,
  onChangePlan,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);
  const frequency = (searchQueries.frequency ||
    IBillingFrequency.Year) as IBillingFrequency;
  const numberOfTasks = searchQueries.number_of_tasks as string;
  const setBillingFrequency = (v) => {
    navigate({
      pathname: location.pathname,
      search: addToQueryString(location.search, { frequency: v }),
    });
  };
  return (
    <Modal
      disableCloseOutside
      open={open}
      maxWidth={false}
      onClose={onClose}
      PaperProps={{
        sx: {
          'position': 'relative',
          'maxWidth': 1040,
          '& .MuiDialogContent-root': {
            padding: 5,
          },
        },
      }}
    >
      <Stack
        mb={2}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box />
        <Typography
          fontWeight='bold'
          color={themes.color.violet900}
          fontSize={30}
          textAlign='center'
        >
          Select a Subscription Plan
        </Typography>
        <IconButton
          sx={{ width: 'fit-content', height: 'fit-content' }}
          onClick={onClose}
        >
          <Icon name='close' size={12} color={themes.color.violet900} />
        </IconButton>
      </Stack>
      <ChangeBilled
        isShowHighlight
        billingFrequency={frequency}
        onChange={(e) => {
          if (e.target.checked) {
            setBillingFrequency(IBillingFrequency.Year);
          } else {
            setBillingFrequency(IBillingFrequency.Month);
          }
        }}
      />
      <Box mt={5}>
        <SubscriptionPlans
          billingFrequency={frequency}
          numberOfTasks={numberOfTasks}
          onChangePlan={({ priceId }) => {
            onClose();
            onChangePlan({
              price_id: priceId,
              frequency,
            });
          }}
        />
      </Box>
      <Typography
        mt={5}
        fontSize={22}
        fontWeight='bold'
        color={themes.color.violet900}
        textAlign='center'
      >
        Compare features across plans
      </Typography>
      <Typography textAlign='center' mt={1}>
        Discover the differences of each individual plan and learn more about
        our product
      </Typography>
      <Box my={5}>
        <ComparePlans />
      </Box>
    </Modal>
  );
};
