import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { GOOGLE_MAPS_API_KEY } from '~/config';

type GoogleMapWrapperProps = {};

export const GoogleMapWrapper: React.FC<GoogleMapWrapperProps> = ({
  children,
}) => {
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div />;
      case Status.FAILURE:
        return <div>Unable to load Google Maps</div>;
      case Status.SUCCESS:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
      default:
        return null;
    }
  };
  return (
    <Wrapper
      apiKey={GOOGLE_MAPS_API_KEY}
      render={render}
      libraries={['geometry', 'drawing', 'marker', 'visualization']}
    />
  );
};
