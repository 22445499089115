import React from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  RadioGroupProps,
  FormLabel,
  SxProps,
} from '@mui/material';
import { createStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { IOptionItem } from '~/models/common';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {},
    root: {
      gap: theme.spacing(1),
    },
    rootDefault: {
      gap: theme.spacing(1),
    },
    item: {
      margin: 0,
    },
    customItem: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(1, 2, 1, 1),
      borderRadius: theme.spacing(0.5),
    },
    fillBackground: {
      background: '#E9E7F6',
    },
    radio: {
      margin: '-9px 0 -9px -9px',
    },
    label: {
      width: '100%',
      marginTop: 2,
    },
    title: {
      fontWeight: 600,
      marginBottom: -2,
    },
    formLabel: {
      'color': theme.color.black,
      'fontSize': 12,
      'marginBottom': theme.spacing(1),
      '&.Mui-focused': {
        color: 'inherit',
      },
    },
  });

export interface IGroupRadioButton extends RadioGroupProps {
  className?: string;
  itemClassName?: string;
  value?: any;
  label?: string;
  options?: IOptionItem[];
  radioProps?: any;
  legend?: string;
  name?: string;
  defaultStyle?: boolean;
  fullWidth?: boolean;
  radioOptionSxProps?: SxProps;
}

const GroupRadioButton: React.FC<
  IGroupRadioButton & WithStyles<typeof style>
> = ({
  classes,
  radioProps,
  value,
  options,
  legend,
  className = '',
  itemClassName = '',
  onChange = () => {},
  defaultStyle = false,
  name,
  radioOptionSxProps,
  fullWidth = false,
  ...props
}) => (
  <FormControl
    className={clsx(classes.container, { [className]: !!className })}
    fullWidth={fullWidth}
  >
    {legend && (
      <FormLabel className={classes.formLabel} component='legend'>
        {legend}
      </FormLabel>
    )}
    <RadioGroup
      {...props}
      classes={{
        root: defaultStyle ? classes.rootDefault : classes.root,
      }}
      aria-label={name}
      name={name}
      data-testid={`${name}-radio-group`}
      value={value}
      onChange={(e, v) => v !== value && onChange(e, v)}
    >
      {options
        ? options.map((x: IOptionItem) => (
            <FormControlLabel
              sx={radioOptionSxProps}
              data-value={
                value === x.value
                  ? 'checked-radio-group'
                  : 'unchecked-radio-group'
              }
              data-testid={`${value}-radio-option`}
              classes={{
                label: classes.label,
              }}
              className={clsx(classes.item, {
                'radio-option-selected': value === x.value,
                [classes.customItem]: !defaultStyle,
                [classes.fillBackground]: false,
                [itemClassName]: !!itemClassName,
              })}
              key={x.value}
              value={x.value}
              disabled={x.disabled}
              control={
                <Radio
                  color='secondary'
                  className={classes.radio}
                  {...radioProps}
                />
              }
              label={
                x.description ? (
                  <>
                    <div className={classes.title}>{x.label}</div>
                    <div>{x.description}</div>
                  </>
                ) : (
                  x.label
                )
              }
            />
          ))
        : []}
    </RadioGroup>
  </FormControl>
);

export default withStyles(style)(GroupRadioButton);
