import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { AddDistanceSchema } from '~/utils/schema/integration';
import { FastField, Field } from '~/components/common/Formik';
import { Grid, Typography } from '@mui/material';
import {
  useCreateHubZonesMutation,
  useUpdateHubZonesMutation,
} from '~/services/api/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { Zone } from '~/models/stores';
import themes from '~/themes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMemo, useState } from 'react';
import { DeliveryZoneSets } from '../../types';
import { FeeTypeElement } from '../DrawnZone/DialogAddZone';

interface DialogAddZoneIProps {
  open: boolean;
  onClose: () => void;
  zoneSelected?: Zone;
  zones: Zone[];
}

export const DistanceTypeElement = ({ type, open }) => (
  <>
    <Typography color={themes.color.violet900}>
      {type === 'kilometer' ? 'Km' : 'Mile'}
    </Typography>
    {open ? (
      <KeyboardArrowDownIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    ) : (
      <KeyboardArrowUpIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    )}
  </>
);

export const DialogAddDistanceRadius: React.FC<DialogAddZoneIProps> = ({
  open,
  onClose,
  zoneSelected,
  zones,
}) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const isEdit = Boolean(zoneSelected);
  const [isFormChange, setIsFormChange] = useState(isEdit);

  const {
    mutate: createHubZonesMutation,
    isLoading: isLoadingCreateHubZonesMutation,
  } = useCreateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const {
    mutate: updateHubZonesMutation,
    isLoading: isLoadingUpdateHubZonesMutation,
  } = useUpdateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const AddDistanceSchemaWithZones = useMemo(
    () =>
      AddDistanceSchema(zones?.filter((zone) => zone?.id !== zoneSelected?.id)),
    [zones],
  );

  const onSubmit = (values: any) => {
    const {
      max_delivery_distance,
      max_delivery_distance_type,
      delivery_fee_type,
      delivery_fee,
      delivery_minimum,
    } = values;

    const params = {
      action: isEdit ? 'update' : 'create',
      parameters: {
        name: 'Distance Radius',
        integration_id: search.id,
        max_delivery_distance,
        max_delivery_distance_type,
        enable: true,
        delivery_fee: delivery_fee || 0,
        delivery_fee_type,
        delivery_minimum: delivery_minimum || 0,
        area_type: DeliveryZoneSets.DistanceRadius,
        ...(isEdit && {
          _id: zoneSelected.id,
        }),
      },
    };
    if (isEdit) {
      return updateHubZonesMutation(params);
    }
    return createHubZonesMutation(params);
  };

  return (
    <Modal
      title={isEdit ? 'Edit Delivery Radius' : 'Add Delivery Radius'}
      open={open}
      onClose={onClose}
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create_distance_radius',
          loading:
            isLoadingCreateHubZonesMutation || isLoadingUpdateHubZonesMutation,
          disabled: !isFormChange,
        },
      ]}
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
    >
      <Formik
        initialValues={
          isEdit
            ? zoneSelected
            : {
                max_delivery_distance: '',
                max_delivery_distance_type: 'kilometer',
                delivery_fee: '',
                delivery_minimum: '',
                delivery_fee_type: 'fixed_amount',
              }
        }
        onSubmit={onSubmit}
        validationSchema={AddDistanceSchemaWithZones}
        enableReinitialize
      >
        {({
          values: { delivery_fee_type, max_delivery_distance_type },
          dirty,
        }) => {
          if (dirty && !isFormChange) setIsFormChange(true);
          return (
            <Form
              id='create_distance_radius'
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field.TextInput
                    type='number'
                    name='max_delivery_distance'
                    legend='Max Delivery Distance'
                    suffix={
                      <Field.ElementSelect
                        name='max_delivery_distance_type'
                        elementSelect={(o: boolean) => (
                          <DistanceTypeElement
                            open={o}
                            type={max_delivery_distance_type}
                          />
                        )}
                        options={[
                          {
                            label: 'Mile',
                            value: 'mile',
                          },
                          {
                            label: 'Km',
                            value: 'kilometer',
                          },
                        ]}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field.TextInput
                    type='number'
                    name='delivery_fee'
                    legend='Delivery Fee'
                    suffix={
                      <Field.ElementSelect
                        name='delivery_fee_type'
                        elementSelect={(o: boolean) => (
                          <FeeTypeElement open={o} type={delivery_fee_type} />
                        )}
                        options={[
                          {
                            label: 'Dollar Value',
                            value: 'fixed_amount',
                          },
                          {
                            label: 'Percentage',
                            value: 'percentage',
                          },
                        ]}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FastField.TextInput
                    type='number'
                    name='delivery_minimum'
                    legend='Order Minimum ($)'
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
