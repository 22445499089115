import { IOrg } from './org';
import { IUserTooltip } from './user';

export interface IAccount {
  id?: string;
  created_at?: string;
  updated_at?: string;
  org_id: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  phone?: string;
  email?: string;
  role?: string;
  status?: string;
  items_per_page?: number;
  timezone?: string;
  timezoneType?: any | string;

  profile_photo?: string;
  avatar?: string;
  avatar_type?: string;
  org?: IOrg;
  tooltip?: IUserTooltip;
  default?: boolean;
}

export enum AdminOptionTabs {
  General = 'account',
  Organization = 'organization',
  Stores = 'stores',
  Drivers = 'drivers',
  Teams = 'teams',
  Users = 'users',
  Plans = 'plans',
  Billing = 'billing',
}
