import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { alertParams, showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import Button, { LinkButton } from '~/components/common/Button';
import CheckBox from '~/components/common/CheckBox';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { Icon } from '~/components/common/Icon';
import Modal from '~/components/common/Modal';
import { Select } from '~/components/common/Select';
import { StripeCardForm } from '~/components/shared/StripeCardForm';
import { agreeTermsURL } from '~/constants/auth';
import { queryClient } from '~/lib/react-query';
import {
  IBillingFrequency,
  IPlan,
  IPlanMetadata,
  IPlanTier,
} from '~/models/plan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import {
  updateSubscriptions,
  useCreateSubscriptions,
  usePreviewSubscriptions,
  useUpdateSubscriptions,
} from '~/services/api/plan';
import { queryKeys } from '~/services/api/queryKeys';
import themes, { styled } from '~/themes';
import { formatPrice } from '~/utils/formatter';

type DialogSubscriptionProps = {
  open: boolean;
  frequencySelected: IBillingFrequency;
  planSelected: IPlanMetadata;
  currentPlan: IPlan;
  onClose: () => void;
  onDowngradePlan: (priceId: string) => void;
};
export const StyledSubscriptionItem = styled(Stack)(({ theme }) => ({
  borderRadius: '10px',
  background: theme.bg.white,
  padding: 20,
}));
export const StyledSummaryItem = styled(Stack)(() => ({
  color: '#282E3A',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogSubscription: React.FC<DialogSubscriptionProps> = ({
  open,
  frequencySelected,
  planSelected = {},
  currentPlan,
  onDowngradePlan,
  onClose,
}) => {
  const { org, plans, plansOrigin } = usePaymentInfo();
  const [isEditCard, setIsEditCard] = useState(false);
  const [isCheckedTerm, setIsCheckedTerm] = useState(true);
  const hasCard = !!org?.card?.last4;
  const [priceId, setPriceId] = useState(null);
  const [frequency, setFrequency] = useState<IBillingFrequency>(null);

  const updatePaymentRef = useRef(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const {
    mutate: createSubscriptionsMutation,
    isLoading: isLoadingCreateSubscriptionsMutation,
  } = useCreateSubscriptions({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
      showAlert({ ...alertParams.success, title: 'Updated Successfully' });
    },
    onError: () => {
      queryClient.refetchQueries(['getBillingInfo']);
    },
  });

  const {
    mutate: updateSubscriptionsMutation,
    isLoading: isLoadingUpdateSubscriptions,
  } = useUpdateSubscriptions({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
      showAlert({
        icon: 'success',
        title: 'Updated Successfully',
        okText: 'Ok',
      });
    },
    onError: () => {
      queryClient.refetchQueries(['getBillingInfo']);
    },
  });

  useEffect(() => {
    if (planSelected?.priceId) {
      setPriceId(planSelected?.priceId);
    }
    if (frequencySelected) {
      setFrequency(frequencySelected);
    }
  }, [planSelected, frequencySelected]);

  useEffect(() => {
    if (!hasCard) {
      setIsEditCard(true);
    }
  }, [hasCard]);

  const planOptions = useMemo(() => {
    const opts = [
      {
        tier: IPlanTier.Standard,
        frequency: IBillingFrequency.Month,
        label: 'Standard',
      },
      {
        tier: IPlanTier.Standard,
        frequency: IBillingFrequency.Year,
        label: 'Standard',
      },
      {
        tier: IPlanTier.Advance,
        frequency: IBillingFrequency.Month,
        label: 'Advanced',
      },
      {
        tier: IPlanTier.Advance,
        frequency: IBillingFrequency.Year,
        label: 'Advanced',
      },
    ]
      .map((e) => ({
        ...e,
        value:
          plans.find(
            (p) =>
              p.tier === e.tier &&
              p.frequency === e.frequency &&
              // eslint-disable-next-line no-underscore-dangle
              p.type_ === 'plan',
          )?.price_id || null,
      }))
      .filter((o) => o.value !== currentPlan.price_id);

    // if (currentPlan.frequency === IBillingFrequency.Year) {
    //   return opts.filter(
    //     (o) =>
    //       !(
    //         o.tier === currentPlan.tier &&
    //         o.frequency === IBillingFrequency.Year
    //       ),
    //   );
    // }

    return opts;
  }, [plans, currentPlan]);

  const tierSelect = useMemo(
    () => planOptions?.find((p) => p.value === priceId)?.tier,
    [planOptions, priceId],
  );

  const getBillingFrequencyPrice = (v: IBillingFrequency) => {
    const price = plans.find(
      // eslint-disable-next-line no-underscore-dangle
      (p) => p.tier === tierSelect && p.frequency === v && p.type_ === 'plan',
    )?.metadata?.price_num;

    return formatPrice(price);
  };

  const getCurrentPriceNumSelect = () => {
    let price = plans.find((p) => p.price_id === priceId)?.metadata?.price_num;
    if (frequency === IBillingFrequency.Year) {
      price *= 12;
    }
    return formatPrice(price, {
      minimumFractionDigits: 2,
    });
  };

  const isCreateSubscription = useMemo(() => {
    const currentTier = currentPlan.tier;
    if (org.subscription_id) return false;
    if (currentTier === IPlanTier.Standard) {
      return tierSelect === IPlanTier.Advance;
    }
    if (currentTier === IPlanTier.Advance) {
      return false;
    }
    return true;
  }, [tierSelect, priceId, currentPlan, org]);

  const isUpgradeSubscription = useMemo(
    () =>
      org.subscription_id &&
      currentPlan.tier === IPlanTier.Standard &&
      tierSelect === IPlanTier.Advance,
    [tierSelect, priceId, currentPlan, org],
  );

  const isDowngradeSubscription = useMemo(
    () =>
      org.subscription_id &&
      currentPlan.tier === IPlanTier.Advance &&
      tierSelect === IPlanTier.Standard,
    [tierSelect, priceId, currentPlan, org],
  );

  const isSwitchSubscription = useMemo(
    () => org.subscription_id && currentPlan.tier === tierSelect,
    [tierSelect, priceId, currentPlan, org],
  );

  const { data: previewSubscription } = usePreviewSubscriptions({
    enabled: !!priceId,
    params: {
      subId: org?.subscription_id,
      priceId,
    },
  });

  const getDueTodayPrice = () => {
    // if (isCreateSubscription) return getCurrentPriceNumSelect();
    if (isSwitchSubscription || isDowngradeSubscription) {
      return '$0.00';
    }

    if (previewSubscription) {
      const changePlanFee = previewSubscription?.change_plan_fee ?? 0;
      const taxFee = previewSubscription?.tax_fee ?? 0;
      return formatPrice(changePlanFee + taxFee, {
        minimumFractionDigits: 2,
      });
    }
    return '-';
  };

  const nextBillingDateTimeText = useMemo(
    () =>
      `This plan will auto-renew on ${moment(
        previewSubscription.effective_date,
      ).format('MM/DD/YYYY')}`,
    [previewSubscription],
  );

  const getGraduatedPriceId = (val: IPlanTier) => {
    if (val === IPlanTier.Free) return null;
    return (
      plans
        // eslint-disable-next-line no-underscore-dangle
        .filter((p) => p.type_ === 'graduated')
        .find((p) => p.tier === val)?.price_id
    );
  };

  const handleUpgradeAdditionalSubscriptions = async (params) => {
    try {
      await updateSubscriptions(params);
    } catch (err) {
      showAlert(errorAlert(err));
    }
  };

  const handleUpdateSubscription = (createSubPriceId?: string) => {
    if (isCreateSubscription) {
      createSubscriptionsMutation({
        payment_account_id: org.payment_account_id,
        price_id: createSubPriceId,
        quantity: 1,
      });
    } else if (isUpgradeSubscription || isSwitchSubscription) {
      // if upgrade plan -> upgrade additional
      if (isUpgradeSubscription) {
        handleUpgradeAdditionalSubscriptions({
          subId: org.graduated_subscription_id,
          price_id: getGraduatedPriceId(tierSelect),
        });
      }
      updateSubscriptionsMutation({
        subId: org.subscription_id,
        price_id: priceId,
      });
    } else {
      onClose();
      onDowngradePlan(priceId);
    }
  };

  const renderCarInfo = () => (
    <Stack
      direction='row'
      sx={{ border: '1px solid #DEE2E6', p: 1, borderRadius: '4px' }}
    >
      <Icon name={org?.card?.brand} useBackgroundImg style={{ width: 24 }} />
      <Typography ml={1}>Visa ending with {org?.card?.last4}</Typography>
      <LinkButton sx={{ ml: 'auto' }} onClick={() => setIsEditCard(true)}>
        Edit Card
      </LinkButton>
    </Stack>
  );

  return (
    <Modal
      title='Subscription'
      open={open}
      maxWidth='lg'
      onClose={onClose}
      disableCloseOutside
      PaperProps={{
        sx: {
          'maxWidth': 700,
          '& .MuiDialogContent-root': {
            background: themes.bg.lightPurple,
            pt: '20px !important',
          },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <StyledSubscriptionItem>
            <Typography variant='h4'>Select a plan</Typography>
            <Select
              options={planOptions.filter((p) => p.frequency === frequency)}
              value={priceId}
              sx={{ mt: 2 }}
              onChange={(e) => setPriceId(e.target.value)}
            />
            <Typography mt={1}>
              This plan allows additional tasks, which will be billed at the end
              of the monthly billing cycle.
            </Typography>

            <Typography mt={2}>Billing Frequency</Typography>
            <GroupRadioButton
              id='billing-frequency'
              value={frequency}
              sx={{ mt: 1, mb: 2 }}
              radioOptionSxProps={{
                '&.radio-option-selected': {
                  background: themes.bg.midPurple,
                },
                'background': themes.bg.lightPurple,
              }}
              onChange={(e) => {
                let pId;
                if (
                  planOptions.find(
                    (p) =>
                      p.frequency === e.target.value && p.tier === tierSelect,
                  )
                ) {
                  pId = planOptions.find(
                    (p) =>
                      p.frequency === e.target.value && p.tier === tierSelect,
                  )?.value;
                } else {
                  pId = planOptions.filter(
                    (p) => p.frequency === e.target.value,
                  )[0]?.value;
                }

                setPriceId(pId);
                setFrequency(e.target.value as IBillingFrequency);
              }}
              options={[
                {
                  value: IBillingFrequency.Year,
                  label: (
                    <Stack direction='row' justifyContent='space-between'>
                      <span>Annually (20% off)</span>
                      <span>
                        {getBillingFrequencyPrice(IBillingFrequency.Year)}/mo
                      </span>
                    </Stack>
                  ),
                },
                {
                  value: IBillingFrequency.Month,
                  label: (
                    <Stack direction='row' justifyContent='space-between'>
                      <span>Monthly</span>
                      <span>
                        {getBillingFrequencyPrice(IBillingFrequency.Month)}/mo
                      </span>
                    </Stack>
                  ),
                },
              ]}
            />

            <CheckBox
              checked={isCheckedTerm}
              onChange={(e) => setIsCheckedTerm(e.target.checked)}
            >
              <>
                I agree to all the{' '}
                <a
                  href={agreeTermsURL}
                  target='_blank'
                  rel='noreferrer'
                  style={{ color: themes.color.primaryOrange, fontSize: 14 }}
                >
                  Agreements and Terms
                </a>
              </>
            </CheckBox>
          </StyledSubscriptionItem>

          <StyledSubscriptionItem mt={2}>
            <Typography variant='h4' mb={1}>
              Payment Info
            </Typography>
            {!isEditCard && hasCard ? (
              renderCarInfo()
            ) : (
              <StripeCardForm
                ref={updatePaymentRef}
                onBeforeConfirm={() => setLoadingUpdate(true)}
                onError={(error) => {
                  setLoadingUpdate(false);
                  if (error?.type !== 'validation_error') {
                    showAlert({
                      ...alertParams.warning,
                      title: 'Warning',
                      description:
                        'Could not initialize payment info, please try again.',
                      okText: 'Close',
                    });
                  }
                }}
                onSuccess={(resp) => {
                  let country = resp[0]?.billing_details?.address?.country;
                  if (!country) {
                    showAlert(
                      errorAlert('Can not get country after add card.'),
                    );
                    return;
                  }

                  country = country === 'CA' ? country : 'US';
                  if (org?.originalCountry) {
                    country = org?.originalCountry;
                  }
                  const priceIdByCountry =
                    plansOrigin.find(
                      (p) =>
                        p.tier === tierSelect &&
                        p.frequency === frequency &&
                        p.country === country &&
                        // eslint-disable-next-line no-underscore-dangle
                        p.type_ === 'plan',
                    )?.price_id || 'price_free';
                  handleUpdateSubscription(priceIdByCountry);
                  setLoadingUpdate(false);
                }}
              />
            )}
          </StyledSubscriptionItem>
        </Grid>

        <Grid item xs={12} md={5}>
          <StyledSubscriptionItem spacing={1}>
            <Typography variant='h4' mb={1}>
              Summary
            </Typography>
            <StyledSummaryItem>
              <span>Plan</span>
              <span>{getCurrentPriceNumSelect() || '$0'}</span>
            </StyledSummaryItem>
            <StyledSummaryItem>
              <span>Tax</span>
              <span>
                {previewSubscription?.tax_fee ||
                previewSubscription?.tax_fee === 0
                  ? formatPrice(previewSubscription?.tax_fee, {
                      hasCurrencyPrefix: false,
                    })
                  : '-'}
              </span>
            </StyledSummaryItem>
            <StyledSummaryItem fontWeight='bold' fontSize={16}>
              <span>Due today</span>
              <span>{getDueTodayPrice()}</span>
            </StyledSummaryItem>
          </StyledSubscriptionItem>
          <Button
            fullWidth
            buttonType='primary-dark'
            sx={{ mt: 1 }}
            disabled={!isCheckedTerm || !priceId}
            loading={
              isLoadingCreateSubscriptionsMutation ||
              loadingUpdate ||
              isLoadingUpdateSubscriptions
            }
            onClick={() => {
              if (isEditCard) {
                updatePaymentRef.current.confirmStripeSetup();
              } else {
                handleUpdateSubscription(priceId);
              }
            }}
          >
            {isCreateSubscription || isUpgradeSubscription
              ? 'Pay Now'
              : 'Continue'}
          </Button>
          <Typography variant='body2' mt={1}>
            {nextBillingDateTimeText}
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
