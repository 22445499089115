import { IconButton, IconButtonProps } from '@mui/material';
import themes, { styled } from '~/themes';
import { Icon } from '../Icon';

interface CloseButtonProps extends IconButtonProps {
  iconSize?: number;
  iconColor?: string;
  background?: string;
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background: string }>(({ theme, background }) => ({
  background: background || theme.bg.midPurple,
}));

export const CloseButton: React.FC<CloseButtonProps> = ({
  iconSize = 8,
  iconColor = themes.color.violet900,
  background,
  ...rest
}) => (
  <StyledIconButton
    data-testid='btn-close'
    aria-label='close'
    size='medium'
    background={background}
    {...rest}
  >
    <Icon name='close' size={iconSize} color={iconColor} />
  </StyledIconButton>
);
