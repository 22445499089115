import { getIn } from 'formik';
import { IFormProps } from '~/models/common';
import {
  ISearchInputProps,
  SearchInput as SearchInputRaw,
} from '../Search/SearchInput';

export interface IFieldSearchInput
  extends IFormProps,
    Omit<ISearchInputProps, 'name'> {
  disableErrorText?: boolean;
}

const SearchInput: React.FC<IFieldSearchInput> = ({
  field,
  form,
  error,
  helperText,
  inputProps = {},
  disableErrorText,
  onChange,
  ...props
}) => (
  <SearchInputRaw
    id={field.name}
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      disableErrorText
        ? ''
        : helperText ||
          (Boolean(getIn(form.touched, field.name)) &&
            getIn(form.errors, field.name))
    }
    inputProps={{
      ...field,
      ...inputProps,
      ...(onChange ? { onChange } : {}),
      value: field.value ?? '',
    }}
    isSearching={!!field.value}
    {...props}
  />
);

export default SearchInput;
