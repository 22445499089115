import { CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ConnectionStoreStatus } from '~/models/stores';
import themes, { styled } from '~/themes';
import NotConnectedIcon from '~/assets/images/icons/not-connected.svg';
import ConnectedIcon from '~/assets/images/icons/connected.svg';

type StoreConnectionProps = { status: ConnectionStoreStatus };
export const StyledConnectedIcon = styled('img')(() => ({
  width: 15,
  height: 15,
}));

export const StoreConnection: React.FC<StoreConnectionProps> = ({ status }) => {
  const contentComponent = useMemo(() => {
    if (status === ConnectionStoreStatus.Checking) {
      return (
        <>
          <CircularProgress
            sx={{
              width: '14px !important',
              height: '14px !important',
              color: themes.color.violet600,
            }}
            thickness={5}
          />
          <Typography>Checking connection…</Typography>
        </>
      );
    }
    if (status === ConnectionStoreStatus.Connected) {
      return (
        <>
          <StyledConnectedIcon alt='connection-icon' src={ConnectedIcon} />
          <Typography color={themes.color.success}>Connected</Typography>
        </>
      );
    }

    if (status === ConnectionStoreStatus.NotConnected) {
      return (
        <>
          <StyledConnectedIcon alt='connection-icon' src={NotConnectedIcon} />
          <Typography color={themes.color.red}>Not Connected</Typography>
        </>
      );
    }
    return '';
  }, [status]);

  return (
    <Stack
      width='100%'
      spacing={0.5}
      direction='row'
      alignItems='center'
      mt={2}
      mb={3}
    >
      {contentComponent}
    </Stack>
  );
};
