import { Box, Stack, Typography } from '@mui/material';
import { ITrackData } from '~/models/track';
import { styled } from '~/themes';
import moment, { CalendarSpec, Moment } from 'moment';

type TrackVerticalProgressProps = {
  value?: number;
  data?: ITrackData;
};

export const StyledLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 2,
  backgroundColor: theme.bg.gray600,
  top: 7,
  left: 3,
  zIndex: -1,
}));

export const StyledTrackItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked: boolean }>(({ checked }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 18,
  ...(checked && {
    '& > div': {
      backgroundColor: `#121142 !important`,
      border: 'none',
    },
  }),
}));

export const StyledTrackIcon = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: theme.spacing(0.8),
  height: theme.spacing(0.8),
  borderRadius: '100%',
  marginRight: theme.spacing(1),
  backgroundColor: '#BEBEBE',
}));

export const StyledTrackTitle = styled(Typography)<{ active: boolean }>(
  ({ theme, active }) => ({
    color: '#9E9E9E',
    fontWeight: 500,
    fontSize: 14,
    ...(active && {
      color: theme.color.black,
    }),
  }),
);

export const formatDateTimeLocal = (
  date?: string | Date | Moment,
  formats?: CalendarSpec,
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
        .local()
        .calendar(null, {
          sameDay: 'h:mm a | [Today]',
          nextDay: 'h:mm a | [Tmrw]',
          lastDay: 'h:mm a | [Ystd]',
          nextWeek: 'h:mm a | MM/DD',
          lastWeek: 'h:mm a | MM/DD',
          sameElse: 'h:mm a | MM/DD',
          ...formats,
        })
    : 'ASAP';

export const TrackVerticalProgress: React.FC<TrackVerticalProgressProps> = ({
  value,
  data,
}) => {
  const calendarFormats = {
    sameDay: 'h:mm a',
    nextDay: 'h:mm a [| tomorrow]',
    lastDay: 'h:mm a [| yesterday]',
    nextWeek: 'h:mm a | MM/DD/YYYY',
    lastWeek: 'h:mm a | MM/DD/YYYY',
    sameElse: 'h:mm a | MM/DD/YYYY',
  };

  const progressList = [
    {
      label: 'Order Received',
      value: 0.25,
    },
    {
      label: 'In Transit',
      value: 0.5,
    },
    {
      label: 'Out for Delivery',
      value: 0.75,
    },
    {
      label: 'Delivered',
      value: 1,
    },
  ];

  const getTrackTime = (val, progress_bar) => {
    switch (val) {
      case 0.25:
        return progress_bar >= 0.25
          ? formatDateTimeLocal(data?.created_at, calendarFormats)
          : '';
      case 0.5:
        return progress_bar >= 0.5
          ? formatDateTimeLocal(
              data?.picked_up_at || data?.created_at,
              calendarFormats,
            )
          : '';
      case 0.75:
        return progress_bar >= 0.75
          ? formatDateTimeLocal(data?.out_for_delivery_time, calendarFormats)
          : '';

      case 1:
        return progress_bar >= 1
          ? formatDateTimeLocal(data?.delivery_completed_time, calendarFormats)
          : '';

      default:
        return '';
    }
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        mt: 1.4,
        mb: 0.2,
      }}
    >
      <StyledLine sx={{ height: (progressList.length + 1) * 22 }} />
      {progressList.map((item) => (
        <Stack
          key={item.value}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <StyledTrackItem
            checked={
              item.value === value || (value === 0.9 && item.value === 0.75)
            }
          >
            <StyledTrackIcon />
            <StyledTrackTitle
              active={
                item.value === value || (value === 0.9 && item.value === 0.75)
              }
            >
              {item.label}
            </StyledTrackTitle>
          </StyledTrackItem>
          <StyledTrackTitle
            active={
              item.value === value || (value === 0.9 && item.value === 0.75)
            }
          >
            {getTrackTime(item.value, data?.progress_bar)}
          </StyledTrackTitle>
        </Stack>
      ))}
    </Stack>
  );
};
