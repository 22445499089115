import { useMemo } from 'react';
import moment from 'moment';
import { Select } from '~/components/common/Select';

type HoursSelectProps = {
  timeGap: number;
  value?: string;
  id?: string;
  maxMinutesTimeSlot?: number;
  minMinutesTimeSlot?: number;
  onChange: (e: any) => void;
  legend?: React.ReactNode | string;
  error?: boolean;
  helperText?: string;
};

export const HoursSelect = (props: HoursSelectProps) => {
  const {
    timeGap,
    value,
    id,
    maxMinutesTimeSlot,
    minMinutesTimeSlot,
    onChange,
    legend,
    ...rest
  } = props;
  const times = useMemo(() => {
    let loop = 0;
    let hour = 0;
    const arrSelectTimes = [];
    for (let i = 0; i <= (24 * 60) / timeGap; i += 1) {
      let label = `${
        hour.toLocaleString().length === 1 ? `0${hour}` : hour
      } : ${
        (loop * timeGap).toLocaleString().length === 1
          ? `0${loop * timeGap}`
          : loop * timeGap
      }`;
      if (hour * 60 + loop * timeGap === 1440) {
        label = '23:59';
      }
      if (
        !(
          minMinutesTimeSlot && minMinutesTimeSlot >= hour * 60 + loop * timeGap
        ) &&
        !(
          maxMinutesTimeSlot && maxMinutesTimeSlot <= hour * 60 + loop * timeGap
        )
      ) {
        arrSelectTimes.push({
          value: moment(label, 'HH:mm').format('HH:mm'),
          label: moment(label, 'hh:mm').format('hh:mm a'),
        });
      }

      loop += 1;
      if (loop === 60 / timeGap) {
        hour += 1;
      }
      if (loop === 60 / timeGap) {
        loop = 0;
      }
    }
    return arrSelectTimes;
  }, [timeGap, maxMinutesTimeSlot, minMinutesTimeSlot]);

  return (
    <Select
      options={times}
      fullWidth={false}
      value={value}
      id={id}
      onChange={onChange}
      paperMaxHeight={500}
      legend={legend}
      formControlProps={{
        sx: {
          'width': '100%',
          'minWidth': 150,
          '& > div': {
            background: 'white',
          },
        },
      }}
      {...rest}
    />
  );
};
