import { createStyles } from '@mui/styles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    formControlLabel: {
      'display': 'flex',
      'flex': 1,
      'alignItems': 'center',
      '& .MuiFormControlLabel-label': {
        color: theme.color.black,
        fontSize: 14,
      },
    },
    checkedIcon: {
      width: '18px !important',
      height: '18px !important',
      border: `1px solid ${theme.bg.purpleOutline}`,
      borderRadius: 5,
      background: theme.bg.lightPurple,
      fill: theme.color.violet900,
    },
    uncheckedIcon: {
      'width': '18px !important',
      'height': '18px !important',
      'border': `1px solid ${theme.bg.purpleOutline}`,
      'borderRadius': 5,
      'color': 'transparent',
      'background': theme.bg.lightPurple,
      '&.error': {},
    },
    disabledBorder: {
      border: `1px solid ${theme.bg.purpleOutline}`,
    },
  });

export default style;
