export const queryKeys = {
  liveTracking: {
    getListDrivers: 'getListDrivers',
    getPickupHubLocation: 'getPickupHubLocation',
    getPickupHubLocationDetail: 'getPickupHubLocationDetail',
    getDriverRoutes: 'getDriverRoutes',
  },
  settings: {
    account: 'getAccounts',
  },
  userManagement: {
    getUsers: 'getUsers',
    getUser: 'getUser',
  },
  stores: {
    getListIntegration: 'getListIntegration',
    getListZone: 'getListZone',
    checkConnectionOnboarding: 'checkConnectionOnboarding',
    getStatusUploadPostalCode: 'getStatusUploadPostalCode',
    previewTimeSlot: 'previewTimeSlot',
  },
  orgManagement: {
    org: 'getOrg',
  },
  billingManagement: {
    invoices: 'getInvoices',
  },
  shared: {
    getCountries: 'getCountries',
    getHubList: 'getHubList',
    getTeamList: 'getTeamList',
    getTeamDetail: 'getTeamDetail',
    getNoteList: 'getNoteList',
  },
  driver: {
    getDriverList: 'getDriverList',
    getDriverDetail: 'getDriverDetail',
    getDriverLog: 'getDriverLog',
    getDriverDoc: 'getDriverDoc',
  },
  task: {
    getTaskList: 'getTaskList',
    getTaskDetail: 'getTaskDetail',
    getTaskLog: 'getTaskLog',
    getTaskPhoto: 'getTaskPhoto',
    getTaskTravelAnalytics: 'getTaskTravelAnalytics',
    getIndependentDrivers: 'getIndependentDrivers',
    getDistanceFromDriverToPickup: 'getDistanceFromDriverToPickup',
  },
  invitation: {
    getInvitation: 'getInvitation',
    validateInvitation: 'validateInvitation',
  },
  timezone: {
    getTimezone: 'getTimezone',
  },
  track: {
    getTrack: 'getTrack',
  },
  dashboard: {
    getDashboard: 'getDashboard',
  },
  address: {
    getAddress: 'getAddress',
  },
};
