import { Divider, Grid, Stack, Typography } from '@mui/material';
import { FastField, Field } from '~/components/common/Formik';
import { useFormikContext } from 'formik';
import {
  getSuggestionAddress,
  getSuggestionAddressDetail,
} from '~/services/api/task';
import { useCallback, useMemo } from 'react';
import {
  convertGoogleLocationDetail,
  formatSuggestionsAddress,
} from '~/utils/formatter';
import { CountryListItem } from '~/models/common';
import AddressIcon from '~/assets/images/icons/address-gray.svg';
import { styled } from '~/themes';
import { useAuth } from '~/providers/AuthProvider';

export const StyledTransitTitle = styled(Typography)(() => ({
  fontWeight: 600,
  alignItems: 'center',
}));

export const StyledGridTitle = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(-1),
}));

interface IAddressInputProps {}

export const AddressInput: React.FC<IAddressInputProps> = () => {
  const { values, setFieldValue, setValues } = useFormikContext<any>();

  const { countries, isFetchingCountry } = useAuth();

  const states = useMemo(
    () =>
      countries.find(({ value }: CountryListItem) => value === values.country)
        ?.states || [],
    [countries, values.country],
  );

  const renderAddressLabelItem = (item) => (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <img src={AddressIcon} alt='address' />
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 14,
        }}
      >
        {item.formatted_address}
      </Typography>
    </Stack>
  );

  const onChangeAddress = useCallback(
    async (_, val) => {
      try {
        const res = await getSuggestionAddressDetail(val.place_id);
        const result = convertGoogleLocationDetail(res.result);

        setValues({
          ...values,
          address_1: val.value,
          country: result.country,
          state: result.state,
          city: result.city,
          postcode: result.postal_code,
        });
      } catch (err) {
        setValues({
          ...values,
          name: '',
          phone: '',
          address_1: '',
          country: '',
          state: '',
          city: '',
          postcode: '',
          country_code: '',
        });
      }
    },
    [values],
  );

  const onBlurAddress = useCallback(
    async (value) => {
      setTimeout(() => {
        setFieldValue('address_1', value || '');
      }, 50);
    },
    [setFieldValue],
  );

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <StyledGridTitle item xs={12} key='contact_info'>
        <StyledTransitTitle>Contact Info</StyledTransitTitle>
      </StyledGridTitle>
      <Grid item xs={12} md={6} key='name'>
        <FastField.TextInput name='name' legend='Name' />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6} key='phone'>
        <Field.TextInput
          legend='Phone Number (Optional)'
          name='phone'
          type='tel'
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          prefix={<FastField.CountryCodeDropdown name='country_code' />}
        />
      </Grid>
      <Grid item xs={12} md={6} key='email'>
        <FastField.TextInput name='email' legend='Email (Optional)' />
      </Grid>
      <Grid item xs={12} key='hr'>
        <Divider />
      </Grid>
      <StyledGridTitle item xs={12} key='address_details'>
        <StyledTransitTitle>Address Details</StyledTransitTitle>
      </StyledGridTitle>
      <Grid item xs={12} key='address_1'>
        <Field.AsyncSelect
          name='address_1'
          legend='Address Line 1'
          onBlur={onBlurAddress}
          fetchOptions={(debouncedSearchText) =>
            getSuggestionAddress(
              formatSuggestionsAddress({
                query: debouncedSearchText,
                country: values.country,
                state: values.state,
              }),
            )
          }
          transformOptions={(data: any) =>
            (data?.results || []).map((item: any) => ({
              customLabel: renderAddressLabelItem(item),
              value: item?.formatted_address
                ? item.formatted_address.split(',')[0]
                : item.name,
              place_id: item.place_id,
              label: '',
            }))
          }
          onChange={onChangeAddress}
        />
      </Grid>
      <Grid item xs={12} key='address_2'>
        <FastField.TextInput
          legend='Address Line 2 (Optional)'
          name='address_2'
        />
      </Grid>
      <Grid item xs={12} md={6} key='country'>
        <Field.Select
          options={countries}
          legend='Country'
          name='country'
          disabled={isFetchingCountry}
          onChange={() => {
            setFieldValue('state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} key='state'>
        <Field.Select
          legend='Province / State'
          name='state'
          options={states}
          disabled={isFetchingCountry}
        />
      </Grid>
      <Grid item xs={12} md={6} key='city'>
        <FastField.TextInput legend='City' name='city' />
      </Grid>
      <Grid item xs={12} md={6} key='postcode'>
        <FastField.TextInput legend='Postal Code' name='postcode' />
      </Grid>
    </Grid>
  );
};
