import { forwardRef } from 'react';
import { useGetBillingInfo } from '~/services/api/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress, Stack } from '@mui/material';
import themes, { styled } from '~/themes';
import { StripeCardElement, StripeCardElementProps } from './StripeCardElement';

interface StripeCardFormProps extends StripeCardElementProps {}

export const StyledLoading = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: 400,
}));

export const StripeCardForm = forwardRef(
  (props: StripeCardFormProps, ref: React.Ref<any>) => {
    const { data: billingInfo, isFetching } = useGetBillingInfo({});

    const stripePromise = billingInfo?.publishable_key
      ? loadStripe(billingInfo.publishable_key)
      : null;

    if (isFetching)
      return (
        <StyledLoading>
          <CircularProgress size={30} />
        </StyledLoading>
      );

    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: billingInfo?.client_secret,
          loader: 'always',
          appearance: {
            variables: {
              fontLineHeight: '1.5',
              borderRadius: '18px',
              colorPrimary: themes.color.black,
              colorBackground: themes.bg.lightPurple,
              colorPrimaryText: themes.color.black,
              colorDanger: themes.color.borderRed,
              focusBoxShadow: 'none',
              fontSizeSm: '12px',
            },
            rules: {
              '.Label': {
                fontSize: '12px',
                color: 'var(--colorPrimaryText)',
                fontFamily: 'inherit',
                marginTop: '10px',
                fontWeight: '500',
              },
              '.Input': {
                fontSize: '14px',
                padding: '9px',
                border: '1px solid #E9E7F7',
                background: 'var(--colorBackground)',
                color: themes.color.black,
              },
              '.Input:focus': {
                borderColor: themes.color.black,
              },
              '.Input--invalid': {
                boxShadow: 'none',
              },
            },
          },
        }}
      >
        {!isFetching && billingInfo?.publishable_key && (
          <StripeCardElement ref={ref} {...props} />
        )}
      </Elements>
    );
  },
);
