import React, { useCallback } from 'react';
import { Grid, Divider, Typography, FormLabel, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useFormikContext } from 'formik';
import { Theme } from '~/themes';
import { Field, FastField } from '~/components/common/Formik';
import { CountryListItem } from '~/models/common';
import { ICustomerSupport, IDriver } from '~/models/driver';
import { formatPhoneNumber } from '~/utils/formatter';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { useAuth } from '~/providers/AuthProvider';

const style = (theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
      marginTop: theme.spacing(0.5),
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
    phone: {
      color: theme.color.black,
      marginTop: 5,
      background: '#FAFAFA',
      padding: 9,
      borderRadius: 80,
    },
    formLabel: {
      color: theme.color.black,
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  });

interface IProps extends WithStyles<typeof style> {}

const Profile: React.FC<IProps> = ({ classes }) => {
  const { values, setFieldValue } = useFormikContext<IDriver>();
  const { countries, isFetchingCountry } = useAuth();

  const CustomerSupportOptions = [
    {
      value: ICustomerSupport.ContactDriverPhone,
      label: 'Customers can only contact the driver’s phone number.',
    },
    {
      value: ICustomerSupport.ContactAlternativePhone,
      label:
        values.customer_support === ICustomerSupport.ContactAlternativePhone ? (
          <>
            <Typography>
              Customers can contact an alternative phone number.
            </Typography>
            <Box mt={2} ml={-2.5} maxWidth={300}>
              <Field.TextInput
                name='alternative_phone'
                legend='Alternative Phone Number'
                prefix={
                  <FastField.CountryCodeDropdown name='alternative_phone_country_code' />
                }
              />
            </Box>
          </>
        ) : (
          'Customers can contact an alternative phone number.'
        ),
    },
    {
      value: ICustomerSupport.NoContact,
      label: 'No contact allowed.',
    },
  ];

  const getStatesByCountry = useCallback(
    (currentCountry) =>
      (countries || []).find(
        ({ value }: CountryListItem) => value === currentCountry,
      )?.states || [],
    [countries],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='First Name' name='first_name' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Last Name' name='last_name' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Display Name' name='display_name' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.DateSelector
          legend='Date of Birth (mm/dd/yyyy)'
          name='dob'
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Divider className={classes.divider} />
        <Typography variant='subtitle2'>Driver’s Address</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Address Line 1' name='address.address_1' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Address Line 2' name='address.address_2' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field.Select
          options={countries || []}
          legend='Country'
          name='address.country'
          disabled={isFetchingCountry}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('address.country', event.target.value);
            setFieldValue('address.state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field.Select
          legend='Province/State'
          name='address.state'
          options={getStatesByCountry(values?.address?.country)}
          disabled={isFetchingCountry}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='City' name='address.city' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Postal Code' name='address.postcode' />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Divider className={classes.divider} />
        <Typography variant='subtitle2'>Contact</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormLabel className={classes.formLabel} component='legend'>
          Phone Number
        </FormLabel>
        <div className={classes.phone}>
          {values?.phone ? formatPhoneNumber(values.phone) : ''}
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Email Address' name='email' />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Divider className={classes.divider} />
        <Typography variant='subtitle2'>Customer Support Options</Typography>
        <GroupRadioButton
          name='customer_support'
          sx={{
            'ml': -0.5,
            'mt': 1,
            'gap': '5px 0px !important',
            '& > label': {
              border: 'none',
              padding: 0.5,
              fontWeight: 'normal',
            },
          }}
          value={values.customer_support}
          onChange={(e) => setFieldValue('customer_support', e.target.value)}
          options={CustomerSupportOptions}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box height={30} />
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Profile);
