import React from 'react';
import clsx from 'clsx';
import { Box, CircularProgress, SxProps } from '@mui/material';
import { createStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: theme.spacing(10),
      maxHeight: theme.spacing(20),
      zIndex: theme.zIndex.modal - 1,
    },
  });

export interface IProps extends WithStyles<typeof style> {
  className?: string;
  loading?: boolean;
  color?: any;
  size?: number;
  dataTestId?: string;
  sxContainer?: SxProps;
}

const CircularLoadLing: React.FC<IProps> = ({
  className,
  classes,
  loading = false,
  color,
  size,
  dataTestId,
  sxContainer,
}) =>
  loading ? (
    <Box
      className={clsx(classes.container, className)}
      data-testid={dataTestId}
      sx={sxContainer}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  ) : null;

export default withStyles(style)(CircularLoadLing);
