/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import qs from 'query-string';
import { Primitive } from 'type-fest';
import { isArray, isObject, isEmpty, isUndefined } from 'lodash';

interface PrimitiveMap {
  [key: string]: Primitive;
}

export const fromQueryString = (queryString: string, opts?: qs.ParseOptions) =>
  qs.parse(queryString, {
    arrayFormat: 'comma',
    parseNumbers: false,
    parseBooleans: true,
    ...opts,
  });

export const toQueryString = (
  obj: Record<string, any>,
  opts?: qs.StringifyOptions,
): string =>
  qs.stringify(obj, {
    arrayFormat: 'comma',
    ...opts,
  });

export const addToQueryString = (
  currentQueryString: string,
  val: PrimitiveMap,
  stringifyOptions?: qs.StringifyOptions,
  parseOptions?: qs.ParseOptions,
): string =>
  toQueryString(
    {
      ...fromQueryString(currentQueryString, parseOptions),
      ...val,
    },
    stringifyOptions,
  );

export const omitFromQueryString = (
  currentQueryString: string,
  key: string | string[],
): string => {
  const parsed = fromQueryString(currentQueryString);
  if (typeof key === 'string') {
    delete parsed[key];
  } else {
    key.forEach((k) => {
      delete parsed[k];
    });
  }

  return toQueryString(parsed);
};

export const cleanDeep = (
  object: Object,
  options: {
    cleanKeys?: string[];
    cleanValues?: Array<any>;
    emptyArrays?: boolean;
    emptyObjects?: boolean;
    emptyString?: boolean;
    NaNValues?: boolean;
    nullValues?: boolean;
    undefinedValues?: boolean;
    excludeKeys?: string[];
  } = {},
) => {
  const {
    cleanKeys = [],
    cleanValues = [],
    emptyArrays = true,
    emptyObjects = true,
    emptyString = true,
    NaNValues = false,
    nullValues = true,
    undefinedValues = true,
    excludeKeys = [],
  } = options;
  const keys = Object.keys(object);
  keys.forEach((item: string) => {
    if (excludeKeys.includes(item)) {
      return true;
    }
    if (undefinedValues && isUndefined(object[item])) {
      delete object[item];
    }
    if (emptyArrays && isArray(object[item]) && object[item]?.length === 0) {
      delete object[item];
    }
    if (emptyString && object[item] === '') {
      delete object[item];
    }
    if (NaNValues && Number.isNaN(object[item])) {
      delete object[item];
    }
    if (nullValues && object[item] === null) {
      delete object[item];
    }
    if (cleanKeys?.includes(item)) {
      delete object[item];
    }
    if (cleanValues?.includes(object[item])) {
      delete object[item];
    }
    if (isObject(object[item]) && !isArray(object[item])) {
      if (emptyObjects && Object.keys(object[item]).length === 0) {
        delete object[item];
      } else {
        return cleanDeep(object[item], options);
      }
    }
    return object;
  });
};
