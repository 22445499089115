import { useContext } from 'react';
import { HitMapContext } from './HitMapContext';

export const useHitMapContext = () => {
  const context = useContext(HitMapContext);
  if (context === undefined) {
    throw new Error('Component should be in HitMapContext ');
  }
  return context;
};
