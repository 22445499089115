import React from 'react';
import CountryCodeDropdownRaw from '~/components/common/CountryCodeDropdown';
import { IFormProps } from '~/models/common';
import { ICountryCodeDropdown } from '../CountryCodeDropdown/CountryCodeDropdown';

export interface IFieldCountryCodeDropdown
  extends IFormProps,
    Omit<ICountryCodeDropdown, 'name'> {}

const CountryCodeDropdown: React.FC<IFieldCountryCodeDropdown> = ({
  field,
  form,
  inputProps = {},
  ...props
}) => (
  <CountryCodeDropdownRaw
    id={field.name}
    inputProps={{
      ...field,
      ...inputProps,
      value: field.value ?? '',
    }}
    {...props}
  />
);

export default CountryCodeDropdown;
