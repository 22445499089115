/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import {
  useGetDriverDocQuery,
  useRemoveDriverDoc,
  useUpdateDriverDoc,
} from '~/services/api/driver';
import { ImageUploadProvider } from '~/components/common/ImageUpload';
import { useLocation } from 'react-router-dom';
import CircularLoading from '~/components/common/CirularLoading';
import Upload from '~/assets/images/icons/upload.svg';
import FullView from '~/assets/images/icons/fullview.svg';
import { DRIVER_DOCS } from '~/constants/driver';
import { useFormikContext } from 'formik';
import { IDriver } from '~/models/driver';
import { convertBase64Image } from '~/utils/image';
import clsx from 'clsx';
import { IImageDataItem } from '~/components/common/ImageList/ImageListProvider';
import { fromQueryString } from '~/utils/queryString';
import { showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';

const style = (theme: Theme) =>
  createStyles({
    loading: {
      maxHeight: 'none',
    },
    label: {
      fontSize: 12,
      color: theme.color.black,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    imageContainer: {
      display: 'flex',
      gap: theme.spacing(3),
    },
    imageDiv: {
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'position': 'relative',
      'width': '100%',
      'height': 300,
      'background': theme.bg.gray300,
      'borderRadius': theme.spacing(0.5),
      '&:hover': {
        '& $imageEdit': {
          display: 'flex',
        },
      },
    },
    imageEdit: {
      borderRadius: theme.spacing(0.5),
      background: 'rgba(0,0,0,.5)',
      color: theme.color.white,
      display: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
      cursor: 'pointer',
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(0.5),
    },
    uploadContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1),
      cursor: 'pointer',
    },
    upload: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.bg.gray600,
    },
    multipleImage: {
      boxShadow: `5px 5px ${theme.bg.purpleOutline}`,
    },
  });

interface IProps extends WithStyles<typeof style> {}

const initImageSrc = DRIVER_DOCS.reduce(
  (rs, { id, isMultiple }) => ({
    ...rs,
    [id]: isMultiple ? [] : '',
  }),
  {},
);

const Document: React.FC<IProps> = ({ classes }) => {
  const location = useLocation();
  const { id: driverId } = fromQueryString(location.search);
  const { values, setFieldValue } = useFormikContext<IDriver>();
  const [imageSrc, setImageSrc] = useState(initImageSrc);
  const driverDocParams = values?.driverDocParams || {};
  const { data, isFetching } = useGetDriverDocQuery(driverDocParams);

  useEffect(() => {
    if (data && data.length === DRIVER_DOCS.length && !isFetching) {
      setImageSrc(
        DRIVER_DOCS.reduce(
          (rs, { id, isMultiple }, index) => ({
            ...rs,
            [id]: isMultiple ? data[index] : data[index]?.blob || '',
            [`${id}_type`]: isMultiple
              ? undefined
              : data[index]?.blob_type || '',
          }),
          {},
        ),
      );
    }
  }, [data, isFetching]);

  const handleUpdateSuccess = (driverDetail) => {
    const newDriverDocParams = DRIVER_DOCS.reduce(
      (rs, { id }) => ({
        ...rs,
        [id]: driverDetail[id],
      }),
      { id: driverDetail?.id },
    );
    setFieldValue('driverDocParams', newDriverDocParams);
  };

  const { mutate: uploadImage, isLoading } = useUpdateDriverDoc({
    onSuccess: (driverDetail: IDriver) => {
      handleUpdateSuccess(driverDetail);
    },
    onError: (error) => {
      showAlert(errorAlert(error));
    },
  });

  const { mutate: removeImage, isLoading: isLoadingRemove } =
    useRemoveDriverDoc({
      onSuccess: (driverDetail: IDriver) => {
        handleUpdateSuccess(driverDetail);
      },
      onError: (error) => {
        showAlert(errorAlert(error));
      },
    });

  const onSaveImage = async (
    id: string,
    formData: FormData | null,
    blob: Blob,
  ) => {
    if (formData) {
      const uploadData = new FormData();
      uploadData.append(id, blob, `${id}.${blob.type.split('/')[1]}`);
      uploadImage({
        id: driverId as string,
        body: uploadData,
      });
    } else {
      removeImage({
        id: driverId as string,
        body: {
          [id]: null,
        },
      });
    }
  };

  const onSaveImageList = async (id: string, imageData: IImageDataItem[]) => {
    if (imageData?.length) {
      const uploadData = new FormData();
      await Promise.all(
        imageData.map(async (item, index) => {
          const blob = await (await fetch(item.src)).blob();
          uploadData.append(
            id,
            blob,
            `${id}${index}.${blob.type.split('/')[1]}`,
          );
        }),
      );
      uploadImage({
        id: driverId as string,
        body: uploadData,
      });
    } else {
      removeImage({
        id: driverId as string,
        body: {
          [id]: null,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <CircularLoading
        className={classes.loading}
        loading={isLoading || isLoadingRemove || isFetching}
      />
      {DRIVER_DOCS.map((item) => (
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            {item.isMultiple
              ? `${item.label}${
                  imageSrc[item.id]?.length
                    ? ` (${imageSrc[item.id].length})`
                    : ''
                }`
              : item.label}
          </div>
          <ImageUploadProvider
            {...(item.isMultiple
              ? {
                  listSrc: imageSrc[item.id]
                    ? (imageSrc[item.id] || []).map((img: any) => ({
                        src: convertBase64Image(img.photo, img.photo_type),
                      }))
                    : [],
                }
              : {
                  src: imageSrc[item.id]
                    ? convertBase64Image(
                        imageSrc[item.id],
                        imageSrc[`${item.id}_type`],
                      )
                    : null,
                })}
            optional
            onSave={(formData: FormData | null, blob: Blob) =>
              onSaveImage(item.id, formData, blob)
            }
            onSaveList={(formData: IImageDataItem[]) =>
              onSaveImageList(item.id, formData)
            }
            title={item.label}
          >
            {({ setOpen }) => (
              <div
                className={clsx(classes.imageDiv, {
                  [classes.multipleImage]: item.isMultiple,
                })}
              >
                {(!item.isMultiple && imageSrc[item.id]) ||
                (item.isMultiple && imageSrc[item.id]?.length) ? (
                  <>
                    <img
                      src={
                        item.isMultiple && !!imageSrc[item.id]?.length
                          ? convertBase64Image(
                              imageSrc[item.id][0].photo,
                              imageSrc[item.id][0].photo_type,
                            )
                          : convertBase64Image(
                              imageSrc[item.id],
                              imageSrc[`${item.id}_type`],
                            )
                      }
                      alt={item.id}
                      className={classes.image}
                    />
                    <div
                      role='button'
                      tabIndex={0}
                      className={classes.imageEdit}
                      onClick={() => setOpen()}
                    >
                      <img src={FullView} alt='fullview' />
                      <Typography>Full View</Typography>
                    </div>
                  </>
                ) : (
                  <div
                    className={classes.uploadContainer}
                    role='button'
                    tabIndex={0}
                    onClick={() => setOpen()}
                  >
                    <div className={classes.upload}>
                      <img src={Upload} alt='upload' />
                    </div>
                    <Typography>Upload</Typography>
                  </div>
                )}
              </div>
            )}
          </ImageUploadProvider>
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(style)(Document);
