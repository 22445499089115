import { Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useGetTaskImage } from '~/pages/LiveTracking/apis';
import themes from '~/themes';
import { StyledTaskImageContainer } from '../../../style';

type TaskImageProps = {
  id: string;
  taskId: string;
  title: string;
  onPreviewTaskImage: (data: { title: string; src: string }) => void;
};

export const TaskImage: React.FC<TaskImageProps> = (props) => {
  const { id, taskId, title, onPreviewTaskImage } = props;
  const { data, isFetching } = useGetTaskImage({
    params: {
      id,
      task_id: taskId,
    },
  });
  const skeletonLoading = useMemo(
    () => <Skeleton width={100} height={80} variant='rectangular' />,
    [],
  );

  const imgSrc = data?.src || '';
  useEffect(() => {});

  return (
    <Stack spacing={1}>
      <Typography
        sx={{
          background: '#EAFFF9',
          borderRadius: '5px',
          color: '#59C9A9',
          fontWeight: 500,
          padding: themes.spacing(0.3, 0.5),
          width: 'fit-content',
        }}
      >
        {title}
      </Typography>
      {isFetching ? (
        skeletonLoading
      ) : (
        <StyledTaskImageContainer>
          <img src={imgSrc} alt='task-img' />
          <Stack
            className='bg-img-hover'
            onClick={() => onPreviewTaskImage({ title, src: imgSrc })}
            sx={{
              width: 100,
              height: 80,
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'none',
              cursor: 'pointer',
            }}
          >
            <ZoomInIcon />
            <Typography>Full View</Typography>
          </Stack>
        </StyledTaskImageContainer>
      )}
    </Stack>
  );
};
