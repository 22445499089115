import { COMMON_API_LABEL } from '~/constants/common';
import { isNullOrUndefined, transformTypeToTitle } from '~/utils/common';
import { IAlert } from './Alert';

const d = {
  icon: 'warn',
  title: 'Warning',
  okText: 'OK',
  closeOnClick: true,
};

const getErrorArray = (
  error: any,
  field: string = 'detail',
  prefix: string = '',
): any[] => {
  if (typeof error[field] === 'object') {
    if (isNullOrUndefined(error[field].length)) {
      return Object.keys(error[field]).reduce(
        (rs: any[], item: any) =>
          rs.concat(
            getErrorArray(
              error[field],
              item,
              `${prefix}${
                field === 'detail'
                  ? ''
                  : `${transformTypeToTitle(field, '_')} > `
              }`,
            ),
          ),
        [],
      );
    }
    return [`${prefix}${transformTypeToTitle(field, '_')}: ${error[field]}`];
  }
  if (field && error[field] && typeof error[field] === 'string') {
    return [`${field}: ${error[field]}`];
  }

  return [];
};

const ERROR_MAPPER = {
  'Email already exists in the organization':
    'This email already existed. Please use another email!',
};

export const getErrorMessage = (error: any, xProps?: any) => {
  if (
    error?.detail &&
    Object.values(error.detail) &&
    typeof error?.detail !== 'string'
  ) {
    return getErrorArray(
      error,
      'detail',
      COMMON_API_LABEL.find((x) => xProps.includes(x))
        ? `${transformTypeToTitle(
            COMMON_API_LABEL.find((x) => xProps.includes(x))?.slice(1) || '',
          )} > `
        : '',
    );
  }
  if (error?.response?.data?.message) {
    if (typeof error?.response?.data?.message === 'string')
      return (
        ERROR_MAPPER[error?.response?.data?.message] ||
        error?.response?.data?.message
      );
    return Object.keys(error?.response?.data?.message)
      .map(
        (k) =>
          `${transformTypeToTitle(k, '_')}: ${
            error?.response?.data?.message[k]
          }`,
      )
      .join('\n');
  }

  if (typeof error?.message === 'string' || typeof error === 'string') {
    return error?.message || error;
  }
  return 'Unknown Error';
};

// eslint-disable-next-line no-unused-vars
type IAlertFunction = (e: any, xProps?: any) => IAlert;

export type AlertLabel = 'success' | 'warning' | 'successDark';

// eslint-disable-next-line no-unused-vars
export const alertParams: { [key: string]: IAlert } = {
  warning: {
    icon: 'warn',
    title: 'Warning',
    okText: 'Ok',
  },
  success: {
    icon: 'success',
    title: 'Changes Saved',
    okText: 'Ok',
    closeOnClick: true,
  },
  successDark: {
    icon: 'successDark',
    title: 'Updated Successfully',
    okText: 'Ok',
    theme: 'secondary',
    closeOnClick: true,
  },
  warnDark: {
    icon: 'warnDark',
    title: 'Are you sure you\n want to cancel all changes?',
    okText: 'Yes',
    cancelText: 'No',
    theme: 'secondary',
    closeOnClick: true,
  },
};

export const errorAlert: IAlertFunction = (e: any, xProps?: any) => ({
  ...d,
  title: 'Error',
  description: getErrorMessage(e, xProps),
});

export default alertParams;
