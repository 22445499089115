import { createStyles } from '@mui/styles';
import themes from '~/themes';

const style = () =>
  createStyles({
    separator: {
      'marginLeft': 0,
      'marginRight': 0,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    textBoxTooltip: {
      maxWidth: '500px',
      fontSize: '0.75rem',
      fontWeight: 400,
      transform: 'translateY(1px)',
      color: themes.color.black,
    },
    linkTo: {
      textDecoration: 'none',
      color: themes.color.black,
    },
  });

export default style;
