import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '~/components/common/Button';
import { Icon } from '~/components/common/Icon';
import { useAuth } from '~/providers/AuthProvider';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import themes from '~/themes';

export const FailPaymentBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAuth();
  const { org } = usePaymentInfo();
  const isLiveTrackingPage =
    location.pathname === '/' || location.pathname === '/tracking/tasks';
  if (!org?.grace_period) return <div />;

  return (
    <Stack
      sx={{
        boxShadow: '0px 2px 10px #EDF1FB',
        borderRadius: '10px',
        width: '80%',
        height: 60,
        position: 'absolute',
        bottom: 20,
        left: '16%',
        padding: themes.spacing(0, 2),
        background: '#D62A28',
        zIndex: themes.zIndex.drawer + 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        ...(isLiveTrackingPage && {
          bottom: 70,
        }),
        [themes.breakpoints.down('lg')]: {
          zIndex: themes.zIndex.drawer - 1,
          left: '5%',
          height: 'fit-content',
          padding: themes.spacing(1),
        },
      }}
      direction='row'
    >
      <Stack direction='row' alignItems='center'>
        <Icon
          name='exclamation'
          size={22}
          color='white'
          style={{ marginRight: 10 }}
        />
        <Typography sx={{ color: themes.color.white, fontWeight: 'bold' }}>
          {account.role === 'dispatcher'
            ? 'We were unable to process your subscription payment. Please contact admins or the organization account’s owner to update the payment method'
            : 'We were unable to process your subscription payment. Please update the payment method to avoid service disruption.'}
        </Typography>
      </Stack>
      {location.pathname.includes('setting/billing') ||
      account.role === 'dispatcher' ? (
        <div />
      ) : (
        <Button
          sx={{
            'background': themes.bg.white,
            'color': '#D62A28',
            'fontWeight': 'bold',
            '&:hover': {
              background: themes.bg.white,
              color: '#D62A28',
            },
          }}
          onClick={() => navigate('/setting/billing')}
        >
          Update Now
        </Button>
      )}
    </Stack>
  );
};
