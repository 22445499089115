import {
  InviteUserPage,
  InvitationSuccess,
  RegisterPage,
  EmailVerified,
  EmailSent,
} from '~/pages/Auth';
import { Track } from '~/pages/Track';
import Privacy from '~/pages/Privacy/Privacy';
import { UnifiedNewPassword } from '~/pages/UnifiedAuth/UnifiedNewPassword';

export const publicRoutes = [
  {
    path: 't/:code',
    element: <Track />,
  },
  {
    path: 'register/verified',
    element: <EmailVerified />,
  },
  {
    path: 'register/sent',
    element: <EmailSent />,
  },
  {
    path: 'invite-user',
    element: <InviteUserPage />,
  },
  {
    path: 'invite-user/success',
    element: <InvitationSuccess />,
  },
  {
    path: 'new-password',
    element: <UnifiedNewPassword />,
  },
  {
    path: 'register',
    element: <RegisterPage />,
  },
  {
    path: 'privacy-policy',
    element: <Privacy />,
  },
];
