import React from 'react';
import { Breadcrumbs as MBreadcrumbs, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { NavigateNext } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ClassNameMap } from '@mui/styles';
import themes from '~/themes';
import style from './style';

export interface IBreadCrumbItem {
  to: string | null;
  name: string;
  color?: string;
}

interface IProps {
  classes: ClassNameMap;
  list: IBreadCrumbItem[];
}

const BreadCrumbs = ({
  classes: { textBoxTooltip, linkTo, ...xClasses },
  list,
}: IProps) => {
  const renderLinkBR = () =>
    list.map((x) =>
      x.to ? (
        <Link key={x.name} color={x.color} to={x.to} className={linkTo}>
          <Typography variant='caption'>{x.name}</Typography>
        </Link>
      ) : (
        <Typography key={x.name} variant='caption' color={themes.color.black}>
          {x.name}
        </Typography>
      ),
    );
  return (
    <MBreadcrumbs
      classes={xClasses}
      separator={<NavigateNext />}
      aria-label='breadcrumb'
    >
      {renderLinkBR()}
    </MBreadcrumbs>
  );
};

export default withStyles(style)(BreadCrumbs);
