import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { Typography } from '@mui/material';
import AddDriver from '~/assets/images/background/add-driver.png';
import Button from '~/components/common/Button';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';

const style = (theme: Theme) =>
  createStyles({
    containerClass: {
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      'padding': theme.spacing(2),
      'marginTop': theme.spacing(2),
      '& > img:first-child': {
        maxHeight: 200,
      },
    },
    title: {
      marginTop: theme.spacing(3),
      fontSize: 22,
      paddingTop: 0,
    },
    desc: {
      color: theme.color.violet900,
      textAlign: 'center',
      marginTop: theme.spacing(1),
      whiteSpace: 'break-spaces',
    },
    button: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
      containerClass: {
        'marginTop': theme.spacing(1),
        'maxWidth': '100%',
        '& > img:first-child': {
          maxWidth: '100%',
        },
        '& > h1': {
          textAlign: 'center',
        },
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  onAddDriver: () => void;
}

const AddYourDrivers: React.FC<IProps> = ({ classes, onAddDriver }) => (
  <div className={classes.containerClass}>
    <img src={AddDriver} alt='add-driver' />
    <Typography variant='h1' className={classes.title}>
      Add your drivers to Breadstack Delivery
    </Typography>
    <Typography className={classes.desc}>
      {
        'Add your first driver by sending an SMS invitation. You can effortlessly track\n your drivers in real-time as they complete delivery tasks.'
      }
    </Typography>
    <Button
      className={classes.button}
      buttonType='primary'
      onClick={onAddDriver}
    >
      Add Driver
    </Button>
  </div>
);

export default withStyles(style)(AddYourDrivers);
