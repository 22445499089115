/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '~/components/common/Button';
import WarningIcon from '~/assets/images/icons/warning.svg';
import SuccessIcon from '~/assets/images/icons/success.svg';
import SuccessDarkIcon from '~/assets/images/icons/success-dark.svg';
import WarningDarkIcon from '~/assets/images/icons/warning-dark.svg';
import themes, { Theme, styled } from '~/themes';
import { SxProps } from '@mui/system';

const Header = styled('div')(({ theme }) => ({
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'padding': theme.spacing(4, 1, 1, 1),
  '& img': {
    objectFit: 'contain',
    height: 100,
  },
  '& h6': {
    paddingTop: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
}));

const Description = styled('p')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 12,
  textAlign: 'center',
  whiteSpace: 'break-spaces',
  color: theme.color.black,
  margin: 0,
}));

const Footer = styled('div')(({ theme }) => ({
  'display': 'flex',
  'justifyContent': 'center',
  'padding': theme.spacing(2, 1, 4, 1),
  '& button:not(:last-child)': {
    marginRight: theme.spacing(1),
  },
}));

export interface IAlert {
  icon?: string;
  title?: string | React.ReactNode;
  theme?: 'primary' | 'secondary';
  closeOnClick?: boolean;
  cancelText?: string;
  description?: string | string[] | React.ReactNode;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  renderContent?: () => React.ReactNode;
  sx?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  sxButton?: SxProps<Theme>;
}

interface IProps extends IAlert {
  open: boolean;
  onClose: () => void;
}

const Alert: React.FC<IProps> = ({
  icon,
  title = '',
  open,
  closeOnClick = true,
  cancelText = null,
  description = '',
  okText = '',
  theme = 'primary',
  renderContent = () => '',
  onClose,
  onCancel = () => {},
  onOk = () => {},
  sx = {},
  sxDescription = {},
  sxButton,
  ...rest
}) => {
  const isSecondaryTheme = theme === 'secondary';
  const iconSrc: { [key: string]: string } = {
    warn: WarningIcon,
    success: SuccessIcon,
    successDark: SuccessDarkIcon,
    warnDark: WarningDarkIcon,
  };

  const handleClose = useCallback(() => {
    if (closeOnClick) {
      onCancel();
      onClose();
    } else {
      onCancel();
    }
  }, [closeOnClick, onCancel, onClose]);

  const handleOk = useCallback(() => {
    if (closeOnClick) {
      onOk();
      onClose();
    } else {
      onOk();
    }
  }, [closeOnClick, onOk, onClose]);

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      onClose={handleClose}
      onKeyDown={(e) => !cancelText && e.key === 'Enter' && handleOk()}
      disableEscapeKeyDown
      sx={{
        zIndex: themes.zIndex.drawer + 1,
        ...sx,
      }}
      PaperProps={{
        sx: {
          borderRadius: '10px',
        },
      }}
      {...rest}
    >
      <>
        <Header>
          {icon && (
            <img
              src={icon ? iconSrc[icon] : iconSrc.default}
              alt={`${icon} icon`}
            />
          )}
          <Typography
            variant='subtitle1'
            data-testid='title-alert'
            sx={{
              textAlign: 'center',
              whiteSpace: 'break-spaces',
              ...(isSecondaryTheme && { fontSize: 18 }),
            }}
          >
            {title}
          </Typography>
        </Header>
        {description && (
          <Content data-testid='description-alert'>
            {Array.isArray(description) ? (
              <>
                {description.map((d, idx) => (
                  <Description key={idx} sx={sxDescription}>
                    {d}
                  </Description>
                ))}
              </>
            ) : (
              <Description
                style={{
                  ...(isSecondaryTheme && {
                    fontSize: 14,
                  }),
                }}
                sx={sxDescription}
              >
                {description}
              </Description>
            )}
          </Content>
        )}

        {Boolean(renderContent) && <Content>{renderContent()}</Content>}
        <Footer>
          {cancelText && (
            <Button
              buttonType={isSecondaryTheme ? 'default' : 'secondary'}
              rounder10={isSecondaryTheme}
              onClick={handleClose}
              sx={sxButton}
            >
              {cancelText}
            </Button>
          )}
          {okText && (
            <Button
              buttonType={isSecondaryTheme ? 'primary-dark' : 'primary'}
              rounder10={isSecondaryTheme}
              sx={{
                ...(isSecondaryTheme && { minHeight: '40px' }),
                ...sxButton,
              }}
              onClick={handleOk}
            >
              {okText}
            </Button>
          )}
        </Footer>
      </>
    </Dialog>
  );
};

export default Alert;
