import { ITutorial } from '~/models/common';

export const TutorialData: { [key: string]: ITutorial } = {
  CreateTask: {
    id: 'create_task',
    title: 'Create Delivery Task',
    description: 'Create a new delivery task and assign to your driver.',
    placement: 'top',
    width: 290,
  },
  OnlineDrivers: {
    id: 'online_driver',
    title: 'Online Drivers',
    description:
      'Monitor online drivers, manage their task routes, and send short messages when needed.',
    placement: 'left',
    width: 330,
  },
  ViewSpecificInfo: {
    id: 'view_specific_info',
    title: 'View Selective Info on the Map',
    description:
      'Use these filters to isolate specific information on the live map.',
    placement: 'right',
    width: 300,
  },
  AssignTask: {
    id: 'assign_task',
    title: 'Assign Task to a Driver',
    description:
      'This task is currently unassigned. Click to view details and assign a driver.',
    placement: 'right',
    width: 300,
  },
  SetTaskRoutes: {
    id: 'set_task_routes',
    title: 'Put Tasks in Desired Order',
    description: (
      <>
        Set the order of this driver’s tasks. This will determine the route the
        driver sees in the app. Note that to enable a drop-off task, you must
        first select its corresponding pick-up task.
      </>
    ),
    placement: 'left',
    width: 310,
    offset: 20,
  },
  CheckIdealRoute: {
    id: 'check_ideal_route',
    title: 'Check the Ideal Route',
    description:
      'Determine the most efficient route for the driver by utilizing Breadstack Delivery suggestions.',
    placement: 'left',
    width: 310,
    offset: [10, 20],
  },
  TaskList: {
    id: 'task_list',
    title: 'Records of Delivery Tasks',
    description: 'Stay on track with all ongoing and completed deliveries.',
    placement: 'bottom',
    width: 240,
    offset: [30, 70],
  },
  EnterTaskDetails: {
    id: 'enter_task_details',
    title: 'Enter Task Details',
    description:
      'Provide the details for the delivery task, including pickup, destination, and scheduling information.',
    placement: 'left',
    width: 250,
    offset: [5, 25],
  },
  AssignTaskCreate: {
    id: 'assign_task_create',
    title: 'Assign Task to a Driver',
    description:
      'Choose a driver to assign this task, or bypass this step and assign at a later time.',
    placement: 'left',
    width: 300,
    offset: [5, 25],
  },
  Organization: {
    id: 'organization',
    title: 'Manage General Settings',
    description:
      'Edit your organization’s name and category, and toggle task auto-assignment on or off.',
    placement: 'bottom',
    width: 300,
    offset: 28,
  },
  Stores: {
    id: 'stores',
    title: 'Link and View Your Stores',
    description:
      'Your stores are your central pickup and delivery hubs. Streamline task management by setting up your stores and assigning driver teams.',
    placement: 'bottom',
    width: 360,
    offset: 28,
  },
  OptimizeStore: {
    id: 'optimize_store',
    title: 'Optimize Store Operations',
    description:
      'Manage your store’s contact details, delivery settings such as hours and zones, and ecommerce integration status here.',
    placement: 'left',
    width: 310,
    offset: 15,
  },
  Drivers: {
    id: 'drivers',
    title: 'Manage Drivers',
    description: 'View and manage drivers within your organization.',
    placement: 'bottom',
    width: 260,
    offset: 28,
  },
  ManageDriver: {
    id: 'manage_driver',
    title: 'Manage Driver Information',
    description:
      'Ensure that your drivers’ information is consistently kept up to date.',
    placement: 'left',
    width: 310,
    offset: [10, 25],
  },
  Teams: {
    id: 'teams',
    title: 'Create Driver Teams',
    description:
      'Easily manage delivery tasks by grouping your drivers into teams.',
    placement: 'bottom',
    width: 250,
    offset: 28,
  },
  Users: {
    id: 'users',
    title: 'Find and Add Users',
    description:
      'Manage fellow admins and dispatchers within your organization.',
    placement: 'bottom',
    width: 260,
    offset: 28,
  },
};
