import { makeStyles } from '@mui/styles';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { Theme } from '~/themes';

export const useCommonStyles = makeStyles((theme: Theme) => ({
  icon: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': 25,
    'height': 25,
    'borderRadius': 5,
    'backgroundColor': theme.bg.darkPurple,
    'marginRight': theme.spacing(1),
    '& img': {
      width: 19,
      color: 'white',
    },
  },
  expandIconWrapper: {
    transform: 'unset !important',
  },
  driverItem: {},
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    driverItem: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const useRadioStyles = makeStyles(() => ({
  item: {
    marginTop: 0,
    alignItems: 'center',
  },
  radio: {
    margin: 0,
  },
}));
