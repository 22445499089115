import { LIVE_MAP_ID } from '~/config';

export enum ControlPosition {
  BOTTOM = 11,
  BOTTOM_CENTER = 11,
  BOTTOM_LEFT = 10,
  BOTTOM_RIGHT = 12,
  CENTER = 13,
  LEFT = 5,
  LEFT_BOTTOM = 6,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  RIGHT = 7,
  RIGHT_BOTTOM = 9,
  RIGHT_CENTER = 8,
  RIGHT_TOP = 7,
  TOP = 2,
  TOP_CENTER = 2,
  TOP_LEFT = 1,
  TOP_RIGHT = 3,
}

export const MAP_GLOBAL_BOUND = {
  north: 85,
  south: -85,
  west: -180,
  east: 180,
};
export const MAP_BOUNDS_PADDING = {
  DEFAULT: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 475,
  },
  TABLET: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 450,
  },
  ROUTES: {
    top: 70,
    bottom: 70,
    right: 110,
    left: 1000,
  },
  TASK_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 1000,
  },
};

export const MAP_BOUNDS_PADDING_DESKTOP = {
  DEFAULT: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 475,
  },
  DRIVER_ROUTES: {
    top: 70,
    bottom: 70,
    right: 110,
    left: 1000,
  },
  TASK_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 1000,
  },
};

export const MAP_BOUNDS_PADDING_MOBILE = {
  DEFAULT: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 70,
  },
  DRIVER_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 70,
  },
  TASK_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 70,
  },
};

export const MAP_BOUNDS_PADDING_SMALL_SCREEN = {
  DEFAULT: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 450,
  },
  DRIVER_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 450,
  },
  TASK_ROUTES: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 450,
  },
};

export const mapOptions: google.maps.MapOptions = {
  mapId: LIVE_MAP_ID,
  disableDefaultUI: true,
  clickableIcons: false,
  center: {
    lat: 49.21556039735341,
    lng: -123.09840777108371,
  },
  zoomControlOptions: {
    position:
      ControlPosition.RIGHT_BOTTOM as unknown as google.maps.ControlPosition,
  },
  zoom: 11,
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  restriction: {
    latLngBounds: MAP_GLOBAL_BOUND,
    strictBounds: true,
  },
};

export const DEFAULT_ADD_TASK_DATA = {
  pickup: {
    name: '',
    phone: '',
    address_1: '',
    address_2: '',
    country: 'CA',
    city: '',
    state: 'BC',
    postcode: '',
    country_code: 'CA',
    save_to_address_book: false,
    disabled_save_to_address_book: false,
    address_book: {},
  },
  delivery: {
    name: '',
    phone: '',
    address_1: '',
    address_2: '',
    country: 'CA',
    city: '',
    state: 'BC',
    postcode: '',
    country_code: 'CA',
    save_to_address_book: false,
    disabled_save_to_address_book: false,
    address_book: {},
    email: '',
  },
  skip_pickup: false,
  require_pickup_proofs: true,
  require_delivery_capture: true,
  require_delivery_signature: true,
};
