import {
  Cluster,
  MarkerClusterer,
  Renderer,
} from '@googlemaps/markerclusterer';
import { BaseMarkers } from './BaseMarkers';
import { LocationInfo } from '../types';
import {
  StoreInfoWindowContent,
  StoreMarkerContent,
} from '../components/Markers/MarkerContents';
import { isInfoWindowOpen, isValidLatLngLiteral } from '../utils';

export class StoreMarkers {
  public markerClusterer: MarkerClusterer;

  public markers: BaseMarkers;

  private map: google.maps.Map | null;

  private googleMapEvents: google.maps.MapsEventListener[] = [];

  private fitBoundsPadding: google.maps.Padding;

  constructor(
    map: google.maps.Map | null,
    renderer: Renderer,
    configs: { fitBoundsPadding: google.maps.Padding },
  ) {
    this.map = map;
    this.markers = new BaseMarkers(map);
    this.fitBoundsPadding = configs?.fitBoundsPadding;

    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      renderer,
      onClusterClick: (
        event: google.maps.MapMouseEvent,
        cluster: Cluster,
        m: google.maps.Map,
      ) => {
        m.fitBounds(cluster.bounds, this.fitBoundsPadding);
      },
    });
  }

  setFitBoundsPadding(fitBoundsPadding: google.maps.Padding) {
    this.fitBoundsPadding = fitBoundsPadding;
  }

  addMarkers(locations: LocationInfo[], isAddMarkerToCluster?: boolean) {
    if (!locations.length) return;
    const { lat, lng, id } = locations[0];
    if (!isValidLatLngLiteral({ lat, lng })) return;

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: {
        lat,
        lng,
      },
      content: StoreMarkerContent({ locations }),
    });

    this.markers.add({
      id,
      marker,
      locations,
    });

    if (isAddMarkerToCluster) {
      this.markerClusterer.addMarker(marker);

      const storeInfoWindow = new google.maps.InfoWindow({
        content: StoreInfoWindowContent(locations[0]),
      });

      marker.addListener('click', () => {
        if (isInfoWindowOpen(storeInfoWindow)) {
          storeInfoWindow.close();
          storeInfoWindow.setZIndex(1);
        } else {
          storeInfoWindow.open(this.map, marker);
          storeInfoWindow.setZIndex(6969);
        }
      });

      const googleMapClickEventListener = google.maps.event.addListener(
        this.map,
        'click',
        () => {
          storeInfoWindow.close();
        },
      );

      this.googleMapEvents = [
        ...this.googleMapEvents,
        googleMapClickEventListener,
      ];
    }
  }

  showMarkers() {
    this.markerClusterer.addMarkers(this.markers.getAll().map((e) => e.marker));
  }

  hideMarkers() {
    this.googleMapEvents.forEach((e) => google.maps.event.removeListener(e));
    this.googleMapEvents = [];
    this.markerClusterer.clearMarkers();
  }
}
