import { transformTypeOptions } from '~/utils/common';

export const DRIVER_DRAWER_TAB_VALUE = {
  PROFILE: 'profile',
  VEHICLES: 'vehicle',
  DOCS: 'documents',
  TIMELINES: 'timeline',
};

export const DRIVER_DRAWER_TABS = transformTypeOptions(DRIVER_DRAWER_TAB_VALUE);

export const DRIVER_DOCS = [
  { id: 'license_photo_front', label: 'Driver License Photo - Front' },
  { id: 'license_photo_back', label: 'Driver License Photo- Back' },
  { id: 'driver_and_license', label: 'Photo of Driver with License' },
  { id: 'driver_abstract', label: 'Driver’s Abstract', isMultiple: true },
];
