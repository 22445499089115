import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import { Field } from '~/components/common/Formik';
import { IAddTaskData } from '~/pages/LiveTracking/types';

type DeliveryTimeFormProps = {};

export const DeliveryTimeForm: React.FC<DeliveryTimeFormProps> = () => {
  const { values, setFieldValue } = useFormikContext<IAddTaskData>();

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          '& .rdtPicker': {
            bottom: 40,
            top: 'unset !important',
          },
        }}
      >
        <Field.DateSelector
          name='expected_delivery_date'
          legend='Date'
          placeholder='Select a date'
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} sm={6}>
        <Field.TimeSelector
          name='expected_delivery_after'
          legend='From'
          onBlur={(v: Moment) => {
            if (!values.expected_delivery_date) {
              setFieldValue(
                'expected_delivery_date',
                moment().format('MM/DD/YYYY'),
              );
            }
            if (!values.expected_delivery_before) {
              setFieldValue('expected_delivery_before', v);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field.TimeSelector
          name='expected_delivery_before'
          legend='To'
          onBlur={(v: Moment) => {
            if (!values.expected_delivery_date) {
              setFieldValue(
                'expected_delivery_date',
                moment().format('MM/DD/YYYY'),
              );
            }
            if (!values.expected_delivery_after) {
              setFieldValue('expected_delivery_after', v);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
