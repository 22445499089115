import { FormikErrors } from 'formik';
import { FastField, Field } from '~/components/common/Formik';
import { Divider, FormHelperText, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import themes from '~/themes';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Zone } from '~/models/stores';
import { DrawnZonesMap } from './DrawnZonesMap';
import { DrawnZonesMapUndoRedo } from './DrawZonesMapUndoRedo';

interface DialogAddZoneFormProps {
  zoneSelected?: Zone;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;
  delivery_fee_type: any;
  long_lat: any;
  drawnZoneMapRef: React.MutableRefObject<any>;
  missCoordinateErr: string;
}

export const FeeTypeElement = ({ type, open }) => (
  <>
    <Typography color={themes.color.violet900}>
      {type === 'fixed_amount' ? '$' : '%'}
    </Typography>
    {open ? (
      <KeyboardArrowDownIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    ) : (
      <KeyboardArrowUpIcon
        sx={{
          color: themes.color.violet900,
        }}
      />
    )}
  </>
);

export const DialogAddZoneForm: React.FC<DialogAddZoneFormProps> = ({
  defaultCenter,
  long_lat,
  delivery_fee_type,
  setFieldValue,
  drawnZoneMapRef,
  missCoordinateErr,
}) => {
  const isUsingUndoRedoFeature = useFeatureIsOn('cf-undo-redo-draw-zone');

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FastField.TextInput name='name' legend='Name' />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Field.TextInput
          type='number'
          name='delivery_fee'
          legend='Delivery Fee'
          suffix={
            <Field.ElementSelect
              name='delivery_fee_type'
              elementSelect={(o: boolean) => (
                <FeeTypeElement open={o} type={delivery_fee_type} />
              )}
              options={[
                {
                  label: 'Dollar Value',
                  value: 'fixed_amount',
                },
                {
                  label: 'Percentage',
                  value: 'percentage',
                },
              ]}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FastField.TextInput
          type='number'
          name='delivery_minimum'
          legend='Order Minimum ($)'
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {isUsingUndoRedoFeature ? (
          <DrawnZonesMapUndoRedo
            setFieldValue={setFieldValue}
            latLngs={long_lat}
            isValidateError={!!missCoordinateErr}
            ref={drawnZoneMapRef}
            defaultCenter={defaultCenter}
          />
        ) : (
          <>
            <Typography variant='h5' color={themes.color.black}>
              Draw Zone
            </Typography>
            <Typography mt={1}>
              Use the draw tool to determine the delivery zone.
            </Typography>
            <DrawnZonesMap
              setFieldValue={setFieldValue}
              latLngs={long_lat}
              isValidateError={!!missCoordinateErr}
              ref={drawnZoneMapRef}
              defaultCenter={defaultCenter}
            />
          </>
        )}
        {missCoordinateErr ? (
          <FormHelperText error>{missCoordinateErr}</FormHelperText>
        ) : null}
      </Grid>
    </Grid>
  );
};
