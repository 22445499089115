import { object, string } from 'yup';

export const AddTeamSchema = object().shape({
  name: string()
    .required('Team name is required')
    .trim()
    .max(50, 'Max 50 characters'),
  description: string()
    .required('Short Description is required')
    .trim()
    .max(150, 'Max 150 characters'),
});
