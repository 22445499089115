import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import theme from '~/themes';
import { queryClient } from '~/lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import DingAudio from '~/assets/audio/ding.mp3';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import {
  FeaturesReady,
  GrowthBook,
  GrowthBookProvider,
} from '@growthbook/growthbook-react';
import { GROWTH_BOOK_CLIENT_KEY } from '~/config';
import { AuthProvider } from './AuthProvider';
import { UnifiedAuthProvider } from './UnifiedAuthProvider';

export const growthbook = new GrowthBook({
  apiHost: 'https://flag-proxy.breadstackcrm.com',
  clientKey: GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    // eslint-disable-next-line no-console
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

const AppProvider = ({ children }) => {
  // https://developer.chrome.com/blog/autoplay/
  const autoPlayAudio = () => {
    const audio = new Audio(DingAudio);
    audio.muted = true;
    audio.play().catch(() => {
      // @NOTE: read https://developer.chrome.com/blog/autoplay/
    });
  };

  useEffect(() => {
    autoPlayAudio();
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ThemeProviderLegacy theme={theme}>
          <QueryClientProvider client={queryClient}>
            <GrowthBookProvider growthbook={growthbook}>
              {/* {import.meta.env.MODE === 'development' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )} */}
              <FeaturesReady fallback={null}>
                <BrowserRouter>
                  <UnifiedAuthProvider>
                    <AuthProvider>{children}</AuthProvider>
                  </UnifiedAuthProvider>
                </BrowserRouter>
              </FeaturesReady>
            </GrowthBookProvider>
          </QueryClientProvider>
        </ThemeProviderLegacy>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppProvider;
