import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchFormik } from '~/components/common/Search';
import Button from '~/components/common/Button';
import { useFirstLoadingDetection } from '~/hooks';
import { StyledPanelDetailContainer } from '~/themes/style';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { fromQueryString } from '~/utils/queryString';
import { CircularProgress, Stack } from '@mui/material';
import { StyledFirstLoadingContainer } from '~/layout/PageLayout';
import { useGetDriverListQuery } from '../apis';
import DialogInviteDriver from '../Invite/DialogInviteDriver';
import { DriverTable } from './DriverTable';
import { DriverDetail } from '../Detail/DriverDetail';
import AddYourDrivers from './AddYourDrivers';
import { StyledFilterContainer } from '../../style';
import { UserExpand, UserRole } from '../../Users/types';

type DriverListProps = {};

export const DriverList: React.FC<DriverListProps> = () => {
  const location = useLocation();
  const { isStarterPlan, setIsOpenUpSellDialog } = usePaymentInfo();
  const [openInviteDriverDialog, setOpenInviteDriverDialog] = useState(false);

  const {
    search,
    sort,
    sort_by,
    id: driverId,
  } = fromQueryString(location.search);

  const { data: rawData, isFetching } = useGetDriverListQuery({
    sort,
    sort_by,
    search,
    roles: UserRole.Driver,
    expand: UserExpand.Team,
  });
  const isFirstLoading = useFirstLoadingDetection([isFetching]);
  const drivers = useMemo(() => rawData || [], [rawData]);

  const filterComponent = useMemo(
    () => (
      <StyledFilterContainer>
        <SearchFormik placeholder='Search' clearIds={['id']} />
        <Button
          onClick={() => {
            if (isStarterPlan && drivers.length >= 1) {
              return setIsOpenUpSellDialog(true);
            }
            return setOpenInviteDriverDialog(true);
          }}
        >
          Add Driver
        </Button>
      </StyledFilterContainer>
    ),
    [search, location, isStarterPlan, drivers],
  );

  const renderDriverDrawerDetail = () => (
    <StyledPanelDetailContainer isOpen={!!driverId} fixedWidth={650}>
      {driverId && <DriverDetail />}
    </StyledPanelDetailContainer>
  );

  if (isFirstLoading) {
    return (
      <StyledFirstLoadingContainer>
        <CircularProgress />
      </StyledFirstLoadingContainer>
    );
  }

  const isEmptyState =
    !drivers?.length && !isFetching && !location.search.length;

  const renderEmpty = (
    <AddYourDrivers onAddDriver={() => setOpenInviteDriverDialog(true)} />
  );

  return (
    <Stack flex={1} width='100%' overflow='hidden'>
      {filterComponent}
      <Stack className='customized-scrollbar' flex={1} overflow='auto'>
        <DriverTable
          isLoading={isFetching}
          data={drivers || []}
          isEmptyState={isEmptyState}
          renderEmpty={renderEmpty}
        />
      </Stack>
      <DialogInviteDriver
        open={openInviteDriverDialog}
        onClose={() => setOpenInviteDriverDialog(false)}
      />
      {renderDriverDrawerDetail()}
    </Stack>
  );
};
