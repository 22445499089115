import { Box, Stack, Typography } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import { getProgressBarOption } from '~/pages/LiveTracking/utils';
import { Task } from '~/pages/LiveTracking/types';

type DriverProgressBarProps = { task: Task };

export const DriverProgressBar: React.FC<DriverProgressBarProps> = ({
  task,
}) => {
  const driverProgressBarInfo = getProgressBarOption(task);
  if (!driverProgressBarInfo) return <div />;

  const process = driverProgressBarInfo.value;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          mt: 2,
        }}
      >
        <Box
          position='absolute'
          sx={{
            background: driverProgressBarInfo.styles.leftBg,
            top: 0,
            left: 0,
            height: '5px',
            borderRadius: '5px',
            width: `${process}%`,
          }}
        />
        <NavigationIcon
          sx={{
            position: 'absolute',
            top: -10,
            transform: 'rotate(90deg)',
            left: `calc(${process}% - 10px)`,
            zIndex: 2,
            color: driverProgressBarInfo.styles.navColor,
          }}
        />
        <Box
          position='absolute'
          sx={{
            background: driverProgressBarInfo.styles.rightBg,
            top: 0,
            right: 0,
            height: '5px',
            borderRadius: '5px',
            width: `${100 - process}%`,
          }}
        />
      </Box>
      <Stack mt={1.5} direction='row' justifyContent='space-between'>
        <Typography>{driverProgressBarInfo.desc}</Typography>
        <Typography>{driverProgressBarInfo.time}</Typography>
      </Stack>
    </>
  );
};
