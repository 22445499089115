import { useMutation, useQuery } from '@tanstack/react-query';
import { plansJsonData } from '~/constants/plan';
import httpClient from '~/lib/client/httpClient';
import {
  IPlan,
  IBillingFrequency,
  IPlanTier,
  ICreateSubscriptionParams,
  IUpdateSubscriptionParams,
} from '~/models/plan';
import { toQueryString } from '~/utils/queryString';

const getPlans = (): Promise<IPlan[]> => httpClient.json.get('prices');

const getSubscriptions = (subId: string): Promise<any> =>
  httpClient.json.get(`stripe/subscription/${subId}`);

const getPreviewSubscriptions = (params: {
  subId: string;
  paymentAccountId: string;
  priceId: string;
}): Promise<any> => {
  const { subId, priceId } = params;
  const queryString = toQueryString({
    subscription_id: subId,
    price_id: priceId,
  });
  return httpClient.json.get(`invoices/upcoming?${queryString}`);
};

const createSubscriptions = (params: ICreateSubscriptionParams): Promise<any> =>
  httpClient.json.post('stripe/subscriptions', params);

export const updateSubscriptions = (
  params: IUpdateSubscriptionParams,
): Promise<any> =>
  httpClient.json.put(`stripe/subscription/${params.subId}`, {
    ...(params.price_id && {
      price_id: params.price_id,
    }),
    ...(params.driver_id && {
      driver_id: params.driver_id,
    }),
    quantity: 1,
  });

export const useGetPlans = (params) => {
  const { onSuccess, onError } = params;
  return useQuery({
    queryKey: ['getPlans'],
    queryFn: getPlans,
    initialData: [],
    cacheTime: 10 * 60 * 1000,
    keepPreviousData: true,
    onSuccess,
    onError,
    select: (data) =>
      data
        .concat([
          {
            name: 'Starter',
            type_: 'plan',
            price_id: 'price_free',
            unit_amount: 0,
            recurring: {
              interval: IBillingFrequency.Month,
              interval_count: 1,
            },
            frequency: IBillingFrequency.Month,
            tier: IPlanTier.Free,
            id: 'price_free',
          },
        ])
        .map((plan) => ({
          ...plan,
          frequency: plan.recurring.interval,
          metadata: plansJsonData.find(
            (p) =>
              p.tier === plan.tier &&
              p.frequency === plan.recurring.interval &&
              // eslint-disable-next-line no-underscore-dangle
              plan.type_ === 'plan',
          ) || {
            title: '',
            price: '',
            price_num: 0,
            key_features: [],
            checks: [],
          },
        })),
  });
};

export const useGetSubscriptions = (params) => {
  const { id, onSuccess, onError } = params;
  return useQuery({
    queryKey: ['getSubscriptions'],
    queryFn: () => getSubscriptions(id),
    onSuccess,
    onError,
  });
};

export const useCreateSubscriptions = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: createSubscriptions,
    onSuccess,
    onError,
  });
};

export const usePreviewSubscriptions = (data) => {
  const { params, enabled = true, onSuccess, onError } = data;
  return useQuery({
    queryKey: ['getPreviewSubscriptions', params],
    queryFn: () => getPreviewSubscriptions(params),
    initialData: {},
    enabled,
    onSuccess,
    onError,
  });
};

export const useUpdateSubscriptions = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateSubscriptions,
    onSuccess,
    onError,
  });
};
