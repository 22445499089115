import { Formik, Form } from 'formik';
import { FormHelperText, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { Field } from '~/components/common/Formik';
import { InviteUserSchema } from '~/utils/schema/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '~/providers/AuthProvider';
import {
  useLogin,
  useLogout,
  useRegister,
  useVerifyReCaptcha,
} from '~/services/api/auth';
import { fromQueryString } from '~/utils/queryString';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetInvitationQuery,
  useValidateInvitationMutation,
} from '~/services/api/shared';
import { showAlert, alertParams } from '~/components/common/Alert';
import { queryKeys } from '~/services/api/queryKeys';
import { queryClient } from '~/lib/react-query';
import { firebaseGetToken } from '~/services/firebase';
import { jwtDecode } from 'jwt-decode';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '~/config';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { AuthLayout } from './components/AuthLayout';
import { StyledAuthTitle } from './style';

type InviteUserProps = {};

export const InviteUser: React.FC<InviteUserProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAuth();
  const reCaptchaRef = createRef();

  const [storeAuthParams, setStoreAuthParams] = useState({
    email: '',
    password: '',
    invitation_code: '',
  });
  const [errorMsg, setErrorMsg] = useState<string>('');
  const loggedWithDiffAcc = useRef(false);

  const { mutate: logoutMutation } = useLogout({
    onSuccess: () => {
      window.location.reload();
    },
  });

  const code = useMemo(
    () => fromQueryString(location.search)?.code as string,
    [location.search],
  );

  const { data: invitationInfo, isFetching: isFetchingInvitation } =
    useGetInvitationQuery({ params: { code } });

  const invitationAlertParams = {
    expired: {
      description: 'Your link has expired',
      okText: 'Close',
    },
    notExist: {
      description: 'Invitation does not exist.',
      okText: 'Close',
    },
    differentAccount: {
      description: 'Please login with your account to accept the invitation.',
      okText: 'Close',
      onOk: () => navigate(`/login?code=${code}`),
      onCancel: () => navigate(`/login?code=${code}`),
    },
    wasAccepted: {
      description: 'Invitation was accepted.',
      okText: 'Close',
    },
    inValid: {
      description: 'Invitation link was invalid.',
      okText: 'Close',
    },
    invitationAcceptSuccess: {
      ...alertParams.success,
      description: 'Accept invitation successfully.',
      okText: 'OK',
    },
  };

  const { mutate: validateInvitation, isLoading: isLoadingValidateInvitation } =
    useValidateInvitationMutation({
      onSuccess: async () => {
        navigate('/invite-user/success');
        channel.postMessage({
          type: BroadcastEvents.RefreshOtherPageWhenLogged,
        });
        queryClient.refetchQueries([queryKeys.settings.account]);
      },
      onError: () => {
        queryClient.refetchQueries([queryKeys.settings.account]);
      },
    });

  const { mutate: loginMutation, isLoading: isLoadingLogin } = useLogin({
    options: {
      isInvalidateQueries: false,
      isSetCustomClaim: true,
    },
    onSuccess: async () => {
      validateInvitation({ code });
    },
    onError: (error) => {
      setErrorMsg(error);
    },
  });

  const { mutate: registerMutation, isLoading: isLoadingRegister } =
    useRegister({
      onSuccess: async () => {
        loginMutation(storeAuthParams);
      },
    });

  const { mutate: verifyReCaptchaMutation } = useVerifyReCaptcha({
    onSuccess: async () => {
      registerMutation(storeAuthParams);
    },
  });

  const hasAccountInOtherPlatform =
    invitationInfo?.exists_in_firebase && !invitationInfo?.has_app;

  useEffect(() => {
    if (!code) {
      showAlert({
        ...alertParams.warning,
        description: 'Missing invitation code!',
        okText: 'Close',
        onOk: () => navigate('/login'),
        onCancel: () => navigate('/login'),
      });
    }
  }, [code]);

  useEffect(() => {
    const handleVerifyInvitation = async () => {
      let alertParamsData = null;
      if (isFetchingInvitation) return;
      if (code && invitationInfo) {
        if (invitationInfo.expired) {
          alertParamsData = invitationAlertParams.expired;
          showAlert({
            ...alertParams.warning,
            onOk: () => navigate('/login'),
            onCancel: () => navigate('/login'),
            ...alertParamsData,
          });
          return;
        }

        if (!invitationInfo.email) {
          alertParamsData = invitationAlertParams.inValid;
          showAlert({
            ...alertParams.warning,
            onOk: () => navigate('/login'),
            onCancel: () => navigate('/login'),
            ...alertParamsData,
          });
          return;
        }

        if (invitationInfo.is_accepted) {
          alertParamsData = invitationAlertParams.wasAccepted;
          showAlert({
            ...alertParams.warning,
            onOk: () => navigate('/login'),
            onCancel: () => navigate('/login'),
            ...alertParamsData,
          });
          return;
        }

        const idToken = await firebaseGetToken();
        const tkDecoded = idToken
          ? jwtDecode<{ email: string }>(idToken)
          : { email: null };

        const isLogged = !!idToken;
        const hasCFAccount =
          invitationInfo.exists_in_firebase && invitationInfo.has_app;
        const isSameAccount = tkDecoded.email === invitationInfo?.email;

        if (hasCFAccount) {
          if (isLogged) {
            if (isSameAccount) {
              validateInvitation({ code });
            } else {
              loggedWithDiffAcc.current = true;
              logoutMutation();
            }
          } else {
            alertParamsData = invitationAlertParams.differentAccount;
            // continuing create account
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isLogged) {
            logoutMutation();
          }
        }
      } else {
        alertParamsData = invitationAlertParams.notExist;
      }

      if (alertParamsData && !loggedWithDiffAcc.current) {
        showAlert({
          ...alertParams.warning,
          onOk: () => navigate('/login'),
          onCancel: () => navigate('/login'),
          ...alertParamsData,
        });
      }
    };
    handleVerifyInvitation();
  }, [code, account, invitationInfo, isFetchingInvitation]);

  const handleSubmit = ({ email, password, captcha_token }) => {
    setErrorMsg('');
    setStoreAuthParams({ email, password, invitation_code: code });
    if (hasAccountInOtherPlatform) {
      return loginMutation({ email, password });
    }
    return verifyReCaptchaMutation(captcha_token);
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={{
          full_name: invitationInfo?.display_name || '',
          email: invitationInfo?.email || '',
          password: '',
          captcha_token: '',
        }}
        enableReinitialize
        validationSchema={InviteUserSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <Stack spacing={2}>
              <StyledAuthTitle>Create account</StyledAuthTitle>
              {hasAccountInOtherPlatform && (
                <Typography
                  sx={{
                    background: '#FDE9AB',
                    p: 1,
                    textAlign: 'center',
                    fontSize: 16,
                    fontFamily: 'Lato',
                  }}
                >
                  Breadstack Delivery is integrated with the Breadstack Account
                  system. It looks like you’ve got a Breadstack Account already,
                  please enter your password to continue.
                </Typography>
              )}

              <Field.TextInput name='full_name' legend='Full Name' disabled />
              <Field.UnifiedTextField
                name='email'
                label='Email'
                disabled
                onChange={() => setErrorMsg('')}
              />
              <Field.UnifiedTextField
                name='password'
                label='Password'
                type='password'
                onChange={() => setErrorMsg('')}
              />
              <Typography
                sx={{
                  lineHeight: 1.66,
                  color: '#F24822',
                  mt: '10px !important',
                  fontSize: 14,
                }}
              >
                {errorMsg}
              </Typography>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={(token) => setFieldValue('captcha_token', token)}
                style={{ marginTop: 20 }}
              />
              {!!errors.captcha_token && !!touched.captcha_token && (
                <FormHelperText error data-testid='captcha-input-error'>
                  Please solve Captcha correctly
                </FormHelperText>
              )}
              <Button
                type='submit'
                loading={
                  isLoadingLogin ||
                  isLoadingRegister ||
                  isLoadingValidateInvitation
                }
              >
                {hasAccountInOtherPlatform
                  ? 'Login to Accept Invitation'
                  : 'Create Account'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
