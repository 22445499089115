import { IconButton, Stack, TextField } from '@mui/material';
import { styled } from '~/themes';
import ArrowLeftIcon from '~/assets/images/icons/arrow-left.svg';
import ArrowRightIcon from '~/assets/images/icons/arrow-right.svg';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addToQueryString } from '~/utils/queryString';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  enabledLocationPageChange?: boolean;
  onChangePage?: (newPage: number) => void;
};

export const StyledArrowKeyButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled, theme }) => ({
  'width': 50,
  'height': 34,
  'borderRadius': 10,
  'backgroundColor': theme.color.violet300,
  '&:hover': {
    backgroundColor: theme.color.violet300,
  },
  ...(disabled && {
    'backgroundColor': theme.bg.gray300,
    'opacity': 0.7,
    '&:hover': {
      backgroundColor: theme.bg.gray300,
    },
    'cursor': 'not-allowed',
  }),
}));

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  enabledLocationPageChange = true,
  onChangePage = () => {},
}) => {
  const [tempPage, setTempPage] = useState(1);
  const inputPageRef: any = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const isDisabledPrevPage = currentPage === 1;
  const isDisabledNextPage = currentPage === totalPages;

  useEffect(() => {
    if (currentPage !== tempPage) {
      setTempPage(currentPage);
    }
  }, [currentPage]);

  const handleChangePage = useCallback(
    (newPage: number) => {
      if (enabledLocationPageChange) {
        navigate({
          pathname: location.pathname,
          search: addToQueryString(location.search, {
            page: newPage,
          }),
        });
      }
      onChangePage(newPage);
    },
    [location, enabledLocationPageChange],
  );

  const handleInputChange = (e) => {
    const tempValue = e.target.value.replace(/[^0-9]/g, '');
    if (tempValue > totalPages) {
      setTempPage(totalPages);
    } else {
      setTempPage(tempValue);
    }
  };

  const handleBlur = () => {
    if (tempPage) {
      let validPage = 1;

      if (tempPage < 1) {
        validPage = 1;
      } else if (tempPage > totalPages) {
        validPage = totalPages;
      } else {
        validPage = tempPage;
      }

      setTempPage(validPage);
      if (validPage !== currentPage) handleChangePage(validPage);
    } else {
      setTempPage(currentPage);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleBlur();
      inputPageRef?.current.blur();
    }
  };

  return (
    <Stack
      sx={{ height: 35, alignItems: 'center' }}
      direction='row'
      spacing={1}
    >
      <span>Page</span>
      <TextField
        id='pagination-input'
        value={tempPage}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        sx={{
          'maxWidth': 60,
          '& > div': {
            borderRadius: '10px !important',
          },
        }}
        inputProps={{
          'data-testid': 'txt-pagination-page',
        }}
      />
      <span>of {`${totalPages || ''}` || ''}</span>
      <StyledArrowKeyButton
        disableRipple
        disabled={isDisabledPrevPage}
        onClick={() => {
          if (isDisabledPrevPage) return;
          handleChangePage(currentPage - 1);
        }}
        {...{ 'data-testid': 'btn-pagination-prev' }}
      >
        <img width={18} height={14} src={ArrowLeftIcon} alt='arrow-left-icon' />
      </StyledArrowKeyButton>
      <StyledArrowKeyButton
        disableRipple
        disabled={isDisabledNextPage}
        onClick={() => {
          if (isDisabledNextPage) return;
          handleChangePage(currentPage + 1);
        }}
        {...{ 'data-testid': 'btn-pagination-next' }}
      >
        <img
          width={18}
          height={14}
          src={ArrowRightIcon}
          alt='arrow-right-icon'
        />
      </StyledArrowKeyButton>
    </Stack>
  );
};
