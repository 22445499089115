import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { toQueryString } from '~/utils/queryString';
import { queryClient } from '~/lib/react-query';
import { IStore, IntegrationType } from '~/models/stores';
import { formatDateTimeUTC } from '~/utils/formatter';
import { ITabValue } from '~/models/shared';
import {
  Task,
  Driver,
  TaskStatus,
  IDriverRouteResponse,
  ITaskLog,
  LocationInfo,
  ITaskResponse,
} from './types';
import { mapTaskLogCreatedByText } from './utils';
import { DEFAULT_ADD_TASK_DATA } from './constants';

export type DriverResponse = Driver[];
export const LiveTrackingQueryKeys = {
  TasksList: 'LiveTracking.TasksList',
  TaskDetail: 'LiveTracking.TaskDetail',
  TaskPhoto: 'LiveTracking.TaskPhoto',
  TaskLog: 'LiveTracking.TaskLog',
  StoreLocations: 'LiveTracking.StoreLocations',
  DriverList: 'LiveTracking.DriverList',
  DriverDetail: 'LiveTracking.DriverDetail',
  DriverRoutes: 'LiveTracking.DriverRoutes',
  OptimizeDriverRoutes: 'LiveTracking.OptimizeDriverRoutes',
  GetAllAddressBook: 'LiveTracking.GetAllAddressBook',
  GetAllAddressCustomer: 'LiveTracking.GetAllAddressCustomer',
};

const formatTasks = (taskResponse: ITaskResponse) => {
  if (!taskResponse.items) return taskResponse;
  const unassignedTasks = taskResponse.items.filter(
    (t) => t.status === TaskStatus.Unassigned,
  );
  const otherTasks = taskResponse.items.filter(
    (t) => t.status !== TaskStatus.Unassigned,
  );
  const dividerRow =
    unassignedTasks.length && otherTasks.length ? [{ isDivider: true }] : [];
  return {
    ...taskResponse,
    items: [...unassignedTasks, ...dividerRow, ...otherTasks],
  };
};

export const getTasks = (params: {
  [key: string]: any;
}): Promise<ITaskResponse> => {
  if (params.statuses === ITabValue.Ongoing) {
    params.statuses = [
      TaskStatus.Unassigned,
      TaskStatus.PendingPickup,
      TaskStatus.Delivering,
      TaskStatus.Returning,
    ];
  }
  if (params.statuses === ITabValue.Completed) {
    params.statuses = [
      TaskStatus.Cancelled,
      TaskStatus.Completed,
      TaskStatus.Returned,
    ];
  }

  if (params.reassigned_status) {
    params.statuses = [];
  }

  return httpClient.json.get(
    `tasks?${toQueryString(params, {
      arrayFormat: 'none',
    })}`,
  );
};

export const getLiveTrackingTasks = (params: {
  [key: string]: any;
}): Promise<ITaskResponse> => {
  if (params.statuses === ITabValue.Ongoing) {
    params.statuses = [
      TaskStatus.Unassigned,
      TaskStatus.PendingPickup,
      TaskStatus.Delivering,
      TaskStatus.Returning,
    ];
  }
  if (params.statuses === ITabValue.Completed) {
    params.statuses = [
      TaskStatus.Cancelled,
      TaskStatus.Completed,
      TaskStatus.Returned,
    ];
  }

  if (params.reassigned_status) {
    params.statuses = [];
  }

  return httpClient.json.get(
    `live-tracking/tasks?${toQueryString(params, {
      arrayFormat: 'none',
    })}`,
  );
};

export const getDrivers = (params: {
  [key: string]: any;
}): Promise<DriverResponse> =>
  httpClient.json.get(`users?${toQueryString(params)}`);

export const getDriverDetail = (params: {
  [key: string]: any;
}): Promise<Driver> => httpClient.json.get(`users/${params.id}`);

export const getDriverRoutes = (params: {
  [key: string]: any;
}): Promise<IDriverRouteResponse[]> =>
  httpClient.json.get(`pickup_hub_task_map?driver_id=${params.id}`);

export const getTaskDetail = (params: { [key: string]: any }): Promise<Task> =>
  httpClient.json.get(`tasks/${params.id}`);

export const getTaskPhoto = (params: {
  [key: string]: any;
}): Promise<{ blob?: string; blob_type?: string }> =>
  httpClient.json.get(`/tasks/${params.task_id}/documents/${params.id}`);

export const getTaskLog = (params: {
  [key: string]: any;
}): Promise<ITaskLog[]> => httpClient.json.get(`tasks/${params.id}/logs`);

export const getStoreLocations = (): Promise<IStore[]> =>
  httpClient.json.get(`integrations:locations`);

export const addTask = (params: { [key: string]: any }): Promise<Task> =>
  httpClient.json.post(`tasks`, params);

export const updateTask = (params: { [key: string]: any }): Promise<Task> =>
  httpClient.json.put(`tasks/${params.id}`, params);

export const cancelTask = (params: { [key: string]: any }): Promise<any> =>
  httpClient.json.post(`tasks:cancel/${params.id}`);

export const sendAlert = (params: { [key: string]: any }): Promise<any> =>
  httpClient.json.post(`users/${params.id}/alerts`, params.body);

export const setOfflineDriver = (params: {
  [key: string]: any;
}): Promise<any> =>
  httpClient.json.put(`users/${params.id}`, {
    driver_status: 'offline',
  });

export const updateDriverRoutesRequest = (params: {
  [key: string]: any;
}): Promise<any> =>
  httpClient.json.put('task_route', {
    driver_id: params.driver_id,
    route: params.routes,
  });

export const getOptimizerRoutes = (params: {
  [key: string]: any;
}): Promise<IDriverRouteResponse[]> =>
  httpClient.json.get(`optimizer/${params.driver_id}`);

export const getSuggestionAddress = (
  query: string,
  include_address_book: boolean = false,
): Promise<any> =>
  httpClient.json.get(
    `places/search?query=${query}${
      include_address_book ? '&include_address_book=True' : ''
    }`,
  );

export const getSuggestionAddressDetail = (placeId: string): Promise<any> =>
  httpClient.json.get(
    `/places/detail?place_id=${placeId}&fields=place_id&fields=address_component&fields=vicinity&fields=name&fields=international_phone_number`,
  );

export const getAllAddressBook = (): Promise<LocationInfo[]> =>
  httpClient.json.get('common_address/search');

export const getAddressCustomer = (query?: string): Promise<LocationInfo[]> =>
  httpClient.json.get(`customer/search${query ? `?query=${query}` : ''}`);

type QueryData = {
  onSuccess?: (resp?: any) => void;
  onError?: (resp?: any) => void;
  params?: { [key: string]: any };
  enabled?: boolean;
  refetchInterval?: number | false;
};

export const useGetTasks = ({
  onSuccess,
  onError,
  params,
  refetchInterval,
  enabled = true,
}: QueryData) =>
  useQuery<ITaskResponse>({
    queryKey: [LiveTrackingQueryKeys.TasksList, params],
    queryFn: () => getTasks(params),
    initialData: () =>
      queryClient.getQueryData(['tasks', params]) as ITaskResponse,
    keepPreviousData: true,
    cacheTime: 1 * 7 * 1000,
    refetchInterval,
    enabled,
    select: formatTasks,
    onSuccess,
    onError,
  });

export const useGetLiveTrackingTasks = ({
  onSuccess,
  onError,
  params,
  refetchInterval,
  enabled = true,
}: QueryData) =>
  useQuery<ITaskResponse>({
    queryKey: [LiveTrackingQueryKeys.TasksList, params],
    queryFn: () => getLiveTrackingTasks(params),
    initialData: () =>
      queryClient.getQueryData(['tasks', params]) as ITaskResponse,
    keepPreviousData: true,
    cacheTime: 1 * 7 * 1000,
    refetchInterval,
    enabled,
    select: formatTasks,
    onSuccess,
    onError,
  });

export const useGetDrivers = ({
  onSuccess,
  onError,
  params,
  refetchInterval,
  enabled = true,
}: QueryData) =>
  useQuery<DriverResponse>({
    queryKey: [LiveTrackingQueryKeys.DriverList, params],
    queryFn: () => getDrivers(params),
    refetchInterval,
    cacheTime: 1 * 10 * 1000,
    keepPreviousData: true,
    initialData: [],
    enabled,
    onSuccess,
    onError,
  });

export const useGetDriverDetail = ({
  onSuccess,
  onError,
  params,
  refetchInterval,
}: QueryData) =>
  useQuery<Driver>({
    queryKey: [LiveTrackingQueryKeys.DriverDetail, params.id],
    queryFn: () => getDriverDetail(params),
    enabled: !!params.id,
    refetchInterval,
    cacheTime: 1 * 10 * 1000,
    keepPreviousData: true,
    initialData: () =>
      queryClient.getQueryData([
        LiveTrackingQueryKeys.DriverDetail,
        params.id,
      ]) as Driver,
    onSuccess,
    onError,
  });

export const useGetDriverRoutes = ({ onSuccess, onError, params }: QueryData) =>
  useQuery<IDriverRouteResponse[]>({
    queryKey: [LiveTrackingQueryKeys.DriverRoutes, params.id],
    queryFn: () => getDriverRoutes(params),
    enabled: !!params.id,
    initialData: [],
    cacheTime: 0,
    onSuccess,
    onError,
  });

export const useGetTaskDetail = ({
  onSuccess,
  onError,
  params,
  refetchInterval,
}: QueryData) =>
  useQuery<Task>({
    queryKey: [LiveTrackingQueryKeys.TaskDetail, params.id],
    queryFn: () => getTaskDetail(params),
    enabled: !!params.id,
    keepPreviousData: true,
    cacheTime: 1 * 10 * 1000,
    refetchInterval,
    select: (data: Task): Task => {
      if (data?.integration_type === IntegrationType.Breadstack) {
        return {
          ...data,
          returnLabelInfo: data?.metadata?.return_address
            ? {
                ...data?.metadata?.return_address,
                phone: data?.metadata?.return_address?.phone_number,
                postcode: data?.metadata?.return_address?.postal_code,
              }
            : DEFAULT_ADD_TASK_DATA.pickup,
        };
      }
      return {
        ...data,
        returnLabelInfo: data?.pickup || DEFAULT_ADD_TASK_DATA.pickup,
      };
    },
    initialData: () =>
      queryClient.getQueryData([
        LiveTrackingQueryKeys.TaskDetail,
        params.id,
      ]) as Task,
    onSuccess,
    onError,
  });

export const useGetTaskImage = ({ onSuccess, onError, params }: QueryData) =>
  useQuery({
    queryKey: [LiveTrackingQueryKeys.TaskPhoto, params],
    queryFn: () => getTaskPhoto(params),
    initialData: {},
    enabled: !!params.id,
    select: (data) => ({
      src: `data:${data?.blob_type};base64,${data?.blob}`,
    }),
    onSuccess,
    onError,
  });

export const useGetTaskLogs = ({
  onSuccess,
  onError,
  refetchInterval,
  params,
}: QueryData) =>
  useQuery({
    queryKey: [LiveTrackingQueryKeys.TaskLog, params],
    queryFn: () => getTaskLog(params),
    initialData: [],
    enabled: !!params.id,
    refetchInterval,
    select: (data) =>
      data.map(({ created_by, created_at, log, id, is_created_by_system }) => ({
        id,
        body: log,
        dispatcherName: `${mapTaskLogCreatedByText(
          created_by?.role,
          is_created_by_system,
        )}: ${created_by?.display_name}`,
        createdBy: formatDateTimeUTC(created_at, 'hh:mm a - MM/DD/YYYY'),
      })),
    onSuccess,
    onError,
  });

export const useGetStoreLocations = ({ onSuccess, onError }: QueryData) =>
  useQuery({
    queryKey: [LiveTrackingQueryKeys.StoreLocations],
    queryFn: getStoreLocations,
    initialData: [],
    onSuccess,
    onError,
  });

export const useGetAllAddressBook = (data) => {
  const { onSuccess, onError } = data;
  return useQuery({
    queryKey: [LiveTrackingQueryKeys.GetAllAddressBook],
    queryFn: getAllAddressBook,
    initialData: [],
    onSuccess,
    onError,
  });
};

export const useOptimizeRoutes = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: getOptimizerRoutes,
    onSuccess,
    onError,
  });

export const useAddTask = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: addTask,
    onSuccess,
    onError,
  });

export const useUpdateTask = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: updateTask,
    onSuccess,
    onError,
  });

export const useCancelTask = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: cancelTask,
    onSuccess,
    onError,
  });

export const useSendAlertMutation = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: sendAlert,
    onSuccess,
    onError,
  });
export const useOfflineDriverMutation = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: setOfflineDriver,
    onSuccess,
    onError,
  });

export const useUpdateDriverRoutes = ({ onSuccess, onError }: QueryData) =>
  useMutation({
    mutationFn: updateDriverRoutesRequest,
    onSuccess,
    onError,
  });

export const useGetAllAddressCustomer = (data) => {
  const { onSuccess, onError } = data;
  return useQuery({
    queryKey: [LiveTrackingQueryKeys.GetAllAddressCustomer],
    queryFn: () => getAddressCustomer(),
    initialData: [],
    onSuccess,
    onError,
  });
};
