import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import CircularLoading from '~/components/common/CirularLoading';
import { Stack } from '@mui/material';
import style from './style';
import LogItem from './LogItem';
import { ILogData } from './type';

export interface LogsProps extends WithStyles<typeof style> {
  data?: ILogData[];
  loading?: boolean;
  isShowAvatar?: boolean;
  noDataText?: string;
}

const Logs: React.FC<LogsProps> = ({
  classes,
  data = [],
  loading,
  isShowAvatar = true,
  noDataText = 'No results found.',
}) => (
  <Stack className={classes.container}>
    {data?.length === 0 ? (
      <div className={classes.noResultFound}>{noDataText}</div>
    ) : (
      <div className={clsx(classes.changeLogContainer, 'customized-scrollbar')}>
        <CircularLoading loading={loading} />
        {data.map((log: ILogData) => (
          <LogItem key={log.id} data={log} isShowAvatar={isShowAvatar} />
        ))}
      </div>
    )}
  </Stack>
);

export default withStyles(style)(Logs);
