import moment from 'moment-timezone';
import { TIMEZONE_SETTING } from '~/constants/adminOptions';
import { IAccount } from '~/models/adminOptions';
import { ITimezone } from '~/models/common';
import { IntegrationType } from '~/models/stores';
import WooIcon from '~/assets/images/icons/woo.svg';
import ShopifyIcon from '~/assets/images/icons/shopify.svg';
import BreadstackIcon from '~/assets/images/icons/breadstack-rounded.svg';
import { USER_DEVICE_ZONE } from './common';

const convertOrgData = (data: IAccount): IAccount => {
  if (data?.avatar && !data.avatar.startsWith('data:image/')) {
    data.avatar = `data:image/${data.avatar_type};base64,${data.avatar}`;
  }
  if (data && data?.timezone) {
    data.timezoneType =
      data.timezone === TIMEZONE_SETTING.AUTO_DETECT
        ? TIMEZONE_SETTING.AUTO_DETECT
        : TIMEZONE_SETTING.MANUALLY;
  }
  return data;
};

export const convertGeneralSettingsResponses = (response: IAccount[]) =>
  response.map((item) => convertOrgData(item));

export const convertGeneralSettingsResponse = (response: IAccount) => {
  if (response?.avatar && !response.avatar.startsWith('data:image/')) {
    response.avatar = `data:image/${response.avatar_type};base64,${response.avatar}`;
  }
  return response;
};

export const updateTimeZoneMoment = (timezone: string): void => {
  if (timezone) {
    moment.tz.setDefault(timezone);
  }
};

export const getDefaultTimezone = (dataTimezone: ITimezone[]): string => {
  if (!dataTimezone || !dataTimezone.length) {
    return null;
  }
  if (dataTimezone.find((x) => x.olson_name === USER_DEVICE_ZONE)?.name) {
    return dataTimezone.find((x) => x.olson_name === USER_DEVICE_ZONE)?.name;
  }
  return null;
};

export const getDashboardTimezone = (dataTimezone: ITimezone[]): string => {
  if (!dataTimezone || !dataTimezone.length) {
    return null;
  }
  if (dataTimezone.find((x) => x.olson_name === USER_DEVICE_ZONE)?.name) {
    return dataTimezone.find((x) => x.olson_name === USER_DEVICE_ZONE)
      ?.olson_name;
  }
  return null;
};

export const parseTimeZone = (timezone: string): string => {
  if (!timezone || timezone === TIMEZONE_SETTING.AUTO_DETECT)
    return USER_DEVICE_ZONE;
  return timezone;
};

export const getPlatformIcon = (type) => {
  switch (type) {
    case IntegrationType.Breadstack:
      return BreadstackIcon;
    case IntegrationType.Woo:
      return WooIcon;
    case IntegrationType.Shopify:
      return ShopifyIcon;
    default:
      return '';
  }
};
