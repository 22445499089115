import { transformTypeOptions } from '~/utils/common';
import { formatDateTimeUTC } from '~/utils/formatter';

import { IOptionItem } from '~/models/common';
import { UserStatus, IUser } from '~/models/user';
import { IDriver, VehicleType } from '~/models/driver';

export const USER_STATUS_OPTIONS: IOptionItem[] = transformTypeOptions(
  UserStatus,
  { count: null },
);

export const convertUser = (user: IUser): IUser => {
  if (user?.avatar && !user.avatar.startsWith('data:image/')) {
    user.avatar = `data:image/${user.avatar_type};base64,${user?.avatar}`;
  }

  user.created_at = formatDateTimeUTC(user.created_at, 'MM/DD/YYYY');
  return user;
};
export const convertDriver = (data: IDriver): IDriver => ({
  ...data,
  avatar: data?.avatar
    ? `data:image/${data.avatar_type};base64,${data?.avatar}`
    : data?.avatar,
  email: data?.email || '',
  first_name: data?.first_name || '',
  last_name: data?.last_name || '',
  display_name: data?.display_name || '',
  phone: data?.phone || '',
  dob: data?.dob ? formatDateTimeUTC(data?.dob, 'MM/DD/YYYY') : '',
  address: data?.address
    ? {
        address_1: data?.address?.address_1 || '',
        address_2: data?.address?.address_2 || '',
        city: data?.address?.city || '',
        state: data?.address?.state || '',
        country: data?.address?.country || '',
        postcode: data?.address?.postcode || '',
      }
    : {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
      },
  vehicle: {
    type: data?.vehicle?.type || ('' as VehicleType),
    make_model: data?.vehicle?.make_model || '',
    model: data?.vehicle?.model || '',
    year: data?.vehicle?.year || '',
    color: data?.vehicle?.color || '',
    licence_plate: data?.vehicle?.licence_plate || '',
    insurance_country: data?.vehicle?.insurance_country || '',
    insurance_state: data?.vehicle?.insurance_state || '',
  },
});

export const convertUsers = (response: any) =>
  response?.map((user) => {
    if (user?.avatar && !user.avatar.startsWith('data:image/')) {
      user.avatar = `data:image/${user.avatar_type};base64,${user.avatar}`;
    }
    return user;
  });
