import { Stack, Typography } from '@mui/material';
import SwitchButton from '~/components/common/SwitchButton';
import themes from '~/themes';

type IncludePickupTaskProps = {
  skipPickup?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const IncludePickupTask: React.FC<IncludePickupTaskProps> = ({
  skipPickup,
  onChange,
}) => (
  <Stack sx={{ background: themes.bg.gray300, borderRadius: '5px', p: 1 }}>
    <Typography fontWeight={500} fontSize={16}>
      Include Pickup Task
    </Typography>
    <Typography my={1}>
      Drivers will receive a pickup task on the Driver app, which they must
      complete before starting the corresponding dropoff task.
    </Typography>
    <SwitchButton
      name='skip_pickup'
      noText
      checked={!skipPickup}
      onChange={onChange}
    />
  </Stack>
);
