import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '~/config';
import HttpClient from './client';
import {
  handleDefaultResponse,
  addAuthHeader,
  addOrgIdHeader,
} from './interceptors';

class HttpFormData extends HttpClient {
  public static create(cfg?: AxiosRequestConfig) {
    const httpFormData = new HttpFormData({
      baseURL: ENDPOINTS,
      timeout: 90 * 1000, // 90s
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...cfg,
    });

    httpFormData.useReq('authHeader', addAuthHeader);
    httpFormData.useReq('addOrgIdHeader', addOrgIdHeader);

    httpFormData.useRes('defaultResponse', handleDefaultResponse);

    return httpFormData;
  }
}

export default HttpFormData;
