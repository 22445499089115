import React from 'react';
import { SwitchItem } from '~/components/common/SwitchButton';
import { IFormProps } from '~/models/common';
import { ISwitchItem } from '../SwitchButton/SwitchItem';

export interface IFieldSwitchItem
  extends IFormProps,
    Omit<ISwitchItem, 'name' | 'form'> {}

const FormSwitchItem: React.FC<IFieldSwitchItem> = ({
  field,
  form,
  ...props
}) => <SwitchItem checked={!!field.value} {...field} {...props} />;

export default FormSwitchItem;
