import { makeStyles } from '@mui/styles';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { Theme } from '~/themes';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': 25,
    'height': 25,
    'borderRadius': 5,
    'backgroundColor': theme.bg.darkPurple,
    'marginRight': theme.spacing(1),
    '& img': {
      width: 19,
      color: 'white',
    },
  },
  switchItem: {
    '& > div': {
      background: 'white',
      justifyContent: 'flex-start',
      paddingLeft: 0,
    },
  },
  switchLabel: {
    color: theme.color.black,
    fontSize: 12,
    marginBottom: theme.spacing(0.5),
  },
  addButton: {
    minWidth: 60,
    minHeight: 25,
    marginRight: 5,
    padding: theme.spacing(0.5, 0.8),
  },
  textArea: {
    lineHeight: 1.5,
  },
  modal: {},

  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    modal: {
      width: '100%',
    },
  },
}));

export default useStyles;
