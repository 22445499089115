import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '~/themes';
import { Formik, Form } from 'formik';
import { Stack } from '@mui/material';
import Button from '~/components/common/Button';
import { Field } from '~/components/common/Formik';
import {
  ONGOING_STATUS_OPTIONS,
  COMPLETED_STATUS_OPTIONS,
} from '~/constants/task';
import { fromQueryString } from '~/utils/queryString';
import { ITabValue } from '~/models/shared';
import { useGetDriverListQuery } from '../AdminOptions/Drivers/apis';
import { UserRole, UserStatus } from '../AdminOptions/Users/types';

export const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledFilterStack = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'rowGap': theme.spacing(2),
  'columnGap': theme.spacing(0.5),
  '& > div': {
    '&:first-of-type': {
      width: 300,
      marginRight: theme.spacing(2.5),
    },
    'width': 180,
  },
  [theme.breakpoints.down('md')]: {
    '& > *': {
      '&:not(:first-of-type)': {
        display: 'none',
      },
    },
    '& > button': {
      display: 'none',
    },
    '& > div': {
      '&:first-of-type': {
        width: '100%',
        marginRight: 0,
      },
    },
  },
}));

interface IFilterFormProps {
  onSubmit?: (values: any) => void;
  onClear?: () => void;
  onClearAll?: () => void;
}

export const FilterForm: React.FC<IFilterFormProps> = ({
  onSubmit,
  onClear,
  onClearAll,
}) => {
  const location = useLocation();

  const { tab, search, statuses, executor_ids } = fromQueryString(
    location.search,
  );
  const tabValue = (tab || ITabValue.Ongoing) as ITabValue;

  const initialValues = {
    search: search || '',
    executor_ids: executor_ids || '',
    statuses: statuses || '',
  };

  const { data: listDrivers } = useGetDriverListQuery({
    roles: UserRole.Driver,
    status: UserStatus.Active,
  });

  const renderDriverList = useMemo(
    () => (
      <Field.Select
        key='driver'
        placeholder='Driver'
        name='executor_ids'
        options={
          listDrivers && listDrivers.length > 0
            ? [
                ...listDrivers.map((driver) => ({
                  label:
                    driver.display_name.length > 16
                      ? `${driver.display_name.slice(0, 16)}...`
                      : driver.display_name,
                  value: driver.id,
                  key: driver.id,
                })),
              ]
            : []
        }
        style={{ marginRight: 20 }}
      />
    ),
    [listDrivers],
  );

  const renderTaskStatus = useMemo(
    () => (
      <Field.Select
        placeholder='Status'
        name='statuses'
        options={
          tabValue === ITabValue.Ongoing
            ? ONGOING_STATUS_OPTIONS
            : COMPLETED_STATUS_OPTIONS
        }
      />
    ),
    [tabValue],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ setFieldValue, resetForm }) => (
        <StyledForm>
          <StyledFilterStack>
            <Field.SearchInput
              name='search'
              placeholder='Search Task'
              onClear={() => {
                onClear();
                setFieldValue('search', '');
              }}
            />

            {renderTaskStatus}
            {renderDriverList}
            <Button type='submit' buttonType='default'>
              Apply
            </Button>
            <Button
              buttonType='default'
              onClick={() => {
                resetForm();
                onClearAll();
              }}
            >
              Clear All
            </Button>
          </StyledFilterStack>
        </StyledForm>
      )}
    </Formik>
  );
};
