import { IOptionItem } from '~/models/common';
import { AdminOptionTabs } from '~/models/adminOptions';
import { transformTypeOptions } from '~/utils/common';
import { IOrg } from '~/models/org';
import { IUser } from '~/models/user';
import { TutorialData } from './tutorial';

export const TIMEZONE_SETTING = {
  AUTO_DETECT: 'auto',
  MANUALLY: 'manual',
};

export const GENERAL_SETTING_DEFAULT: IUser = {
  id: '',
  items_per_page: 25,
  timezone: 'auto',
  timezoneType: '',
  first_name: '',
  last_name: '',
  avatar: '',
  profile_photo: '',
};

export const ORGANIZATION_DEFAULT: IOrg = {
  name: '',
  auto_assign: false,
};

export const MANUALLY_TIMEZONE_OPTIONS: IOptionItem[] = [
  {
    subLabel: 'UTC +7',
    label: 'Indochina Time',
    zone: 'Asia/Ho_Chi_Minh',
    value: 'Asia/Ho_Chi_Minh',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -3.5',
    label: 'Newfoundland Standard Time',
    zone: 'America/St_Johns',
    value: 'America/St_Johns',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -4',
    label: 'Atlantic Standard Time',
    zone: 'America/Halifax',
    value: 'America/Halifax',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -5',
    label: 'Eastern Standard Time',
    zone: 'America/New_York',
    value: 'America/New_York',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -6',
    label: 'Central Standard Time',
    zone: 'America/Chicago',
    value: 'America/Chicago',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -7',
    label: 'Mountain Standard Time',
    zone: 'America/Denver',
    value: 'America/Denver',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -8',
    label: 'Pacific Standard Time',
    zone: 'America/Vancouver',
    value: 'America/Vancouver',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -9',
    label: 'Alaska Standard Time',
    zone: 'America/Juneau',
    value: 'America/Juneau',
    country: 'Timezones',
  },
  {
    subLabel: 'UTC -10',
    label: 'Hawaii-Aleutian Standard Time',
    zone: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
    country: 'Timezones',
  },
];

export const ADMIN_OPTIONS_OPTIONS: IOptionItem[] =
  transformTypeOptions(AdminOptionTabs);

export const AdminOptionTutorial = {
  organization: TutorialData.Organization,
  stores: TutorialData.Stores,
  drivers: TutorialData.Drivers,
  teams: TutorialData.Teams,
  users: TutorialData.Users,
};
