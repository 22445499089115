import { Box, Grid, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import themes, { styled } from '~/themes';
import { useGetPlans } from '~/services/api/plan';
import {
  IBillingFrequency,
  IPlan,
  IPlanMetadata,
  IPlanTier,
  PlanTierWithNumberOfTask,
} from '~/models/plan';
import { useMemo } from 'react';
import { plansJsonData } from '~/constants/plan';
import { StyledCheckIcon } from './ComparePlans';

type SubscriptionPlansProps = {
  billingFrequency: IBillingFrequency;
  currentPlan?: IPlan;
  numberOfTasks?: string;
  countryOfOrg?: string;
  onChangePlan?: (planSelect?: IPlanMetadata) => void;
};

export const StyledPlanItem = styled(Stack)(({ theme }) => ({
  height: 500,
  border: '1px solid #F2F3F8',
  borderRadius: '10px',
  background: theme.bg.white,
  width: 300,
  position: 'relative',
  [theme.breakpoints.down('ss')]: {
    width: '100%',
    height: '100%',
  },
}));

export const StyledMostPopular = styled(Stack)(({ theme }) => ({
  background: theme.bg.darkPurple,
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: 26,
  position: 'absolute',
  color: 'white',
  width: 302,
  fontSize: 11,
  borderRadius: '10px 10px 0px 0px',
  fontWeight: '500',
  top: -1,
  left: -1,
  [theme.breakpoints.down('ss')]: {
    width: '100%',
  },
}));

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  billingFrequency,
  currentPlan,
  numberOfTasks,
  countryOfOrg,
  onChangePlan,
}) => {
  const { data } = useGetPlans({});

  const plans = useMemo(() => {
    if (countryOfOrg) {
      return data.filter(
        (p) => p.tier === IPlanTier.Free || p.country === countryOfOrg,
      );
    }
    return data;
  }, [data, countryOfOrg]);

  const tierWithNumberOfTask = numberOfTasks
    ? PlanTierWithNumberOfTask[numberOfTasks?.toString()]
    : null;

  const plansConverted = useMemo(() => {
    const filteredPlans = plansJsonData
      .map((p) => ({
        ...p,
        priceId: plans.find(
          (e) =>
            e.frequency === p.frequency &&
            p.tier === e.tier &&
            // eslint-disable-next-line no-underscore-dangle
            e.type_ === 'plan',
        )?.price_id,
      }))
      .filter((p) => p.frequency === billingFrequency);
    const freePlan = plansJsonData.find((p) => p.tier === IPlanTier.Free);
    const standardPlan = filteredPlans.find(
      (p) => p.tier === IPlanTier.Standard,
    );
    const advancePlan = filteredPlans.find((p) => p.tier === IPlanTier.Advance);
    return [freePlan, standardPlan, advancePlan].filter((t) => !!t);
  }, [plans, billingFrequency]);

  return (
    <Grid container spacing={2}>
      {plansConverted.map((item) => {
        const isCurrentPlan = currentPlan
          ? (currentPlan.tier === IPlanTier.Free &&
              item.tier === IPlanTier.Free) ||
            item.priceId === currentPlan.price_id
          : false;

        return (
          <Grid item xs={12} sm={12} md={4} key={item.id}>
            <StyledPlanItem
              sx={{
                ...(isCurrentPlan && {
                  background: '#F7F5FD',
                }),
              }}
            >
              {item.tier === tierWithNumberOfTask && (
                <StyledMostPopular>RECOMMEND FOR YOU</StyledMostPopular>
              )}
              {!numberOfTasks && item.tier === IPlanTier.Standard && (
                <StyledMostPopular>MOST POPULAR</StyledMostPopular>
              )}
              <Stack flex={1} alignItems='center' px={2}>
                <Typography
                  fontSize={18}
                  mt={5}
                  color={themes.color.primaryOrange}
                  fontWeight='bold'
                >
                  {item?.title}
                </Typography>
                <Stack
                  mt={1}
                  fontSize={40}
                  color={themes.color.black}
                  fontWeight='bold'
                  direction='row'
                  alignItems='center'
                >
                  {item.tier !== IPlanTier.Free ? item.price : 'Free'}
                  {item.tier !== IPlanTier.Free && (
                    <Stack ml={0.5}>
                      {billingFrequency === IBillingFrequency.Year ? (
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 'normal',
                            textDecoration: 'line-through',
                          }}
                        >
                          {
                            plansJsonData.find(
                              (p) =>
                                p.tier === item.tier &&
                                p.frequency === IBillingFrequency.Month,
                            )?.price
                          }
                        </span>
                      ) : (
                        <Box height={18} />
                      )}

                      <span style={{ fontSize: 14, fontWeight: 'normal' }}>
                        {' '}
                        /mo
                      </span>
                    </Stack>
                  )}
                </Stack>
                {item.tier === IPlanTier.Free ? (
                  <Box height={21} mt={1} />
                ) : (
                  <Typography fontSize={14} mt={1}>
                    {billingFrequency === IBillingFrequency.Month
                      ? 'billed monthly'
                      : 'billed annually'}
                  </Typography>
                )}

                {isCurrentPlan ? (
                  <Typography
                    sx={{ lineHeight: '35px', mt: 3, fontWeight: 'bold' }}
                  >
                    Current plan
                  </Typography>
                ) : (
                  <Button
                    fullWidth
                    sx={{ mt: 3 }}
                    onClick={() => {
                      if (item.tier === IPlanTier.Free) {
                        return onChangePlan({ ...item, priceId: 'price_free' });
                      }
                      return onChangePlan(item);
                    }}
                  >
                    Choose Plan
                  </Button>
                )}
              </Stack>
              <Stack flex={1} p={2}>
                <Typography fontWeight='bold'>Key features</Typography>
                <Stack spacing={1} mt={2}>
                  {item.key_features.map((k) => (
                    <span key={k}>{k}</span>
                  ))}
                </Stack>

                <Stack spacing={2} mt={2}>
                  {item.checks.map((check, idx) => (
                    <Stack
                      // eslint-disable-next-line react/no-array-index-key
                      key={check + idx}
                      direction='row'
                      spacing={1}
                      alignItems='center'
                    >
                      {check ? (
                        <>
                          <StyledCheckIcon name='check' useBackgroundImg />
                          <span>{check}</span>
                        </>
                      ) : (
                        <Typography
                          fontWeight='bold'
                          color={themes.color.gray900}
                          ml={0.5}
                        >
                          -
                        </Typography>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </StyledPlanItem>
          </Grid>
        );
      })}
    </Grid>
  );
};
