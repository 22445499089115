import { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { Stack } from '@mui/material';
import Button from '~/components/common/Button';
import { FastField, Field } from '~/components/common/Formik';
import { ORG_CATEGORY_OPTIONS } from '~/constants/common';
import themes, { styled } from '~/themes';
import { UserOnboardingSchema } from '~/utils/schema/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import {
  addToQueryString,
  fromQueryString,
  toQueryString,
} from '~/utils/queryString';
import { isEmpty, omitBy } from 'lodash';
import { getCountryCodeFromPhone } from '~/utils/common';
import PublicLayout from '~/layout/PublicLayout';
import { SetupPlanStep } from '~/models/plan';
import { NumberOfTaskOptions } from '~/constants/auth';
import { StyledAuthTitle } from './style';
import { AuthLayout } from './components/AuthLayout';
import { DialogSelectPlan } from './DialogSelectPlan';
import { UserSelectedPlan } from './UserSelectedPlan';

type UserOnboardingProps = {};

export const StyledTextInput = styled(Field.TextInput)(({ theme }) => ({
  '& ::placeholder': {
    color: `${theme.bg.gray600} !important`,
  },
}));

export const UserOnboarding: React.FC<UserOnboardingProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = fromQueryString(location.search, { arrayFormat: 'none' });
  const [disabledFields, setDisabledFields] = useState([]);
  const [openDialogSelectPlan, setOpenDialogSelectPlan] = useState(false);
  const step = queryParams?.step || SetupPlanStep.AddOrg;

  const convertQueryParams = () => {
    const mobileNumber = queryParams.mobile_number as string;
    const countryCode = getCountryCodeFromPhone(mobileNumber) as CountryCode;
    const onboardingData = {
      category: queryParams?.category || '',
      number_of_delivery_tasks: queryParams?.number_of_delivery_tasks || '',
      org_name: queryParams.org_name,
      full_name: queryParams.full_name,
      job_title: queryParams.job_title,
      country_code: countryCode,
      ...(mobileNumber && {
        phone: parsePhoneNumber(mobileNumber, countryCode).nationalNumber,
      }),
    };
    if (
      queryParams?.business_category &&
      ORG_CATEGORY_OPTIONS.find(
        (e) => e.value === queryParams?.business_category,
      )
    ) {
      onboardingData.category = queryParams?.business_category as string;
    }

    if (queryParams?.delivery_tasks) {
      const deliveryTaskItem = NumberOfTaskOptions.find(
        (e) => e.value === queryParams?.delivery_tasks,
      );

      if (deliveryTaskItem) {
        onboardingData.number_of_delivery_tasks =
          deliveryTaskItem.value as string;
      }
    }

    return omitBy(onboardingData, isEmpty);
  };

  useEffect(() => {
    if (queryParams && disabledFields.length === 0) {
      const convertedQueryParams = convertQueryParams();
      setDisabledFields(Object.keys(convertedQueryParams));
    }
  }, [queryParams, disabledFields]);

  const userInfoForm = useMemo(
    () => (
      <AuthLayout>
        <Stack spacing={2} pt={10} alignItems='center'>
          <Stack width={400} spacing={2}>
            <StyledAuthTitle>Hi, Let’s get to know you</StyledAuthTitle>
            <StyledTextInput
              name='full_name'
              placeholder='John Doe'
              legend='Full Name*'
              disabled={disabledFields.includes('full_name')}
            />
            <StyledTextInput
              name='job_title'
              legend='Job Title (optional)'
              placeholder='Admin'
            />
            <StyledTextInput
              name='org_name'
              legend='Name of Organization*'
              placeholder='My Delivery Business INC.'
              disabled={disabledFields.includes('org_name')}
            />
            <Field.Select
              options={ORG_CATEGORY_OPTIONS}
              name='category'
              legend='Business Category (optional)'
              placeholder='Select a category'
              disabled={disabledFields.includes('category')}
              sxPlaceholder={{
                color: themes.bg.gray600,
              }}
            />
            <Field.TextInput
              name='phone'
              type='tel'
              legend='Mobile Phone Number (optional)'
              prefix={<FastField.CountryCodeDropdown name='country_code' />}
            />
          </Stack>
          <Stack direction='row' spacing={2} width={400}>
            <Button fullWidth type='submit'>
              Next
            </Button>
          </Stack>
        </Stack>
      </AuthLayout>
    ),
    [disabledFields],
  );

  const userSelectedPlanForm = useMemo(
    () => (
      <PublicLayout>
        <UserSelectedPlan
          openDialogSelectPlan={openDialogSelectPlan}
          setOpenDialogSelectPlan={setOpenDialogSelectPlan}
        />
        <DialogSelectPlan
          open={openDialogSelectPlan}
          onClose={() => setOpenDialogSelectPlan(false)}
          onChangePlan={(data) => {
            navigate({
              pathname: location.pathname,
              search: addToQueryString(location.search, data),
            });
          }}
        />
      </PublicLayout>
    ),
    [location, openDialogSelectPlan],
  );

  if (step === SetupPlanStep.PreviewSummary || step === SetupPlanStep.AddCard)
    return userSelectedPlanForm;

  return (
    <Formik
      initialValues={{
        org_name: '',
        full_name: '',
        country_code: 'CA',
        number_of_delivery_tasks: '',
        job_title: '',
        phone: '',
        mobile_number: '',
        category: '',
        ...convertQueryParams(),
      }}
      enableReinitialize
      onSubmit={() => setOpenDialogSelectPlan(true)}
      validationSchema={UserOnboardingSchema}
    >
      {({ values }) => (
        <Form style={{ height: '100%' }}>
          {step === SetupPlanStep.AddOrg && userInfoForm}
          <DialogSelectPlan
            open={openDialogSelectPlan}
            onClose={() => setOpenDialogSelectPlan(false)}
            onChangePlan={(data) => {
              navigate({
                pathname: location.pathname,
                search: toQueryString({
                  step: SetupPlanStep.PreviewSummary,
                  category: values.category,
                  org_name: values.org_name,
                  ...(values.phone.trim() && {
                    phone: parsePhoneNumber(
                      values?.phone,
                      (values?.country_code as CountryCode) || 'CA',
                    )?.nationalNumber,
                  }),
                  country_code: values.country_code,
                  job_title: values.job_title.trim(),
                  full_name: values.full_name,
                  number_of_delivery_tasks: values.number_of_delivery_tasks,
                  ...data,
                }),
              });
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
