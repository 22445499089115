import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ORG_ID_HEADER } from '~/constants/common';
import { memoryStorage } from '~/utils/localStorage';
import { firebaseGetToken } from '~/services/firebase';
import { showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';

// Request interceptors
export const addAuthHeader = async (cfg: AxiosRequestConfig) => {
  const token = await firebaseGetToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    cfg.headers.authorization = `Bearer ${token}`;
  }

  return cfg;
};

export const addOrgIdHeader = (cfg: AxiosRequestConfig) => {
  const orgId = memoryStorage.getOrgId();
  if (orgId && cfg.url !== 'accounts') {
    // eslint-disable-next-line no-param-reassign
    cfg.headers[ORG_ID_HEADER] = orgId;
  }
  return cfg;
};

// Response interceptors
export const handleDefaultResponse = (res: AxiosResponse) => res.data;

export const handleUnauthorizedError = () => (error: AxiosError) => {
  const { config: originalRequest, response: { status } = { status: null } } =
    error;
  const isShowErrorAlert = ![
    '/accounts:verify_admin_change_password',
    '/accounts:admin_change_password',
    '/accounts:accept_admin_change_password',
  ].includes(originalRequest.url);

  // Only queue up and handle unauthorized requests but refresh token
  if (
    status === 400 &&
    !(originalRequest as any)._retry && // eslint-disable-line no-underscore-dangle
    isShowErrorAlert
  ) {
    showAlert(errorAlert(error));
  }
  if (
    status === 401 &&
    ![
      'accounts',
      'unification/navigation',
      '/check-session',
      '/clear-session',
    ].includes(originalRequest.url)
  ) {
    window.location.reload();
  }

  throw error;
};
