export enum HubRole {
  API_KEY = 'api_key',
}

interface IHubCreatedBy {
  integration_type?: string;
  role?: HubRole;
}

export interface IHub {
  metadata: { bs_warehouse_id: string };
  name?: string;
  description?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  phone?: string;
  country?: string;
  postcode?: string;
  lng?: number;
  lat?: number;
  org_id?: string;
  created_at?: Date;
  updated_at?: Date;
  id?: string;
  team_ids?: [string];
  team_info?: ITeam[];
  is_auto_assigned?: boolean;
  allow_ignore_team?: boolean;
  created_by?: IHubCreatedBy;
  status?: string;
  status_description?: string;
}
export interface ITeam {
  name?: string;
  description?: string;
  hub_id?: string;
  org_id?: string;
  created_at?: Date;
  updated_at?: Date;
  id?: string;
  driver_ids?: [string];
  hub_serve?: string[];
  driver_info?: any[];
  is_auto_assigned?: boolean;
  is_preferred_team?: boolean;
}

export interface IDriverInfo {
  first_name?: string;
  last_name?: string;
  phone?: string;
  team_ids?: string[];
  email?: null;
  display_name?: string;
  username?: string;
  org_id?: string;
  role?: string;
  default?: boolean;
  status?: string;
  invited_by?: string;
  invited_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  driver_status?: string;
  avatar?: string;
  id?: string;
}

export interface TaskAddress {
  address_1: string;
  address_2: string;
  country: string;
  state: string;
  city: string;
  postcode: string;
  phone: string;
}

export interface INote {
  note?: string;
  created_at?: Date;
  created_by?: NoteCreatedBy;
  task_id?: string;
  id?: string;
}

export interface NoteCreatedBy {
  display_name?: string;
  avatar_type?: string;
  avatar?: string;
  id?: string;
}

export enum ITabValue {
  Ongoing = 't_ongoing',
  Completed = 't_completed',
  Assigned = 'assigned',
}
