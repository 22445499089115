import React from 'react';
import { DonutChart } from 'analytics-library';
import { Box, SxProps } from '@mui/system';
import InfoIcon from '~/assets/images/icons/info.svg';
import { makeStyles } from '@mui/styles';
import { Theme } from '~/themes';
import Tooltip from '../../Tooltip';
import { IPieLegendItem } from '../PieLegend/PieLegend';
import PieLegend from '../PieLegend';
import ChartContainer from '../ChartContainer';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(97, 97, 97, 0.8)',
    width: 250,
  },
}));

const loadingColors = ['#E5E5E5', '#E5E5E5'];

export interface IDonutItem {
  value: number;
  quantity: number;
  type: string;
  convertedValue: string;
  externalLink?: string;
}

interface IDonut {
  title: string;
  colors: string[];
  items: IDonutItem[];
  bigTitle?: string;
  bigDescription?: string;
  titleSx?: SxProps<Theme>;
  tooltip?: string;
  customHtml?: () => string;
  isLoading: boolean;
}

const Donut: React.FC<IDonut> = ({
  title,
  colors,
  items,
  bigTitle,
  bigDescription,
  titleSx,
  tooltip,
  customHtml,
  isLoading,
}) => {
  const legendColors = [...colors].reverse();
  const legendData: IPieLegendItem[] = [...items]
    .reverse()
    .map((item, idx) => ({
      color: legendColors[idx],
      title: item.convertedValue,
      description: item.type,
      titleSx: titleSx || {},
      externalLink: item.externalLink,
    }));
  const total = items[0].quantity + items[1].quantity;
  const percentage = !total
    ? '0%'
    : `${Math.round((items[1].quantity * 100) / total)}%`;
  const classes = useStyles();

  return (
    <ChartContainer title={title} sxContent={{ gap: 3 }}>
      <Box position='relative'>
        <DonutChart
          data={items}
          noData={false}
          angleField='value'
          colorField='type'
          color={isLoading ? loadingColors : colors}
          innerRadius={0.75}
          height={180}
          width={180}
          tooltip={false}
          pieStyle={{
            lineWidth: 0,
          }}
          statistic={{
            title: {
              offsetY: 0,
              style: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '28px',
              },
              customHtml: () => `<div>${percentage}</div>`,
            },
            content: {
              offsetY: 9,
              style: {
                fontWeight: '400',
                color: '#9B9B9B',
                fontSize: '12px',
              },
              customHtml,
            },
          }}
        />
        {tooltip && (
          <Box
            position='absolute'
            right='50%'
            bottom={35}
            sx={{
              transform: 'translateX(50%)',
            }}
          >
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
              }}
              placement='bottom-start'
              title={tooltip}
            >
              <img width={13} height={13} src={InfoIcon} alt='info' />
            </Tooltip>
          </Box>
        )}
      </Box>
      <PieLegend
        items={legendData}
        bigTitle={bigTitle}
        bigDescription={bigDescription}
      />
    </ChartContainer>
  );
};

export default Donut;
