import { getIn } from 'formik';
import React from 'react';
import { IFormProps } from '~/models/common';
import {
  AsyncSelect as AsyncSelectRaw,
  AsyncSelectProps,
} from '../AsyncSelect';

export interface IAsyncSelect
  extends IFormProps,
    Omit<AsyncSelectProps, 'name'> {}

const AsyncSelect: React.FC<IAsyncSelect> = ({
  field,
  form,
  error,
  inputProps,
  ...props
}) => (
  <AsyncSelectRaw
    value={field.value ?? ''}
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      getIn(form.touched, field.name) && getIn(form.errors, field.name)
    }
    inputProps={{
      ...field,
      ...inputProps,
    }}
    id={field.name}
    {...props}
  />
);

export default AsyncSelect;
