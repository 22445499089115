import { Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '~/assets/images/logo/logo.svg';
import { TextLink } from '~/components/common/TextLink';
import themes from '~/themes';

type AuthLayoutProps = {};

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';
  const isRegisterPage = location.pathname === '/register';
  const isUserOnboardingPage = location.pathname === '/user-onboarding';

  const textLinkComponent = useMemo(() => {
    if (isLoginPage)
      return (
        <Typography fontSize={16} fontFamily='Lato'>
          Don’t have an account?{' '}
          <TextLink to='/register' style={{ textDecoration: 'none' }}>
            Create Account
          </TextLink>
        </Typography>
      );
    if (isRegisterPage)
      return (
        <Typography fontSize={16} fontFamily='Lato'>
          Already have an account?{' '}
          <TextLink style={{ textDecoration: 'none' }} to='/login'>
            Log in
          </TextLink>
        </Typography>
      );
    return <Box sx={{ width: 130 }} />;
  }, [isLoginPage, isRegisterPage]);

  return (
    <Box
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        padding: 5,
        [themes.breakpoints.down('md')]: {
          padding: 2,
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isRegisterPage ? (
          <Box />
        ) : (
          <img src={Logo} alt='logo' width={160} height={33} />
        )}

        {textLinkComponent}
      </Stack>
      <Container
        maxWidth={false}
        sx={{
          'marginTop': -3.3,
          'maxWidth': 400,
          '& > form': {
            width: '100%',
          },
          'height': '100%',
          'px': '0px !important',
          'display': 'flex',
          'alignItems': 'center',
          ...(isUserOnboardingPage && {
            'paddingTop': 0,
            'maxWidth': 520,
            '& > form': {
              width: '100%',
              height: '100%',
            },
          }),
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
