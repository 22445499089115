import { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthLayout } from '~/pages/Auth/components/AuthLayout';

export const InvitationSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthLayout>
      <Stack>
        <Typography variant='h1'>
          Thank you for accepting the invitation!
        </Typography>
        <br />
        <p>Please wait while we take you to the home page.</p>
        <p>
          If your browser doesn&apos;t automatically refresh,{' '}
          <Link to='/'>click here</Link>.
        </p>
      </Stack>
    </AuthLayout>
  );
};
