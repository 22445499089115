import Modal from '~/components/common/Modal';
import { Typography } from '@mui/material';
import { DrawnZonesMap } from './DrawnZonesMap';

interface DialogViewAllZoneProps {
  open: boolean;
  latLngs?: any;
  onClose: () => void;
}

export const DialogViewAllZone: React.FC<DialogViewAllZoneProps> = ({
  open,
  onClose,
  latLngs,
}) => (
  <Modal
    title='View All Zones'
    open={open}
    onClose={onClose}
    disableCloseOutside
  >
    <Typography>
      This map shows all delivery zones currently in effect.
    </Typography>
    <DrawnZonesMap allLatLngs={latLngs} />
  </Modal>
);
