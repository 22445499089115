import { Stack, Typography } from '@mui/material';
import Modal from '~/components/common/Modal';
import Button from '~/components/common/Button';
import { useAuth } from '~/providers/AuthProvider';
import { USER_ROLE } from '~/constants/userManagement';
import themes from '~/themes';
import WarningIcon from '~/assets/images/icons/warning.svg';

type DialogAccountLockedProps = {
  open?: boolean;
  onClose?: () => void;
  onOkClick?: () => void;
};

export const DialogAccountLocked: React.FC<DialogAccountLockedProps> = ({
  open,
  onClose,
  onOkClick,
}) => {
  const { account } = useAuth();

  return (
    <Modal
      disableCloseOutside
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          'borderRadius': '10px !important',
          '& .MuiDialogContent-root': {
            padding: 4,
          },
          'width': 520,
        },
      }}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={WarningIcon} alt='warn-icon' width={113} height={113} />

        <Typography variant='h3' mt={3}>
          Account is Locked
        </Typography>
        <Typography
          sx={{
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            mt: 1,
            mb: 2,
            color: themes.color.violet900,
          }}
        >
          {account.role === USER_ROLE.DISPATCHER
            ? 'Your organization’s Breadstack Delivery account is locked due to a failed\n subscription payment. Please contact the organization account’s owner.'
            : 'Your organization’s Breadstack Delivery account is locked due to a failed\n subscription payment. Please update the payment method.'}
        </Typography>

        <Button onClick={onOkClick}>Ok</Button>
      </Stack>
    </Modal>
  );
};
