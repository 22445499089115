import React, { ReactNode } from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { SxProps } from '@mui/system';
import style, { Accordion, AccordionSummary, AccordionDetails } from './style';

export interface ICollapsible extends WithStyles<typeof style> {
  title?: string | ReactNode;
  rightTitle?: boolean;
  children?: any;
  onChange?(event: React.ChangeEvent<{}>, expanded: boolean): void;
  className?: string;
  contentClassName?: string;
  customRightTitle?: string | ReactNode;
  defaultExpanded?: boolean;
  sx?: SxProps;
}

const Collapsible: React.FC<ICollapsible> = ({
  classes: { childCollapsible, ...classes },
  children,
  title,
  rightTitle,
  className = '',
  contentClassName = '',
  customRightTitle,
  onChange,
  defaultExpanded = false,
  sx = {},
}) => (
  <Accordion
    square
    className={clsx({ [className]: !!className })}
    onChange={onChange}
    defaultExpanded={defaultExpanded}
    sx={sx}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <div className={classes.wrapHeader}>
        {typeof title === 'string' ? (
          <span className={classes.title}>{title}</span>
        ) : (
          title
        )}
        {rightTitle && customRightTitle && customRightTitle}
      </div>
    </AccordionSummary>
    <AccordionDetails
      className={clsx({ [contentClassName]: !!contentClassName })}
    >
      {children}
    </AccordionDetails>
  </Accordion>
);

export default withStyles(style)(Collapsible);
