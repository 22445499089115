/* eslint-disable react/no-array-index-key */
import { Stack } from '@mui/material';
import themes from '~/themes';

type StepperProps = {
  activeStep?: number;
  totalStep?: number;
};

export const Stepper: React.FC<StepperProps> = ({
  activeStep = 1,
  totalStep = 3,
}) => (
  <Stack
    sx={{
      'flexDirection': 'row',
      'justifyContent': 'center',
      '& > span': {
        'width': 100,
        'marginLeft': 0.5,
        'height': 4,
        'borderRadius': '10px',
        'background': themes.bg.midPurple,
        '&.active': {
          background: themes.bg.primaryOrange,
        },
      },
    }}
  >
    {Array(totalStep)
      .fill('')
      .map((_, idx) => {
        if (idx + 1 > activeStep) return <span key={idx} />;
        return <span key={idx} className='active' />;
      })}
  </Stack>
);
