/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IImageDataItem } from '../ImageList/ImageListProvider';
// eslint-disable-next-line import/no-cycle
import ImageUpload from './ImageUpload';
// eslint-disable-next-line import/no-cycle
import MutipleImageUpload from './MultipleImageUpload';

export interface ImageUploadProps {
  src?: string;
  title?: string;
  // eslint-disable-next-line no-unused-vars
  children: (element: any) => React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onSave?: (value: FormData | null, blob?: Blob) => void;
  // eslint-disable-next-line no-unused-vars
  onSaveList?: (value: IImageDataItem[]) => void;
  disableSquare?: boolean;
  listSrc?: IImageDataItem[];
  optional?: boolean;
  readonly?: boolean;
  maxLength?: number;
}
interface IContextProps {
  src?: string;
  imgSrc: string;
  setImgSrc: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onSave: (value: FormData | null, blob?: Blob) => void;
  // eslint-disable-next-line no-unused-vars
  onSaveList: (value: IImageDataItem[]) => void;
  disableSquare?: boolean;
  listSrc?: IImageDataItem[];
  listImgSrc?: IImageDataItem[];
  // eslint-disable-next-line no-unused-vars
  setListImgSrc: (
    index: number,
    value?: IImageDataItem | IImageDataItem[],
  ) => void;
  // eslint-disable-next-line no-unused-vars
  setDefaultListImgSrc: React.Dispatch<
    React.SetStateAction<IImageDataItem[] | undefined>
  >;
  optional?: boolean;
  readonly?: boolean;
  maxLength: number;
}

export const ImageUploadContext = React.createContext({} as IContextProps);

const ImageUploadProvider: React.FC<ImageUploadProps> = ({
  children,
  src,
  title,
  disableSquare,
  listSrc,
  onSaveList = () => {},
  onSave = () => {},
  optional,
  readonly,
  maxLength,
}) => {
  const [imgSrc, setImgSrc] = useState<string>('');
  const [listImgSrc, setListImgSrc] = useState<any>();
  const [open, handleOpen] = useState<boolean>(false);

  const setOpen = useCallback(
    (value?: boolean) => {
      handleOpen(value !== undefined ? value : !open);
    },
    [open],
  );

  useEffect(() => {
    setImgSrc(src || '');
  }, [src]);

  useEffect(() => {
    if (listSrc) {
      setListImgSrc(listSrc);
    }
  }, [listSrc]);

  function instanceOfItem(object: any): object is IImageDataItem {
    return 'src' in object;
  }

  const onSetListImgSrc = useCallback(
    (index: number, item?: IImageDataItem | IImageDataItem[]) => {
      if (!item || instanceOfItem(item)) {
        setListImgSrc([
          ...(listImgSrc || []).slice(0, index),
          ...(item ? [item] : []),
          ...(listImgSrc || []).slice(index + 1, (listImgSrc || []).length),
        ]);
      } else {
        setListImgSrc([
          ...(listImgSrc || []).slice(0, index),
          ...item,
          ...(listImgSrc || []).slice(index, (listImgSrc || []).length),
        ]);
      }
    },
    [listImgSrc],
  );

  // ...

  const values = useMemo(
    () => ({
      src,
      imgSrc,
      open,
      disableSquare,
      optional,
      readonly,
      listSrc,
      listImgSrc,
      maxLength: maxLength || 5,
      setOpen,
      onSave,
      setImgSrc,
      setListImgSrc: onSetListImgSrc,
      setDefaultListImgSrc: setListImgSrc,
      onSaveList,
    }),
    [
      maxLength,
      src,
      disableSquare,
      optional,
      readonly,
      listSrc,
      listImgSrc,
      imgSrc,
      open,
    ],
  );

  // ...
  return (
    <ImageUploadContext.Provider value={values}>
      {listSrc ? (
        <MutipleImageUpload title={title} />
      ) : (
        <ImageUpload title={title} />
      )}
      {children(values)}
    </ImageUploadContext.Provider>
  );
};

export default ImageUploadProvider;
