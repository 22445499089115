import { object, string, ref } from 'yup';
import { RegexValidPassword } from '~/constants/common';

export const UnifiedSentEmailResetPasswordSchema = object().shape({
  email: string()
    .trim()
    .email('Email is invalid.')
    .required('Email must not be empty.'),
});

export const UnifiedLoginSchema = object().shape({
  email: string()
    .trim()
    .email('Email is invalid.')
    .required('Email must not be empty.'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format.',
    })
    .min(8, 'Password must be more than 8 characters long.')
    .required('Password must not be empty.'),
});

export const UnifiedResetPasswordSchema = object().shape({
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format.',
    })
    .min(8, 'Password must be more than 8 characters long.')
    .required('Password must not be empty.'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Password does not match.')
    .required('Confirm password must not be empty.'),
});
