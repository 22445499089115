import {
  Box,
  IconButton,
  Popper,
  PopperPlacementType,
  SxProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { ReactNode, useEffect, useRef } from 'react';
import themes, { Theme, bgColors, colors } from '~/themes';
import CloseIcon from '~/assets/images/icons/close-white.svg';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';

const StyledPopper = styled(Popper)(({ theme }: { theme: Theme }) => ({
  'maxWidth': '375px',
  '&[data-popper-placement*="bottom"] .arrow': {
    'top': 0,
    'left': 0,
    'marginTop': '-0.9em',
    'width': '3em',
    'height': '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${colors.violet900} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    'bottom': 0,
    'left': 0,
    'marginBottom': '-0.9em',
    'width': '3em',
    'height': '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${colors.violet900} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    'left': 0,
    'marginLeft': '-0.9em',
    'height': '3em',
    'width': '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${colors.violet900} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    'right': 0,
    'marginRight': '-0.9em',
    'height': '3em',
    'width': '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${colors.violet900}`,
    },
  },
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    display: 'none',
  },
}));

interface IProps {
  isOpen?: boolean;
  title?: string;
  description?: ReactNode;
  placement: PopperPlacementType;
  id: string;
  width?: number;
  height?: number;
  sx?: SxProps;
  arrow?: boolean;
  disabled?: boolean;
  offset?: number | [number, number];
  delay?: number;
  onClose?: () => void;
  className?: string;
}

const Tutorial: React.FC<IProps> = ({
  isOpen,
  children,
  title,
  description,
  placement,
  id,
  width,
  height,
  sx,
  disabled,
  offset,
  delay,
  onClose,
  className,
  arrow = true,
}) => {
  const ref = useRef(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  useEffect(() => {
    if (ref) {
      if (delay) {
        setTimeout(() => {
          setAnchorEl(isOpen ? ref.current : null);
        }, delay);
      } else {
        setAnchorEl(isOpen ? ref.current : null);
      }
    }

    return () => {
      setAnchorEl(null);
    };
  }, [isOpen, ref]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const open = Boolean(anchorEl);

  const popperId = open ? id : undefined;

  return (
    <>
      <Box sx={sx} ref={ref} className={className}>
        {children}
      </Box>
      <StyledPopper
        id={popperId}
        open={open && !disabled}
        anchorEl={disabled ? null : anchorEl}
        placement={placement}
        sx={{
          ...(width ? { width } : {}),
          ...(height ? { height } : {}),
          zIndex: themes.zIndex.drawer,
          ...([
            'enter_task_details',
            'assign_task_create',
            'set_task_routes',
          ].includes(id) && {
            zIndex: themes.zIndex.tooltip + 1,
          }),
          ...(['optimize_store', 'task_list', 'view_specific_info'].includes(
            id,
          ) && {
            zIndex: themes.zIndex.drawer + 1,
          }),
        }}
        modifiers={[
          {
            name: 'arrow',
            enabled: arrow,
            options: {
              element: arrowRef,
            },
          },
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [
                offset?.[0] || offset || 10,
                offset?.[1] || offset || 10,
              ],
            },
          },
        ]}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: colors.white,
            fontSize: 14,
            position: 'relative',
            borderRadius: 2.5,
            bgcolor: bgColors.darkPurple,
            p: 1,
          }}
        >
          <Typography sx={{ fontWeight: 500, marginBottom: '5px' }}>
            {title}
          </Typography>
          <Typography sx={{ lineHeight: '17px' }}>{description}</Typography>
          <IconButton
            sx={{
              color: colors.white,
              fontSize: 14,
              width: 14,
              height: 14,
              position: 'absolute',
              right: 4,
              top: 5,
            }}
            onClick={handleClose}
          >
            <img alt='close-img' width={8} height={8} src={CloseIcon} />
          </IconButton>
        </Box>
        {arrow ? (
          <Box
            component='span'
            className='arrow'
            sx={{
              'position': 'absolute',
              'fontSize': 7,
              'width': '3em',
              'height': '3em',
              '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
              },
            }}
            ref={setArrowRef}
          />
        ) : null}
      </StyledPopper>
    </>
  );
};

export default Tutorial;
