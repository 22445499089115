import { useRef } from 'react';

/**
 * The function listens to the given loading(s). On the first time one of them becomes `true`, the function returns `true`.
 * This will return `true` only once, then always return `false`.
 * @param {array} loadingFactors array of loading statuses
 * @returns {boolean}
 */
export const useFirstLoadingDetection = (loadingFactors): boolean => {
  const loadingCount = useRef(0);

  if (loadingCount.current <= 1 && loadingFactors.some((l) => l)) {
    loadingCount.current = 1;
    return true;
  }

  if (loadingCount.current === 1 && !loadingFactors.some((l) => l)) {
    loadingCount.current = 2;
  }

  return false;
};
