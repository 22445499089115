import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { TextLink } from '~/components/common/TextLink';
import { useLogin } from '~/services/api/auth';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { useValidateInvitationMutation } from '~/services/api/shared';
import { showAlert, alertParams } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { styled } from '~/themes';
import { UnifiedPublicLayout } from '~/layout/UnifiedPublicLayout';
import { Button, theme as unifiedTheme } from 'bs-unified-ui';
import { Field } from '~/components/common/Formik';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { useUnifiedAuthContext } from '~/providers/UnifiedAuthProvider';
import { UnifiedLoginSchema } from './schema';

type LoginProps = {};

export const StyledLoginForm = styled(Form)(() => ({
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
}));

export const UnifiedLogin: React.FC<LoginProps> = () => {
  const { isLoginLoading } = useUnifiedAuthContext();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { mutate: validateInvitation } = useValidateInvitationMutation({
    onSuccess: async () => {
      showAlert({
        ...alertParams.success,
        title: 'Accept invitation successfully!',
        okText: 'Ok',
      });
      queryClient.invalidateQueries([queryKeys.settings.account]);
    },
    onError: () => {
      queryClient.invalidateQueries([queryKeys.settings.account]);
    },
  });

  const { mutate: loginMutation, isLoading } = useLogin({
    options: {
      isInvalidateQueries: !searchQueries?.code,
      isValidateCustomClaim: true,
      isShowAlert: false,
    },
    onSuccess: () => {
      const code = searchQueries?.code as string;
      if (code) {
        validateInvitation({ code });
      }
    },
    onError: (error) => {
      setErrorMsg(error);
    },
  });

  useEffect(() => {
    channel.postMessage({
      type: BroadcastEvents.SyncLoginState,
      payload: isLoading,
    });
  }, [isLoading]);

  return (
    <UnifiedPublicLayout>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        enableReinitialize
        validationSchema={UnifiedLoginSchema}
        onSubmit={(values) => {
          setErrorMsg('');
          loginMutation(values);
        }}
      >
        {() => (
          <StyledLoginForm>
            <Typography variant='h1' className='headerContainer'>
              Login to your account
            </Typography>
            <Stack spacing={2}>
              <Field.UnifiedTextField
                name='email'
                label='Email'
                fullWidth
                onChange={() => setErrorMsg('')}
              />
              <Field.UnifiedTextField
                name='password'
                type='password'
                label='Password'
                fullWidth
                onChange={() => setErrorMsg('')}
              />
              <Typography
                sx={{
                  lineHeight: 1.66,
                  color: '#F24822',
                  mt: '10px !important',
                  fontSize: 14,
                }}
              >
                {errorMsg}
              </Typography>
              <TextLink
                to='/reset-password'
                style={{
                  textAlign: 'end',
                  lineHeight: '24px',
                  color: unifiedTheme.foreground.black,
                }}
              >
                Forgot Password
              </TextLink>
            </Stack>
            <Button
              type='submit'
              sx={{ mt: 3 }}
              loading={isLoading || isLoginLoading}
            >
              Login
            </Button>
          </StyledLoginForm>
        )}
      </Formik>
    </UnifiedPublicLayout>
  );
};
