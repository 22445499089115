import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { useMemo } from 'react';
import { LinkButton } from '~/components/common/Button';
import { Icon } from '~/components/common/Icon';
import Table from '~/components/common/Table';
import { TColumn } from '~/components/common/Table/Table';
import { TextLink } from '~/components/common/TextLink';
import themes from '~/themes';
import { formatPrice } from '~/utils/formatter';
import { InvoiceStatus } from '~/models/billing';
import { IInvoice } from './types';
import { useGetInvoicesQuery } from './apis';

type BillingHistoryProps = {};

export const BillingHistory: React.FC<BillingHistoryProps> = () => {
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetInvoicesQuery();

  const invoices: IInvoice[] = useMemo(
    () =>
      data.pages.length
        ? data.pages
            .reduce((acc, cur) => acc.concat(cur.items), [])
            .filter((e) => e.total > 0)
        : [],
    [data],
  );

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'created',
        title: 'Date',
        cellStyle: { textAlign: 'left' },
        renderComponent: (item) =>
          moment.unix(item.created).format('MM/DD/YYYY'),
      },
      {
        id: 'number',
        title: 'Invoice No.',
        renderComponent: (item) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography>{item?.number ? item?.number : '-'}</Typography>
            {item?.status !== InvoiceStatus.Paid &&
              item?.status !== InvoiceStatus.Draft && (
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <Icon name='exclamation' color='#FF0000' size={14} />
                  <Typography color='#FF0000' fontSize={16}>
                    Fail payment
                  </Typography>
                </Stack>
              )}
          </Stack>
        ),
      },
      {
        id: 'total',
        title: 'Charge',
        renderComponent: (item) =>
          item?.total ? formatPrice(item.total / 100) : '-',
      },
      {
        id: 'invoice_pdf',
        title: 'Action',
        cellStyle: { width: 100 },
        renderComponent: (item) =>
          item.invoice_pdf ? (
            <TextLink to={item.invoice_pdf} color={themes.color.black}>
              Download PDF
            </TextLink>
          ) : (
            '-'
          ),
      },
    ],
    [],
  );

  const viewMoreComponent = useMemo(() => {
    if (!hasNextPage) return '';
    if (isFetchingNextPage) {
      return (
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            mt: 2,
            color: themes.color.gray900,
          }}
        >
          load more bills ...
        </Typography>
      );
    }
    return (
      <LinkButton
        sx={{
          width: '100%',
          textAlign: 'center',
          mt: 2,
          color: themes.color.violet900,
        }}
        onClick={() => fetchNextPage()}
      >
        View More
      </LinkButton>
    );
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <>
      <Table
        columns={columns}
        dataProps={invoices || []}
        noHoverInteraction
        loading={isFetching}
        onRowClick={null}
      />
      {viewMoreComponent}
    </>
  );
};
