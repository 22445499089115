import { object, string, ref } from 'yup';
import { RegexValidPassword } from '~/constants/common';
import { validatePhoneNumber } from '~/pages/LiveTracking/utils';

const CreateWorkspaceSchema = object().shape({
  name: string()
    .trim()
    .max(50, 'Max 50 characters')
    .required('Name is required'),
});

const SentEmailResetPasswordSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
});

const LoginSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
});

const ResetPasswordSchema = object().shape({
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
});

const RegisterSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
  captcha_token: string().required('Please solve Captcha correctly'),
});

const ChangePwdSchema = object().shape({
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
  newPassword: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
  confirmPassword: string()
    .oneOf([ref('newPassword')], 'Password is not matched')
    .required('Confirm password is required'),
});

const UserOnboardingSchema = object().shape({
  full_name: string()
    .trim()
    .max(50, 'Max 50 characters')
    .required('Full Name is required'),
  job_title: string().trim().max(50, 'Max 50 characters'),
  org_name: string()
    .trim()
    .max(50, 'Max 50 characters')
    .required('Organization’s Name is required'),
  country_code: string(),
  phone: string()
    .trim()
    .test('phone', 'Invalid phone number', (value: any, testContext: any) => {
      if (!value) return true;
      return validatePhoneNumber(value, testContext);
    }),
});

const InviteUserSchema = object().shape({
  full_name: string().required('Full name is required'),
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
  captcha_token: string().required('Please solve Captcha correctly'),
});

const InviteUserSchemaWith2FA = object().shape({
  full_name: string().required('Full name is required'),
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
});

const VerifyEmailSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
});

const RegisterWithInBsAccountSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
  captcha_token: string().required('Please solve Captcha correctly'),
});

const RegisterWithOutReCaptchaSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .matches(RegexValidPassword, {
      message: 'Password is in wrong format',
    })
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
});

export {
  CreateWorkspaceSchema,
  LoginSchema,
  ResetPasswordSchema,
  RegisterSchema,
  ChangePwdSchema,
  UserOnboardingSchema,
  InviteUserSchema,
  VerifyEmailSchema,
  RegisterWithInBsAccountSchema,
  RegisterWithOutReCaptchaSchema,
  SentEmailResetPasswordSchema,
  InviteUserSchemaWith2FA,
};
