import { useIsFetching } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ViewControl } from './components/ViewControl';
import { LiveMonitoring } from './components/LiveMonitoring';
import { LiveMapContext, LiveMapProvider } from './context/LiveMapContext';
import './index.scss';
import { TaskDetail } from './components/TaskDetail';
import { ManageRoutes } from './components/ManageRoutes/ManageRoutes';
import { ManageRoutesWithoutDragDrop } from './components/ManageRoutes/ManageRoutesWithoutDragDrop';
import { LiveTrackingQueryKeys } from './apis';

type LiveTrackingProps = {};

export const LiveTracking: React.FC<LiveTrackingProps> = () => {
  const fetchingDriversCount = useIsFetching([
    LiveTrackingQueryKeys.DriverList,
  ]);

  const isEnabledManageRouteDragDrop = useFeatureIsOn(
    'cf-enabled-manage-route-drag-drop',
  );
  return (
    <LiveMapProvider>
      <LiveMapContext.Consumer>
        {({
          mapRef,
          taskId,
          driverId,
          taskRoutes,
          multiTaskIds,
          manageRoutesRef,
          isAssignMultiTask,
          setMultiTaskIds,
          handleCloseTaskDetail,
        }) => (
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <div
              id='live-tracking-map'
              ref={mapRef}
              style={{ width: '100%', height: '100%' }}
            />
            <ViewControl />
            <LiveMonitoring />
            {(taskId || isAssignMultiTask) && (
              <TaskDetail
                taskId={taskId}
                taskRoutes={taskRoutes}
                isAssignMultiTask={isAssignMultiTask}
                multiTaskIds={multiTaskIds}
                handleCloseTaskDetail={handleCloseTaskDetail}
                setMultiTaskIds={setMultiTaskIds}
              />
            )}
            {driverId &&
              fetchingDriversCount === 0 &&
              isEnabledManageRouteDragDrop && (
                <ManageRoutes ref={manageRoutesRef} />
              )}
            {driverId &&
              fetchingDriversCount === 0 &&
              !isEnabledManageRouteDragDrop && (
                <ManageRoutesWithoutDragDrop ref={manageRoutesRef} />
              )}
          </div>
        )}
      </LiveMapContext.Consumer>
    </LiveMapProvider>
  );
};
