/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from '~/components/common/Modal';
import themes from '~/themes';
import { Icon } from '~/components/common/Icon';
import { TextLink } from '~/components/common/TextLink';
import {
  useUploadPostalCodes,
  useGetPostalCodeUploadedInfo,
} from '~/services/api/stores';
import { showAlert, alertParams } from '~/components/common/Alert';
import { useDrop } from '~/hooks/useDrop';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { importFileUrl } from '~/constants/stores';
import { CloseButton } from '~/components/common/Button';
import { fromQueryString } from '~/utils/queryString';

type ImportPostalCodeProps = {
  open: boolean;
  setOpen: (v: boolean) => void;
};
export enum ImportStep {
  ChooseFile = 'choose-file',
  ErrorImport = 'errorImport',
  Importing = 'importing',
}

export const ImportPostalCode: React.FC<ImportPostalCodeProps> = ({
  open,
  setOpen,
}) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const [step, setStep] = useState<ImportStep>(ImportStep.ChooseFile);
  const [uploadId, setUploadId] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>();

  const { data } = useGetPostalCodeUploadedInfo({
    id: uploadId,
    onSuccess: ({ status, success_info }) => {
      if (status === 'importing' || status === 'validating') {
        return setStep(ImportStep.Importing);
      }
      if (status === 'success') {
        setOpen(false);
        queryClient.invalidateQueries([queryKeys.stores.getListZone]);
        showAlert({
          ...alertParams.success,
          title: 'Postal Codes Imported',
          description: success_info,
        });

        return setUploadId('');
      }

      if (status === 'failed') {
        return setStep(ImportStep.ErrorImport);
      }
      return true;
    },
  });

  const { mutate: uploadMutation, isLoading: isLoadingUpload } =
    useUploadPostalCodes({
      onSuccess: ({ id }) => {
        setUploadId(id);
      },
    });

  const failInfo: Array<{ rowId: string; messages: string[] }> = useMemo(() => {
    if (data.status === 'failed') {
      return data?.failed_info?.map((d) => ({
        rowId: d.length ? d[0] : '',
        messages: d.length ? d[1] : [''],
      }));
    }
    return [];
  }, [data.failed_info, data.status]);

  const handleUpload = () => {
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const formData = new FormData();
        formData.append('upload_file', file);
        uploadMutation({ storeId: search.id, file: formData });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    fileReader.onerror = () => {
      showAlert({
        ...alertParams.warning,
        description:
          'New changes have been made to the file. Please upload the file again.',
        onOk: () => {
          setFile(null);
          inputFileRef.current.value = null;
        },
      });
    };

    fileReader.readAsArrayBuffer(file);
  };

  const handleImportFile = (files: FileList) => {
    const fileMBSize = files[0].size / 1024 / 1024;
    const fileType = files[0].type;
    const validMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    if (files.length > 1) {
      return showAlert({
        ...alertParams.warning,
        description:
          'You are attempting to upload multiple files.\n The system only accepts one .xlsx, .xls file.',
      });
    }
    if (!validMimeTypes.includes(fileType)) {
      return showAlert({
        ...alertParams.warning,
        description: 'The file is not in .xlsx or .xls format.',
      });
    }
    if (fileMBSize >= 10) {
      return showAlert({
        ...alertParams.warning,
        title: 'File Size Exceeds Maximum Limit',
        description: 'Please re-upload file (max 10 MB)',
      });
    }

    setFile(files[0]);
    return '';
  };

  const { dropFuncProps, dropState } = useDrop({
    onDropped: async (fileData) => {
      handleImportFile(fileData);
      inputFileRef.current.value = null;
    },
  });

  const renderContent = useMemo(() => {
    switch (step) {
      case ImportStep.ChooseFile:
        return (
          <>
            <Stack
              justifyContent='start'
              alignItems='flex-start'
              sx={{
                position: 'relative',
                paddingBottom: themes.spacing(3),
                width: '100%',
              }}
              {...dropFuncProps}
            >
              <Stack
                alignItems='start'
                sx={{
                  'width': '100%',
                  '& > input': {
                    display: 'none',
                  },
                  '& > label': {
                    textAlign: 'center',
                    width: 112,
                    cursor: 'pointer',
                    background: themes.bg.midPurple,
                    padding: themes.spacing(0.8, 0),
                    borderRadius: '6px',
                    color: themes.color.violet900,
                    fontWeight: 'bold',
                  },
                }}
              >
                <Typography
                  mb={themes.spacing(2)}
                  whiteSpace='break-spaces'
                  textAlign='center'
                >
                  Import from an Excel file
                </Typography>
                {file && (
                  <Stack
                    justifyContent='start'
                    alignItems='flex-start'
                    flexDirection='column'
                    columnGap={themes.spacing(1)}
                    mb={themes.spacing(3)}
                    width='100%'
                    display='flex'
                    rowGap={themes.spacing(0.5)}
                  >
                    <Typography whiteSpace='break-spaces' textAlign='left'>
                      File uploaded:
                    </Typography>
                    <Typography
                      whiteSpace='break-spaces'
                      textAlign='left'
                      py={themes.spacing(0.5)}
                      pl={themes.spacing(1)}
                      pr={themes.spacing(0.25)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: themes.bg.lightPurple,
                        borderRadius: themes.spacing(0.5),
                        boxSizing: 'border-box',
                      }}
                    >
                      {file?.name}
                      <CloseButton
                        onClick={() => setFile(null)}
                        title='Remove'
                        background='none'
                      />
                    </Typography>
                  </Stack>
                )}
                <label htmlFor='file-select'>Upload file</label>
                <input
                  ref={inputFileRef}
                  id='file-select'
                  type='file'
                  multiple={false}
                  // accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  accept='.xlsx,.xls'
                  onChange={(e) => {
                    handleImportFile(e.target.files);
                    inputFileRef.current.value = null;
                  }}
                />
              </Stack>
            </Stack>
            <Box
              sx={{
                background: themes.bg.lightPurple,
                borderRadius: '10px',
                padding: 2,
              }}
            >
              View{' '}
              <TextLink to={importFileUrl.file}>
                the sample import file
              </TextLink>{' '}
              and please make sure you input all required information before
              uploading your file.
            </Box>
          </>
        );
      case ImportStep.ErrorImport:
        return (
          <>
            <Stack direction='row'>
              <Icon
                name='exclamation'
                size={20}
                style={{ marginRight: 10, color: '#D62A28' }}
                useBackgroundImg
              />
              <Typography
                sx={{
                  '& > span': {
                    fontWeight: 'bold',
                    color: '#D62A28',
                  },
                }}
              >
                <span>
                  {failInfo.length}{' '}
                  {failInfo?.length === 1 ? 'issue' : 'issues'}
                </span>{' '}
                were found when importing. Please correct the issues and
                re-upload the file.
              </Typography>
            </Stack>
            <Stack
              sx={{
                border: '1px solid #E9E7F6',
                borderRadius: '5px',
                mt: 2,
                maxHeight: 400,
                overflow: 'hidden',
              }}
            >
              <Stack
                className='customized-scrollbar'
                sx={{ overflow: 'auto', padding: 2 }}
                spacing={1}
              >
                {failInfo.map(({ rowId, messages }, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Stack key={idx}>
                    <Typography>{`Row ${rowId + 1}`}</Typography>
                    {messages.map((msg: string) => (
                      <React.Fragment key={msg}>
                        <Typography mt={0.5} color='#D62A28'>
                          {msg || '-'}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </>
        );

      case ImportStep.Importing:
        return (
          <Stack
            sx={{ height: 370 }}
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress
              size={80}
              sx={{ mb: 3, color: themes.color.violet600 }}
              thickness={5}
            />
            <Typography
              fontWeight='bold'
              fontSize={18}
              color={themes.color.violet900}
            >
              {data?.status === 'validating' ? 'Validating...' : 'Importing...'}
            </Typography>
            <Typography sx={{ mt: 2 }}>{data?.progress}</Typography>
          </Stack>
        );
      default:
        return <div />;
    }
  }, [step, isLoadingUpload, data?.progress, dropState, file]);

  const modelTitle = useMemo(() => {
    if (step === ImportStep.Importing) return '';
    return 'Import Postal Code';
  }, [step]);

  const modelActions = useMemo(() => {
    if (step === ImportStep.Importing) return [];
    if (step === ImportStep.ChooseFile)
      return [
        {
          title: 'Cancel',
          onClick: () => setOpen(false),
          buttonType: 'default',
        },
        {
          title: 'Import',
          buttonType: 'primary',
          onClick: () => {
            handleUpload();
          },
          loading: isLoadingUpload,
          // disabled: !file,
        },
      ];
    return [
      {
        title: 'Cancel',
        onClick: () => setOpen(false),
        buttonType: 'default',
      },
      {
        title: 'Re-Upload',
        buttonType: 'primary',
        onClick: () => {
          setUploadId('');
          setFile(null);
          setStep(ImportStep.ChooseFile);
        },
      },
    ];
  }, [step, file, isLoadingUpload]);

  const modelWidth = useMemo(() => {
    if (step === ImportStep.Importing) return 480;
    return 650;
  }, [step]);

  return (
    <Modal
      disableCloseOutside
      actions={modelActions}
      PaperProps={{
        sx: {
          maxWidth: modelWidth,
        },
      }}
      title={modelTitle}
      open={open}
      onClose={() => setOpen(false)}
    >
      {renderContent}
    </Modal>
  );
};
