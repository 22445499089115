import { IFormProps } from '~/models/common';
import {
  ElementSelect,
  ElementSelectProps,
} from '../ElementSelect/ElementSelect';

export interface IFormElementSelectProps
  extends IFormProps,
    Omit<ElementSelectProps, 'name' | 'form'> {}

const FormElementSelect = ({
  field,
  form,
  options,
  ...props
}: IFormElementSelectProps) => (
  <ElementSelect
    options={options}
    value={field.value ?? ''}
    onChange={(option: any) =>
      form.setFieldValue(`${field.name}`, option.value)
    }
    {...props}
  />
);

export default FormElementSelect;
