import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

// Interceptor unique name
type IntName = string;
// Interceptor unique Id
type IntID = number;

class HttpClient {
  readonly client: AxiosInstance;

  reqInt: { [key: IntName]: IntID } = {};

  resInt: { [key: IntName]: IntID } = {};

  constructor(cfg?: AxiosRequestConfig) {
    this.client = axios.create(cfg);
    this.reqInt = {};
    this.resInt = {};
  }

  useReq(key: IntName, fulfilledFn?, rejectedFn?): HttpClient {
    this.reqInt[key] = this.client.interceptors.request.use(
      fulfilledFn,
      rejectedFn,
    );

    return this;
  }

  useRes(key: IntName, fulfilledFn?, rejectedFn?): HttpClient {
    this.resInt[key] = this.client.interceptors.response.use(
      fulfilledFn,
      rejectedFn,
    );

    return this;
  }

  ejectReq(key: IntName): HttpClient {
    if (this.reqInt[key] === undefined || this.reqInt === null) return this;
    this.client.interceptors.request.eject(this.reqInt[key]);

    return this;
  }

  ejectRes(key: IntName): HttpClient {
    if (this.resInt[key] === undefined || this.resInt === null) return this;
    this.client.interceptors.response.eject(this.resInt[key]);

    return this;
  }

  request<T = any, R = AxiosResponse<T>>(cfg: AxiosRequestConfig): Promise<R> {
    return this.client.request(cfg);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.get(url, cfg);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.delete(url, cfg);
  }

  head<T = any, R = AxiosResponse<T>>(
    url: string,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.head(url, cfg);
  }

  options<T = any, R = AxiosResponse<T>>(
    url: string,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.options(url, cfg);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.post(url, data, cfg);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.put(url, data, cfg);
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    cfg?: AxiosRequestConfig,
  ): Promise<R> {
    return this.client.patch(url, data, cfg);
  }

  getUri(cfg?: AxiosRequestConfig): string {
    return this.client.getUri(cfg);
  }

  clearToken() {
    delete this.client.defaults.headers.common.Authorization;
  }

  setToken(token: string) {
    this.client.defaults.headers.common.Authorization = token;
  }

  getToken() {
    return this.client.defaults.headers.common.Authorization;
  }
}

export default HttpClient;
