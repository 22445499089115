import { convertShortString } from '~/utils/formatter';
import Tooltip from '.';

type ToolTipTextProps = {
  text: string;
  textNode?: (v: string) => React.ReactNode;
  maxLength: number;
};

export const ToolTipText: React.FC<ToolTipTextProps> = ({
  text = '',
  maxLength,
  textNode = () => null,
}) => {
  const shortText = convertShortString(text, maxLength);
  const isShow = text?.length > maxLength;
  return (
    <Tooltip title={isShow ? text : ''}>
      {textNode(shortText) || shortText}
    </Tooltip>
  );
};
