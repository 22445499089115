import React, { useEffect, useState } from 'react';
import { queryClient } from '~/lib/react-query';
import { fromQueryString } from '~/utils/queryString';
import {
  UploadImageDialog,
  UploadImageProvider,
  UploadImageSelection,
} from '~/components/shared/UploadImage';
import { useLocation } from 'react-router-dom';
import { driverSettingsQueryKeys, useUpdateDriverAvatar } from '../../apis';

interface IProps {
  initAvatar: string;
}

export const DriverAvatar: React.FC<IProps> = ({ initAvatar }) => {
  const [driverAvatar, setDriverAvatar] = useState(null);
  const location = useLocation();

  const { id: driverId } = fromQueryString(location.search);
  const { mutate: uploadAvatar, isLoading: isLoadingUpdateAvatar } =
    useUpdateDriverAvatar({
      onSuccess: (res) => {
        queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
        setDriverAvatar(`data:image/${res?.avatar_type};base64,${res.avatar}`);
      },
    });

  const { mutate: removeAvatar, isLoading: isLoadingRemoveAvatar } =
    useUpdateDriverAvatar({
      onSuccess: () => {
        queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
        setDriverAvatar(null);
      },
    });

  useEffect(() => {
    setDriverAvatar(initAvatar);
  }, [initAvatar]);

  const onSaveImage = async (formData: FormData | null, blob: Blob) => {
    if (formData) {
      const uploadData = new FormData();
      uploadData.append('file', blob, `avatar.${blob.type.split('/')[1]}`);
      uploadData.append('driver_id', driverId as string);
      uploadAvatar(uploadData);
    } else {
      removeAvatar({
        driver_id: driverId,
        file: null,
      });
    }
  };

  return (
    <UploadImageProvider
      defaultImages={[driverAvatar].filter((item) => !!item)}
      onSaveImage={onSaveImage}
    >
      <UploadImageSelection
        loading={isLoadingUpdateAvatar || isLoadingRemoveAvatar}
      />
      <UploadImageDialog />
    </UploadImageProvider>
  );
};
