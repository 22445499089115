import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { FastField, Field } from '~/components/common/Formik';
import UserInfoIcon from '~/assets/images/icons/user-info.svg';
import { USER_ROLE_OPTION } from '~/constants/userManagement';

const style = (theme: Theme) =>
  createStyles({
    transitTitle: {
      display: 'flex',
      fontWeight: 600,
      alignItems: 'center',
    },
    transitLogo: {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'width': 25,
      'height': 25,
      'borderRadius': 5,
      'backgroundColor': theme.bg.darkPurple,
      'marginRight': theme.spacing(1),
      '& img': {
        width: 19,
      },
      '&.store': {
        backgroundColor: theme.bg.primaryOrange,
      },
      '&.warehouse': {
        backgroundColor: theme.bg.breadstack,
      },
    },
  });

interface IProps extends WithStyles<typeof style> {}

const InviteUserForm: React.FC<IProps> = ({ classes }) => (
  <>
    <div className={classes.transitTitle}>
      <span className={classes.transitLogo}>
        <img src={UserInfoIcon} alt='user-info' />
      </span>
      User Info
    </div>
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Grid item xs={12} md={6} key='first_name'>
        <FastField.TextInput name='first_name' legend='First Name' />
      </Grid>
      <Grid item xs={12} md={6} key='last_name'>
        <FastField.TextInput name='last_name' legend='Last Name' />
      </Grid>
      <Grid item xs={12} md={6} key='email'>
        <FastField.TextInput name='email' legend='Email Address' />
      </Grid>
      <Grid item xs={12} md={6} key='role'>
        <Field.Select name='role' legend='Role' options={USER_ROLE_OPTION} />
      </Grid>
    </Grid>
  </>
);

export default withStyles(style)(InviteUserForm);
