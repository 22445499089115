import React from 'react';
// eslint-disable-next-line import/no-cycle
import { ImageUploadContext } from './ImageUploadProvider';

const useImageUploadContext = () => {
  const context = React.useContext(ImageUploadContext);
  if (context === undefined) {
    throw new Error('Unable to load Context Provider');
  }

  return context;
};

export default useImageUploadContext;
