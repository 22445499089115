import {
  getMultiFactorResolver,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { alertParams, showAlert } from '~/components/common/Alert';
import { firebaseAuth } from '~/services/firebase';

type Props = {
  recaptchaId: string;
  onLoginSuccess: () => void;
};

export const use2Fa = ({ recaptchaId, onLoginSuccess }: Props) => {
  const [phone, setPhone] = useState('');
  const recaptchaVerifierRef = useRef<any>(null);
  const verificationIdRef = useRef<string>();
  const resolverRef = useRef<any>();
  const timeoutVerifyCaptchaRef = useRef<any>();
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [enable2FA, setEnable2FA] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSendCode = async () => {
    try {
      setLoadingSendCode(true);
      const phoneInfoOptions = {
        multiFactorHint: resolverRef.current.hints[0],
        session: resolverRef.current.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);
      verificationIdRef.current = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifierRef.current,
      );
      setEnable2FA(true);
      setErrorMsg('');
    } catch (error) {
      const errCode = error?.code;
      switch (errCode) {
        case 'auth/invalid-multi-factor-session': {
          setErrorMsg('Your session has expired. Please try again.');
          setEnable2FA(false);
          break;
        }
        case 'auth/too-many-requests': {
          setErrorMsg(
            'Too many resent code request is detected. Please wait before trying again.',
          );
          break;
        }
        case 'auth/internal-error-encountered': {
          showAlert({
            ...alertParams.warning,
            description:
              'Service is temporary unavailable. Please try again later.',
            onOk: () => window.location.reload(),
          });
          break;
        }

        default: {
          setErrorMsg(
            'Service is temporary unavailable. Please try again later.',
          );
        }
      }
    }
    setLoadingSendCode(false);
  };

  const handleEnable2FA = async (error: MultiFactorError) => {
    resolverRef.current = getMultiFactorResolver(firebaseAuth, error);
    try {
      if (
        resolverRef.current.hints[0].factorId ===
        PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        // @ts-ignore
        const phoneNumber = resolverRef.current.hints[0]?.phoneNumber || '';
        await handleSendCode();
        setPhone(phoneNumber);
      }
    } catch (err: any) {
      showAlert({
        ...alertParams.warning,
        description: err?.code,
      });
    }
  };

  const handleVerifyCode = async (code: string) => {
    try {
      setLoadingVerify(true);
      const cred = PhoneAuthProvider.credential(
        verificationIdRef.current,
        code,
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolverRef.current?.resolveSignIn(multiFactorAssertion);
      await onLoginSuccess();
    } catch (err) {
      if (err?.code === 'auth/multi-factor-auth-required') {
        handleEnable2FA(err);
      }
      const errCode = err?.code;
      switch (errCode) {
        case 'auth/code-expired':
        case 'auth/invalid-verification-code': {
          setErrorMsg('Verification code is invalid or expired.');
          break;
        }

        default: {
          setErrorMsg(
            'Service is temporary unavailable. Please try again later.',
          );
        }
      }
    }
    setLoadingVerify(false);
  };

  const clearErrorMsg = () => {
    setErrorMsg('');
  };

  const handleCancel = () => {
    setEnable2FA(false);
    setErrorMsg('');
  };

  useEffect(() => {
    if (loadingSendCode) {
      timeoutVerifyCaptchaRef.current = setTimeout(() => {
        setLoadingSendCode(false);
      }, 30000);
    }
    return () => clearTimeout(timeoutVerifyCaptchaRef.current);
  }, [loadingSendCode]);

  useEffect(() => {
    if (recaptchaId) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        recaptchaId,
        {
          size: 'invisible',
        },
        firebaseAuth,
      );
      recaptchaVerifierRef.current?.render();
    }
  }, [recaptchaId]);

  return {
    handleEnable2FA,
    handleVerifyCode,
    handleSendCode,
    phone,
    loadingSendCode,
    enable2FA,
    handleCancel,
    loadingVerify,
    errorMsg,
    clearErrorMsg,
  };
};
