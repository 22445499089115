import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { ITeam } from '~/models/shared';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getTeamList = (): Promise<ITeam[]> =>
  httpClient.json.get(`${endpoints.share.team}`);

export const getTeamDetail = (id): Promise<ITeam> =>
  httpClient.json.get(`${endpoints.share.team}/${id}`);

export const createTeam = (params): Promise<ITeam> =>
  httpClient.json.post(`${endpoints.share.team}`, params);
export const editTeam = (params): Promise<ITeam> =>
  httpClient.json.put(`${endpoints.share.team}/${params.id}`, params);
export const deleteTeam = (id): Promise<ITeam> =>
  httpClient.json.delete(`${endpoints.share.team}/${id}`);

export const useGetTeamDetail = (params) => {
  const { id, onSuccess, onError } = params;
  return useQuery({
    queryKey: [queryKeys.shared.getTeamDetail],
    queryFn: () => getTeamDetail(id),
    enabled: !!id,
    onSuccess,
    onError,
  });
};

export const useCreateTeamMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: createTeam,
    onSuccess,
    onError,
  });

export const useEditTeamMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: editTeam,
    onSuccess,
    onError,
  });

export const useDeleteTeamMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: deleteTeam,
    onSuccess,
    onError,
  });

export const useGetTeamListQuery = () =>
  useQuery({
    queryKey: [queryKeys.shared.getTeamList],
    queryFn: getTeamList,
    initialData: [],
  });
