import { useState } from 'react';

export enum DropState {
  Draggable = 'Draggable',
  DraggingOver = 'DraggingOver',
  Dropped = 'Dropped',
  DragLeave = 'DragLeave',
  DragEnter = 'DragEnter',
}

export const useDrop = ({ onDropped }) => {
  const [dropState, setDropState] = useState<DropState>(DropState.Draggable);

  const onDragOver = (event: any) => {
    const { types } = event.dataTransfer;
    const hasFilesInEvent = types && types.length === 1 && types[0] === 'Files';
    if (!hasFilesInEvent) {
      return;
    }
    setDropState(DropState.DraggingOver);
    event.preventDefault();
  };

  const onDrop = (event: any) => {
    if (event.dataTransfer.files.length === 0) {
      return;
    }

    setDropState(DropState.Dropped);
    onDropped(event.dataTransfer.files);
    event.preventDefault();
  };

  const onDragLeave = (event: any) => {
    setDropState(DropState.DragLeave);
    event.preventDefault();
  };

  const onDragEnter = (event: any) => {
    setDropState(DropState.DragEnter);
    event.preventDefault();
  };

  return {
    dropFuncProps: {
      onDrop,
      onDragOver,
      onDragLeave,
      onDragEnter,
    },
    dropState,
    onDropped,
  };
};
