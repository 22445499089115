import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import themes from '~/themes';

type ToolTipListTextProps = {
  list: string[];
  max: number;
  defaultText?: string;
  tooltipProps?: TooltipProps;
  textNode?: (v: string) => React.ReactNode;
};

export const ToolTipListText: React.FC<ToolTipListTextProps> = ({
  list = [],
  max,
  defaultText = '-',
  tooltipProps,
  textNode = () => {},
}) => {
  if (!list.length) {
    return <span>{defaultText}</span>;
  }
  if (list.length <= max) {
    return <span>{list.join(', ')}</span>;
  }
  const text = list.slice(0, max).join(', ');
  const title = list.slice(max, list.length).join(', ');
  const isShow = list.length > max;
  return (
    <>
      <span>{textNode(text) || text}</span>
      <Tooltip title={isShow ? title : ''} placement='right' {...tooltipProps}>
        <span style={{ color: themes.color.primaryOrange, marginLeft: 5 }}>
          +{list.length - max}
        </span>
      </Tooltip>
    </>
  );
};
