/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IStore, StorePlatform } from '~/models/stores';
import themes from '~/themes';
import { fromQueryString } from '~/utils/queryString';
import StoreIcon from '~/assets/images/icons/team-store.svg';
import SetupTeamBackgroundImg from '~/assets/images/background/setup-team.png';
import { useGetTeamsQuery } from '~/services/api/shared';
import { Select } from '~/components/common/Select';
import Tooltip from '~/components/common/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Button from '~/components/common/Button';
import { Form, Formik } from 'formik';
import { IHub } from '~/models/shared';
import { editHub } from '~/services/api/hub';
import { queryKeys } from '~/services/api/queryKeys';
import { queryClient } from '~/lib/react-query';
import { alertParams, showAlert } from '~/components/common/Alert';
import CheckBox from '~/components/common/CheckBox';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { StyledOneLineText } from '~/themes/style';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { UpgradePlanButton } from '~/components/shared/UpgradePlan';
import { AccordionItem } from '~/pages/LiveTracking/components/AddTask/components/AccordionItem';

type TeamsProps = {
  data: IStore;
};

export const Teams: React.FC<TeamsProps> = ({ data }) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const expanded = (id: string) => !!expandedIds.find((e) => e === id);
  const { data: rawData, isFetching: isFetchingGetTeam } = useGetTeamsQuery();
  const [isUpdateTeamLoading, setIsUpdateTeamLoading] = useState(false);

  const teamsData = useMemo(() => rawData || [], [rawData]);
  const { isStarterOrStandardPlan } = usePaymentInfo();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeExpandedIds = (newId: string) => (event, isExpanded) => {
    setExpandedIds(() => (isExpanded ? [newId] : []));
  };

  const renderTeamSetting = (
    hubs: { [id: string]: IHub },
    hubId,
    setFieldValue,
  ) => {
    const hub = hubs[hubId] || {};
    if (isFetchingGetTeam)
      return (
        <Stack alignItems='center'>
          <CircularProgress size={18} />
        </Stack>
      );
    return (
      <Stack data-testid='team-container'>
        <Typography variant='h5' sx={{ mb: 1 }} color={themes.color.black}>
          Teams
        </Typography>

        <Select
          id='teams'
          disabled={isFetchingGetTeam}
          sx={{ width: '50%' }}
          placeholder='Select a team'
          noOptionsText='No team available'
          options={teamsData
            .filter((t) => !hub?.team_ids?.includes(t.id))
            .map((team) => ({
              value: team.id,
              id: team.id,
              label: team.name,
              name: team.name,
              driver_ids: team.driver_ids || [],
            }))}
          onChange={(event) => {
            const value = event.target.value as string;
            if (value) {
              setFieldValue('hubs', {
                ...hubs,
                [hubId]: { ...hub, team_ids: hub?.team_ids.concat([value]) },
              });
            }
          }}
        />

        <Stack
          sx={{
            mt: 1,
            position: 'relative',
            boxSizing: 'border-box',
            borderRadius: '10px',
            backgroundColor: '#F8F8FC',
            maxHeight: 200,
            minHeight: 200,
            overflow: 'hidden',
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{ overflow: 'auto', p: 1 }}
            className='customized-scrollbar'
          >
            {hub?.team_ids?.map((teamId, index) => {
              const team = teamsData.find((t) => t.id === teamId);
              if (!team) return <div />;
              return (
                <Grid key={teamId} item xs={12}>
                  <Stack
                    component='li'
                    data-testid='team-item'
                    data-value={teamId}
                    direction='row'
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid #BFC3E0',
                      borderRadius: '20px',
                      background: 'white',
                      pr: 2,
                    }}
                  >
                    <Stack direction='row' alignItems='center' padding={1}>
                      <IconButton
                        onClick={() => {
                          setFieldValue('hubs', {
                            ...hubs,
                            [hubId]: {
                              ...hub,
                              team_ids: hub?.team_ids.filter(
                                (id) => id !== team.id,
                              ),
                            },
                          });
                        }}
                        sx={{
                          width: 20,
                          height: 20,
                          color: themes.color.violet900,
                          fontSize: 18,
                        }}
                      >
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                      <Tooltip title={team.name}>
                        <StyledOneLineText
                          fontWeight={500}
                          mobileMaxWidth={150}
                          style={{ cursor: 'pointer' }}
                        >
                          {team.name}
                        </StyledOneLineText>
                      </Tooltip>
                    </Stack>
                    {teamsData.length > 1 ? (
                      index === 0 ? (
                        <Typography
                          fontWeight={500}
                          color={themes.color.primaryOrange}
                          data-testid='is-team-preferred'
                        >
                          Preferred
                        </Typography>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <Typography
                          sx={{
                            'textDecoration': 'underline',
                            'cursor': 'pointer',
                            'color': themes.color.violet900,
                            '&:hover': {
                              color: themes.color.black,
                            },
                          }}
                          onClick={() => {
                            const newTeams = [
                              hub.team_ids[index],
                              ...hub.team_ids.slice(0, index),
                              ...hub.team_ids.slice(
                                index + 1,
                                hub.team_ids.length,
                              ),
                            ];

                            setFieldValue('hubs', {
                              ...hubs,
                              [hubId]: {
                                ...hub,
                                team_ids: newTeams,
                              },
                            });
                          }}
                        >
                          Set to preferred
                        </Typography>
                      )
                    ) : null}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <CheckBox
          sx={{
            my: 1,
            ml: 0.5,
          }}
          id='allow_ignore_team'
          checked={hub.allow_ignore_team}
          onChange={(e: any) => {
            const { checked } = e.target;
            setFieldValue('hubs', {
              ...hubs,
              [hubId]: {
                ...hub,
                allow_ignore_team: checked,
              },
            });
          }}
          name='allow_ignore_team'
          label='If none of the teams above are available, assign tasks to other drivers.'
        />
      </Stack>
    );
  };

  if (isStarterOrStandardPlan) {
    return (
      <Stack justifyContent='center' alignItems='center' height='100%'>
        <img
          src={SetupTeamBackgroundImg}
          width={400}
          height={240}
          alt='setup-team-background'
        />
        <Typography variant='h2' mt={3}>
          Set up driver teams
        </Typography>
        <Typography sx={{ whiteSpace: 'break-spaces', mt: 1 }}>
          {
            'Group your drivers into teams - for example, based on shared geographic range.\n Teams make for easier task management and smarter auto-assignment.'
          }
        </Typography>
        <UpgradePlanButton sxProps={{ mt: 3 }} />
      </Stack>
    );
  }

  return (
    <div>
      <Typography
        sx={{
          borderRadius: '5px',
          background: themes.bg.lightPurple,
          padding: 1,
        }}
      >
        Assigning dedicated driver teams to this store to improve operational
        flow, efficiency, and accuracy in auto-assignments.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Formik
        initialValues={{
          hubs: data?.hubs.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {}) as { [id: string]: IHub },
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            setIsUpdateTeamLoading(true);
            await Promise.all(
              Object.keys(values.hubs).map(async (hId) =>
                editHub({
                  id: hId,
                  ...values.hubs[hId],
                }),
              ),
            );
            queryClient.invalidateQueries([
              queryKeys.stores.getListIntegration,
            ]);
            showAlert({
              ...alertParams.success,
              title: 'Store Updated Successfully',
            });
          } catch (error) {
            showAlert(errorAlert(error));
          } finally {
            setIsUpdateTeamLoading(false);
          }
        }}
      >
        {({ values, setFieldValue, isValid, dirty }) => (
          <Form id='update-team'>
            {platform === StorePlatform.Woo
              ? renderTeamSetting(values.hubs, data?.hubs[0]?.id, setFieldValue)
              : data.hubs.map((hub) => (
                  <AccordionItem
                    styles={{
                      summary: {
                        padding: '10px 0px !important',
                        ...(!expanded(hub.id) && {
                          borderBottom: `1px solid ${themes.bg.midPurple}`,
                        }),
                      },
                      container: {},
                      detail: { padding: 0 },
                    }}
                    id={hub.id}
                    expanded={expanded}
                    handleChangeExpandedIds={() =>
                      handleChangeExpandedIds(hub.id)
                    }
                    summaryContent={
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <img
                          alt='store-icon'
                          width={25}
                          height={25}
                          src={StoreIcon}
                        />
                        <Typography
                          variant='h5'
                          data-testid='breadstack-wh-name'
                        >
                          {hub.name}
                        </Typography>
                      </Stack>
                    }
                  >
                    {renderTeamSetting(values.hubs, hub.id, setFieldValue)}
                  </AccordionItem>
                ))}

            <Button
              fullWidth
              className='save-change-btn'
              loading={isUpdateTeamLoading}
              type='submit'
              form='update-team'
              disabled={!isValid || !dirty}
            >
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
