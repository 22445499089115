import { forwardRef, useMemo } from 'react';
import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import themes, { Theme } from '~/themes';
import { Stack, Typography } from '@mui/material';
import BellDarkIcon from '~/assets/images/icons/bell-dark.svg';
import CarNotifyIcon from '~/assets/images/icons/car-notify.svg';
import BellOrangeIcon from '~/assets/images/icons/bell-orange.svg';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { CloseButton } from '~/components/common/Button';
import { NotiTaskType } from './Notifications';

interface TaskMessageProps extends CustomContentProps {
  taskName: string;
  type: NotiTaskType;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'end',
  },
}));

export const TaskMessage = forwardRef(
  (props: TaskMessageProps, ref: React.Ref<HTMLDivElement | any>) => {
    const { closeSnackbar } = useSnackbar();

    const classes = useStyles();
    const { taskName, type, message, id } = props;

    const notifyIcon = useMemo(() => {
      switch (type) {
        case NotiTaskType.Completed:
          return BellDarkIcon;
        case NotiTaskType.NewTask:
          return BellOrangeIcon;

        case NotiTaskType.NewDriver:
          return CarNotifyIcon;
        default:
          return BellDarkIcon;
      }
    }, [type]);
    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Stack
          direction='row'
          p={themes.spacing(1.2)}
          sx={{
            background: themes.bg.primaryOrange,
            width: '100%',
            borderRadius: '20px 0px 0px 20px',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...([NotiTaskType.NewDriver, NotiTaskType.Completed].includes(
              type,
            ) && {
              background: themes.bg.darkPurple,
            }),
            [themes.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
              width: '50%',
            },
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <Stack
              sx={{
                width: 45,
                height: 45,
                background: themes.bg.white,
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={notifyIcon} alt='bell-icon' />
            </Stack>
            <Stack>
              <Typography color={themes.color.white}>{message}</Typography>
              <Typography
                variant='h5'
                color={themes.color.white}
                sx={{
                  ...(type === NotiTaskType.NewDriver && {
                    fontWeight: 'bold',
                  }),
                }}
              >
                {taskName}
              </Typography>
            </Stack>
          </Stack>
          <CloseButton
            onClick={() => closeSnackbar(id)}
            sx={{ background: 'inherit' }}
            iconColor={themes.color.white}
            iconSize={12}
          />
        </Stack>
      </SnackbarContent>
    );
  },
);
