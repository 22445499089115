import { Typography, Box, Stack } from '@mui/material';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import {
  StyledTaskLogItem,
  StyledTaskLogPoint,
} from '~/pages/LiveTracking/style';
import themes from '~/themes';

export const TaskLog = ({ data }) => (
  <Stack>
    {data.map((log) => (
      <StyledTaskLogItem key={log.id}>
        <StyledTaskLogPoint />
        <Box
          sx={{
            'background': themes.bg.white,
            'borderRadius': '10px',
            'padding': themes.spacing(1.5),
            'width': '100%',
            '&>*:first-of-type': {
              marginBottom: themes.spacing(0.5),
            },
          }}
        >
          <Stack
            color=' #979DC3'
            direction='row'
            justifyContent='space-between'
          >
            <ToolTipText maxLength={20} text={log.dispatcherName} />
            <span>{log.createdBy}</span>
          </Stack>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
            }}
            color={themes.color.black}
          >
            {log.body}
          </Typography>
        </Box>
      </StyledTaskLogItem>
    ))}
  </Stack>
);
