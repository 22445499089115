import { Box } from '@mui/material';
import { styled } from '~/themes';
import ChartContainer from '~/components/common/Chart/ChartContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import MetroHomeIcon from '~/assets/images/icons/metro-home.svg';
import './index.scss';
import { HitMapData, PrecisionLevel } from '~/services/api/dashboard';
import { useHitMapContext } from '../../context/useHitMapContext';

type DropOffHitMapProps = {
  data: any;
  storeLocations: { lat: number; lng: number }[];
};

export const StyledDropOffHitMapContainer = styled(Box)(() => ({
  height: 500,
  width: '100%',
  position: 'relative',
}));

export const DropOffHitMap: React.FC<DropOffHitMapProps> = ({
  data,
  storeLocations,
}) => {
  const {
    mapRef,
    map,
    googleMap,
    isMapLoaded,
    setPolygonOnMap,
    createStoreLocationOnMap,
  } = useHitMapContext();
  const [precision, setPrecision] = useState<PrecisionLevel>(null);
  const [mapBounds, setMapBounds] = useState<any>(map?.getBounds());

  const hitMapData: HitMapData[] = useMemo(() => {
    if (!data) return [];
    const PrecisionKeys = Object.keys(data);
    return PrecisionKeys.map((k: PrecisionLevel) => ({
      precisionKey: k,
      zones: Object.values(data[k]).map(
        ({ total_task, main_lat_long, border }) => ({
          totalTask: total_task,
          centerPoint: { lat: main_lat_long[0], lng: main_lat_long[1] },
          latLngs: ['w-s', 'e-s', 'e-n', 'w-n'].map((direction) => {
            const lngKey = direction.split('-')[0];
            const latKey = direction.split('-')[1];
            return {
              lat: border[latKey] || '',
              lng: border[lngKey] || '',
            };
          }),
        }),
      ),
    }));
  }, [data]);

  const zonesData = useMemo(() => {
    const z = hitMapData.find((e) => e.precisionKey === precision)?.zones;
    if (!z) {
      return [];
    }

    return mapBounds
      ? z.filter((e) => e.latLngs.some((f) => mapBounds.contains(f)))
      : z;
  }, [hitMapData, precision, mapBounds]);

  const dropOffCountInfoWindowContent = useCallback(
    (c) =>
      ReactDOMServer.renderToStaticMarkup(
        <div
          style={{
            padding: '5px',
            background: '#3DC09B',
            borderRadius: '8px',
            border: '1px solid white',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <span style={{ fontSize: 12, fontWeight: 'bold' }}>{c}</span>
          <br />
          <span style={{ fontSize: 10 }}>drop-offs</span>
        </div>,
      ),
    [],
  );

  const dropOffStoreLocationContent = useMemo(
    () =>
      ReactDOMServer.renderToStaticMarkup(
        <div
          style={{
            padding: '8px',
            background: '#F7982A',
            borderRadius: '50%',
            border: '2px solid white',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <img src={MetroHomeIcon} alt='metro-home-icon' />
        </div>,
      ),
    [],
  );
  useEffect(() => {
    if (map) {
      map?.addListener('zoom_changed', () => {
        const currentZoom = map.getZoom();
        if (currentZoom < 8) {
          setPrecision(PrecisionLevel.Precision3);
        }
        if (currentZoom >= 8 && currentZoom < 12) {
          setPrecision(PrecisionLevel.Precision4);
        }
        if (currentZoom >= 12) {
          setPrecision(PrecisionLevel.Precision5);
        }
      });

      map?.addListener('dragend', () => {
        const bounds = map.getBounds();
        setMapBounds(bounds);
      });
    }
  }, [map]);

  useEffect(() => {
    if (isMapLoaded) {
      googleMap.clearInfoWindows();
      googleMap.clearDrawnPolygon();
      if (zonesData.length) {
        const maxTotalTask = Math.max(...zonesData.map((e) => e.totalTask));
        const transformFillOpacity = (ratio) => {
          if (ratio > 0 && ratio <= 0.25) return 0.15;
          if (ratio > 0.25 && ratio <= 0.5) return 0.3;
          if (ratio > 0.5 && ratio <= 0.75) return 0.5;
          if (ratio > 0.75) return 0.7;
          return 0.5;
        };

        zonesData.forEach(({ latLngs, centerPoint, totalTask }) => {
          setPolygonOnMap(
            latLngs,
            centerPoint,
            {
              editable: false,
              strokeWeight: 0,
              fillColor: '#3DC09B',
              fillOpacity: transformFillOpacity(totalTask / maxTotalTask),
              clickable: false,
            },
            dropOffCountInfoWindowContent(totalTask),
          );
        });
      }
    }
  }, [zonesData]);

  useEffect(() => {
    if (storeLocations.length) {
      createStoreLocationOnMap(storeLocations, dropOffStoreLocationContent);
    }
  }, [storeLocations]);

  useEffect(() => {
    if (isMapLoaded) {
      const z =
        hitMapData.find((e) => e.precisionKey === PrecisionLevel.Precision3)
          ?.zones || [];
      if (z?.length) {
        if (z?.length === 1) {
          googleMap?.fitBounds(z[0].latLngs);
        } else {
          googleMap?.fitBounds(z.map(({ centerPoint }) => centerPoint));
        }
      } else {
        map?.setCenter({
          lat: 49.21556039735341,
          lng: -123.09840777108371,
        });
      }
    }
  }, [hitMapData, isMapLoaded, map]);

  return (
    <ChartContainer
      title='Drop-off Heatmap'
      sxContainer={{
        height: '500px !important',
      }}
      sxContent={{ mt: 1 }}
    >
      <div
        ref={mapRef}
        id='drop-of-heap-map'
        onMouseEnter={() => {
          const element = document.getElementById('insight-container');
          const oldWidth = element.clientWidth;

          element.style.overflow = 'hidden';
          const newWidth = element.clientWidth;
          const scrollbarWidth = Math.max(0, newWidth - oldWidth);
          element.style.width = `calc(100% - ${scrollbarWidth}px)`;
        }}
        onMouseLeave={() => {
          const element = document.getElementById('insight-container');
          element.style.overflow = 'auto';
          element.style.width = '100%';
        }}
        style={{ height: '100%', width: '100%', borderRadius: '20px' }}
      />
    </ChartContainer>
  );
};
