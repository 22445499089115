import { useEffect, useState } from 'react';

export const useKeyHold = (key: 'Control' | 'Meta') => {
  const [keyHeld, setKeyHeld] = useState(false);

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (event.key === key) setKeyHeld(true);
    };

    const upHandler = (event: KeyboardEvent) => {
      if (event.key === key) setKeyHeld(false);
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [key]);

  return keyHeld;
};
