import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';

enum BroadcastEvents {
  SyncAccountData = 'sync_account_data',
  SyncSignOutAfterRegister = 'sync_sign_out_after_register',
  RefreshOtherPageWhenLogged = 'refresh_other_page_when_logged',
  RefreshOtherPageWhenLogout = 'refresh_other_page_when_logout',
  RefreshOtherPageWhenChangedOrg = 'refresh_other_page_when_changed_org',
  RefreshOtherPageWhenActiveAccount = 'refresh_other_page_when_active_account',
  SyncLoginState = 'sync_login_state',
}

declare type AppEvents = {
  type: BroadcastEvents;
  payload?: any;
};

const channel: BroadcastChannel<AppEvents> = new BroadcastChannel(
  'app-events',
  {
    webWorkerSupport: false,
  },
);

const elector = createLeaderElection(channel);

export { elector, channel, BroadcastEvents };
