import {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { ControlPosition } from '~/pages/LiveTracking/constants';

type DrawnZonesMapProps = {
  latLngs?: any;
  setFieldValue?: any;
  isValidateError?: boolean;
  allLatLngs?: any;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
};

export const DEFAULT_LAT = 49.2577143;
export const DEFAULT_LNG = -122.9539433;
export const DEFAULT_LOCATION = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
const polygonOptions = {
  clickable: true,
  editable: true,
  draggable: true,
  geodesic: true,
  zIndex: 100,
  strokeWeight: 2,
};

const disabledPolygonOptions = {
  clickable: false,
  editable: false,
  draggable: false,
  geodesic: false,
  zIndex: 100,
  strokeWeight: 1,
};

const mapOptions: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: true,
  streetViewControl: false,
  zoomControlOptions: {
    position:
      ControlPosition.RIGHT_BOTTOM as unknown as google.maps.ControlPosition,
  },
  zoomControl: true,
  styles: null,
  zoom: 11,
  disableDefaultUI: true,
  center: {
    lat: 49.21556039735341,
    lng: -123.09840777108371,
  },
};

export const DrawnZonesMap = forwardRef(
  (
    {
      latLngs = [],
      allLatLngs = [],
      setFieldValue,
      isValidateError,
      defaultCenter,
    }: DrawnZonesMapProps,
    ref: React.Ref<any>,
  ) => {
    const shapeRef = useRef<any>();
    const mapRef = useRef();
    const [map, setMap] = useState<google.maps.Map | null>();
    const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();

    useEffect(() => {
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
    }, []);

    const handleSetPolygonToMap = (paths) => {
      const polygonDrawn = new google.maps.Polygon({
        paths,
        ...polygonOptions,
        ...(allLatLngs.length && disabledPolygonOptions),
      });
      shapeRef.current = polygonDrawn;
      polygonDrawn.setMap(map);
    };

    useEffect(() => {
      if (map) {
        drawingManagerRef.current = new google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
        });
        drawingManagerRef.current.addListener('overlaycomplete', (e) => {
          if (e?.type === google.maps.drawing.OverlayType.POLYGON) {
            drawingManagerRef.current.setDrawingMode(null);
            const shape = e.overlay.getPath();
            const newPolygon = [];
            for (let i = 0; i < shape.getLength(); i += 1) {
              newPolygon.push([shape.getAt(i).lng(), shape.getAt(i).lat()]);
            }
            setFieldValue('long_lat', newPolygon);
            drawingManagerRef.current.setDrawingMode(null);
            if (shapeRef.current) {
              shapeRef.current.setMap(null);
            }
            shapeRef.current = e.overlay;
          }
        });
        drawingManagerRef.current.setMap(map);
      }
    }, [map]);

    useEffect(() => {
      if (map) {
        drawingManagerRef.current.setOptions({
          drawingControl: !allLatLngs.length,
          drawingMode: allLatLngs?.length
            ? null
            : google.maps.drawing.OverlayType.POLYGON,
          polygonOptions: {
            ...polygonOptions,
            ...(allLatLngs.length && {
              editable: false,
            }),
          },
        });
        if ((latLngs?.length || allLatLngs.length) && !shapeRef.current) {
          if (allLatLngs.length) {
            allLatLngs.forEach((item) => {
              const cvPaths = item.map((e) => ({ lng: e[0], lat: e[1] }));
              handleSetPolygonToMap(cvPaths);
            });
          } else {
            const cvPaths = latLngs.map((e) => ({ lng: e[0], lat: e[1] }));
            handleSetPolygonToMap(cvPaths);
          }
        }
      }
    }, [map, latLngs, allLatLngs, shapeRef.current, drawingManagerRef.current]);

    useEffect(() => {
      if (map) {
        const bounds = new google.maps.LatLngBounds();
        let positions = [];

        if (allLatLngs.length) {
          positions = allLatLngs.flat().map((e) => ({ lng: e[0], lat: e[1] }));
        }
        if (latLngs.length) {
          positions = latLngs.map((e) => ({ lng: e[0], lat: e[1] }));
        }

        if (positions.length) {
          positions.forEach((position) => {
            bounds.extend(position);
          });
          map.fitBounds(bounds);
        } else {
          map.setCenter(defaultCenter || DEFAULT_LOCATION);
        }
      }
    }, [map, latLngs, allLatLngs, defaultCenter]);

    useImperativeHandle(
      ref,
      () => ({
        getPaths: () => {
          if (!shapeRef?.current) return [];
          const newPolygon = [];
          const shape = shapeRef?.current?.getPath();
          for (let i = 0; i < shape.getLength(); i += 1) {
            newPolygon.push([shape.getAt(i).lng(), shape.getAt(i).lat()]);
          }
          return newPolygon;
        },
      }),
      [],
    );

    return (
      <div
        ref={mapRef}
        id='drawn-zones-map'
        style={{
          marginTop: 20,
          minHeight: 300,
          width: '100%',
          position: 'relative',
          borderRadius: '20px',
          ...(isValidateError && {
            border: '1px solid #F55073',
          }),
        }}
      />
    );
  },
);
