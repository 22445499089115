import { styled } from '~/themes';
import NoImage from '~/assets/images/icons/round-no-image.svg';
import { Skeleton } from '@mui/material';
import { useUploadImageContext } from './UploadImageProvider';

type UploadImageSelectionProps = {
  loading?: boolean;
};

const StyledImageContainer = styled('div')(({ theme }) => ({
  'position': 'relative',
  'width': theme.spacing(6),
  'height': theme.spacing(6),
  'borderRadius': theme.spacing(0.5),
  '&:hover': {
    '& > div': {
      display: 'flex',
    },
  },
}));

const StyledImageEdit = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  background: 'rgba(0,0,0,.5)',
  color: theme.color.white,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  top: 0,
  left: 0,
  right: 0,
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: theme.spacing(0.5),
  objectFit: 'contain',
}));

const StyledSkeletonLoading = styled(Skeleton)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(0.5),
}));

export const UploadImageSelection: React.FC<UploadImageSelectionProps> = ({
  loading = false,
}) => {
  const { defaultImages, onToggleUploadDialog } = useUploadImageContext();
  return (
    <StyledImageContainer>
      {loading ? (
        <StyledSkeletonLoading variant='rectangular' />
      ) : (
        <StyledImage src={defaultImages[0] || NoImage} alt='avatar' />
      )}
      <StyledImageEdit
        role='button'
        tabIndex={0}
        onClick={onToggleUploadDialog}
      >
        Edit
      </StyledImageEdit>
    </StyledImageContainer>
  );
};
