import { Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { errorAlert, showAlert } from '~/components/common/Alert';
import Button from '~/components/common/Button';
import { FastField, Field } from '~/components/common/Formik';
import RegisterStepper from '~/components/common/RegisterStepper';
import { ORG_CATEGORY_OPTIONS } from '~/constants/common';
import PublicLayout from '~/layout/PublicLayout';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { ICreateOrgParams } from '~/models/auth';
import { useCreateOrgMutation } from '~/services/api/org';
import { removeDefaultOrg, updateDefaultOrg } from '~/services/api/shared';
import { memoryStorage } from '~/utils/localStorage';
import { CreateWorkspaceSchema } from '~/utils/schema/auth';

interface ICreateWorkspace {}

const CreateWorkspace: React.FC<ICreateWorkspace> = () => {
  const handleUpdateDefaultOrg = async (orgId) => {
    const currentOrgId = memoryStorage.getOrgId();
    try {
      await removeDefaultOrg();
      memoryStorage.setOrgId(orgId);
      await updateDefaultOrg();
      channel.postMessage({
        type: BroadcastEvents.RefreshOtherPageWhenChangedOrg,
      });
      window.location.replace('/');
    } catch (err) {
      showAlert(errorAlert(err));
      memoryStorage.setOrgId(currentOrgId);
    }
  };

  const { mutate: createOrgMutation, isLoading: isLoadingCreateOrg } =
    useCreateOrgMutation({
      onSuccess: async (resp: any) => {
        handleUpdateDefaultOrg(resp.id);
      },
      onError: (err) => {
        showAlert(errorAlert(err));
      },
    });

  const handleSubmit = (data: ICreateOrgParams) => {
    createOrgMutation({
      ...data,
      display_name: data.name,
    });
  };

  return (
    <PublicLayout>
      <Formik
        initialValues={
          {
            name: '',
            create_by_internal_user: true,
          } as ICreateOrgParams
        }
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={CreateWorkspaceSchema}
      >
        {({ isValid }) => (
          <Form>
            <Grid container columnSpacing={6} spacing={1.5}>
              <Grid item xs={12} md={10}>
                <RegisterStepper activeStep={1} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h1'>Organization Info</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <FastField.TextInput name='name' legend='Name' />
                  <Field.Select
                    name='category'
                    options={ORG_CATEGORY_OPTIONS}
                    legend='Business Category'
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Button
                  buttonType='primary'
                  type='submit'
                  loading={isLoadingCreateOrg}
                  disabled={!isValid}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </PublicLayout>
  );
};

export default CreateWorkspace;
