import { LatLng } from '../types';

const POLYGON_OPTIONS = {
  clickable: true,
  editable: true,
  draggable: true,
  geodesic: true,
  zIndex: 100,
  strokeWeight: 2,
};
interface IGoogleMaps {}

export class GoogleMaps implements IGoogleMaps {
  public map: google.maps.Map;

  public drawnPolygons: google.maps.Polygon[] = [];

  public infoWindows: google.maps.InfoWindow[] = [];

  constructor(map?: google.maps.Map) {
    this.map = map;
  }

  initialDrawingManager(data: {
    latLngs: LatLng[];
    editable?: boolean;
    onOverlayCompleted: (data: [string, string][]) => void;
  }) {
    const { latLngs, editable = false, onOverlayCompleted } = data;

    if (latLngs) {
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: latLngs?.length
          ? null
          : google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          ...POLYGON_OPTIONS,
          editable,
        },
      });

      drawingManager.addListener('overlaycomplete', (e) => {
        if (e?.type === google.maps.drawing.OverlayType.POLYGON) {
          drawingManager.setDrawingMode(null);
          const shape = e.overlay.getPath();
          const newPolygon = [];
          for (let i = 0; i < shape.getLength(); i += 1) {
            newPolygon.push([shape.getAt(i).lng(), shape.getAt(i).lat()]);
          }
          onOverlayCompleted(newPolygon);
          drawingManager.setDrawingMode(null);
        }
      });
      drawingManager.setMap(this.map);
    }
  }

  attachPolygonInfoWindow(latLng: LatLng, content: string) {
    const infoWindow = new google.maps.InfoWindow({});
    this.infoWindows = [...this.infoWindows, infoWindow];
    infoWindow.setContent(content);
    infoWindow.setPosition(latLng);
    infoWindow.open(this.map);
  }

  setPolygonToMap(data: {
    latLngs: LatLng[];
    centerLatLng: LatLng;
    opts?: google.maps.PolygonOptions | null;
    infoWindowContent?: string;
  }) {
    const { latLngs, opts, infoWindowContent, centerLatLng } = data;
    const polygon = new google.maps.Polygon({
      paths: latLngs,
      ...POLYGON_OPTIONS,
      ...opts,
      map: this.map,
    });

    this.drawnPolygons = [...this.drawnPolygons, polygon];
    this.attachPolygonInfoWindow(centerLatLng, infoWindowContent);
  }

  clearDrawnPolygon() {
    if (this.drawnPolygons.length) {
      this.drawnPolygons.forEach((drawnPolygon) => {
        drawnPolygon?.setMap(null);
        drawnPolygon = null;
      });
    }
  }

  clearInfoWindows() {
    if (this.infoWindows.length) {
      this.infoWindows.forEach((infoWindow) => {
        infoWindow?.setContent('');
      });
    }
  }

  createStoreLocationInfoWindow(latLng: LatLng, content: string) {
    const infoWindow = new google.maps.InfoWindow();
    infoWindow.setContent(content);
    infoWindow.setPosition(latLng);
    infoWindow.open(this.map);
  }

  fitBounds(latLngs: LatLng[]) {
    const bounds = new google.maps.LatLngBounds();

    latLngs.forEach((latLng) => {
      const ll = new google.maps.LatLng(latLng.lat, latLng.lng);
      bounds.extend(ll);
    });

    this.map.fitBounds(bounds);
  }
}
