import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FastField, Field } from '~/components/common/Formik';
import { useAuth } from '~/providers/AuthProvider';

type StoreAddressFormProps = {};

export const StoreAddressForm: React.FC<StoreAddressFormProps> = () => {
  const { countries, isFetchingCountry } = useAuth();

  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <Grid
      sx={{ mt: 1, mb: 9 }}
      container
      spacing={2}
      className='customized-scrollbar'
    >
      <Grid item xs={12}>
        <FastField.TextInput legend='Address Line 1' name='address_1' />
      </Grid>
      <Grid item xs={12}>
        <FastField.TextInput legend='Address Line 2' name='address_2' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.Select
          legend='Country'
          name='country'
          options={countries}
          disabled={isFetchingCountry}
          onChange={() => {
            setFieldValue('state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field.Select
          legend='Province / State'
          name='state'
          options={
            countries.find(({ value }) => value === values.country)?.states ||
            []
          }
          disabled={isFetchingCountry}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastField.TextInput legend='City' name='city' />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastField.TextInput legend='Postal Code' name='postcode' />
      </Grid>
    </Grid>
  );
};
