import { IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { styled } from '.';

export const StyledPanelDetailContainer = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isOpen' && props !== 'fixedWidth',
})<{ isOpen?: boolean; fixedWidth?: number }>(
  ({ isOpen, fixedWidth, theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    background: 'white',
    boxShadow: '0px 1px 6px #160E4D26',
    transition: 'width ease .15s',
    top: 0,
    right: 0,
    height: '100%',
    width: 0,
    overflow: 'hidden',
    ...(isOpen && {
      width: fixedWidth || 650,
    }),
    [theme.breakpoints.down('lg')]: {
      transition: 'unset',
      maxWidth: 'calc(100% - 30px)',
      height: `calc(100% - 40px)`,
      right: 15,
      top: 20,
      borderRadius: 20,
    },
  }),
);

export const StyledOneLineText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'mobileMaxWidth',
})<{ mobileMaxWidth?: number }>(({ mobileMaxWidth, theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    ...(mobileMaxWidth && {
      maxWidth: mobileMaxWidth,
    }),
  },
}));

export const StyledLogoText = styled(Typography)(({ theme }) => ({
  color: theme.color.primaryOrange,
  fontSize: 20,
  fontWeight: 'bold',
  marginLeft: '0px !important',
  transition: 'all ease 0.2s',
  fontFamily: 'Poppins',
}));

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  'position': 'absolute',
  'marginLeft': 'auto',
  'width': theme.spacing(2),
  'height': theme.spacing(2),
  'right': theme.spacing(2),
  'top': theme.spacing(2),
  'background': theme.bg.midPurple,
  'color': theme.color.violet900,
  '& svg': {
    fontSize: '1rem !important',
  },
}));

export const StyledFilterContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    '& > div': {
      display: 'none',
    },
  },
}));
