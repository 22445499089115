import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import themes, { Theme } from '~/themes';
import { StyledContainer, StyledContent, StyledTitle } from './styles';

interface IChartContainer {
  title?: string;
  sxTitle?: SxProps<Theme>;
  sxContent?: SxProps<Theme>;
  sxContainer?: SxProps<Theme>;
  titleDescription?: ReactNode;
}

const ChartContainer: React.FC<IChartContainer> = ({
  title,
  children,
  sxTitle = {},
  sxContent = {},
  sxContainer = {},
  titleDescription,
}) => (
  <StyledContainer
    sx={{
      height: 300,
      boxSizing: 'border-box',
      [themes.breakpoints.down('md')]: {
        height: '100%',
      },
      ...sxContainer,
    }}
  >
    {title && (
      <StyledTitle sx={sxTitle}>
        {title}
        {!!titleDescription && titleDescription}
      </StyledTitle>
    )}
    <StyledContent sx={sxContent}>{children}</StyledContent>
  </StyledContainer>
);

export default ChartContainer;
