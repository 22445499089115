import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Button, { LinkButton } from '~/components/common/Button';
import { DrawerTabs } from '~/components/common/Tabs';
import { isObjectEqual, getCountryCodeFromPhone } from '~/utils/common';
import {
  DriverProfileSchema,
  DriverVehiclesSchema,
} from '~/utils/schema/driver';
import { ICustomerSupport, VehicleType } from '~/models/driver';
import { fromQueryString, omitFromQueryString } from '~/utils/queryString';
import { alertParams, showAlert } from '~/components/common/Alert';
import { IAlert } from '~/components/common/Alert/Alert';
import { AdminOptionTabs } from '~/models/adminOptions';
import themes, { colors, styled } from '~/themes';
import Tutorial from '~/components/common/Tutorial';
import { TutorialData } from '~/constants/tutorial';
import { cloneDeep, omit } from 'lodash';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { useAuth } from '~/providers/AuthProvider';
import { queryClient } from '~/lib/react-query';
import { StyledCloseButton } from '~/themes/style';
import { useKeyDow } from '~/hooks/useKeyDown';
import { formatDateTimeUTC } from '~/utils/formatter';
import { useFirstLoadingDetection } from '~/hooks';
import Vehicles from './components/Vehicles';
import Profile from './components/Profile';
import { DriverAvatar } from './components/DriverAvatar';
import { Timeline } from './components/Timeline';
import {
  driverSettingsQueryKeys,
  useDeleteDriver,
  useGetDriverDetailQuery,
  useResendInvitationDriver,
  useUpdateDriver,
} from '../apis';
import { IDriver } from '../types';
import {
  DRIVER_DOCS,
  DRIVER_DRAWER_TABS,
  DRIVER_DRAWER_TAB_VALUE,
} from '../constants';
import Document from './components/Document';

const StyledDetailContainer = styled(Stack)(() => ({
  'position': 'relative',
  'height': '100%',
  'flexDirection': 'column',
  'boxSizing': 'border-box',
  '.save-change-btn': {
    'position': 'absolute',
    'bottom': 0,
    'left': 0,
    'borderRadius': 0,
    'minHeight': 50,
    '&.loading': {
      opacity: 1,
      background: '#FED1C7 !important',
    },
  },
}));

interface IDriverDetailProps {}

export const DriverDetail: React.FC<IDriverDetailProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, getTooltipOpen, updateUserTooltip } = useAuth();

  const { search, page, sort, id: driverId } = fromQueryString(location.search);
  const [tab, setTab] = useState(DRIVER_DRAWER_TAB_VALUE.PROFILE);

  const { data, isFetching } = useGetDriverDetailQuery({
    id: driverId,
  });

  const isFirstLoading = useFirstLoadingDetection([isFetching]);

  const { mutate: updateDriver, isLoading: isLoadingUpdate } = useUpdateDriver({
    onSuccess: () => {
      queryClient.invalidateQueries(
        driverSettingsQueryKeys.getDriverDetail({ id: driverId }),
      );
      queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
      showAlert(alertParams.success);
    },
  });

  const { mutate: resendInvitation } = useResendInvitationDriver({
    onSuccess: () => {
      showAlert({
        ...alertParams.success,
        title: 'Resent Invitation Successfully',
        description:
          'The invitation SMS has been resent to the driver’s phone.',
      });
    },
  });

  const { mutate: deleteDriverMutation } = useDeleteDriver({
    onSuccess: () => {
      queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
      showAlert({
        ...alertParams.success,
        title: 'Driver was deleted successfully',
        onOk: () => {
          navigate(`/setting/${AdminOptionTabs.Drivers}`);
        },
        onCancel: () => {
          navigate(`/setting/${AdminOptionTabs.Drivers}`);
        },
      });
    },
  });

  const handleChangeTab = useCallback((value: string) => {
    setTab(value);
  }, []);

  useEffect(() => {
    handleChangeTab(DRIVER_DRAWER_TAB_VALUE.PROFILE);
  }, [driverId]);

  const onDeleteAlert = useCallback((id: string) => {
    let params: IAlert = {
      ...alertParams.warning,
      title: 'Warning',
      cancelText: 'Cancel',
      okText: 'Yes, Delete',
      onOk: () => deleteDriverMutation({ id }),
    };
    params = {
      ...params,
      description:
        'You are about to delete this driver. This will affect tasks with the driver assigned. Are you sure you want to continue?',
    };
    showAlert(params);
  }, []);

  const checkFormChange = (formData, resData) => {
    if (
      formData.customer_support === resData.customer_support &&
      formData.customer_support === ICustomerSupport.ContactAlternativePhone
    ) {
      return isObjectEqual(formData, resData);
    }
    return isObjectEqual(
      omit(formData, ['alternative_phone']),
      omit(resData, ['alternative_phone']),
    );
  };

  const driverInfoComponent = useMemo(
    () => (
      <Box display='flex' flexDirection='column' marginLeft={1} flex={1}>
        <Typography
          variant='subtitle1'
          style={{ minHeight: 28 }}
          color={colors.violet900}
          data-testid='display-name-driver-detail'
        >
          {data?.display_name}
        </Typography>
        <div>
          {data?.status === 'pending' && (
            <>
              <LinkButton
                onClick={(e) => {
                  resendInvitation({ id: data?.id });
                  e.stopPropagation();
                }}
              >
                Resend Invitation
              </LinkButton>
              <span role='presentation'>{'  |  '}</span>
            </>
          )}
          <LinkButton
            onClick={(e) => {
              onDeleteAlert(data?.id);
              e.stopPropagation();
            }}
          >
            Delete
          </LinkButton>
        </div>
      </Box>
    ),
    [data],
  );

  const tabComponent = useMemo(
    () => (
      <Tutorial
        isOpen={driverId && getTooltipOpen(TutorialData.ManageDriver.id)}
        onClose={() =>
          updateUserTooltip({
            [TutorialData.ManageDriver.id]: true,
          })
        }
        delay={300}
        {...TutorialData.ManageDriver}
      >
        <DrawerTabs
          tabs={DRIVER_DRAWER_TABS}
          value={tab}
          onChange={handleChangeTab}
          lowerCaseLabel
          variant='standard'
        />
      </Tutorial>
    ),
    [tab, data, search, page, sort, driverId, account],
  );

  const tabContentComponent = useMemo(() => {
    if (isFirstLoading)
      return (
        <Stack alignItems='center'>
          <CircularProgress size={20} />
        </Stack>
      );
    switch (tab) {
      case DRIVER_DRAWER_TAB_VALUE.PROFILE:
        return <Profile />;
      case DRIVER_DRAWER_TAB_VALUE.VEHICLES:
        return <Vehicles />;
      case DRIVER_DRAWER_TAB_VALUE.DOCS:
        return <Document />;
      case DRIVER_DRAWER_TAB_VALUE.TIMELINES:
        return <Timeline />;
      default:
        return <Profile />;
    }
  }, [tab, isFirstLoading]);

  const handleSubmit = (d: any) => {
    const values: IDriver = cloneDeep(d);
    switch (tab) {
      case DRIVER_DRAWER_TAB_VALUE.VEHICLES:
        Object.keys(values.vehicle).forEach((k) => {
          if (!values.vehicle[k]) {
            values.vehicle[k] = null;
          }
        });
        updateDriver({
          vehicle: {
            ...values.vehicle,
            type: VehicleType.CAR,
          },
          id: data?.id,
        });
        break;
      case DRIVER_DRAWER_TAB_VALUE.PROFILE:
      default:
        values.id = data?.id;
        if (values.dob) {
          values.dob = formatDateTimeUTC(values.dob, 'YYYY-MM-DD');
        }

        Object.keys(values.address).forEach((k) => {
          if (!values.address[k]) {
            values.address[k] = null;
          }
        });
        if (
          values.customer_support === ICustomerSupport.ContactAlternativePhone
        ) {
          values.alternative_phone = parsePhoneNumber(
            values.alternative_phone,
            values.alternative_phone_country_code as CountryCode,
          ).number;
        } else {
          delete values.alternative_phone;
        }

        values.email = values.email ? values.email : null;
        values.dob = values.dob ? values.dob : null;
        delete values.vehicle;
        delete values.driverDocParams;
        updateDriver({
          ...values,
          id: data?.id,
        });
        break;
    }
  };

  const handleCloseDriverDetail = () => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, 'id'),
    });
  };

  useKeyDow({
    targetKey: 'Escape',
    disabled: !driverId,
    callback: handleCloseDriverDetail,
  });

  const validationSchema = useMemo(() => {
    switch (tab) {
      case DRIVER_DRAWER_TAB_VALUE.VEHICLES:
        return DriverVehiclesSchema;
      case DRIVER_DRAWER_TAB_VALUE.PROFILE:
      default:
        return DriverProfileSchema;
    }
  }, [tab]);

  const initialValues = useMemo(
    () => ({
      email: data?.email,
      first_name: data?.first_name,
      last_name: data?.last_name,
      display_name: data?.display_name,
      phone: data?.phone,
      dob: data?.dob,
      address: data?.address,
      customer_support:
        data?.customer_support || ICustomerSupport.ContactDriverPhone,
      alternative_phone: data?.alternative_phone,
      alternative_phone_country_code: 'CA',
      ...(data?.alternative_phone && {
        alternative_phone: parsePhoneNumber(data?.alternative_phone)
          .nationalNumber,
        alternative_phone_country_code: getCountryCodeFromPhone(
          data?.alternative_phone,
        ),
      }),
      vehicle: data?.vehicle,
      driverDocParams: DRIVER_DOCS.reduce(
        (rs, { id }) => ({
          ...rs,
          [id]: data[id],
        }),
        { id: data?.id },
      ),
    }),
    [tab, data],
  );

  return (
    <StyledDetailContainer data-testid='driver-detail-container'>
      {!!driverId && (
        <StyledCloseButton onClick={handleCloseDriverDetail} size='large'>
          <CloseIcon />
        </StyledCloseButton>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values, isValid }) => (
          <>
            <Box display='flex' p={2}>
              <DriverAvatar initAvatar={data?.avatar} />
              {driverInfoComponent}
            </Box>
            {tabComponent}
            <Stack
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '100%',
                padding: themes.spacing(2),
              }}
              className='customized-scrollbar'
            >
              <Form>
                {tabContentComponent}
                {![
                  DRIVER_DRAWER_TAB_VALUE.DOCS,
                  DRIVER_DRAWER_TAB_VALUE.TIMELINES,
                ].includes(tab) && (
                  <Button
                    className='save-change-btn'
                    type='submit'
                    fullWidth
                    loading={isLoadingUpdate}
                    disabled={
                      checkFormChange(values, initialValues) || !isValid
                    }
                  >
                    Save Changes
                  </Button>
                )}
              </Form>
            </Stack>
          </>
        )}
      </Formik>
    </StyledDetailContainer>
  );
};
