import {
  useContext,
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';

export interface UploadImageContextProps {
  images: string[];
  defaultImages?: string[];
  openUploadDialog: boolean;
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  onToggleUploadDialog: () => void;
  onSaveImage: (formData?: FormData | null, blob?: Blob) => void;
}

export const UploadImageContext = createContext({} as UploadImageContextProps);
export type UploadImageProviderProps = {
  defaultImages?: string[];
  onSaveImage: (formData?: FormData | null, blob?: Blob) => void;
};

const UploadImageProvider: React.FC<UploadImageProviderProps> = ({
  children,
  defaultImages = [],
  onSaveImage,
}) => {
  const [images, setImages] = useState<string[]>([]);
  const [openUploadDialog, setIsOpenUploadDialog] = useState(false);

  useEffect(() => {
    setImages(defaultImages);
  }, [defaultImages]);

  const onToggleUploadDialog = useCallback(
    () => setIsOpenUploadDialog((v) => !v),
    [],
  );

  const values = useMemo(
    () => ({
      openUploadDialog,
      images,
      defaultImages,
      onSaveImage,
      setImages,
      onToggleUploadDialog,
    }),
    [openUploadDialog, defaultImages, images],
  );

  return (
    <UploadImageContext.Provider value={values}>
      {children}
    </UploadImageContext.Provider>
  );
};

const useUploadImageContext = () => {
  const context = useContext(UploadImageContext);
  if (!context) {
    throw new Error(
      'Components cannot be rendered outside the UploadImageContext',
    );
  }
  return context;
};

export { UploadImageProvider, useUploadImageContext };
