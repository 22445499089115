import { Typography } from '@mui/material';
import { styled } from '~/themes';

export const StyledPlatformIcon = styled('img')(() => ({
  borderRadius: '50%',
  width: 35,
  height: 35,
}));

export const StyledConnectedIcon = styled('img')(() => ({
  width: 15,
  height: 15,
}));

export const StyledStatusText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'enabled',
})<{ enabled: boolean }>(({ enabled, theme }) => ({
  color: theme.color.red,
  ...(enabled && {
    color: theme.color.green,
  }),
}));
