import { Box, Stack, ThemeProvider, Typography } from '@mui/material';
import { styled } from '~/themes';
import BackgroundImg from '~/assets/images/background/unified-login-background.jpg';
import LogoIcon from '~/assets/images/logo/logo.svg';
import { TextLink } from '~/components/common/TextLink';
import { Icon, theme as unifiedTheme } from 'bs-unified-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { isMobile } from 'react-device-detect';

type UnifiedPublicLayoutProps = {
  children: React.ReactNode;
  hasButtonBack?: boolean;
  onBack?: () => void;
};

export const StyledContainer = styled(Stack)(() => ({
  backgroundImage: `url('${BackgroundImg}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100%',
  display: 'flex',
  alignItems: 'end',
}));

export const StyledSectionWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 664,
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  color: unifiedTheme.foreground.black,
  fontFamily: 'Lato',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const StyledSection = styled(Box)(({ theme }) => ({
  'height': '100%',
  'padding': 175,
  'paddingBottom': 'unset',
  [theme.breakpoints.down('lg')]: {
    padding: '20%',
    paddingTop: '30%',
    paddingBottom: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '30%',
  },
  'h1': {
    fontSize: 20,
    fontWeight: 'bold',
  },
  '& .headerContainer': {
    minHeight: 75,
    marginTop: 38,
    marginBottom: 16,
  },
}));

export const StyledBackButton = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  left: '32px',
  top: '17px',
  cursor: 'pointer',
}));

export const StyledFooterText = styled(Typography)(() => ({
  fontSize: 14,
  textAlign: 'left',
  marginBottom: '40px',
  justifyContent: 'center',
  display: 'flex',
}));

export const UnifiedPublicLayout: React.FC<UnifiedPublicLayoutProps> = ({
  children,
  hasButtonBack,
  onBack,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);

  const isResetPasswordSuccessPage =
    location.pathname === '/new-password' &&
    !searchQueries?.email &&
    !searchQueries?.code;

  const isShowBackButton =
    (location.pathname !== '/login' && !isResetPasswordSuccessPage) ||
    hasButtonBack;

  return (
    <ThemeProvider theme={unifiedTheme}>
      <StyledContainer
        sx={{
          '& input': {
            fontSize: isMobile ? 16 : 'inherit', // increase font size to avoid auto zoom animation
            touchAction: 'none', // disable zoom action
          },
        }}
      >
        <StyledSectionWrapper>
          <StyledSection>
            <img
              src={LogoIcon}
              alt='logo'
              height={25}
              style={{ marginLeft: '-2px' }}
            />
            <Stack
              style={{
                flexDirection: 'row-reverse',
                justifyContent: 'center',
              }}
            >
              {children}
            </Stack>
          </StyledSection>
          {isShowBackButton && (
            <StyledBackButton
              onClick={() => (onBack ? onBack() : navigate('/login'))}
            >
              <Icon name='arrow-left-s' color='#33363D' size={16} />
              <Typography
                sx={{
                  color: unifiedTheme.foreground.black,
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: '24px',
                }}
              >
                {hasButtonBack ? 'Back' : 'Back to login'}
              </Typography>
            </StyledBackButton>
          )}

          <StyledFooterText>
            Don’t have an account? &nbsp;
            <TextLink color={unifiedTheme.accent.blue} to='/register'>
              Create an account
            </TextLink>
          </StyledFooterText>
        </StyledSectionWrapper>
      </StyledContainer>
    </ThemeProvider>
  );
};
