/* eslint-disable no-underscore-dangle */
import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addToQueryString, fromQueryString } from '~/utils/queryString';
import {
  IBillingFrequency,
  IPlan,
  IPlanTier,
  SetupPlanStep,
} from '~/models/plan';
import { Form, Formik } from 'formik';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { FastField, Field } from '~/components/common/Formik';
import Button, { LinkButton } from '~/components/common/Button';
import themes, { styled } from '~/themes';
import { useGetPlans } from '~/services/api/plan';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { StripeCardForm } from '~/components/shared/StripeCardForm';
import { alertParams, showAlert } from '~/components/common/Alert';
import { useActiveAccount } from '~/services/api/auth';
import { queryClient } from '~/lib/react-query';
import { useCreateOrgMutation } from '~/services/api/org';
import { memoryStorage } from '~/utils/localStorage';
import CheckBox from '~/components/common/CheckBox';
import { formatPrice } from '~/utils/formatter';
import Tooltip from '~/components/common/Tooltip';
import { DEFAULT_DOWN_SIZE, ORG_CATEGORY_OPTIONS } from '~/constants/common';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { NumberOfTaskOptions, agreeTermsURL } from '~/constants/auth';
import { UserOnboardingSchema } from '~/utils/schema/auth';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import helpIcon from '~/assets/images/icons/help.svg';

type UserSelectedPlanProps = {
  openDialogSelectPlan?: boolean;
  setOpenDialogSelectPlan?: (v: boolean) => void;
};

export const StyledPlanTextValue = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  fontSize: 16,
  fontWeight: 600,
}));
export const UserSelectedPlan: React.FC<UserSelectedPlanProps> = ({
  setOpenDialogSelectPlan,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search, {
    arrayFormat: 'none',
  });
  const frequency = (searchQueries.frequency ||
    IBillingFrequency.Month) as IBillingFrequency;
  const setupPlanStep = (searchQueries.step ||
    SetupPlanStep.PreviewSummary) as SetupPlanStep;
  const priceId = searchQueries.price_id as string;
  const handleUpdateLocation = (searchObj: any) => {
    navigate(
      {
        pathname: location.pathname,
        search: addToQueryString(location.search, searchObj),
      },
      { replace: true },
    );
  };
  const [isCheckedTerm, setIsCheckedTerm] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updatePaymentRef = useRef(null);
  const { data: plans, isFetching: isFetchingPlans } = useGetPlans({});
  const planSelect = useMemo(
    () =>
      isFetchingPlans
        ? { metadata: { title: '', frequency: '' } }
        : plans.find((p) => p.price_id === priceId),
    [plans, isFetchingPlans, priceId],
  ) as IPlan;
  const { mutate: activeAccountMutation, isLoading: isLoadingActiveAccount } =
    useActiveAccount({
      onSuccess: () => {
        channel.postMessage({
          type: BroadcastEvents.RefreshOtherPageWhenActiveAccount,
        });
        navigate(0);
      },
      onError: () => {
        if (planSelect.tier !== IPlanTier.Free) {
          queryClient.refetchQueries(['getBillingInfo']);
        }
      },
    });
  const { mutate: createOrgMutation, isLoading: isLoadingCreateOrg } =
    useCreateOrgMutation({
      onSuccess: (resp: any) => {
        memoryStorage.setOrgId(resp.id);
        if (planSelect.tier === IPlanTier.Free) {
          activeAccountMutation({
            price_id: 'price_free',
            tier: planSelect.tier,
            quantity: 1,
          });
        } else {
          handleUpdateLocation({
            org_id: resp.id,
            step: SetupPlanStep.AddCard,
            org: resp.name,
          });
        }
      },
    });
  const getGraduatedPriceId = (val: IPlanTier, country: string) => {
    if (val === IPlanTier.Free) return null;
    return (
      plans
        // eslint-disable-next-line no-underscore-dangle
        .filter((p) => p.type_ === 'graduated' && p.country === country)
        .find((p) => p.tier === val)?.price_id
    );
  };
  const getBillingFrequencyPrice = (v: IBillingFrequency) => {
    const price = plans.find(
      (p) =>
        // eslint-disable-next-line no-underscore-dangle
        p.tier === planSelect.tier && p.frequency === v && p.type_ === 'plan',
    )?.metadata?.price_num;
    return formatPrice(price);
  };
  const handleSubmit = (values: any) => {
    if (setupPlanStep === SetupPlanStep.PreviewSummary) {
      const phone = values.phone?.toString();
      createOrgMutation({
        display_name: values.full_name,
        name: values.org_name,
        category: values?.category || 'N/A',
        ...(values.job_title && {
          job_title: values.job_title,
        }),
        ...(phone && {
          phone: parsePhoneNumber(phone, values.country_code as CountryCode)
            .number,
        }),
        number_of_delivery_tasks:
          NumberOfTaskOptions.find(
            (e) =>
              e.value === searchQueries?.number_of_delivery_tasks?.toString(),
          )?.apiValue || 'N/A',
      });
    }
  };
  const renderOrgForm = () => (
    <Stack>
      <FastField.TextInput name='org_name' legend='Name of organization' />
      <Field.Select
        name='category'
        options={ORG_CATEGORY_OPTIONS}
        legend='Business Category'
        onChange={(e: any) => {
          handleUpdateLocation({
            category: e.target.value,
          });
        }}
      />
      <Stack direction='row' alignItems='center'>
        <Field.Select
          name='number_of_delivery_tasks'
          endAdornment={
            <InputAdornment
              position='end'
              sx={{ position: 'absolute', right: -30 }}
            >
              <Tooltip
                title={
                  <Typography sx={{ whiteSpace: 'break-spaces' }}>
                    {
                      ' Select the approximate\n number of deliveries you\n expect to make on a monthly\n basis. This will help us\n recommend the right plan for\n your needs.'
                    }
                  </Typography>
                }
                arrow
              >
                <img
                  src={helpIcon}
                  alt='help-icon'
                  style={{
                    width: 20,
                    height: 20,
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </InputAdornment>
          }
          legend='Number of delivery tasks'
          options={NumberOfTaskOptions}
          onChange={(e: any) => {
            handleUpdateLocation({
              number_of_delivery_tasks: e.target.value,
            });
          }}
        />
      </Stack>
    </Stack>
  );
  const renderBillingFrequency = () => (
    <Stack spacing={1}>
      <Typography>Billing Frequency</Typography>
      <GroupRadioButton
        id='billing-frequency'
        value={frequency}
        radioOptionSxProps={{
          '&.radio-option-selected': {
            background: themes.bg.midPurple,
          },
          'background': themes.bg.lightPurple,
        }}
        onChange={(e) => {
          const priceIdSelect = plans.find(
            (p) =>
              p.type_ === 'plan' &&
              p.tier === planSelect.tier &&
              p.frequency === e.target.value,
          )?.price_id;
          handleUpdateLocation({
            frequency: e.target.value,
            price_id: priceIdSelect,
          });
        }}
        options={[
          {
            value: IBillingFrequency.Year,
            label: (
              <Stack direction='row' justifyContent='space-between'>
                <span>Annually (20% off)</span>
                <span>
                  {getBillingFrequencyPrice(IBillingFrequency.Year)}/mo
                </span>
              </Stack>
            ),
          },
          {
            value: IBillingFrequency.Month,
            label: (
              <Stack direction='row' justifyContent='space-between'>
                <span>Monthly</span>
                <span>
                  {getBillingFrequencyPrice(IBillingFrequency.Month)}/mo
                </span>
              </Stack>
            ),
          },
        ]}
      />
    </Stack>
  );
  const renderPreviewPlanInfo = () => (
    <Stack
      sx={{
        background: themes.bg.lightPurple,
        p: 2,
        borderRadius: '10px',
        my: 2,
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Box>
          <Typography fontWeight={500} fontSize={16}>
            {planSelect.metadata.title}{' '}
          </Typography>
          <Typography>
            This plan includes {planSelect.metadata.numbers_of_task} tasks/mo
          </Typography>
        </Box>
        <LinkButton
          sx={{ mb: 2 }}
          onClick={() => setOpenDialogSelectPlan(true)}
        >
          Change Plan
        </LinkButton>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Typography>
        {planSelect.tier === IPlanTier.Free
          ? 'Please keep in mind that you are not allowed to have additional tasks on the Stater plan.'
          : 'If you purchase additional tasks, they will be billed at the end of your chosen billing cycle.'}
      </Typography>
    </Stack>
  );
  const renderPreviewPlanSummary = () => (
    <Stack
      sx={{
        background: themes.bg.lightPurple,
        p: 2,
        borderRadius: '10px',
      }}
    >
      <StyledPlanTextValue>Summary</StyledPlanTextValue>
      <Typography
        sx={{
          'display': 'flex',
          'mt': 1,
          '& > li': {
            'ml': 0.5,
            '&::marker': {
              fontSize: 12,
            },
          },
          '& > li > span': {
            position: 'relative',
            left: -10,
          },
        }}
      >
        {planSelect.metadata.title}
        {' Plan'}
        {planSelect.tier === IPlanTier.Free ? (
          ''
        ) : (
          <li>
            <span>
              {planSelect.frequency === IBillingFrequency.Month
                ? 'billed monthly'
                : 'billed annually'}
            </span>
          </li>
        )}
      </Typography>
      <Typography mt={0.5}>
        {planSelect?.metadata?.key_features?.length
          ? planSelect?.metadata?.key_features[0]
          : '-'}
      </Typography>
      <Typography mt={0.5}>
        {planSelect.metadata.numbers_of_task} tasks/mo
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack direction='row' justifyContent='space-between'>
        <StyledPlanTextValue>Due today</StyledPlanTextValue>
        <StyledPlanTextValue>
          {planSelect.frequency === IBillingFrequency.Year
            ? formatPrice(planSelect.metadata.price_num * 12)
            : formatPrice(planSelect.metadata.price_num, {
                displayZeroNumber: true,
              })}
        </StyledPlanTextValue>
      </Stack>
    </Stack>
  );
  const renderAddCard = () => (
    <>
      <StripeCardForm
        ref={updatePaymentRef}
        onBeforeConfirm={() => setLoadingUpdate(true)}
        onError={(error) => {
          setLoadingUpdate(false);
          if (error?.type !== 'validation_error') {
            showAlert({
              ...alertParams.warning,
              title: 'Warning',
              description:
                'Could not initialize payment info, please try again.',
              okText: 'Close',
            });
          }
        }}
        onSuccess={(resp) => {
          let country = resp[0]?.billing_details?.address?.country;
          if (!country) {
            showAlert(errorAlert('Can not get country after add card.'));
            return;
          }
          country = country === 'CA' ? country : 'US';
          const priceIdByCountry =
            plans.find(
              (p) =>
                p.tier === planSelect.tier &&
                p.frequency === planSelect.frequency &&
                p.country === country &&
                // eslint-disable-next-line no-underscore-dangle
                p.type_ === 'plan',
            )?.price_id || 'price_free';
          activeAccountMutation({
            price_id: priceIdByCountry,
            tier: planSelect.tier,
            quantity: 1,
            ...(getGraduatedPriceId(planSelect.tier, country) && {
              graduated_price: getGraduatedPriceId(planSelect.tier, country),
            }),
          });
          setLoadingUpdate(false);
        }}
      />
      <Typography sx={{ color: themes.color.gray900, mt: 2, fontSize: 12 }}>
        By providing your card information, you allow Breadstack Delivery to
        charge your card for future payments in accordance with{' '}
        <a href={agreeTermsURL} target='_blank' rel='noreferrer'>
          their terms
        </a>
        .
      </Typography>
    </>
  );
  const renderPreviewPlan = () => (
    <Grid container columnSpacing={6}>
      <Grid item xs={12} md={7}>
        {setupPlanStep === SetupPlanStep.AddCard ? (
          renderAddCard()
        ) : (
          <>
            {renderOrgForm()}
            {renderPreviewPlanInfo()}
            {planSelect.tier !== IPlanTier.Free && renderBillingFrequency()}
            <CheckBox
              checked={isCheckedTerm}
              onChange={(e) => setIsCheckedTerm(e.target.checked)}
            >
              <>
                I agree to all the{' '}
                <a
                  href={agreeTermsURL}
                  target='_blank'
                  rel='noreferrer'
                  style={{ color: themes.color.primaryOrange, fontSize: 14 }}
                >
                  Agreements and Terms
                </a>
              </>
            </CheckBox>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {renderPreviewPlanSummary()}
      </Grid>
      <Grid item xs={12} md={7} sx={{ mt: 2 }}>
        {setupPlanStep === SetupPlanStep.AddCard ? (
          <Stack direction='row' spacing={1} pb={5}>
            <Button
              fullWidth
              buttonType='default'
              onClick={() =>
                handleUpdateLocation({
                  step: SetupPlanStep.PreviewSummary,
                })
              }
            >
              Back
            </Button>
            <Button
              fullWidth
              loading={loadingUpdate || isLoadingActiveAccount}
              type='button'
              onClick={() => updatePaymentRef.current.confirmStripeSetup()}
            >
              Start Subscription
            </Button>
          </Stack>
        ) : (
          <Button
            sx={{
              mt: 1,
              mb: 3,
              [themes.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
                mb: 0,
              },
            }}
            fullWidth
            type='submit'
            disabled={!isCheckedTerm}
            loading={isLoadingCreateOrg}
          >
            Next
          </Button>
        )}
      </Grid>
    </Grid>
  );
  return (
    <Formik
      initialValues={{
        org_name: searchQueries?.org_name as string,
        number_of_delivery_tasks:
          searchQueries?.number_of_delivery_tasks?.toString(),
        category: searchQueries?.category || '',
        country_code: searchQueries?.country_code || 'CA',
        job_title: searchQueries?.job_title || '',
        phone: searchQueries?.phone || '',
        full_name: searchQueries?.full_name || '',
      }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={UserOnboardingSchema}
    >
      {() => (
        <Form>
          <Typography variant='h1'>
            {setupPlanStep === SetupPlanStep.AddCard
              ? 'Start Your Subscription'
              : 'Build Your Plan'}
          </Typography>
          {renderPreviewPlan()}
        </Form>
      )}
    </Formik>
  );
};
