import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '~/themes/index';

const style = (theme: Theme) =>
  createStyles({
    childCollapsible: {
      padding: theme.spacing(1),
    },
    wrapHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: theme.color.black,
      fontSize: 14,
    },
  });

export const Accordion = withStyles((theme: Theme) => ({
  root: {
    'color': theme.color.black,
    'minHeight': 35,
    'borderRadius': theme.spacing(3),
    '&:not(:first-child)': {
      margin: theme.spacing(2, 0, 0, 0),
    },
    '&:before': {
      display: 'none !important',
    },
    '&$expanded': {
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    'alignItems': 'center',
    'minHeight': 35,
    'background': theme.bg.midPurple,
    'borderRadius': theme.spacing(3),
    'boxSizing': 'border-box',
    'color': theme.color.black,
    // transition: 'border-color 225ms ease-in-out',
    '&.highlight': {
      background: 'white',
    },
    '&$expanded': {
      'minHeight': 35,
      'background': theme.bg.midPurple,
      '&.highlight': {
        background: 'white',
      },
    },
  },
  content: {
    'margin': theme.spacing(1, 0),
    '&$expanded': {
      margin: theme.spacing(1, 0),
    },
  },
  expanded: {},
  expandIcon: {
    color: '#140D49',
  },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

export const ChildAccordion = withStyles((theme: Theme) => ({
  root: {
    'minHeight': 35,
    'borderRadius': theme.spacing(3),
    '&:before': {
      display: 'none !important',
    },
    '&$expanded': {
      background: theme.bg.white,
      border: `1px solid ${theme.bg.midPurple}`,
    },
  },
  expanded: {},
}))(MuiAccordion);

export const ChildAccordionSummary = withStyles((theme: Theme) => ({
  root: {
    'alignItems': 'center',
    'minHeight': 35,
    'background': theme.bg.white,
    'borderRadius': theme.spacing(3),
    'borderBottom': `1px solid ${theme.bg.midPurple}`,
    'boxSizing': 'border-box',
    'lineHeight': 1,
    'transition': 'border-color 225ms ease-in-out',
    '&$expanded': {
      minHeight: 35,
      background: theme.bg.white,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  content: {
    'margin': theme.spacing(1, 0),
    '&$expanded': {
      margin: theme.spacing(1, 0),
    },
  },
  expanded: {},
  expandIcon: {
    color: '#140D49',
  },
}))(MuiAccordionSummary);

export const ChildAccordionDetails = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

export default style;
