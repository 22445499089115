import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { AddPostalCodeSchema } from '~/utils/schema/integration';
import { FastField, Field } from '~/components/common/Formik';
import { Grid } from '@mui/material';
import {
  useCreateHubZonesMutation,
  useUpdateHubZonesMutation,
} from '~/services/api/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { Zone } from '~/models/stores';
import { FeeTypeElement } from '../DrawnZone/DialogAddZone';
import { DeliveryZoneSets } from '../../types';

interface DialogAddZoneIProps {
  open: boolean;
  onClose: () => void;
  zoneSelected?: Zone;
}

export const DialogAddPostalCode: React.FC<DialogAddZoneIProps> = ({
  open,
  onClose,
  zoneSelected,
}) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const isEdit = !!zoneSelected;

  const {
    mutate: createHubZonesMutation,
    isLoading: isLoadingCreateHubZonesMutation,
  } = useCreateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const {
    mutate: updateHubZonesMutation,
    isLoading: isLoadingUpdateHubZonesMutation,
  } = useUpdateHubZonesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const onSubmit = (values: any) => {
    const { postal_code, delivery_fee_type, delivery_fee, delivery_minimum } =
      values;

    const params = {
      action: isEdit ? 'update' : 'create',
      parameters: {
        name: postal_code,
        integration_id: search.id,
        postal_code,
        enable: true,
        delivery_fee,
        delivery_fee_type,
        delivery_minimum,
        area_type: DeliveryZoneSets.PostalCode,
        ...(isEdit && {
          _id: zoneSelected.id,
        }),
      },
    };
    if (isEdit) {
      return updateHubZonesMutation(params);
    }
    return createHubZonesMutation(params);
  };

  return (
    <Modal
      title={isEdit ? 'Edit Postal Code' : 'Add Postal Code'}
      open={open}
      onClose={onClose}
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create',
          loading:
            isLoadingCreateHubZonesMutation || isLoadingUpdateHubZonesMutation,
        },
      ]}
    >
      <Formik
        initialValues={
          isEdit
            ? zoneSelected
            : {
                postal_code: '',
                delivery_fee: 0,
                delivery_minimum: 0,
                delivery_fee_type: 'fixed_amount',
              }
        }
        onSubmit={onSubmit}
        validationSchema={AddPostalCodeSchema}
        enableReinitialize
      >
        {({ values: { delivery_fee_type } }) => (
          <Form
            id='create'
            onKeyPress={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FastField.TextInput name='postal_code' legend='Postal Code' />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Field.TextInput
                  type='number'
                  name='delivery_fee'
                  legend='Delivery Fee'
                  suffix={
                    <Field.ElementSelect
                      name='delivery_fee_type'
                      elementSelect={(o: boolean) => (
                        <FeeTypeElement open={o} type={delivery_fee_type} />
                      )}
                      options={[
                        {
                          label: 'Dollar Value',
                          value: 'fixed_amount',
                        },
                        {
                          label: 'Percentage',
                          value: 'percentage',
                        },
                      ]}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FastField.TextInput
                  type='number'
                  name='delivery_minimum'
                  legend='Order Minimum ($)'
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
