import Modal from '~/components/common/Modal';
import DriverIcon from '~/assets/images/icons/driver-white.svg';
import {
  useCreateTeamMutation,
  useEditTeamMutation,
} from '~/services/api/team';
import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { FastField } from '~/components/common/Formik';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '~/themes';
import { AddTeamSchema } from '~/utils/schema/team';
import { queryKeys } from '~/services/api/queryKeys';
import { queryClient } from '~/lib/react-query';
import { alertParams, showAlert } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { ITeam } from '~/models/shared';
import { formatPhoneNumber } from '~/utils/formatter';
import Tooltip from '~/components/common/Tooltip';
import { Select } from '~/components/common/Select';
import { useMemo } from 'react';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import useStyles from './style';
import { useGetDriverListQuery } from '../../Drivers/apis';
import { UserStatus } from '../../Users/types';

type DialogAddTeamProps = {
  open: boolean;
  onClose: () => void;
  teamEditData?: ITeam;
};

export const DialogAddTeam: React.FC<DialogAddTeamProps> = (props) => {
  const { open, onClose, teamEditData } = props;
  const classes = useStyles();

  const { data: rawData, isFetching: isLoadingDriver } = useGetDriverListQuery({
    status: UserStatus.Active,
  });

  const driverList = useMemo(() => rawData || [], [rawData]);

  const { mutate: createTeamMutation, isLoading: isLoadingCreateTeam } =
    useCreateTeamMutation({
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries([queryKeys.shared.getTeamList]);
        showAlert({
          ...alertParams.success,
          title: 'Team Added Successfully',
        });
      },
      onError: (error) => {
        onClose();
        showAlert(errorAlert(error));
      },
    });

  const { mutate: editTeamMutation, isLoading: isLoadingEditTeam } =
    useEditTeamMutation({
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries([queryKeys.shared.getTeamList]);
        showAlert({
          ...alertParams.success,
          title: 'Team Edited Successfully',
        });
      },
      onError: (error) => {
        onClose();
        showAlert(errorAlert(error));
      },
    });

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      title={teamEditData?.id ? 'Edit Team' : 'Add Team'}
      maxWidth='sm'
      fullWidth
      actions={[
        {
          title: 'Cancel',
          onClick: onClose,
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create-team',
          loading: isLoadingCreateTeam || isLoadingEditTeam,
        },
      ]}
    >
      <Stack direction='row' fontWeight={600} alignItems='center'>
        <span className={classes.icon}>
          <img src={DriverIcon} alt='team-info' />
        </span>
        Team Info
      </Stack>
      <Formik
        initialValues={
          teamEditData?.id
            ? {
                ...teamEditData,
                driver_ids: teamEditData.driver_info || [],
              }
            : {
                name: '',
                description: '',
                driver_ids: [],
                is_auto_assigned: true,
              }
        }
        validationSchema={AddTeamSchema}
        onSubmit={(values) => {
          const newValues = {
            ...values,
            driver_ids: (values.driver_ids || []).map((item) => item.id),
          };
          if (teamEditData?.id) {
            return editTeamMutation(newValues);
          }
          return createTeamMutation(newValues);
        }}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form id='create-team'>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <FastField.TextInput name='name' legend='Name' />
              </Grid>
              <Grid item xs={12}>
                <FastField.TextInput
                  name='description'
                  legend='Short Description'
                  multiline
                  rows={2}
                  className={classes.textArea}
                  sx={{
                    '& > div': {
                      borderRadius: '10px !important',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            <Stack direction='row' fontWeight={600} alignItems='center'>
              <span className={classes.icon}>
                <img src={DriverIcon} alt='driver-info' />
              </span>
              Drivers
            </Stack>
            <Typography mt={1}>
              Search for drivers and add them to your team roster.
            </Typography>
            <Grid container marginTop={1}>
              <Grid item xs={12} md={6}>
                <Select
                  id='drivers'
                  placeholder='Search'
                  noOptionsText='No driver available'
                  options={driverList
                    .filter(
                      (driver) =>
                        !values.driver_ids.find(
                          (item) => driver.id === item.id,
                        ),
                    )
                    .map((driver) => ({
                      ...driver,
                      value: driver.id,
                      label: driver.display_name,
                    }))}
                  disabled={isLoadingDriver}
                  onChange={(event) => {
                    const value =
                      event && event.target ? event.target.value : undefined;
                    const selectedValue = driverList.find(
                      (x) => x.id === value,
                    );
                    if (selectedValue) {
                      setFieldValue(
                        'driver_ids',
                        values.driver_ids.concat(selectedValue),
                      );
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                sx={{
                  minHeight: 240,
                  maxHeight: 240,
                  overflow: 'hidden',
                  background: '#F8F8FC',
                  borderRadius: '10px',
                  mt: 1,
                }}
              >
                <Stack
                  spacing={1}
                  padding={1}
                  sx={{
                    overflow: 'auto',
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                  className='customized-scrollbar'
                >
                  {values.driver_ids.map((driver) => (
                    <Stack
                      component='li'
                      value={driver.id}
                      data-testid='driver-item'
                      data-value={driver.display_name}
                      key={driver.id}
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      spacing={1}
                      border='1px solid #BFC3E0'
                      borderRadius={6}
                      sx={{ background: 'white', p: 0.5, px: 1 }}
                    >
                      <Stack
                        direction='row'
                        alignItems='center'
                        spacing={0.5}
                        gap={0.5}
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              'driver_ids',
                              values.driver_ids.filter(
                                (item) => item.id !== driver.id,
                              ),
                            );
                          }}
                          sx={{
                            width: 20,
                            height: 20,
                            color: colors.violet900,
                            fontSize: 18,
                          }}
                        >
                          <CloseIcon fontSize='inherit' />
                        </IconButton>
                        <DriverAvatar
                          name={driver.display_name}
                          avatar={driver.avatar}
                          size={25}
                        />

                        {driver.display_name?.length > 40 ? (
                          <Tooltip title={driver.display_name}>
                            <span>{`${driver.display_name.slice(
                              0,
                              40,
                            )}...`}</span>
                          </Tooltip>
                        ) : (
                          <Typography>{driver.display_name}</Typography>
                        )}
                      </Stack>
                      <Typography>{formatPhoneNumber(driver.phone)}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
