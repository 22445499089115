/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return { width: windowWidth };
};

export const useMobileViewPort = (defaultValue: number = 20) => {
  const [bottom, setBottom] = useState(defaultValue);

  useEffect(() => {
    const handleResize = () => {
      setBottom(
        Math.round(
          document.documentElement.clientHeight -
            window.visualViewport.height +
            20,
        ),
      );
    };

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return { bottom };
};
