import { Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { alertParams, showAlert } from '~/components/common/Alert';
import RegisterStepper from '~/components/common/RegisterStepper';
import PublicLayout from '~/layout/PublicLayout';
import { useResendVerificationEmail } from '~/services/api/auth';
import { fromQueryString } from '~/utils/queryString';

export const EmailSent = () => {
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);

  const emailSent = searchQueries?.email || '';

  const { mutate: sendEmailMutation, isLoading: isSendEmailLoading } =
    useResendVerificationEmail({
      onSuccess: () => {
        if (emailSent) {
          showAlert({
            ...alertParams.success,
            title: 'Email sent',
            description:
              'New verification email has been resent to your inbox.',
            okText: 'Close',
          });
        }
      },
    });

  return (
    <PublicLayout>
      <Grid container columnSpacing={6} spacing={1.5} mt={3}>
        <Grid item xs={12} md={10}>
          <RegisterStepper activeStep={1} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h1'>Verification Email Sent</Typography>
          <Typography variant='body1' mt={3}>
            Please check your inbox for a verification email from us.
          </Typography>
          <Typography variant='body1' mt={3}>
            *If you have not received the email, please check the spam box. Or
            you can choose to{' '}
            <span
              onClick={() => {
                if (!isSendEmailLoading) {
                  sendEmailMutation(emailSent as string);
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                ...(isSendEmailLoading && {
                  opacity: 0.5,
                }),
              }}
              role='presentation'
            >
              resend the email.
            </span>
          </Typography>
        </Grid>
      </Grid>
    </PublicLayout>
  );
};
