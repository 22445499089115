import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { Theme } from '~/themes';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';

import { useAuth } from '~/providers/AuthProvider';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { InviteDriverSchema } from '~/utils/schema/user';
import InviteDriverForm from './InviteDriverForm';
import { driverSettingsQueryKeys, useAddDriver } from '../apis';
import { UserRole } from '../../Users/types';

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.8),
    },
    modal: {
      width: 650,
    },
    link: {
      color: theme.color.primaryOrange,
    },
  });

interface IProps extends WithStyles<typeof style> {
  open: boolean;
  onClose: () => void;
}

const DialogInviteDriver = ({ classes, open, onClose }: IProps) => {
  const { org } = usePaymentInfo();
  const { updateOrgTooltip } = useAuth();

  const { mutate: inviteUserMutation, isLoading } = useAddDriver({
    onSuccess: () => {
      if (org?.show_invite_driver === true) {
        updateOrgTooltip({
          show_invite_driver: false,
        });
      }
      onClose();
      showAlert({
        ...alertParams.success,
        title: 'Invitation Sent Successfully',
        description: <>The invitation SMS has been sent.</>,
      });
      queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
    },
    onError: () => {
      onClose();
      updateOrgTooltip({
        show_invite_driver: false,
      });
    },
  });

  const onSubmit = ({ country_code, ...values }: any) => {
    values.role = UserRole.Driver;
    const phone = parsePhoneNumber(
      values.phone,
      country_code as CountryCode,
    ).number;
    values.phone = phone;

    inviteUserMutation(values);
  };

  return (
    <Modal
      title='Add Driver'
      open={open}
      onClose={onClose}
      className={classes.modal}
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Send Invitation via SMS',
          buttonType: 'primary',
          type: 'submit',
          form: 'invite-driver',
          loading: isLoading,
        },
      ]}
    >
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          display_name: '',
          phone: '',
          country_code: 'CA',
        }}
        onSubmit={onSubmit}
        validationSchema={InviteDriverSchema}
      >
        <Form
          id='invite-driver'
          className={classes.container}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <InviteDriverForm />
        </Form>
      </Formik>
    </Modal>
  );
};

export default withStyles(style)(DialogInviteDriver);
