import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '~/config';
import HttpClient from './client';
import {
  handleDefaultResponse,
  addAuthHeader,
  addOrgIdHeader,
  handleUnauthorizedError,
} from './interceptors';

class HttpJson extends HttpClient {
  public static create(cfg?: AxiosRequestConfig) {
    const httpJson = new HttpJson({
      baseURL: ENDPOINTS,
      timeout: 90 * 1000, // 90s
      headers: {
        'Content-Type': 'application/json',
      },
      // withCredentials: true,
      ...cfg,
    });

    httpJson.useReq('authHeader', addAuthHeader);
    httpJson.useReq('addOrgIdHeader', addOrgIdHeader);
    httpJson.useRes('defaultResponse', handleDefaultResponse);
    httpJson.useRes('unauthorizedError', null, handleUnauthorizedError());

    return httpJson;
  }
}

export default HttpJson;
