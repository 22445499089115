import { createStyles } from '@mui/styles';
import { Theme } from '~/themes';

export const publicLayoutStyle = (theme: Theme) =>
  createStyles({
    container: {},
    contentContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: theme.spacing(3, 0),
    },
    footer: {
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      alignItems: 'flex-end',
    },
    backgroundImage: {
      height: '100%',
      width: '100%',
      background: 'linear-gradient(to bottom, #160E4D, #3E397C, #160E4D)',
    },
    titleContainer: {
      marginBottom: theme.spacing(3),
    },
    logo: {},
    title: {
      display: 'inline',
      color: '#FF7B5E',
      fontFamily: 'Poppins',
    },
    content: {
      'display': 'flex',
      'flexDirection': 'column',
      'justifyContent': 'center',
      'padding': 0,
      'height': '100%',
      '& h1': {
        margin: theme.spacing(3, 0, 2, 0),
        padding: 0,
        fontSize: 30,
      },
      '& MuiStepper-root': {
        marginTop: theme.spacing(2),
      },
      '&>form': {
        '& .MuiStepper-root': {
          'marginTop': theme.spacing(3),
          '&.space-between': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        },
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(1.5),
        },
        '& > button': {
          width: '100%',
          margin: theme.spacing(1, 0, 3, 0),
        },
      },
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'start',
      },
    },
    [theme.breakpoints.down('ss')]: {
      contentContainer: {
        padding: theme.spacing(2),
      },
      logo: {
        marginTop: theme.spacing(5),
      },
      footer: {
        marginTop: theme.spacing(3),
      },
    },
  });
