import {
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import Modal from '~/components/common/Modal';
import { TextInput } from '~/components/common/TextInput';
import { TIME_SLOT_TYPE, weekdays } from '~/constants/stores';
import {
  DeliveryHours,
  IStore,
  IndividualTimeSlotsType,
} from '~/models/stores';
import { usePreviewTimeSlot } from '~/services/api/stores';
import themes from '~/themes';
import { IndividualOrderLimitSchema } from '~/utils/schema/stores';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type DialogIndividualTimeSlotsProps = {
  open?: boolean;
  stores?: IStore;
  onClose: () => void;
  onSaveDataSetLimit: (values: DeliveryHours) => void;
};

export const DialogIndividualTimeSlots: React.FC<
  DialogIndividualTimeSlotsProps
> = ({ open, stores, onClose, onSaveDataSetLimit }) => {
  const orderLimitAllInputRef = useRef(null);
  const orderLimitTimeSlotInputRef = useRef(null);
  const { data: deliveryData, isFetching: isFetchingPreviewTimeSlot } =
    usePreviewTimeSlot({
      params: stores,
    });
  const deliveryHoursPreview = deliveryData?.delivery_hours;

  const [errors, setErrors] = useState<
    {
      id: string;
      errMsg: string;
      type: IndividualTimeSlotsType;
    }[]
  >([]);

  const convertDeliveryHours = (dHours) => {
    if (!dHours) return {};
    const newData = {};
    const weekDays = Object.keys(dHours);
    weekDays.forEach((wd) => {
      const hours = dHours[wd];
      const { time_slot } = hours;
      const timeSlots = time_slot.flat();
      let type = IndividualTimeSlotsType.LimitIndividualSlot;
      if (!hours.limit && !timeSlots.some((e) => e.limit))
        type = IndividualTimeSlotsType.LimitAllSlot;
      if (hours.limit) type = IndividualTimeSlotsType.LimitAllSlot;
      newData[wd] = { ...hours, type };
    });
    return newData;
  };

  const getTimeSlots = (dHours, weekday) => {
    if (!dHours) return [];
    const { time_slot } = dHours[weekday];
    return dHours[weekday]
      ? time_slot.flat().map((slot) => ({
          label: `${moment(slot.from_time, 'HH:mm').format(
            'hh:mm a',
          )} - ${moment(slot.to_time, 'HH:mm').format('hh:mm a')}`,
          value: `${weekday}@${slot.from_time} - ${slot.to_time}`,
          limit: slot.limit || null,
        }))
      : [];
  };

  const getTimeSlotSelected = (dHours, weekday) =>
    getTimeSlots(dHours, weekday)[0]?.value || '';

  const updateDeliveryHoursField = (
    setFieldValue,
    values,
    updateValues: {
      allLimit?: number;
      updateTimeSlotKey?: string;
      timeSlotLimit?: number;
      type?: IndividualTimeSlotsType;
    },
  ) => {
    const { allLimit, updateTimeSlotKey, timeSlotLimit } = updateValues;

    const newVal = {
      ...values.deliveryHours,
      [values.weekday]: {
        ...values.deliveryHours[values.weekday],
        limit: allLimit || null,
        ...(updateValues.type && { type: updateValues.type }),
        ...(updateTimeSlotKey && {
          time_slot: values.deliveryHours[values.weekday]?.time_slot?.length
            ? values.deliveryHours[values.weekday].time_slot.map((slot) =>
                `${values.weekday}@${slot.from_time} - ${slot.to_time}` ===
                updateTimeSlotKey
                  ? { ...slot, limit: timeSlotLimit || null }
                  : slot,
              )
            : {},
        }),
      },
    };

    setFieldValue('deliveryHours', newVal);
  };

  const validateSyncLimit = (id, limit, type?: IndividualTimeSlotsType) => {
    try {
      IndividualOrderLimitSchema.validateSync({ limit: limit || null });
      setErrors(errors.filter((e) => e.id !== id && e.type === type));
    } catch (e) {
      const errMsg = e?.message || '';
      setErrors(
        errors.length && errors.find((err) => err.id === id)
          ? errors.map((err) => (err.id === id ? { id, errMsg, type } : err))
          : [...errors, { id, errMsg, type }],
      );
    }
  };

  const handleSubmit = (values) => {
    const params = Object.keys(values.deliveryHours).reduce((acc, key) => {
      const hours = values.deliveryHours[key];
      const { time_slot_generation, available } = hours;

      if (hours.type === IndividualTimeSlotsType.LimitAllSlot) {
        acc[key] = {
          ...hours,
          from_time: hours.from_time,
          to_time: hours.to_time,
          limit: hours.limit,
          ...(hours?.time_slot && {
            time_slot: hours.time_slot.map((slot) => ({
              from_time: slot.from_time,
              to_time: slot.to_time,
              lead_time: slot?.lead_time,
              limit: null,
            })),
          }),
        };
      } else
        acc[key] = {
          ...hours,
          from_time: hours.from_time,
          to_time: hours.to_time,
          limit: null,
          time_slot:
            !available || time_slot_generation === TIME_SLOT_TYPE.NONE
              ? hours?.time_slot?.map((slot) => ({
                  from_time: slot.from_time,
                  to_time: slot.to_time,
                  lead_time: slot?.lead_time,
                  limit: 999,
                }))
              : hours?.time_slot || [],
        };
      delete acc[key].type;
      return acc;
    }, {});

    onSaveDataSetLimit(params);
    onClose();
  };

  const weekDaysComponent = (values, setFieldValue) => (
    <Stack direction='column'>
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          height: '40px',
        }}
      >
        <Typography
          sx={{
            color: '#8C95BA',
          }}
          variant='caption'
        >
          Day of the week
        </Typography>
      </Stack>
      <Stack direction='column' spacing={0.5} mt={0.5}>
        {weekdays.map(({ label, value }) => (
          <Stack
            key={label}
            sx={{
              'lineHeight': '20px',
              'color': '#33363D',
              'borderRadius': '4px',
              'padding': '8px',
              'cursor': 'pointer',
              '&:hover': { background: '#E5E3F4' },
              ...(value === values.weekday && {
                background: '#E5E3F4',
              }),
            }}
            onClick={() => {
              setFieldValue('weekday', value);
              setFieldValue(
                'timeSlotSelected',
                getTimeSlotSelected(values.deliveryHours, value),
              );
            }}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );

  const timeSlotsComponent = (values, setFieldValue) => {
    const { delivery_hours } = stores;
    const timeSlots = getTimeSlots(values.deliveryHours, values.weekday);
    const noTimeSlot =
      !timeSlots.length ||
      !delivery_hours[values.weekday]?.available ||
      delivery_hours[values.weekday]?.time_slot_generation ===
        TIME_SLOT_TYPE.NONE;
    if (noTimeSlot) {
      return (
        <Grid container columnSpacing={2}>
          <Grid
            item
            xs={6}
            sx={{
              height: '100%',
            }}
          >
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                height: '40px',
              }}
            >
              <Typography
                sx={{
                  color: '#8C95BA',
                }}
                variant='caption'
              >
                Time slots
              </Typography>
              <ElementSelect
                paperProps={{
                  sx: {
                    width: 160,
                  },
                }}
                elementSelect={() => (
                  <MoreHorizIcon sx={{ color: '#33363D' }} />
                )}
                options={[
                  {
                    label: 'All Time Slots',
                    value: IndividualTimeSlotsType.LimitAllSlot,
                  },
                  {
                    label: 'Each Time Slot',
                    value: IndividualTimeSlotsType.LimitIndividualSlot,
                  },
                ]}
                onChange={() => {}}
              />
            </Stack>
            <Stack maxHeight={500} mt={0.5} spacing={0.5}>
              <Typography
                sx={{
                  lineHeight: '20px',
                  color: '#33363D',
                  borderRadius: '4px',
                  padding: '8px',
                  cursor: 'pointer',
                  background: '#E5E3F4',
                }}
              >
                No time slot
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                height: '40px',
              }}
            >
              <Typography
                sx={{
                  color: '#8C95BA',
                }}
                variant='caption'
              >
                Order Limit
              </Typography>
            </Stack>
            <TextInput
              type='number'
              inputProps={{
                autoFocus: true,
                min: 1,
                step: 'any',
                readonly: true,
              }}
              inputRef={orderLimitAllInputRef}
              sx={{
                mt: 0.55,
                [themes.breakpoints.down('md')]: { width: '100%' },
              }}
              disabled
            />
          </Grid>
        </Grid>
      );
    }

    const individualTimeSlotsType =
      values.deliveryHours[values.weekday]?.type ||
      IndividualTimeSlotsType.LimitAllSlot;

    const { timeSlotSelected } = values;
    const orderLimitValue = values.deliveryHours[
      values.weekday
    ]?.time_slot.find(
      (s) =>
        `${values.weekday}@${s.from_time} - ${s.to_time}` === timeSlotSelected,
    )?.limit;

    const error = errors?.find((e) => e.id === timeSlotSelected);
    const errorAllSlots = errors?.find((e) => e.id === values.weekday);

    return (
      <Grid container columnSpacing={2}>
        <Grid
          item
          xs={6}
          sx={{
            height: '100%',
          }}
        >
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              height: '40px',
            }}
          >
            <Typography
              sx={{
                color: '#8C95BA',
              }}
              variant='caption'
            >
              Time slots
            </Typography>
            <ElementSelect
              paperProps={{
                sx: {
                  width: 160,
                },
              }}
              elementSelect={() => <MoreHorizIcon sx={{ color: '#33363D' }} />}
              value={individualTimeSlotsType}
              onChange={(o) => {
                updateDeliveryHoursField(setFieldValue, values, {
                  allLimit: values.deliveryHours[values.weekday]?.limit,
                  timeSlotLimit: null,
                  updateTimeSlotKey: null,
                  type: o.value,
                });
                setFieldValue(
                  'timeSlotSelected',
                  getTimeSlotSelected(deliveryHoursPreview, values.weekday),
                );
                setTimeout(() => {
                  orderLimitAllInputRef?.current?.focus();
                  orderLimitTimeSlotInputRef?.current?.focus();
                }, 0);
                setErrors((err) => err.filter((e) => e.type === o.value));

                if (o.value === IndividualTimeSlotsType.LimitAllSlot) {
                  validateSyncLimit(
                    values.weekday,
                    values.deliveryHours[values.weekday]?.limit,
                    o.value,
                  );
                }
                if (o.value === IndividualTimeSlotsType.LimitIndividualSlot) {
                  const validateTimeSlots = getTimeSlots(
                    values.deliveryHours,
                    values.weekday,
                  );
                  validateTimeSlots
                    .filter((e) => e.limit)
                    .forEach((e) => {
                      validateSyncLimit(e.value, e.limit, o.value);
                    });
                }
              }}
              options={[
                {
                  label: 'All Time Slots',
                  value: IndividualTimeSlotsType.LimitAllSlot,
                },
                {
                  label: 'Each Time Slot',
                  value: IndividualTimeSlotsType.LimitIndividualSlot,
                },
              ]}
            />
          </Stack>
          {individualTimeSlotsType === IndividualTimeSlotsType.LimitAllSlot && (
            <Stack maxHeight={500} mt={0.5} spacing={0.5}>
              <Typography
                sx={{
                  lineHeight: '20px',
                  color: '#33363D',
                  borderRadius: '4px',
                  padding: '8px',
                  cursor: 'pointer',
                  background: '#E5E3F4',
                }}
              >
                All time slots
              </Typography>
            </Stack>
          )}
          {individualTimeSlotsType ===
            IndividualTimeSlotsType.LimitIndividualSlot && (
            <Stack
              maxHeight={500}
              mt={0.5}
              spacing={0.5}
              className='customized-scrollbar'
              sx={{
                overflowY: 'auto',
              }}
            >
              {timeSlots.map((opt) => (
                <Typography
                  onClick={() => {
                    setTimeout(
                      () => orderLimitTimeSlotInputRef.current.focus(),
                      0,
                    );
                    setFieldValue('timeSlotSelected', opt.value);
                  }}
                  sx={{
                    'lineHeight': '20px',
                    'color': '#33363D',
                    'borderRadius': '4px',
                    'padding': '8px',
                    'cursor': 'pointer',
                    '&:hover': { background: '#E5E3F4' },
                    ...(timeSlotSelected === opt.value && {
                      background: '#E5E3F4',
                    }),
                  }}
                  key={opt.value}
                >
                  {opt.label}
                </Typography>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              height: '40px',
            }}
          >
            <Typography
              sx={{
                color: '#8C95BA',
              }}
              variant='caption'
            >
              Order Limit
            </Typography>
          </Stack>
          {individualTimeSlotsType === IndividualTimeSlotsType.LimitAllSlot && (
            <TextInput
              type='number'
              error={!!errorAllSlots?.errMsg}
              id={IndividualTimeSlotsType.LimitAllSlot}
              inputProps={{ autoFocus: true, min: 1, step: 'any' }}
              inputRef={orderLimitAllInputRef}
              sx={{
                mt: 0.55,
                [themes.breakpoints.down('md')]: { width: '100%' },
              }}
              value={values.deliveryHours[values.weekday]?.limit || ''}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                updateDeliveryHoursField(setFieldValue, values, {
                  allLimit: value || null,
                });
                validateSyncLimit(
                  values.weekday,
                  value,
                  IndividualTimeSlotsType.LimitAllSlot,
                );
              }}
            />
          )}
          {errorAllSlots?.errMsg &&
          individualTimeSlotsType === IndividualTimeSlotsType.LimitAllSlot ? (
            <FormHelperText error={!!errorAllSlots?.errMsg}>
              {errorAllSlots?.errMsg}
            </FormHelperText>
          ) : null}
          {individualTimeSlotsType ===
            IndividualTimeSlotsType.LimitIndividualSlot && (
            <TextInput
              value={orderLimitValue || ''}
              error={!!error?.errMsg}
              type='number'
              id={IndividualTimeSlotsType.LimitIndividualSlot}
              inputRef={orderLimitTimeSlotInputRef}
              inputProps={{ autoFocus: true, min: 1, step: 'any' }}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                updateDeliveryHoursField(setFieldValue, values, {
                  allLimit: values.deliveryHours[values.weekday]?.limit,
                  updateTimeSlotKey: timeSlotSelected,
                  timeSlotLimit: value || null,
                });
                validateSyncLimit(
                  timeSlotSelected,
                  value,
                  IndividualTimeSlotsType.LimitIndividualSlot,
                );
              }}
              sx={{
                mt: 0.55,
                [themes.breakpoints.down('md')]: { width: '100%' },
              }}
            />
          )}
          {error?.errMsg &&
          individualTimeSlotsType ===
            IndividualTimeSlotsType.LimitIndividualSlot ? (
            <FormHelperText error={!!error?.errMsg}>
              {error?.errMsg}
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const loadingComponent = useMemo(
    () => (
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    ),
    [],
  );

  const limitSlotForm = () => {
    if (!deliveryHoursPreview)
      return <Typography>No delivery hours data.</Typography>;
    return (
      <Formik
        initialValues={{
          weekday: 'sunday',
          deliveryHours: convertDeliveryHours(deliveryHoursPreview),
          timeSlots: getTimeSlots(deliveryHoursPreview, 'sunday'),
          timeSlotSelected: getTimeSlotSelected(deliveryHoursPreview, 'sunday'),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form id='individual_time_slots'>
            <Grid container columnSpacing={2}>
              <Grid item xs={4}>
                {weekDaysComponent(values, setFieldValue)}
              </Grid>
              <Grid item xs={8}>
                {timeSlotsComponent(values, setFieldValue)}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <Modal
      onClose={onClose}
      open={open}
      title='Edit Order Limit'
      disableCloseOutside
      PaperProps={{
        sx: {
          'maxWidth': 800,
          '& .MuiDialogContent-root': {
            overflow: 'hidden',
          },
        },
      }}
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'individual_time_slots',
          id: 'individual_time_slots',
          loading: false,
          disabled: !deliveryHoursPreview || !!errors.length,
        },
      ]}
    >
      {isFetchingPreviewTimeSlot ? loadingComponent : limitSlotForm()}
    </Modal>
  );
};
