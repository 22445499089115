import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import Button from '~/components/common/Button';
import Collapsible from '~/components/common/Collapsible';
import { useGetTeamsQuery } from '~/services/api/shared';
import { IDriverInfo, ITeam } from '~/models/shared';
import { useDeleteTeamMutation } from '~/services/api/team';
import { showAlert, alertParams } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import CircularLoading from '~/components/common/CirularLoading';
import Tooltip from '~/components/common/Tooltip';
import { formatPhoneNumber } from '~/utils/formatter';
import { SearchFormik } from '~/components/common/Search/SearchFormik';
import { useFirstLoadingDetection } from '~/hooks';
import { Icon } from '~/components/common/Icon';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import useStyles from './style';
import SetupYourTeams from './SetupYourTeams';
import { DialogAddTeam } from '../AddTeam/DialogAddTeam';

const EmptyStateData = [
  {
    name: 'Day Shift',
    description: 'This team works 8am - 4pm',
    hub_id: null,
    org_id: '64489c4244de8f93841ef993',
    driver_ids: ['649d230515bb7004b31ecca0'],
    created_at: '2023-07-20T09:37:46.165000',
    updated_at: '2023-07-20T09:37:46.165000',
    hub_serve: [],
    driver_info: [
      {
        display_name: 'John Doe',
        phone: '11010101010',
        email: null,
        driver_status: 'offline',
        lat: 21.0455626,
        lng: 105.843085,
        id: '649d230515bb7004b31ecca0',
      },
      {
        display_name: 'Jane Doe',
        phone: '11010101010',
        email: null,
        driver_status: 'offline',
        lat: 21.0455626,
        lng: 105.843085,
        id: '649d230515bb7004b31ecca0',
      },
    ],
    id: '64b9006a1d71b94711ab88ad',
  },
];

type TeamListProps = {};

export const TeamList: React.FC<TeamListProps> = () => {
  const location = useLocation();
  const queries = fromQueryString(location.search);
  const searchTeamQuery = (queries?.search || '') as string;

  const [collapsed, setCollapsed] = useState(false);
  const [openAddTeamDialog, setOpenAddTeamDialog] = useState(false);
  const [teamEditData, setTeamEditData] = useState(null);

  const toggleOpenAddTeamDialog = () => setOpenAddTeamDialog((v) => !v);
  const { isStarterOrStandardPlan, setIsOpenUpSellDialog } = usePaymentInfo();

  const { data: rawData, isFetching: isFetchingTeams } = useGetTeamsQuery({
    enabled: !isStarterOrStandardPlan,
    params: {
      search: searchTeamQuery,
    },
  });

  const handleEditTeam = (team) => {
    toggleOpenAddTeamDialog();
    setTeamEditData(team);
  };

  const classes = useStyles();

  const teams = useMemo(() => rawData || [], [rawData]);
  const isFirstLoading = useFirstLoadingDetection([isFetchingTeams]);

  const { mutate: deleteTeamMutation, isLoading: isLoadingDeleteTeam } =
    useDeleteTeamMutation({
      onSuccess: ({ team_id }) => {
        queryClient.invalidateQueries([queryKeys.shared.getTeamList]);
        queryClient.setQueriesData(
          [queryKeys.shared.getTeamList],
          (oldData: any) => oldData.filter((data) => data.id !== team_id),
        );
        showAlert({
          ...alertParams.success,
          title: 'Team Deleted Successfully',
        });
        setTeamEditData(null);
      },
      onError: (error) => {
        showAlert(errorAlert(error));
      },
    });

  const isEmptyState = !teams?.length && !isFetchingTeams && !searchTeamQuery;

  const renderTeamInfo = (team: ITeam) => {
    const { name, description } = team;
    return (
      <Stack
        spacing={1}
        sx={{
          b: {
            fontWeight: 500,
          },
        }}
      >
        <b>Team Information</b>
        <Typography data-testid='team-info-name' data-value={name}>
          <b>Name: </b>
          {name}
        </Typography>
        <Typography>
          <b>Short Description: </b>
          {description}
        </Typography>

        {!isEmptyState && (
          <>
            <Divider />
            <Stack direction='row' spacing={1}>
              <Button
                buttonType='default'
                noRounder
                loading={isLoadingDeleteTeam}
                onClick={() => {
                  showAlert({
                    ...alertParams.warning,
                    title: 'Warning',
                    cancelText: 'Cancel',
                    okText: 'Yes, Delete',
                    description: (
                      <>
                        You are about to delete this team.
                        <br />
                        Are you sure you want to continue?
                      </>
                    ),
                    onOk: () => {
                      deleteTeamMutation(team.id);
                    },
                  });
                }}
              >
                Delete Team
              </Button>
              <Button
                onClick={() => {
                  handleEditTeam(team);
                }}
                buttonType='primary-dark'
                noRounder
              >
                Edit Team
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    );
  };

  const renderDriversInTeam = ({
    driver_info,
    name,
  }: {
    driver_info: IDriverInfo[];
    name: string;
  }) => (
    <>
      <Typography fontWeight='500'>Drivers in This Team</Typography>
      <Stack
        data-testid={`${name}-driver-list-in-team`}
        sx={{
          mt: 1,
          position: 'relative',
          boxSizing: 'border-box',
          borderRadius: '10px',
          backgroundColor: isEmptyState ? 'rgba(0,0,0,0.08)' : '#F8F8FC',
          maxHeight: 180,
          minHeight: isEmptyState ? 60 : 180,
          overflow: 'hidden',
          ...(isEmptyState
            ? {
                '& > div': {
                  padding: '10px',
                },
              }
            : {}),
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ overflow: 'auto', p: 2 }}
          className='customized-scrollbar'
        >
          {driver_info.map((driver) => (
            <Grid key={driver.id} item xs={12} md={6}>
              <Stack
                direction='row'
                component='li'
                data-testid='driver-item-in-team'
                data-value={driver.display_name}
                value={driver.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #BFC3E0',
                  borderRadius: 6,
                  background: 'white',
                  p: 0.5,
                  pr: 2,
                }}
              >
                <Stack direction='row' alignItems='center' spacing={0.5}>
                  {isEmptyState ? (
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                    />
                  ) : (
                    <DriverAvatar
                      name={driver.display_name}
                      size={30}
                      avatar={driver.avatar}
                    />
                  )}
                  <Tooltip title={driver.display_name}>
                    <span data-testid='driver-name'>
                      {driver.display_name.length > 10
                        ? `${driver.display_name.slice(0, 10)}...`
                        : driver.display_name}
                    </span>
                  </Tooltip>
                </Stack>
                <Typography data-testid='driver-phone'>
                  {isEmptyState
                    ? '(+1) 101-010-1010'
                    : formatPhoneNumber(driver.phone)}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );

  const headerComponent = useMemo(
    () => (
      <div className={classes.filterContainer}>
        <SearchFormik placeholder='Search' clearIds={['id']} />
        <Button
          fullWidth={false}
          onClick={() => {
            if (isStarterOrStandardPlan) {
              setIsOpenUpSellDialog(true);
            } else {
              setTeamEditData(null);
              toggleOpenAddTeamDialog();
            }
          }}
        >
          Add Team
        </Button>
      </div>
    ),
    [isStarterOrStandardPlan],
  );

  const teamListComponent = useMemo(() => {
    if (!teams.length && !isEmptyState)
      return (
        <Typography textAlign='center' mt={4}>
          No data result
        </Typography>
      );

    return (isEmptyState ? EmptyStateData : teams).map((team) => {
      const { name, id, driver_info } = team;
      return (
        <Collapsible
          defaultExpanded={!!isEmptyState}
          key={id}
          title={
            <Typography data-testid='team-name' data-value={name} variant='h5'>
              {name}
            </Typography>
          }
          rightTitle
          className={classes.item}
          contentClassName={classes.itemContent}
          customRightTitle={
            <Stack direction='row'>
              <Icon name='driver' className={classes.driverIcon} />
              <Typography
                data-testid='number-of-driver-in-team'
                data-value={driver_info?.length || 0}
                sx={{ width: 65, fontWeight: 500 }}
              >
                {`${driver_info?.length || 0} Driver${
                  driver_info?.length !== 1 ? 's' : ''
                }`}
              </Typography>
            </Stack>
          }
          onChange={(_: React.ChangeEvent<any>, expanded: boolean) => {
            if (!collapsed) {
              setCollapsed(expanded);
            }
          }}
          sx={{
            ...(isEmptyState
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.25,
                  pointerEvents: 'none',
                }
              : {}),
          }}
        >
          <Grid container spacing={2} key={id}>
            <Grid item xs={12} md={5}>
              {renderTeamInfo(team)}
            </Grid>
            <Grid item xs={12} md={7}>
              {renderDriversInTeam({
                name: team.name,
                driver_info: team.driver_info,
              })}
            </Grid>
          </Grid>
        </Collapsible>
      );
    });
  }, [teams, searchTeamQuery, collapsed, isEmptyState]);

  if (isFirstLoading)
    return (
      <CircularLoading loading={isFetchingTeams} className={classes.loading} />
    );

  return (
    <Container
      className={classes.containerClass}
      sx={{
        maxWidth: 1460,
        p: 3,
      }}
    >
      <Stack sx={{ mt: 2, position: 'relative' }}>
        {headerComponent}
        {teamListComponent}
        {isEmptyState && <SetupYourTeams setOpen={setOpenAddTeamDialog} />}
        {openAddTeamDialog && (
          <DialogAddTeam
            teamEditData={teamEditData}
            open={openAddTeamDialog}
            onClose={toggleOpenAddTeamDialog}
          />
        )}
        <CircularLoading loading={isFetchingTeams} />
      </Stack>
    </Container>
  );
};
