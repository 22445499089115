import { FirebaseError, initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  sendPasswordResetEmail,
  updatePassword,
} from 'firebase/auth';
import {
  IChangePasswordParam,
  ILoginParam,
  IResetPasswordParam,
} from '~/models/auth';
import { FIREBASE_CONFIG } from '~/config';

export const formatFirebaseError = (error: FirebaseError) => {
  switch (true) {
    case error.message.includes('(auth/too-many-requests)'):
      return 'Access to this account has been temporarily disabled due to many failed change password attempts.';
    case error.message.includes('(auth/wrong-password)'):
      return 'Incorrect password';
    case error.message.includes('(auth/user-not-found)'):
      return 'Email/Password is incorrect';
    case error.message.includes('(auth/requires-recent-login)'):
      return 'Your credential has been expired, please login again';
    case error.message.includes('(auth/email-already-in-use)'):
      return 'The email address is already in use by another account.';
    case error.message.includes('(auth/invalid-email)'):
      return 'The email address is not valid.';
    case error.message.includes('(auth/operation-not-allowed)'):
      return 'Email/password accounts are not enabled. Enable email/password in the Firebase Console, under the Auth tab.';
    case error.message.includes('(auth/weak-password)'):
      return 'The password is not strong enough.';
    case error.message.includes('(auth/invalid-login-credentials)'):
      return 'Email/Password is incorrect';
    case error.message.includes(
      '(auth/account-exists-with-different-credential)',
    ):
      return 'An account already exists with the same email address but different sign-in credentials.';
    default:
      return "Sorry we couldn't complete your request please try again!";
  }
};

const firebaseApp = initializeApp(JSON.parse(FIREBASE_CONFIG));
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseLogin = async (data: ILoginParam) =>
  signInWithEmailAndPassword(firebaseAuth, data.email, data.password);

export const firebaseLogout = () => signOut(firebaseAuth);

export const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject,
    );
  });

export const firebaseGetToken = async () => {
  await getCurrentUser();
  return firebaseAuth.currentUser?.getIdToken();
};

export const login = async (data: ILoginParam) =>
  signInWithEmailAndPassword(firebaseAuth, data.email, data.password);

export const signInWCustomToken = async (token: string) =>
  signInWithCustomToken(firebaseAuth, token);

export const sendVerificationEmail = async () => {
  const user = firebaseAuth?.currentUser;
  if (user) {
    await sendEmailVerification(user, {
      url: `${window.location.origin}/register/verified`,
    });
  }
};

export const updateUserProfile = async (full_name: string) => {
  const user = firebaseAuth?.currentUser;
  if (user) {
    await updateProfile(user, { displayName: full_name });
  }
};

export const register = async (data: {
  email: string;
  password: string;
  shouldVerifyEmail?: boolean;
}) => {
  await createUserWithEmailAndPassword(firebaseAuth, data.email, data.password);
  if (data.shouldVerifyEmail) {
    await sendVerificationEmail();
  }
};

export const forceUpdateToken = async () => {
  const user = firebaseAuth?.currentUser;
  if (user) {
    await user.getIdToken(true);
  }
};

export const passwordReset = async ({ email }: IResetPasswordParam) =>
  sendPasswordResetEmail(firebaseAuth, email, {
    url: `${window.location.origin}/login`,
  });

export const logout = async () => signOut(firebaseAuth);

export const changePwd = async ({
  newPassword,
  password,
  email,
}: IChangePasswordParam) =>
  firebaseLogin({
    email,
    password,
  })
    .then(() => {
      const user = firebaseAuth?.currentUser;
      updatePassword(user, newPassword).then((res) => res);
    })
    .catch((error: FirebaseError) => {
      // const errorMessage = formatFirebaseError(error);
      throw error;
    });

export const updatePwd = async ({ newPassword }: IChangePasswordParam) => {
  const user = firebaseAuth?.currentUser;
  await updatePassword(user, newPassword);
};
