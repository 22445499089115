import React from 'react';
import clsx from 'clsx';
import {
  FormControlLabel,
  FormHelperText,
  Checkbox,
  CheckboxProps,
  SxProps,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import CheckIcon from '@mui/icons-material/Check';
import { colors } from '~/themes';
import style from './style';

const CheckBoxStyled = withStyles(() => ({
  root: {
    color: colors.violet900,
  },
  checked: {
    color: `${colors.violet900} !important`,
  },
}))(Checkbox);

export interface ICheckBox extends Omit<CheckboxProps, 'classes'> {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  label?: React.ReactNode | string;
  color?: 'primary' | 'secondary' | 'default';
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: React.ChangeEvent<any>) => void;
  id?: string;
  checked?: boolean;
  defaultCheckedIcon?: boolean;
  helperText?: string;
  name?: string;
  sxContainer?: SxProps;
}

const CheckBox: React.FC<ICheckBox & WithStyles<typeof style>> = ({
  className,
  classes,
  disabled = false,
  error = false,
  children,
  color,
  label,
  name,
  id,
  checked,
  helperText = '',
  defaultCheckedIcon,
  sxContainer,
  ...rest
}) => (
  <div>
    <FormControlLabel
      sx={sxContainer}
      className={clsx(classes.formControlLabel, className)}
      label={label || children}
      control={
        <CheckBoxStyled
          inputProps={
            {
              'data-testid': `chk-${id}`,
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
          {...rest}
          checked={checked}
          color={color}
          disabled={disabled}
          name={name}
          id={id}
          {...(!defaultCheckedIcon && {
            checkedIcon: (
              <CheckIcon
                className={clsx(classes.checkedIcon, {
                  [classes.disabledBorder]: disabled,
                })}
              />
            ),
          })}
          icon={
            <CheckIcon className={clsx(classes.uncheckedIcon, { error })} />
          }
          role='presentation'
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      {...(disabled ? { onClick: (e) => e.preventDefault() } : {})}
    />
    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
  </div>
);

export default withStyles(style)(CheckBox);
