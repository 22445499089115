import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from '@mui/system';
import themes from '~/themes';

type ProgressProps = {
  sxContainer?: SxProps;
  size?: number;
  thickness?: number;
  value?: number;
  isEmpty?: boolean;
};

export const Progress: React.FC<ProgressProps> = ({
  value,
  sxContainer,
  size = 15,
  thickness = 10,
  isEmpty = false,
}) => (
  <Stack
    position='relative'
    justifyContent='center'
    alignItems='center'
    sx={{ width: 'fit-content', height: 'fit-content', ...sxContainer }}
  >
    <Box top={0} left={0} bottom={0} right={0} position='absolute'>
      <CircularProgress
        size={size}
        sx={{
          '&.MuiCircularProgress-root': {
            '& > svg': {
              transform: 'scale(1, 1)',
            },
            'color': themes.bg.lightPurple,
          },
        }}
        variant='determinate'
        value={100}
        thickness={thickness}
      />
    </Box>
    <CircularProgress
      size={size}
      sx={{
        '&.MuiCircularProgress-root': {
          'color': themes.bg.greenInsight,
          '& > svg': {
            transform: 'scale(1, 1)',
            // '& > circle': {
            //   strokeLinecap: 'round',
            // },
          },
          ...(isEmpty && {
            color: '#C9C9C9',
          }),
          ...(value === 0 && {
            color: themes.bg.lightPurple,
          }),
        },
      }}
      variant='determinate'
      value={value}
      thickness={thickness}
    />
  </Stack>
);
