import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from '~/themes';

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string; textDecoration?: string }>(
  ({ color, textDecoration, theme }) => ({
    textDecoration: textDecoration || 'underline',
    color: color || theme.color.primaryOrange,
  }),
);

interface TextLinkProps extends LinkProps {
  style?: React.CSSProperties;
  color?: string;
}
export const TextLink: React.FC<TextLinkProps> = ({
  children,
  style,
  color,
  ...rest
}) => (
  <StyledLink color={color} style={style} {...rest}>
    {children}
  </StyledLink>
);
