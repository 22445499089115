import { IUserTooltip } from './user';

interface IDriverAddress {
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  country?: string;
  postcode?: string;
}

export interface IDriver {
  first_name?: string;
  last_name?: string;
  display_name?: string;
  phone?: string;
  country_code?: string;
  team_ids?: string[];
  email?: string;
  username?: string;
  org_id?: string;
  role?: string;
  default?: boolean;
  status?: string;
  timezone?: string;
  invited_by?: string;
  invited_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  accepted_invitation_at?: Date;
  vehicle?: Vehicle;
  avatar?: string;
  avatar_type?: string;
  driver_status?: string;
  items_per_page?: number;
  tooltip?: IUserTooltip;
  last_location_at?: Date;
  lat?: number;
  lng?: number;
  is_alerted?: boolean;
  last_location_lat?: number;
  last_location_lng?: number;
  teams?: Team[];
  alerting?: boolean;
  in_hand_task?: number;
  id?: string;
  dob?: string;
  address?: IDriverAddress;
  file?: string | null;
  customer_support?: ICustomerSupport;
  alternative_phone?: string;
  alternative_phone_country_code?: string;
  driverDocParams?: any;
}

export enum ICustomerSupport {
  ContactDriverPhone = 'contact_driver_phone',
  ContactAlternativePhone = 'contact_alternative_phone',
  NoContact = 'no_contact',
}

export const CustomerSupportTableLabels = {
  [ICustomerSupport.ContactDriverPhone]: 'Contact driver’s phone number',
  [ICustomerSupport.ContactAlternativePhone]:
    'Contact an alternative phone number',
  [ICustomerSupport.NoContact]: 'No contact allowed.',
};

export interface Team {
  name?: string;
  description?: string;
  hub_id?: null | string;
  org_id?: string;
  driver_ids?: string[];
  created_at?: Date;
  updated_at?: Date;
  is_auto_assigned?: boolean;
  id?: string;
}

export enum VehicleType {
  CAR = 'car',
}

export interface Vehicle {
  type?: VehicleType;
  make_model?: string;
  model?: string;
  year?: string;
  color?: string;
  licence_plate?: string;
  insurance_country?: string;
  insurance_state?: string;
}

export interface IDriverLog {
  created_at?: Date;
  created_by?: IDriverLogCreatedBy;
  user_id?: string;
  type?: 'created ' | 'updated';
  update_info?: string;
  log?: string;
  id?: string;
}

export interface IDriverLogCreatedBy {
  display_name?: string;
  avatar_type?: string;
  avatar?: null | string;
  id?: string;
}

export enum IDriverStatus {
  Online = 'online',
  Offline = 'offline',
  Idle = 'idle',
}
