import { Dialog, IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import {
  Close,
  Done,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import clsx from 'clsx';
import { canvasPreview } from '~/utils/imageCrop/canvasPreview';
import { Theme } from '~/themes';
import CropIcon from '~/assets/images/icons/crop.svg';
import Revert from '~/assets/images/icons/revert.svg';
import Upload from '~/assets/images/icons/upload.svg';
import Remove from '~/assets/images/icons/delete.svg';
import { showAlert } from '~/components/common/Alert';
import { rotateImage } from '~/utils/imageCrop/rotateImage';
import { errorAlert } from '../Alert/alertParams';
// eslint-disable-next-line import/no-cycle
import useImageUploadContext from './useImageUploadContext';
import Button from '../Button';

const scale = 1;

const style = (theme: Theme) =>
  createStyles({
    paper: {
      boxSizing: 'border-box',
      width: 600,
      maxWidth: '100%',
      background: theme.bg.image,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
      gap: theme.spacing(3),
    },
    header: {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      '& button:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      color: 'white',
    },
    closeButton: {
      width: 20,
      height: 20,
      background: 'white',
      color: 'black',
      fontSize: 14,
      padding: 0,
    },
    buttonContainer: {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'space-between',
      'gap': theme.spacing(1),
      '&>div:first-child': {
        width: 125,
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      '& button:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    buttonGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    button: {
      width: 40,
      height: 40,
      background: theme.bg.gray900,
      color: 'white',
      fontSize: 25,
    },
    disableButton: {
      border: `1px solid ${theme.bg.gray300}`,
    },
    saveButton: {
      background: theme.bg.gray900,
      color: 'white',
      height: 40,
      borderRadius: 20,
      width: 125,
    },
    uploadButton: {
      'cursor': 'pointer',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'borderRadius': 40,
      'boxSizing': 'border-box',
      '&:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    fileInput: {
      display: 'none',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: 540,
      background: 'black',
    },
    image: {
      maxHeight: '540px !important',
      maxWidth: '540px !important',
      width: 'auto',
      height: 'auto',
      objectFit: 'contain',
    },
    popup: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'background': 'rgba(0, 0, 0, .8)',
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      'color': 'white',
      '& > div': {
        '&:first-child': {
          fontSize: 18,
          fontWeight: 600,
          marginBottom: theme.spacing(1),
        },
        '&:last-child': {
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(2),
          justifyContent: 'center',
          marginTop: theme.spacing(3),
        },
      },
    },
    customUploadButton: {
      background: '#FA8C73',
      borderRadius: 19,
      maxHeight: 35,
      padding: '9px 36px',
      marginTop: theme.spacing(3),
      color: 'white',
      fontWeight: 600,
      cursor: 'pointer',
    },
    arrowButton: {
      'color': 'white',
      'background': theme.bg.gray900,
      'top': '50%',
      'transform': 'translate(0, -50%)',
      'position': 'absolute',
      'left': theme.spacing(2),
      '&.right': {
        left: 'auto',
        right: theme.spacing(2),
      },
    },
    listIndex: {
      'position': 'absolute',
      'display': 'flex',
      'justifyContent': 'center',
      'bottom': theme.spacing(2),
      'maxWidth': '100%',
      'overflow': 'auto',
      '& div': {
        'width': theme.spacing(1),
        'height': theme.spacing(1),
        'background': theme.bg.gray900,
        'marginRight': theme.spacing(1),
        'borderRadius': '50%',
        '&:last-child': {
          marginRight: 0,
        },
        '&.active': {
          background: 'white',
        },
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  title?: string;
}

const MultipleImageUpload: React.FC<IProps> = ({
  classes,
  title = 'Profile Image',
}) => {
  const {
    open,
    setOpen,
    onSaveList,
    listImgSrc,
    setListImgSrc,
    listSrc,
    setDefaultListImgSrc,
    disableSquare,
    optional,
    readonly,
    maxLength,
  } = useImageUploadContext();

  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (index > (listImgSrc?.length || 1) - 1) {
      setIndex((listImgSrc?.length || 1) - 1);
    }
  }, [index, listImgSrc]);

  const imgSrc = useMemo(
    () => listImgSrc?.[index]?.src || '',
    [listImgSrc, index],
  );

  const disableEdit = useMemo(
    () => imgSrc.includes('firebasestorage.googleapis.com'),
    [imgSrc],
  );

  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<PixelCrop | undefined>();
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    if (listSrc?.length) {
      setIndex(0);
    }
  }, [listSrc]);

  const onCloseCrop = () => {
    setIsCrop(false);
    setCrop(undefined);
    setRotate(0);
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onCloseCrop();
    const listFile: any = e.target.files;
    if (
      listFile &&
      (listFile.length || 0) + (listImgSrc?.length || 0) > maxLength
    ) {
      showAlert(errorAlert(`Could not upload more than ${maxLength} images`));
    } else if (listFile && listFile.length > 0) {
      Promise.all(
        [...listFile]
          // eslint-disable-next-line no-unused-vars
          .map(
            (item) =>
              new Promise((resolve: (value: string) => void) => {
                if (item.size > 5 * 1024 * 1024) {
                  showAlert(errorAlert('File size must be less than 5 MB.'));
                } else {
                  setCrop(undefined);
                  const reader = new FileReader();

                  reader.addEventListener('load', () => {
                    resolve(reader?.result?.toString() || '');
                  });
                  reader.readAsDataURL(item);
                }
              }),
          ),
      ).then((results) => {
        setListImgSrc(
          index,
          results.filter((item) => !!item).map((item) => ({ src: item })),
        );
      });
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!disableEdit) {
      // @ts-ignore
      imgRef.current = e.currentTarget;
      imgRef.current.setAttribute('crossorigin', 'anonymous');
      const { width, height } = imgRef.current;
      const newCrop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width,
          },
          disableSquare ? height / width : 1,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop({
        ...newCrop,
        unit: 'px',
      });
    } else {
      onCloseCrop();
    }
  };

  const onCrop = () => {
    setIsCrop(true);
  };

  const handleSave = async () => {
    if (isCrop && crop?.width && crop?.height && imgRef.current) {
      onCloseCrop();
      const url = canvasPreview(imgRef.current, crop, scale, 0);
      if (url) {
        setListImgSrc(index, { src: url });
      } else {
        showAlert(errorAlert('Invalid file'));
      }
    }
  };

  const onRotate = async (newValue?: number) => {
    const newRotate = ((newValue || rotate) - 90) % 360;
    setRotate(newRotate);
    if (imgRef.current) {
      const type = imgSrc?.substring(
        'data:'.length,
        imgSrc?.indexOf(';base64'),
      );
      const data = await rotateImage(imgRef.current, type);
      setListImgSrc(index, {
        src: data,
      });
    }
  };

  const handleSaveList = () => {
    setOpen(false);
    onSaveList(listImgSrc || []);
  };

  const onClose = useCallback(() => {
    setOpen(false);
    onCloseCrop();
    setDefaultListImgSrc(listSrc || []);
  }, [listSrc]);

  const handleNext = () => {
    if (index < (listImgSrc?.length || 0) - 1) {
      setIndex((idx) => idx + 1);
      onCloseCrop();
    }
  };

  const handleBack = () => {
    if (index > 0) {
      setIndex((idx) => idx - 1);
      onCloseCrop();
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        handleBack();
        break;
      case 'ArrowRight':
        handleNext();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (open) document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open, index, listImgSrc]);

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          {`${title}${listImgSrc?.length ? ` (${index + 1})` : ''}`}
        </div>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
        >
          <Close fontSize='inherit' />
        </IconButton>
      </div>
      <div className={classes.content}>
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={isCrop ? crop : undefined}
            onChange={(pixelCrop) => setCrop(pixelCrop)}
            disabled={!isCrop}
          >
            <img
              className={classes.image}
              alt='img'
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {index > 0 && (
          <IconButton
            className={classes.arrowButton}
            onClick={() => handleBack()}
          >
            <KeyboardArrowLeft fontSize='inherit' />
          </IconButton>
        )}
        {index < (listImgSrc?.length || 0) - 1 && (
          <IconButton
            className={clsx(classes.arrowButton, 'right')}
            onClick={() => handleNext()}
          >
            <KeyboardArrowRight fontSize='inherit' />
          </IconButton>
        )}
        {listImgSrc?.length && (
          <div className={classes.listIndex}>
            {listImgSrc.map((_, itemIndex) => (
              <div className={clsx({ active: itemIndex === index })} />
            ))}
          </div>
        )}
        {deletePopup && (
          <div className={classes.popup}>
            <div>Are You Sure?</div>
            <div>Once deleted, image cannot be recover.</div>
            <div>
              <Button
                buttonType='default'
                onClick={() => setDeletePopup(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setListImgSrc(index);
                  onCloseCrop();
                  setDeletePopup(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        {!imgSrc && (
          <div className={classes.popup}>
            <div>Upload New Image</div>
            <div>You can not leave this empty. Please upload a new image.</div>
            <label htmlFor='file-upload'>
              <div className={classes.customUploadButton}>Upload</div>
              <input
                id='file-upload'
                className={classes.fileInput}
                type='file'
                accept='image/*'
                onChange={onSelectFile}
                multiple
              />
            </label>
          </div>
        )}
      </div>
      {!readonly && (
        <div className={classes.buttonContainer}>
          <div />
          {isCrop ? (
            <div className={classes.buttonGroup}>
              <IconButton
                className={classes.button}
                onClick={() => handleSave()}
                size='large'
              >
                <Done />
              </IconButton>
              <IconButton
                className={classes.button}
                onClick={() => setIsCrop(false)}
                size='large'
                disabled={disableEdit}
              >
                <Close />
              </IconButton>
            </div>
          ) : (
            <div className={classes.buttonGroup}>
              <label htmlFor='file-upload'>
                <div className={clsx(classes.button, classes.uploadButton)}>
                  <img alt='upload' src={Upload} />
                </div>
                <input
                  id='file-upload'
                  className={classes.fileInput}
                  type='file'
                  accept='image/*'
                  onChange={onSelectFile}
                  multiple
                />
              </label>
              <IconButton
                className={classes.button}
                onClick={() => Boolean(imgSrc) && setDeletePopup(true)}
                size='large'
              >
                <img alt='remove' src={Remove} />
              </IconButton>
              <IconButton
                className={clsx(classes.button, {
                  [classes.disableButton]: disableEdit,
                })}
                onClick={() => Boolean(imgSrc) && onCrop()}
                size='large'
                disabled={disableEdit}
              >
                <img alt='crop' src={CropIcon} />
              </IconButton>
              <IconButton
                className={clsx(classes.button, {
                  [classes.disableButton]: disableEdit,
                })}
                onClick={() => onRotate()}
                size='large'
                disabled={disableEdit}
              >
                <img alt='rotate' src={Revert} />
              </IconButton>
            </div>
          )}
          <Button
            className={classes.saveButton}
            onClick={() => handleSaveList()}
            disabled={!optional && !listImgSrc?.length}
          >
            Save
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default withStyles(style)(MultipleImageUpload);
