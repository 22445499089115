import { Typography } from '@mui/material';
import { Driver } from '~/pages/LiveTracking/types';
import { useGetDistanceFromDriverToPickupQuery } from '~/services/api/task';

type DriverDistanceProps = {
  driver: Driver;
  pickup?: any;
  delivery: any;
};

export const DriverDistance: React.FC<DriverDistanceProps> = ({
  driver,
  pickup,
  delivery,
}) => {
  const params = {
    driver_id: driver.id,
    pickup_address_1: pickup.address_1,
    pickup_state: pickup.state,
    pickup_country: pickup.country,
    pickup_postcode: pickup.postcode,
    pickup_city: pickup.city,
    ...(!pickup?.address_1 && {
      pickup_address_1: delivery.address_1,
      pickup_state: delivery.state,
      pickup_country: delivery.country,
      pickup_postcode: delivery.postcode,
      pickup_city: delivery.city,
    }),
  };
  const { data, isFetching } = useGetDistanceFromDriverToPickupQuery({
    params,
    // enabled: driver.driver_status === 'online' && !!pickup?.address_1,
    enabled: driver.driver_status === 'online',
  });

  if (isFetching) return <div>...</div>;
  if (!data.distance.length) return <Typography>-</Typography>;
  return (
    <Typography color='#979DC3'>
      {data.distance[0] === -1 ? '-' : `${data.distance[0]} km away`}
    </Typography>
  );
};
