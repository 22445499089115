import httpClient from '~/lib/client/httpClient';
import { cleanDeep, toQueryString } from '~/utils/queryString';
import {
  MutationFunction,
  QueryFunction,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from '~/lib/react-query';
import { genAvatarSrc } from '~/components/shared/AssignTask/DriverAvatar';
import { formatDateTimeUTC } from '~/utils/formatter';
import {
  IDriver,
  IGetDriverListRequest,
  IAddDriverRequest,
  IGetDriverDetailRequest,
  IDriverLogItem,
  IDriverDocItem,
} from './types';
import { UserRole } from '../Users/types';

export const convertDriverDetailData = (data: IDriver): IDriver => ({
  ...data,
  avatar: genAvatarSrc(data?.avatar),
  email: data?.email || '',
  first_name: data?.first_name || '',
  last_name: data?.last_name || '',
  display_name: data?.display_name || '',
  phone: data?.phone || '',
  dob: data?.dob ? formatDateTimeUTC(data?.dob, 'MM/DD/YYYY') : '',
  address: data?.address
    ? {
        address_1: data?.address?.address_1 || '',
        address_2: data?.address?.address_2 || '',
        city: data?.address?.city || '',
        state: data?.address?.state || '',
        country: data?.address?.country || '',
        postcode: data?.address?.postcode || '',
      }
    : {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
      },
  vehicle: {
    type: data?.vehicle?.type || '',
    make_model: data?.vehicle?.make_model || '',
    model: data?.vehicle?.model || '',
    year: data?.vehicle?.year || '',
    color: data?.vehicle?.color || '',
    licence_plate: data?.vehicle?.licence_plate || '',
    insurance_country: data?.vehicle?.insurance_country || '',
    insurance_state: data?.vehicle?.insurance_state || '',
  },
});

export const getOneDriverDoc = (
  id: string,
  docId: string,
): Promise<{ blob: string; blob_type: string }> =>
  httpClient.json.get(`/users/${id}/documents/${docId}`);

export const getMultiDriverDoc = (id: string, docIds: string[]) =>
  Promise.all(docIds.map((mId) => getOneDriverDoc(id, mId))).then((res) =>
    res.map(({ blob, blob_type }) => ({ photo: blob, photo_type: blob_type })),
  );

export enum DriverSettingsKeys {
  GET_DRIVER_LIST = 'DriverSettings.GET_DRIVER_LIST',
  GET_DRIVER_DRIVER_DETAIL = 'DriverSettings.GET_DRIVER_DRIVER_DETAIL',
  GET_DRIVER_DRIVER_LOG_ITEM = 'DriverSettings.GET_DRIVER_DRIVER_LOG_ITEM',
  GET_DRIVER_DRIVER_DOC = 'DriverSettings.GET_DRIVER_DRIVER_DOC',
}

export const driverSettingsQueryKeys = {
  getDriverList: (params?: any) =>
    [DriverSettingsKeys.GET_DRIVER_LIST, params].filter(Boolean),
  getDriverDetail: (params?: any) =>
    [DriverSettingsKeys.GET_DRIVER_DRIVER_DETAIL, params].filter(Boolean),
  getDriverLogItem: (params?: any) =>
    [DriverSettingsKeys.GET_DRIVER_DRIVER_LOG_ITEM, params].filter(Boolean),
  getDriverDoc: (params?: any) =>
    [DriverSettingsKeys.GET_DRIVER_DRIVER_DOC, params].filter(Boolean),
};

export const fetchDriverList: QueryFunction<IDriver[]> = async ({ meta }) => {
  const params = meta as IGetDriverListRequest;
  params.roles = UserRole.Driver;
  cleanDeep(params);
  return httpClient.json.get(
    `/users?${toQueryString(params, {
      arrayFormat: 'none',
    })}`,
  );
};

export const fetchDriverDetail: QueryFunction<IDriver> = async ({ meta }) => {
  const params = meta as IGetDriverDetailRequest;
  return httpClient.json.get(`/users/${params.id}`);
};

export const fetchDriverLog: QueryFunction<IDriverLogItem[]> = async ({
  meta,
}) => {
  const params = meta as { id: string };
  return httpClient.json.get(`/driver_log/${params.id}`);
};

export const fetchDriverDoc: QueryFunction<IDriverDocItem> = async ({
  meta,
}) => {
  const params = meta as { id: string; docId: string };
  return httpClient.json.get(`/users/${params.id}/documents/${params.docId}`);
};

export const addDriver: MutationFunction<IDriver, IAddDriverRequest> = (
  params,
) => httpClient.json.post('/users:invite_driver', params);

export const updateDriver: MutationFunction<IDriver, IDriver> = (params) =>
  httpClient.json.put(`/users/${params.id}`, params);

export const deleteDriver: MutationFunction<unknown, { id: string }> = (
  params,
) => httpClient.json.delete(`/users/${params.id}`);

export const updateDriverAvatar: MutationFunction<
  unknown,
  { file: FormData | null; driver_id?: string }
> = (params) => {
  if (!params?.file) {
    return httpClient.json.put('/user/avatar', params);
  }
  return httpClient.formData.put('/user/avatar', params);
};

export const resendInvitationDriver: MutationFunction<
  unknown,
  { id: string }
> = (params) => httpClient.json.post(`/users/${params.id}/invitation:resend`);

export const useGetDriverListQuery = (
  params: IGetDriverListRequest,
  options?: UseQueryOptions<IDriver[]>,
) =>
  useQuery({
    queryKey: driverSettingsQueryKeys.getDriverList(params),
    queryFn: fetchDriverList,
    initialData: () =>
      queryClient.getQueryData(
        driverSettingsQueryKeys.getDriverList(params),
      ) as IDriver[],
    meta: params,
    keepPreviousData: true,
    ...options,
  });

export const useGetDriverDetailQuery = (
  params: IGetDriverDetailRequest,
  options?: UseQueryOptions<IDriver>,
) =>
  useQuery({
    queryKey: driverSettingsQueryKeys.getDriverDetail(params),
    queryFn: fetchDriverDetail,
    initialData: {},
    meta: params,
    select: (data) => convertDriverDetailData(data),
    ...options,
  });
export const useGetDriverLogQuery = (
  params: { id: string },
  options?: UseQueryOptions<IDriverLogItem[]>,
) =>
  useQuery({
    queryKey: driverSettingsQueryKeys.getDriverLogItem(params),
    queryFn: fetchDriverLog,
    initialData: [],
    meta: params,
    ...options,
  });

export const useGetDriverDocQuery = (
  params: { id: string; docId: string },
  options?: UseQueryOptions<IDriverDocItem>,
) =>
  useQuery({
    queryKey: driverSettingsQueryKeys.getDriverDoc(params),
    queryFn: fetchDriverDoc,
    initialData: {},
    select: (data) => ({ src: genAvatarSrc(data.blob), type: data.blob_type }),
    meta: params,
    ...options,
  });

export const useAddDriver = (
  options?: UseMutationOptions<IDriver, unknown, IAddDriverRequest>,
) =>
  useMutation({
    mutationFn: addDriver,
    ...options,
  });

export const useUpdateDriver = (
  options?: UseMutationOptions<IDriver, unknown, IDriver>,
) =>
  useMutation({
    mutationFn: updateDriver,
    ...options,
  });
export const useUpdateDriverAvatar = (
  options?: UseMutationOptions<IDriver, unknown, any>,
) =>
  useMutation({
    mutationFn: updateDriverAvatar,
    ...options,
  });

export const useDeleteDriver = (
  options?: UseMutationOptions<unknown, unknown, { id: string }>,
) =>
  useMutation({
    mutationFn: deleteDriver,
    ...options,
  });

export const useResendInvitationDriver = (
  options?: UseMutationOptions<unknown, unknown, { id: string }>,
) =>
  useMutation({
    mutationFn: resendInvitationDriver,
    ...options,
  });
