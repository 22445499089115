import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { TextLink } from '~/components/common/TextLink';
import { handleLoginSuccess, useLogin } from '~/services/api/auth';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { useValidateInvitationMutation } from '~/services/api/shared';
import { showAlert, alertParams } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { styled } from '~/themes';
import { UnifiedPublicLayout } from '~/layout/UnifiedPublicLayout';
import { Button, theme as unifiedTheme } from 'bs-unified-ui';
import { Field } from '~/components/common/Formik';
import { Stack } from '@mui/system';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { useUnifiedAuthContext } from '~/providers/UnifiedAuthProvider';
import { useEffect, useState } from 'react';
import VerifyCode2FA from '~/pages/Auth/VerifyCode2FA';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { use2Fa } from '~/hooks/use2Fa';
import { UnifiedLoginSchema } from './schema';

type LoginProps = {};

export const StyledLoginForm = styled(Form)(() => ({
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
}));

export const UnifiedLoginWith2FA: React.FC<LoginProps> = () => {
  const { isLoginLoading } = useUnifiedAuthContext();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );

  const { mutate: validateInvitation } = useValidateInvitationMutation({
    onSuccess: async () => {
      showAlert({
        ...alertParams.success,
        title: 'Accept invitation successfully!',
        okText: 'Ok',
      });
      queryClient.invalidateQueries([queryKeys.settings.account]);
    },
    onError: () => {
      queryClient.invalidateQueries([queryKeys.settings.account]);
    },
  });

  const {
    handleEnable2FA,
    handleSendCode,
    loadingSendCode,
    phone,
    handleVerifyCode,
    enable2FA,
    handleCancel,
    loadingVerify,
    errorMsg: errorMsg2Fa,
    clearErrorMsg,
  } = use2Fa({
    recaptchaId: 'recaptcha-container-id',
    onLoginSuccess: async () => {
      await handleLoginSuccess(isNewSSO, {
        isInvalidateQueries: !searchQueries?.code,
        isValidateCustomClaim: true,
        isShowAlert: false,
      });
      const code = searchQueries?.code as string;
      if (code) {
        validateInvitation({ code });
      }
    },
  });

  const { mutate: loginMutation, isLoading } = useLogin({
    options: {
      isInvalidateQueries: !searchQueries?.code,
      isValidateCustomClaim: true,
      isShowAlert: false,
    },
    onSuccess: () => {
      const code = searchQueries?.code as string;
      if (code) {
        validateInvitation({ code });
      }
    },
    onError: (errorString, error) => {
      if (error?.code === 'auth/multi-factor-auth-required') {
        handleEnable2FA(error);
      } else setErrorMsg(errorString);
    },
  });

  const clearAllErrorMsg = () => {
    clearErrorMsg();
    setErrorMsg('');
  };

  useEffect(() => {
    channel.postMessage({
      type: BroadcastEvents.SyncLoginState,
      payload: isLoading,
    });
  }, [isLoading]);

  const renderVerifyCode = () => (
    <VerifyCode2FA
      phone={phone}
      onComplete={handleVerifyCode}
      onClickReSend={handleSendCode}
      loading={isLoading || isLoginLoading || loadingSendCode || loadingVerify}
      error={errorMsg2Fa}
      clearError={clearErrorMsg}
    />
  );

  const renderLoginForm = () => (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      enableReinitialize
      validationSchema={UnifiedLoginSchema}
      onSubmit={(values) => {
        clearAllErrorMsg();
        loginMutation(values);
      }}
    >
      {() => (
        <StyledLoginForm>
          <Typography variant='h1' className='headerContainer'>
            Login to your account
          </Typography>
          <Stack spacing={2}>
            <Field.UnifiedTextField
              name='email'
              label='Email'
              fullWidth
              onChange={() => clearAllErrorMsg()}
            />
            <Field.UnifiedTextField
              name='password'
              type='password'
              label='Password'
              fullWidth
              onChange={() => clearAllErrorMsg()}
            />
            <Typography
              sx={{
                lineHeight: 1.66,
                color: '#F24822',
                mt: '10px !important',
                fontSize: 14,
              }}
            >
              {errorMsg || errorMsg2Fa}
            </Typography>
            <TextLink
              to='/reset-password'
              style={{
                textAlign: 'end',
                lineHeight: '24px',
                color: unifiedTheme.foreground.black,
              }}
            >
              Forgot Password
            </TextLink>
          </Stack>
          <Button
            type='submit'
            sx={{ mt: 3 }}
            loading={isLoading || isLoginLoading || loadingSendCode}
          >
            Login
          </Button>
        </StyledLoginForm>
      )}
    </Formik>
  );

  return (
    <UnifiedPublicLayout hasButtonBack={enable2FA} onBack={handleCancel}>
      {enable2FA ? renderVerifyCode() : renderLoginForm()}
      <div id='recaptcha-container-id' />
    </UnifiedPublicLayout>
  );
};
