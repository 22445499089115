import { Stack, Tooltip } from '@mui/material';
import themes, { styled } from '~/themes';
import StoreIcon from '~/assets/images/map/store.svg';
import DriverIcon from '~/assets/images/map/driver.svg';
import DropOffIcon from '~/assets/images/map/drop-off.svg';
import { useCallback } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { MarkerType } from '../types';
import { useLiveMapContext } from '../hooks/useLiveMapContext';

type ViewControlProps = {};

export const StyledViewControlWrapperIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bg',
})<{ bg: string; disabled?: boolean }>(({ bg, theme, disabled = false }) => ({
  width: theme.spacing(2.4),
  height: theme.spacing(2.4),
  background: bg,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...(disabled && {
    background: theme.bg.gray600,
  }),
}));

export const StyledViewControlIcon = styled('img')(() => ({}));

export const ViewControl: React.FC<ViewControlProps> = () => {
  const {
    isOpenTaskDetail,
    isOpenManageRoutes,
    viewControls,
    expandedLiveMonitoring,
    setViewControls,
    showMarkers,
    hideMarkers,
  } = useLiveMapContext();

  const handleViewControl = useCallback(
    (val: MarkerType) => {
      const isOn = viewControls.includes(val);
      const updatedViewControls = isOn
        ? viewControls.filter((e) => e !== val)
        : viewControls.concat([val]);

      const markerOptions = {
        taskDetail: isOpenTaskDetail,
        default: !isOpenTaskDetail && !isOpenManageRoutes,
        manageRoute: isOpenManageRoutes,
      };

      if (isOn) {
        hideMarkers([val], markerOptions);
      } else {
        showMarkers([val], markerOptions);
      }

      setViewControls(updatedViewControls);
    },
    [viewControls, isOpenTaskDetail, isOpenManageRoutes],
  );

  const ViewControlOptions = [
    {
      icon: DriverIcon,
      value: MarkerType.Driver,
      bg: '#0C508B',
      title: 'Drivers',
    },
    {
      icon: DropOffIcon,
      value: MarkerType.DropOff,
      bg: themes.bg.darkPurple,
      title: 'Dropoffs',
    },
    {
      icon: StoreIcon,
      value: MarkerType.Store,
      bg: themes.bg.primaryOrange,
      title: 'Stores',
    },
  ];

  if (isMobile && !isTablet && expandedLiveMonitoring) {
    return <div />;
  }

  return (
    <Stack
      sx={{
        padding: themes.spacing(0.8),
        position: 'absolute',
        right: themes.spacing(1),
        bottom: themes.spacing(12),
        boxShadow: '0px 3px 6px #160E4D14',
        borderRadius: '5px',
        background: themes.bg.white,
        zIndex: themes.zIndex.drawer + 1,
        [themes.breakpoints.down('md')]: {
          bottom: themes.spacing(2),
        },
      }}
      spacing={0.8}
    >
      {ViewControlOptions.map(({ icon, bg, value, title }) => (
        <Tooltip title={title} key={value} placement='left' arrow>
          <StyledViewControlWrapperIcon
            bg={bg}
            disabled={!viewControls.includes(value)}
            role='presentation'
            onClick={() => handleViewControl(value)}
          >
            <StyledViewControlIcon alt={value} src={icon} />
          </StyledViewControlWrapperIcon>
        </Tooltip>
      ))}
    </Stack>
  );
};
