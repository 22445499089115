import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { IOrg } from '~/models/org';
import { ICreateOrgParams } from '~/models/auth';
import { queryClient } from '~/lib/react-query';
import { setAccountQueryData } from '~/utils/reactQuery';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getOrg = (): Promise<IOrg> =>
  httpClient.json.get(endpoints.org.org);

const createOrg = (data: ICreateOrgParams) =>
  httpClient.json.post(endpoints.org.create, data);

const updateOrg = (data: ICreateOrgParams) =>
  httpClient.json.put(endpoints.org.org, data);

export const useGetOrgQuery = (params?: {
  enabled?: boolean;
  onSuccess?: (data: IOrg) => void;
}) =>
  useQuery({
    queryKey: [queryKeys.orgManagement.org],
    queryFn: getOrg,
    enabled: params?.enabled !== false,
    initialData: {} as IOrg,
    select: (data: IOrg) => ({
      ...data,
      country: data?.country === 'CA' ? data.country : 'US',
      originalCountry: data?.country,
    }),
    onSuccess: params?.onSuccess,
  });

export const useCreateOrgMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: createOrg,
    onSuccess,
    onError,
  });

export const useUpdateOrgMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateOrg,
    onSuccess: (resp: any) => {
      setAccountQueryData({ org_id: resp?.id, org: resp });
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
      onSuccess?.(resp);
    },
    onError,
  });
