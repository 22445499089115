import createStyles from '@mui/styles/createStyles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    formLabel: {
      color: theme.color.black,
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
    dateTimeContainer: {
      'width': '100%',
      '& table': {
        borderSpacing: '0px 0px',
      },
      '& .rdtPicker': {
        background: 'white',
        top: 40,
        right: 0,
        left: 0,
        padding: '10px',
        borderRadius: theme.spacing(2),
        maxWidth: 250,
        minWidth: 250,
        boxShadow: '0px 1px 6px #160E4D14',
        fontSize: 13,
        fontWeight: 500,
      },
      '&.withTime': {
        '& .rdtPicker': {
          width: 540,
          maxWidth: 540,
          [theme.breakpoints.down('lg')]: {
            width: `calc(100% - ${theme.spacing(2)})`,
          },
        },
      },
      '& .rdtPicker td.rdtOld': {
        fontWeight: 400,
      },
      '& .rdtPicker td.rdtNew': {
        fontWeight: 400,
      },
      '& .rdtPicker td.rdtDisabled': {
        fontWeight: 400,
      },
      '& .rdtPicker td.rdtToday:before': {
        borderBottom: `7px solid ${theme.bg.darkPurple}`,
      },
      '& .rdtDay': {
        borderRadius: '50%',
        textAlign: 'center',
        height: 33,
      },
      '& .rdtDays tr .dow': {
        color: '#000000',
        borderWidth: 0,
        textTransform: 'uppercase',
        letterSpacing: 0,
        fontSize: 13,
        fontWeight: 500,
        paddingBottom: 3,
        borderBottom: `1px solid ${theme.bg.midPurple}`,
      },
      '& .rdtPicker th': {
        borderBottom: 'none',
      },
      '& .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev': {
        'fontSize': theme.spacing(3),
        'fontWeight': theme.spacing(10),
        '&:hover': {
          background: 'none',
        },
      },
      '& .rdtPicker th.rdtSwitch': {
        fontFamily: 'DM Sans',
        fontWeight: '500',
      },
      '& .rdtPicker td.rdtActive': {
        backgroundColor: `${theme.bg.darkPurple} !important`,
        color: 'white',
      },
      '& .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover':
        {
          textDecoration: 'underline',
        },
      '& .MuiSvgIcon-root': {
        color: `${theme.color.violet900} !important`,
      },
    },
    footer: {
      'display': 'flex',
      'flexWrap': 'wrap',
      'gap': theme.spacing(1),
      'paddingBottom': theme.spacing(1),
      '& button': {
        flex: 1,
        minWidth: 0,
        color: theme.color.violet900,
        background: theme.bg.midPurple,
      },
    },
    icon: {
      width: 36,
      height: 36,
      borderRadius: 18,
      border: `1px solid ${theme.bg.purpleOutline}`,
      fontSize: 18,
    },
    dirtyIcon: {
      'position': 'relative',
      '&::after': {
        content: '""',
        height: theme.spacing(1),
        width: theme.spacing(1),
        position: 'absolute',
        right: 0,
        top: 0,
        background: theme.bg.green,
        borderRadius: theme.spacing(0.5),
      },
    },
    activeIcon: {
      'border': `2px solid ${theme.bg.darkPurple}`,
      '&::after': {
        right: -1,
        top: -1,
      },
    },
    wrapper: {
      'display': 'flex',
      'flexDirection': 'column',
      'flexWrap': 'wrap',
      'gap': theme.spacing(2, 4),
      '&>div': {
        flex: '1 1 100%',
      },
      '&.withTime': {
        'flexDirection': 'row',
        '&>div:nth-child(-n+2)': {
          flex: '1 1 calc(50% - 21px)',
          maxHeight: 284,
        },
      },
    },
    timeContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      fontWeight: 400,
      fontSize: 14,
      borderLeft: `1px solid ${theme.bg.midPurple}`,
      marginLeft: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
    },
    selectedDate: {
      fontWeight: 500,
      fontSize: 16,
      margin: theme.spacing(1, 0),
    },
  });

export default style;
