import {
  useRef,
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { GoogleMaps } from '~/components/shared/GoogleMap/maps/GoogleMaps';
import { LatLng } from '~/components/shared/GoogleMap/types';
import { mapOptions } from '~/pages/LiveTracking/constants';

export interface HitMapProviderProps {
  extraConfigs?: google.maps.MapOptions;
}
export interface HitMapContextProps {
  googleMap: GoogleMaps;
  map: google.maps.Map;
  isMapLoaded: boolean;
  mapRef: any;
  setPolygonOnMap: (
    latLngs: LatLng[],
    centerLatLng: LatLng,
    opts?: google.maps.PolygonOptions | null,
    infoWindowContent?: string,
  ) => void;
  createStoreLocationOnMap: (
    latLngs: LatLng[],
    infoWindowContent?: string,
  ) => void;
}

export const HitMapContext = createContext({} as HitMapContextProps);
export const HitMapProvider: React.FC<HitMapProviderProps> = ({
  children,
  extraConfigs = {},
}) => {
  const googleMapRef = useRef<GoogleMaps | null>(null);
  const mapRef = useRef();
  const [map, setMap] = useState<google.maps.Map | null>();

  useEffect(() => {
    setMap(
      new window.google.maps.Map(mapRef.current, {
        ...mapOptions,
        ...extraConfigs,
      }),
    );
  }, []);

  useEffect(() => {
    if (map) {
      googleMapRef.current = new GoogleMaps(map);
    }
  }, [map]);

  const setPolygonOnMap = useCallback(
    (
      latLngs: LatLng[],
      centerLatLng: LatLng,
      opts?: google.maps.PolygonOptions | null,
      infoWindowContent?: string,
    ) => {
      if (map) {
        googleMapRef.current.setPolygonToMap({
          latLngs,
          centerLatLng,
          opts,
          infoWindowContent,
        });
      }
    },
    [map],
  );

  const createStoreLocationOnMap = useCallback(
    (latLngs: LatLng[], infoWindowContent?: string) => {
      if (map) {
        latLngs.forEach((latLng) => {
          googleMapRef.current.createStoreLocationInfoWindow(
            latLng,
            infoWindowContent,
          );
        });
      }
    },
    [map],
  );

  const contextValue = useMemo(
    () => ({
      googleMap: googleMapRef.current,
      map,
      mapRef,
      isMapLoaded: !!map,
      setPolygonOnMap,
      createStoreLocationOnMap,
    }),
    [googleMapRef.current, map, mapRef, map],
  );

  return (
    <HitMapContext.Provider value={contextValue}>
      {children}
    </HitMapContext.Provider>
  );
};
