import React from 'react';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { IFormProps } from '~/models/common';
import { IGroupRadioButton } from '../GroupRadioButton/GroupRadioButton';

export interface IFieldGroupRadioButton
  extends IFormProps,
    Omit<IGroupRadioButton, 'name'> {}

const FormGroupRadioButton: React.FC<IFieldGroupRadioButton> = ({
  field,
  form,
  onChange,
  ...props
}) => (
  <GroupRadioButton
    {...field}
    onChange={(_, newValue) => {
      form.setFieldValue(field.name, newValue);
      if (onChange) {
        onChange(_, newValue);
      }
    }}
    {...props}
  />
);

export default FormGroupRadioButton;
