import { boolean, object, string } from 'yup';
import moment from 'moment';
import {
  setDateToTimeSelector,
  validateNumber,
  validatePhoneNumber,
} from './utils';

export const SendAlertSchema = object().shape({
  message: string()
    .trim()
    .required('Message is required')
    .max(150, 'Max 150 characters'),
  requestPhoneCall: boolean(),
  phone: string().when('requestPhoneCall', {
    is: true,
    then: string()
      .required('Phone is required')
      .test('phone', 'Invalid phone number', validatePhoneNumber),
  }),
});

export const AddTaskSchema = object().shape({
  skip_pickup: boolean(),
  pickup: object().when('skip_pickup', {
    is: false,
    then: object().shape({
      name: string().trim().required('Name is required'),
      address_1: string().trim().required('Address is required'),
      phone: string()
        .required('Phone is required')
        .test('pickup.phone', 'Invalid phone number', validatePhoneNumber),
      country: string().required('Country is required'),
      state: string().required('State is required'),
      city: string().required('City is required'),
      postcode: string().trim().required('Postcode is required'),
    }),
  }),

  delivery: object().shape({
    name: string().trim().required('Name is required'),
    phone: string()
      .required('Phone is required')
      .test('delivery.phone', 'Invalid phone number', validatePhoneNumber),
    address_1: string().trim().required('Address is required'),
    country: string().required('Country is required'),
    state: string().required('State is required'),
    city: string().required('City is required'),
    postcode: string().trim().required('Postcode is required'),
    email: string().trim().email('Invalid email'),
  }),
  expected_delivery_date: string().nullable(true),
  expected_delivery_after: string().nullable(true),
  expected_delivery_before: string()
    .nullable(true)
    .trim()
    .test(
      'validate time',
      'To time must be greater than From time',
      (value: any, testContext: any) => {
        if (!value && !testContext.parent.expected_delivery_after) return true;
        // when task created from woo or breadstack expected_delivery_after will be null => skip validate
        if (value && !testContext.parent.expected_delivery_after) return true;
        if (value && testContext.parent.expected_delivery_after) {
          const expectedDeliveryDate =
            testContext.parent.expected_delivery_date || moment();
          const timeBefore = setDateToTimeSelector(
            expectedDeliveryDate,
            moment(value),
          );
          const timeAfter = setDateToTimeSelector(
            expectedDeliveryDate,
            moment(testContext.parent.expected_delivery_after),
          );

          return moment(timeBefore).unix() > moment(timeAfter).unix();
        }
        return false;
      },
    ),
});

export const AddTaskWithoutPhonePickupSchema = object().shape({
  skip_pickup: boolean(),
  pickup: object().when('skip_pickup', {
    is: false,
    then: object().shape({
      name: string().trim().required('Name is required'),
      address_1: string().trim().required('Address is required'),
      phone: string()
        .trim()
        .test('pickup.phone', 'Invalid phone number', validateNumber),
      country: string().required('Country is required'),
      state: string().required('State is required'),
      city: string().required('City is required'),
      postcode: string().trim().required('Postcode is required'),
    }),
  }),

  delivery: object().shape({
    name: string().trim().required('Name is required'),
    phone: string()
      .required('Phone is required')
      .test('delivery.phone', 'Invalid phone number', validatePhoneNumber),
    address_1: string().trim().required('Address is required'),
    country: string().required('Country is required'),
    state: string().required('State is required'),
    city: string().required('City is required'),
    postcode: string().trim().required('Postcode is required'),
    email: string().trim().email('Invalid email'),
  }),
  expected_delivery_date: string().nullable(true),
  expected_delivery_after: string().nullable(true),
  expected_delivery_before: string()
    .nullable(true)
    .trim()
    .test(
      'validate time',
      'To time must be greater than From time',
      (value: any, testContext: any) => {
        if (!value && !testContext.parent.expected_delivery_after) return true;
        // when task created from woo or breadstack expected_delivery_after will be null => skip validate
        if (value && !testContext.parent.expected_delivery_after) return true;
        if (value && testContext.parent.expected_delivery_after) {
          const expectedDeliveryDate =
            testContext.parent.expected_delivery_date || moment();
          const timeBefore = setDateToTimeSelector(
            expectedDeliveryDate,
            moment(value),
          );
          const timeAfter = setDateToTimeSelector(
            expectedDeliveryDate,
            moment(testContext.parent.expected_delivery_after),
          );

          return moment(timeBefore).unix() > moment(timeAfter).unix();
        }
        return false;
      },
    ),
});
