import { object, string } from 'yup';
import {
  validateNumber,
  validatePhoneNumber,
} from '~/pages/LiveTracking/utils';

const AddressFormSchema = object().shape({
  name: string()
    .trim()
    .required('Name is required')
    .max(50, 'Max 50 characters'),
  phone: string()
    .trim()
    .test('phone', 'Invalid phone number', (value: any, testContext: any) => {
      if (!value) return true;
      return validatePhoneNumber(value, testContext);
    }),
  email: string().trim().email('Invalid email'),
  address_1: string().trim().required('Address 1 is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  city: string().required('City is required'),
  postcode: string().trim().required('Postcode is required'),
});

const AddressWithoutValidatePhoneFormSchema = object().shape({
  name: string()
    .trim()
    .required('Name is required')
    .max(50, 'Max 50 characters'),
  phone: string()
    .trim()
    .test('phone', 'Invalid phone number', (value: any, testContext: any) => {
      if (!value) return true;
      return validateNumber(value, testContext);
    }),
  email: string().trim().email('Invalid email'),
  address_1: string().trim().required('Address 1 is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  city: string().required('City is required'),
  postcode: string().trim().required('Postcode is required'),
});
export { AddressFormSchema, AddressWithoutValidatePhoneFormSchema };
