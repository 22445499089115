import { IUserDetail, IUser, IUserTooltip } from '~/models/user';
import { convertUser, convertUsers } from '~/utils/userManagement';
import httpClient from '~/lib/client/httpClient';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ITimezone } from '~/models/common';
import { queryClient } from '~/lib/react-query';
import { alertParams, showAlert } from '~/components/common/Alert';
import { IStore } from '~/models/stores';
import { toQueryString } from '~/utils/queryString';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getUsers = (params: { [key: string]: any }): Promise<IUser[]> =>
  httpClient.json.get(
    `${endpoints.user.users}?${toQueryString(params, { arrayFormat: 'none' })}`,
  );

export const getUser = ({ id }: { id: string }): Promise<IUser> =>
  httpClient.json.get(endpoints.user.detail(id));

export const updateUserStatus = ({ id, ...body }): Promise<any> =>
  httpClient.json.put(endpoints.user.status(endpoints.user.status(id)), body);
export const updateUser = ({
  id,
  first_name,
  last_name,
  email,
  role,
}): Promise<IUserDetail> =>
  httpClient.json.put(endpoints.user.update(id), {
    first_name,
    last_name,
    email,
    role,
  });

const updateUserTooltip = ({
  tooltip,
}: {
  tooltip: IUserTooltip;
}): Promise<IUser> =>
  httpClient.json.put(endpoints.user.user, {
    tooltip,
  });

export const updateConnection = (params): Promise<IStore> =>
  httpClient.json.put(`integrations/${params.id}`, params);

export const inviteUser = ({ role, ...body }): Promise<any> =>
  httpClient.json.post(endpoints.user.invite(role), body);

export const createAPIKey = (body): Promise<any> =>
  httpClient.json.post('integrations', body);

export const deleteUser = (id: string): Promise<any> =>
  httpClient.json.delete(endpoints.user.detail(id));

export const deleteConnection = (id: string): Promise<any> =>
  httpClient.json.delete(`integrations/${id}`);

export const resendInvitation = (id: string): Promise<any> =>
  httpClient.json.post(endpoints.user.resendInvitation(id));

export const rotateAPIKey = (id: string): Promise<any> =>
  httpClient.json.post(`integrations/${id}`);

export const useGetUsersQuery = ({ params }) =>
  useQuery({
    queryKey: [queryKeys.userManagement.getUsers, params],
    queryFn: () => getUsers(params),
    initialData: () =>
      queryClient.getQueryData([queryKeys.userManagement.getUsers, params]),
    select: (data) => convertUsers(data),
  });

export const useGetUserQuery = ({ id }) =>
  useQuery({
    queryKey: [queryKeys.userManagement.getUser, id],
    queryFn: () => getUser({ id }),
    enabled: !!id,
    initialData: {} as IUser,
    select: (data) => convertUser(data),
  });

export const useUpdateUserStatusMutation = (
  { onSuccess, onError } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateUserStatus,
    onSuccess,
    onError,
  });

export const useUpdateUserMutation = (
  { onSuccess, onError } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateUser,
    onSuccess,
    onError,
  });

export const useUpdateConnectionMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateConnection,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      showAlert({
        ...alertParams.success,
        title: 'Store Updated Successfully',
      });
    },
    onError,
  });

export const useInviteUserMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: inviteUser,
    onSuccess,
    onError,
  });

export const useCreateAPIKeyMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: createAPIKey,
    onSuccess,
    onError,
  });
};

export const useDeleteUserMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: deleteUser,
    onSuccess,
    onError,
  });
};

export const useResendInvitationMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: resendInvitation,
    onSuccess,
    onError,
  });
};

export const useDeleteConnectionMutation = ({ onSuccess }) =>
  useMutation({
    mutationFn: deleteConnection,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      showAlert({
        ...alertParams.success,
        title: 'Store was deleted successfully',
      });
    },
  });

export const useRotateAPIKeyMutation = () =>
  useMutation({
    mutationFn: rotateAPIKey,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      showAlert({
        ...alertParams.success,
        title: 'Regenerate Key Successfully',
      });
    },
  });

export const getTimezone = (): Promise<ITimezone[]> =>
  httpClient.json.get(endpoints.timezone.timezone);

export const useGetTimezoneQuery = () =>
  useQuery({
    queryKey: [queryKeys.timezone.getTimezone],
    queryFn: () => getTimezone(),
    initialData: null,
  });

export const useUpdateUserTooltipMutation = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: updateUserTooltip,
    onSuccess,
    onError,
  });
};
