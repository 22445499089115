import { object, string } from 'yup';
import { validatePhoneNumber } from '~/pages/LiveTracking/utils';

const InviteUserSchema = object().shape({
  first_name: string().trim().required('First Name is required'),
  last_name: string().trim().required('Last Name is required'),
  email: string().trim().email('Invalid email').required('Email is required'),
  role: string().trim().required('Role is required'),
});

const InviteDriverSchema = object().shape({
  first_name: string().trim().required('First Name is required'),
  last_name: string().trim().required('Last Name is required'),
  display_name: string().trim(),
  phone: string()
    .required('Phone is required')
    .test('phone', 'Invalid phone number', validatePhoneNumber),
});

const ChangeAccountSchema = object().shape({
  display_name: string()
    .trim()
    .required('Name is required')
    .trim()
    .max(50, 'Max 50 characters'),
});

export { InviteUserSchema, InviteDriverSchema, ChangeAccountSchema };
