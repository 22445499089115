import { Stack } from '@mui/material';
import { styled } from '~/themes';

export const StyledFilterContainer = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'gap': theme.spacing(1, 2),
  'flexWrap': 'wrap',
  'marginBottom': theme.spacing(1),
  '& > *': {
    '&:last-child': {
      marginLeft: 'auto',
    },
  },
  '& > form': {
    minWidth: 300,
  },

  [theme.breakpoints.down('md')]: {
    'gap': theme.spacing(1),
    '& > form': {
      minWidth: 0,
      display: 'flex',
      flex: 1,
    },
  },
}));
