import { createRoot } from 'react-dom/client';
import './assets/styles/index.scss';
import AppProvider from './providers/AppProvider';
import { AppRoutes } from './routers';
import { addPendoScript } from './utils/pendo';
import {
  addGoogleTagCustomScript,
  addGoogleTagScript,
} from './utils/googleTag';
import { addMetaPixelScript } from './utils/metaPixel';
import { initializeSentry } from './utils/sentry';

addPendoScript();
addGoogleTagScript();
addGoogleTagCustomScript();
addMetaPixelScript();
initializeSentry();
const App = () => (
  <AppProvider>
    <AppRoutes />
  </AppProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
