import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import themes from '~/themes';
import moment from 'moment-timezone';
import { getTimeZoneNameAbbr } from '~/utils/formatter';
import { KeyboardArrowRight } from '@mui/icons-material';
import Button from '~/components/common/Button';
import SelectIcon from '~/assets/images/icons/bulk-select.svg';
import CloseIcon from '~/assets/images/icons/close.svg';
import CheckBox from '~/components/common/CheckBox';
import EditIcon from '~/assets/images/icons/edit.svg';
import DisableEditIcon from '~/assets/images/icons/edit-gray.svg';
import { DeliveryHours, IStore } from '~/models/stores';
import { TIME_SLOT_TYPE } from '~/constants/stores';
import { maxBy, minBy } from 'lodash';
import {
  DeliveryHoursSelectItem,
  DialogAddTimeSlotsHours,
} from './DialogAddTimeSlots';

type Props = {
  data: Array<DeliveryHoursSelectItem>;
  onChange: (val) => void;
  rawData: DeliveryHours;
  store: IStore;
  enableDelivery: boolean;
};

const TimeSlots = ({
  data,
  onChange,
  rawData,
  store,
  enableDelivery,
}: Props) => {
  const [hasBulkSelect, setHasBulkSelect] = useState(false);
  const [daySelected, setDaySelected] = useState<
    Array<DeliveryHoursSelectItem>
  >([]);
  const [openAddTimeSlots, setOpenAddTimeSlots] = useState(false);

  const currentTimeZone = useMemo(() => {
    const { timezone } = store;
    return getTimeZoneNameAbbr(timezone);
  }, [store]);

  const handleCancelBulk = () => {
    setDaySelected([]);
    setHasBulkSelect(false);
  };

  const handleEditTimeSlot = (timeSlot) => {
    if (!hasBulkSelect) {
      setDaySelected([timeSlot]);
      setOpenAddTimeSlots(true);
    }
  };

  const renderSlot = (item) => {
    let content = null;

    if (!item?.available) {
      content = (
        <Typography
          variant='body1'
          sx={{
            color: '#8C95BA',
          }}
        >
          Not available
        </Typography>
      );
    } else if (enableDelivery) {
      if (item.time_slot_generation === TIME_SLOT_TYPE.NONE) {
        content = (
          <Typography variant='body1'>{`Before ${moment(
            item.last_order_time,
            'HH:mm',
          ).format('hh:mm a')}`}</Typography>
        );
      }

      if (item.time_slot_generation === TIME_SLOT_TYPE.CUSTOM) {
        const earliest_from_time = minBy(
          item?.time_slot,
          (slot: any) => slot?.from_time,
        )?.from_time;

        const latest_to_time = maxBy(
          item?.time_slot,
          (slot: any) => slot?.to_time,
        )?.to_time;

        content = (
          <Typography variant='body1'>{`${item?.totalTimeSlot} time slot${
            item?.totalTimeSlot === 1 ? '' : 's'
          } from ${moment(earliest_from_time, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(latest_to_time, 'HH:mm').format(
            'hh:mm a',
          )}`}</Typography>
        );
      }
      if (item.time_slot_generation === TIME_SLOT_TYPE.AUTO) {
        content = (
          <Typography variant='body1'>
            {`${item?.totalTimeSlot} time slot${
              item?.totalTimeSlot === 1 ? '' : 's'
            } from ${moment(item.from_time, 'HH:mm').format(
              'hh:mm a',
            )} to ${moment(item.to_time, 'HH:mm').format('hh:mm a')}`}
          </Typography>
        );
      }
    } else {
      content = (
        <Typography variant='body1'>
          {`${moment(item.last_order_time, 'HH:mm').format('hh:mm a')}`}
        </Typography>
      );
    }

    return (
      <Stack
        key={`${item.weekdayValue}`}
        flexDirection='row'
        alignItems='center'
        sx={{
          'padding': '0px 8px',
          '&:hover': {
            backgroundColor: '#E5E3F4',
            cursor: 'pointer',
          },
          'minHeight': 42,
        }}
        onClick={() => handleEditTimeSlot(item)}
      >
        <Stack sx={{ width: 140 }}>
          {hasBulkSelect ? (
            <CheckBox
              checked={daySelected.includes(item)}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setDaySelected((prevState) => [...prevState, item]);
                } else {
                  setDaySelected((prevState) =>
                    prevState?.filter(
                      (day) => day.weekdayValue !== item.weekdayValue,
                    ),
                  );
                }
              }}
              defaultCheckedIcon
              label={item.weekDayLabel}
            />
          ) : (
            <Typography variant='body1'>{item?.weekDayLabel}</Typography>
          )}
        </Stack>
        <Stack flex={1}>{content}</Stack>
        <KeyboardArrowRight
          sx={{ color: `${themes.color.violet900} !important` }}
        />
      </Stack>
    );
  };

  return (
    <Stack flexDirection='column' gap={1.8}>
      <Stack flexDirection='column' gap={0.8}>
        <Typography variant='h5' color={themes.color.black}>
          {enableDelivery ? 'Time Slots' : 'Regular Hours'}
        </Typography>
        <Typography variant='caption'>
          Current location timezone: {currentTimeZone}
        </Typography>
      </Stack>
      <Stack flexDirection='column'>
        <Stack flexDirection='row' sx={{ padding: '6px 8px' }}>
          <Typography
            variant='caption'
            sx={{
              color: '#8C95BA',
              width: 140,
            }}
          >
            Day of the week
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: '#8C95BA',
            }}
          >
            {enableDelivery
              ? 'Time slot(s)/Availability'
              : 'Last order time/Availability'}
          </Typography>
        </Stack>
        {data?.map((item) => renderSlot(item))}
        <Stack flexDirection='row' gap={1.6}>
          {hasBulkSelect ? (
            <>
              <Button
                noRounder
                buttonType='default'
                sx={{
                  mt: 1.5,
                  gap: '4px',
                  width: 'fit-content',
                }}
                onClick={() => {
                  // setHasBulkSelect(false);
                  setOpenAddTimeSlots(true);
                }}
                disabled={!daySelected?.length}
              >
                <img
                  src={daySelected?.length ? EditIcon : DisableEditIcon}
                  alt=''
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
                Bulk edit
              </Button>
              <Button
                noRounder
                buttonType='default'
                sx={{
                  mt: 1.5,
                  gap: '4px',
                  width: 'fit-content',
                }}
                onClick={handleCancelBulk}
              >
                <img src={CloseIcon} alt='' />
                Cancel
              </Button>
            </>
          ) : (
            <Button
              noRounder
              buttonType='default'
              sx={{
                mt: 1.5,
                gap: '4px',
                width: 'fit-content',
              }}
              onClick={() => setHasBulkSelect(true)}
            >
              <img src={SelectIcon} alt='' />
              Bulk Select
            </Button>
          )}
        </Stack>
      </Stack>
      {openAddTimeSlots && (
        <DialogAddTimeSlotsHours
          open
          enableDelivery={enableDelivery}
          onClose={() => {
            if (!hasBulkSelect) {
              setDaySelected([]);
            }
            setOpenAddTimeSlots(false);
          }}
          data={daySelected}
          onSave={(val) => {
            const newVal = { ...rawData };
            daySelected?.forEach((day) => {
              newVal[day.weekdayValue] = {
                ...newVal[day.weekdayValue],
                ...val,
                weekdayValue: day.weekdayValue,
                weekDayLabel: day.weekDayLabel,
              };
            });

            onChange(newVal);
            setHasBulkSelect(false);
            setDaySelected([]);
          }}
          store={store}
        />
      )}
    </Stack>
  );
};

export default TimeSlots;
