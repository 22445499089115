import { useEffect, useState } from 'react';

export const useOperatingSystem = () => {
  const [os, setOs] = useState('');

  useEffect(() => {
    const { userAgent } = window.navigator;
    let v = 'unknown';

    if (userAgent.includes('Mac')) {
      v = 'macOS';
    } else if (userAgent.includes('Win')) {
      v = 'Windows';
    }

    setOs(v);
  }, []);

  return {
    isMacOs: os === 'macOS',
    isWindow: os === 'Windows',
  };
};
