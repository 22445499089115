import { object, string, number, array } from 'yup';
import { validatePhoneNumber } from '~/pages/LiveTracking/utils';

// Driver Verification

const DriverAddressSchema = object().shape({
  address_line_1: string().trim().required('Address Line 1 is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  city: string().trim().required('City is required'),
  postcode: string().trim().required('Postcode is required'),
  phone_number: string().trim().required('Phone number is required'),
  email: string().trim().email('Invalid email').required('Email is required'),
});

const DriverVerificationSchema = object().shape({});

const DriverAgeSchema = object().shape({
  first_name: string().trim().required('First Name is required'),
  last_name: string().trim().required('Last Name is required'),
  date_of_birth: string().required('Date of birth is required'),
});

const DriverSinNumberSchema = object().shape({
  sin_number: string().trim().required('SIN Number is required'),
});

const DriverVehiclesSchema = object().shape({
  vehicle: object().shape({
    // make_model: string().required('Make / Brand is a required field'),
    // color: string().required('Color is a required field'),
    // licence_plate: string().required('Plate No. is a required field'),
  }),
});

const DriverWeeklyHoursSchema = object().shape({
  preferred_areas: array().of(
    object().shape({
      name: string().required('Name is a required field'),
      address_line_1: string().required('Address Line 1 is a required field'),
      country: string().required('Country is a required field'),
      state: string().required('Province / State is a required field'),
      city: string().required('City is a required field'),
      postcode: string().required('Postal Code is a required field'),
      radius: number()
        .required('Radius is a required field')
        .moreThan(0, 'Must be more than 0')
        .max(25, 'Must be less than or equal to 25'),
    }),
  ),
});

const DriverPaymentBankingSchema = object().shape({
  payment_detail: object().shape({
    transit_number: string().required('Transit Number is a required field'),
    institution_number: string().required(
      'Institution Number is a required field',
    ),
    account_number: string().required('Account Number is a required field'),
    payment_frequency: string().required(
      'Payment Frequency is a required field',
    ),
  }),
});

// Driver Detail

const DriverProfileSchema = object().shape({
  first_name: string().trim().required('First Name is required'),
  last_name: string().trim().required('Last Name is required'),
  display_name: string()
    .required('This field is required')
    .trim()
    .max(50, 'Max 50 characters'),
  phone: string().trim().required('Phone number is required'),
  email: string().email('Invalid email address'),
  customer_support: string(),
  alternative_phone_country_code: string(),
  alternative_phone: string().when('customer_support', {
    is: (v) => v && v === 'contact_alternative_phone',
    then: string()
      .trim()
      .required('Alternative Phone number is required')
      .test(
        'alternative_phone',
        'Invalid phone number',
        (value: any, testContext: any) => {
          testContext.parent.country_code =
            testContext.parent.alternative_phone_country_code || 'CA';
          return validatePhoneNumber(value, testContext);
        },
      ),
  }),
});

const DriverSettingSchema = object().shape({
  commission_rate: string().trim().required('Commission Rate is required'),
});

export {
  DriverAddressSchema,
  DriverVerificationSchema,
  DriverAgeSchema,
  DriverSinNumberSchema,
  DriverVehiclesSchema,
  DriverWeeklyHoursSchema,
  DriverPaymentBankingSchema,
  DriverProfileSchema,
  DriverSettingSchema,
};
