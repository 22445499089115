import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { styled } from '~/themes';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import {
  AddressFormSchema,
  AddressWithoutValidatePhoneFormSchema,
} from '~/utils/schema/address';
import { IAddress } from '~/models/address';
import { cloneDeep } from 'lodash';
import { CountryCode } from 'libphonenumber-js';
import { getStateNameByStateCodeAndCountry } from '~/utils/formatter';
import { useAuth } from '~/providers/AuthProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useMemo } from 'react';
import { getPhoneWithCountryCode } from '~/utils/common';
import { AddressInput } from './AddressInput';
import {
  addressBookQueryKeys,
  useCreateAddressBook,
  useUpdateAddressBook,
} from '../../apis';

export const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.8),
  },
}));

interface IAddressFormProps {
  open: boolean;
  onClose: () => void;
  selectedItem: IAddress;
}

export const AddressForm = ({
  open,
  selectedItem,
  onClose,
}: IAddressFormProps) => {
  const isOffValidatePhone = useFeatureIsOn(
    'ubp-ignore-validate-phone-in-warehouse-pickup-addressbook',
  );
  const validationSchema = useMemo(
    () =>
      isOffValidatePhone
        ? AddressWithoutValidatePhoneFormSchema
        : AddressFormSchema,
    [isOffValidatePhone],
  );
  const { countries } = useAuth();

  const { mutate: createAddressMutation, isLoading: isCreateAddressLoading } =
    useCreateAddressBook({
      onSuccess: () => {
        onClose();
        showAlert({
          ...alertParams.success,
          title: 'Contact is added',
        });
        queryClient.invalidateQueries(
          addressBookQueryKeys.getAddressBookList(),
        );
      },
      onError: () => {
        onClose();
      },
    });

  const { mutate: updateAddressMutation, isLoading: isUpdateAddressLoading } =
    useUpdateAddressBook({
      onSuccess: () => {
        onClose();
        showAlert({
          ...alertParams.success,
          title: 'Updated successfully',
        });
        queryClient.invalidateQueries(
          addressBookQueryKeys.getAddressBookList(),
        );
      },
      onError: () => {
        onClose();
      },
    });

  const onSubmit = (values: any) => {
    const params: IAddress = cloneDeep(values);

    if (params.phone) {
      params.phone = getPhoneWithCountryCode(
        params.phone,
        params.country_code as CountryCode,
      );
    } else if (!selectedItem) {
      delete params.phone;
    }

    const stateName = getStateNameByStateCodeAndCountry(
      countries,
      params.country,
      params.state,
    );

    params.state_name = stateName;

    if (selectedItem) {
      return updateAddressMutation(params);
    }

    if (!params.email) {
      delete params.email;
    }
    return createAddressMutation(params);
  };

  return (
    <Modal
      title={selectedItem ? 'Edit Contact' : 'Add New Contact'}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'create-address',
          loading: isCreateAddressLoading || isUpdateAddressLoading,
        },
      ]}
    >
      <Formik
        initialValues={
          selectedItem || {
            name: '',
            phone: '',
            country_code: 'CA',
            address_1: '',
            address_2: '',
            country: 'CA',
            state: 'BC',
            city: '',
            postcode: '',
            email: '',
          }
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <StyledForm
          id='create-address'
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <AddressInput />
        </StyledForm>
      </Formik>
    </Modal>
  );
};
