import React from 'react';
import SwitchButton from '~/components/common/SwitchButton';
import { IFormProps } from '~/models/common';
import { ISwitchButton } from '../SwitchButton/SwitchButton';

export interface IFieldSwitchButton
  extends IFormProps,
    Omit<ISwitchButton, 'name' | 'form'> {}

const FormSwitchButton: React.FC<IFieldSwitchButton> = ({
  field,
  form,
  inputProps,
  ...props
}) => (
  <SwitchButton
    checked={!!field.value}
    name={field.name}
    inputProps={{
      ...inputProps,
      ...field,
    }}
    {...props}
  />
);

export default FormSwitchButton;
