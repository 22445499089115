import Modal from '~/components/common/Modal';
import { IconButton, Stack, Typography } from '@mui/material';
import Button, { CloseButton } from '~/components/common/Button';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import themes, { styled } from '~/themes';
import { useCallback, useMemo, useRef, useState } from 'react';
import CropIcon from '~/assets/images/icons/crop.svg';
import RevertIcon from '~/assets/images/icons/revert.svg';
import UploadIcon from '~/assets/images/icons/upload.svg';
import RemoveIcon from '~/assets/images/icons/delete.svg';
import { errorAlert, showAlert } from '~/components/common/Alert';
import { canvasPreview, rotateImage } from './utils';
import { useUploadImageContext } from './UploadImageProvider';

const DEFAULT_SCALE = 1;

export interface IUploadImageItem {
  id: string;
  src: string;
}

export const StyledUploadButton = styled('label')(() => ({
  input: {
    display: 'none',
  },
}));

export const StyledPopup = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.color.white,
}));

type UploadImageDialogProps = {
  isSelectMultiFile?: boolean;
};

export const UploadImageDialog: React.FC<UploadImageDialogProps> = ({
  isSelectMultiFile = false,
}) => {
  const {
    images,
    defaultImages,
    openUploadDialog,
    onToggleUploadDialog,
    setImages,
    onSaveImage,
  } = useUploadImageContext();

  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<PixelCrop | undefined>();
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [rotate, setRotate] = useState(0);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imgSelectId, setImgSelectId] = useState<number>(0);

  const handleDisabledCrop = useCallback(() => {
    setIsCrop(false);
    setCrop(undefined);
    setRotate(0);
  }, []);

  const handleSaveImage = async () => {
    onToggleUploadDialog();
    handleDisabledCrop();
    if (!images.length) {
      onSaveImage(null);
      return setImages([]);
    }

    if (isCrop && crop?.width && crop?.height && imgRef.current) {
      // Update Crop Image
      // We use canvasPreview as it's much faster than imgPreview.
      const url = canvasPreview(imgRef.current, crop, DEFAULT_SCALE, 0);
      const blob = await (await fetch(url)).blob();
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob, `avatar.${blob.type.split('/')[1]}`);
        onSaveImage(formData, blob);
      } else {
        showAlert(errorAlert('Invalid file.'));
      }
      return true;
    }

    // For rotate
    fetch(images[imgSelectId])
      .then((res) => res.blob())
      .then(async (res) => {
        const formData = new FormData();
        formData.append('file', res, `avatar.${res.type.split('/')[1]}`);
        onSaveImage(formData, res);
      });
    return true;
  };

  const onRotate = useCallback(
    async (value?: number) => {
      if (images.length) {
        const newRotate = ((value || rotate) - 90) % 360;
        setRotate(newRotate);
        if (imgRef.current) {
          const type = images[imgSelectId]?.substring(
            'data:'.length,
            images[imgSelectId]?.indexOf(';base64'),
          );
          const data = await rotateImage(imgRef.current, type);
          setImages([data]);
        }
      }
    },
    [images, imgSelectId, rotate],
  );

  const onCrop = useCallback(() => {
    setIsCrop(true);
  }, []);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    imgRef.current = e.currentTarget;
    imgRef.current.setAttribute('crossorigin', 'anonymous');
    const { width, height } = imgRef.current;
    const newCrop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width,
        },
        width / height,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop({
      ...newCrop,
      unit: 'px',
    });
  };

  const onSelectFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        handleDisabledCrop();
        if (event.target.files[0].size > 5 * 1024 * 1024) {
          showAlert(errorAlert('File size must be less than 5 MB.'));
        } else {
          setCrop(undefined);
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            const img = reader?.result?.toString() as string;

            if (isSelectMultiFile) {
              setImages((e: string[]) => e.concat(img));
            } else {
              setImages([img]);
            }
          });
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    },
    [],
  );

  const handleCloseDialog = useCallback(() => {
    onToggleUploadDialog();
    handleDisabledCrop();
    setImages(defaultImages || []);
  }, [defaultImages]);

  const actionButtonsComponent = useMemo(
    () => (
      <Stack
        direction='row'
        justifyContent='flex-end'
        spacing={1.5}
        p={1}
        pt={2}
        sx={{
          button: {
            'width': 40,
            'height': 40,
            'background': themes.bg.gray900,
            'color': 'white',
            '&:hover': {
              background: themes.bg.primaryOrange,
            },
          },
          label: {
            'cursor': 'pointer',
            'width': 40,
            'height': 40,
            'background': themes.bg.gray900,
            'color': 'white',
            '&:hover': {
              background: themes.bg.gray900,
            },
            'borderRadius': '50%',
            'textAlign': 'center',
            'img': {
              marginTop: '10px',
            },
          },
          input: {
            display: 'none',
          },
        }}
      >
        <label htmlFor='file-upload'>
          <img alt='upload' src={UploadIcon} />
          <input
            id='file-upload'
            type='file'
            accept='image/*'
            multiple={isSelectMultiFile}
            onChange={onSelectFile}
          />
        </label>
        <IconButton
          onClick={() => Boolean(images.length) && setDeletePopup(true)}
          size='large'
        >
          <img alt='remove' src={RemoveIcon} />
        </IconButton>
        <IconButton
          onClick={() => Boolean(images.length) && onCrop()}
          size='large'
        >
          <img alt='crop' src={CropIcon} />
        </IconButton>
        <IconButton
          onClick={() => Boolean(images.length) && onRotate()}
          size='large'
        >
          <img alt='revert' src={RevertIcon} />
        </IconButton>
        <Button
          onClick={handleSaveImage}
          disabled={
            (!defaultImages.length && !images[imgSelectId]) ||
            (images[imgSelectId] &&
              defaultImages.includes(images[imgSelectId]) &&
              rotate === 0 &&
              !isCrop)
          }
        >
          Save
        </Button>
      </Stack>
    ),
    [images, imgSelectId, rotate, imgRef.current, crop, isCrop],
  );

  const bodyComponent = useMemo(() => {
    if (deletePopup) {
      return (
        <StyledPopup>
          <Typography fontSize={18} fontWeight={600} mb={1}>
            Delete this image?
          </Typography>
          <Typography>
            You won’t be able to recover it in the future, unless you upload it
            again.
          </Typography>
          <Stack spacing={2} direction='row' mt={3}>
            <Button buttonType='default' onClick={() => setDeletePopup(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setImages([]);
                setCrop(undefined);
                setDeletePopup(false);
              }}
            >
              Delete
            </Button>
          </Stack>
        </StyledPopup>
      );
    }

    if (images.length) {
      return (
        <ReactCrop
          crop={isCrop ? crop : undefined}
          onChange={(pixelCrop) => setCrop(pixelCrop)}
          disabled={!isCrop}
          style={{ height: 'fit-content' }}
        >
          <img alt='img' src={images[imgSelectId] || ''} onLoad={onImageLoad} />
        </ReactCrop>
      );
    }

    return (
      <StyledPopup>
        <Typography fontSize={18} fontWeight={600} mb={1}>
          Upload New Image
        </Typography>
        <StyledUploadButton htmlFor='file-upload'>
          <Typography
            sx={{
              background: '#FA8C73',
              borderRadius: 19,
              maxHeight: 35,
              padding: '9px 36px',
              marginTop: themes.spacing(3),
              color: 'white',
              fontWeight: 600,
              cursor: 'pointer',
            }}
          >
            Upload
          </Typography>
          <input
            id='file-upload'
            type='file'
            accept='image/*'
            onChange={onSelectFile}
          />
        </StyledUploadButton>
      </StyledPopup>
    );
  }, [images, imgSelectId, deletePopup, isCrop, crop, imgRef.current]);
  return (
    <Modal
      disableCloseOutside
      PaperProps={{
        sx: {
          background: '#2E2E2E',
        },
      }}
      open={openUploadDialog}
      onClose={handleCloseDialog}
      maxWidth='sm'
      fullWidth
    >
      <Stack minHeight={540}>
        <Stack direction='row' py={2} justifyContent='space-between'>
          <Typography color='white' fontSize={16} fontWeight='bold'>
            Profile Image
          </Typography>
          <CloseButton onClick={handleCloseDialog} />
        </Stack>

        <Stack
          direction='row'
          justifyContent='center'
          py={2}
          sx={{
            background: 'black',
            flex: 1,
            alignItems: 'center',
          }}
        >
          {bodyComponent}
        </Stack>
        {actionButtonsComponent}
      </Stack>
    </Modal>
  );
};
