import { Stack, Typography } from '@mui/material';
import themes from '~/themes';
import AddIcon from '~/assets/images/icons/add-black.svg';
import Button from '~/components/common/Button';
import moment from 'moment';
import { useState } from 'react';
import { IStore } from '~/models/stores';
import { maxBy, minBy, sumBy, values } from 'lodash';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { TIME_SLOT_TYPE } from '~/constants/stores';
import { DialogAddTimeSlotsHours } from './DialogAddTimeSlots';

type Props = {
  data?: any;
  store?: IStore;
  rawData?: any;
  onChange: (val) => void;
  enableDelivery: boolean;
};

const SpecialHours = ({
  data,
  store,
  rawData,
  onChange,
  enableDelivery,
}: Props) => {
  const [openSpecialHours, setOpenSpecialHours] = useState(false);
  const [daySelected, setDaySelected] = useState<any>();

  const getTotalTimeSlots = (date) => {
    if (!enableDelivery) {
      return `${moment(date.last_order_time, 'HH:mm').format('hh:mm a')}`;
    }

    const { from_time, to_time, delivery_hours } = date;
    const totalTimeSlot = sumBy(
      values(delivery_hours),
      (day) => day?.time_slot?.length,
    );

    return `${totalTimeSlot} time slot${
      totalTimeSlot === 1 ? '' : 's'
    } from ${moment(from_time, 'HH:mm').format('hh:mm a')} to ${moment(
      to_time,
      'HH:mm',
    ).format('hh:mm a')}`;
  };

  const getInValidDays = (specialHours) => {
    const dates = (specialHours || []).reduce((acc, item) => {
      let now = item.start_date ? moment(item.start_date) : null;
      const endM = item.end_date ? moment(item.end_date) : null;
      while (now && endM && now.isSameOrBefore(endM)) {
        acc[now.format('MM/DD/YYYY')] = true;
        now = now.add(1, 'days');
      }
      return acc;
    }, {});
    return Object.keys(dates);
  };

  const renderSlot = (date, idx) => {
    let content = null;

    if (!date?.available) {
      content = (
        <Typography
          variant='body1'
          sx={{
            color: '#8C95BA',
          }}
        >
          Not available
        </Typography>
      );
    } else if (enableDelivery) {
      if (date.time_slot_generation === TIME_SLOT_TYPE.NONE) {
        content = (
          <Typography variant='body1'>{`Before ${moment(
            date.last_order_time,
            'HH:mm',
          ).format('hh:mm a')}`}</Typography>
        );
      }

      if (date.time_slot_generation === TIME_SLOT_TYPE.CUSTOM) {
        const totalTimeSlot = sumBy(
          values(date.delivery_hours),
          (day) => day?.time_slot?.length,
        );
        const earliest_from_time = minBy(
          date.delivery_hours?.[date.start_date]?.time_slot,
          (slot: any) => slot?.from_time,
        ).from_time;

        const latest_to_time = maxBy(
          date.delivery_hours?.[date.start_date]?.time_slot,
          (slot: any) => slot?.to_time,
        ).to_time;

        content = (
          <Typography
            variant='body1'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            <ToolTipText
              text={`${totalTimeSlot} time slot${
                totalTimeSlot === 1 ? '' : 's'
              } from ${moment(earliest_from_time, 'HH:mm').format(
                'hh:mm a',
              )} to ${moment(latest_to_time, 'HH:mm').format('hh:mm a')}`}
              maxLength={30}
            />
          </Typography>
        );
      }
      if (date.time_slot_generation === TIME_SLOT_TYPE.AUTO) {
        content = (
          <Typography
            variant='body1'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            <ToolTipText text={getTotalTimeSlots(date)} maxLength={30} />
          </Typography>
        );
      }
    } else {
      content = (
        <Typography variant='body1'>
          {`${moment(date.last_order_time, 'HH:mm').format('hh:mm a')}`}
        </Typography>
      );
    }

    return (
      <Stack
        key={idx}
        flexDirection='row'
        alignItems='center'
        gap={0.5}
        sx={{
          'padding': '0px 8px',
          '&:hover': {
            backgroundColor: '#E5E3F4',
            cursor: 'pointer',
          },
          'minHeight': 42,
        }}
      >
        <Stack sx={{ width: 120 }}>
          <Typography variant='body1'>{date?.name}</Typography>
        </Stack>
        <Stack sx={{ width: 220 }}>
          <Typography variant='body1'>{`${moment(date?.start_date).format(
            'MMM D, YYYY',
          )} - ${moment(date?.end_date).format('MMM D, YYYY')}`}</Typography>
        </Stack>
        <Stack flex={1}>{content}</Stack>
        <ElementSelect
          paperProps={{
            sx: {
              width: 160,
            },
          }}
          elementSelect={() => (
            <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
          )}
          onChange={(o) => {
            if (o.value === 'edit') {
              setDaySelected({ ...date, idx });
              setOpenSpecialHours(true);
            }
            if (o.value === 'delete') {
              onChange(data.filter((_, i) => idx !== i));
            }
          }}
          options={[
            {
              label: 'Edit',
              value: 'edit',
            },
            {
              label: 'Delete',
              value: 'delete',
            },
          ]}
        />
      </Stack>
    );
  };

  const renderTimeSlots = () => {
    if (data?.length === 0) return null;

    return (
      <>
        <Stack flexDirection='row' gap={0.5} sx={{ padding: '6px 8px' }}>
          <Typography
            variant='caption'
            sx={{
              color: '#8C95BA',
              width: 120,
            }}
          >
            Name
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: '#8C95BA',
              width: 220,
            }}
          >
            Date
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: '#8C95BA',
            }}
          >
            {enableDelivery
              ? 'Time slot(s)/Availability'
              : 'Last order time/Availability'}
          </Typography>
        </Stack>
        {data?.map((date, idx) => renderSlot(date, idx))}
      </>
    );
  };

  return (
    <Stack flexDirection='column' gap={1.8}>
      <Stack flexDirection='column' gap={0.8}>
        <Typography variant='h5' color={themes.color.black}>
          Special Hours
        </Typography>
        <Typography variant='caption'>
          Set special hours for an occasion (holiday, vacation, construction
          etc.)
        </Typography>
      </Stack>
      <Stack flexDirection='column'>
        {renderTimeSlots()}
        <Stack flexDirection='row' gap={1.6}>
          <Button
            noRounder
            buttonType='default'
            sx={{
              mt: 1.5,
              gap: '4px',
              width: 'fit-content',
            }}
            onClick={() => setOpenSpecialHours(true)}
          >
            <img src={AddIcon} alt='' />
            Add Special Hours
          </Button>
        </Stack>
      </Stack>
      {openSpecialHours && (
        <DialogAddTimeSlotsHours
          isSpecial
          open
          enableDelivery={enableDelivery}
          onClose={() => {
            setOpenSpecialHours(false);
            setDaySelected(null);
          }}
          data={daySelected ? [daySelected] : []}
          onSave={(val) => {
            let newVal = [...rawData];
            if (!daySelected) {
              newVal = [...newVal, val];
            } else {
              newVal = rawData?.map((day, idx) => {
                if (idx === daySelected.idx) {
                  return val;
                }
                return day;
              });
            }

            onChange(newVal);
            setDaySelected(null);
          }}
          store={store}
          invalidDays={
            daySelected
              ? getInValidDays(rawData).filter(
                  (e) => !getInValidDays([daySelected]).includes(e),
                )
              : getInValidDays(rawData)
          }
        />
      )}
    </Stack>
  );
};

export default SpecialHours;
