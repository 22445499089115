import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { toQueryString, cleanDeep } from '~/utils/queryString';
import { IDriver, IDriverLog } from '~/models/driver';
import { convertDriver, convertUsers } from '~/utils/userManagement';
import { DRIVER_DOCS } from '~/constants/driver';
import { queryClient } from '~/lib/react-query';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getDriverList = (
  params: {
    [key: string]: any;
  } = {},
): Promise<IDriver[]> => {
  cleanDeep(params);
  const queryString = toQueryString({
    ...params,
    roles: 'driver',
  });

  return httpClient.json.get(`${endpoints.driver.drivers}?${queryString}`);
};

export const getDriver = ({ id }: { id: string }): Promise<IDriver> =>
  httpClient.json.get(endpoints.user.detail(id));

export const getDriverLog = ({ id }: { id: string }): Promise<IDriverLog[]> =>
  httpClient.json.get(endpoints.driver.log(id));

export const getOneDriverDoc = (
  id: string,
  docId: string,
): Promise<{ blob: string; blob_type: string }> =>
  httpClient.json.get(endpoints.driver.docDetail(id, docId));

export const getMultiDriverDoc = (id: string, docIds: string[]) =>
  Promise.all(docIds.map((mId) => getOneDriverDoc(id, mId))).then((res) =>
    res.map(({ blob, blob_type }) => ({ photo: blob, photo_type: blob_type })),
  );

export const getDriverDoc = (data: IDriver): Promise<any[]> =>
  Promise.all(
    DRIVER_DOCS.map(async ({ id, isMultiple }) => {
      if (!data[id]) {
        return isMultiple ? null : {};
      }
      if (isMultiple && data[id].length) {
        return getMultiDriverDoc(data.id, data[id]);
      }
      return getOneDriverDoc(data.id, data[id]);
    }),
  );

export const updateDriver = ({ id, ...params }: IDriver) =>
  httpClient.json.put(endpoints.user.detail(id), params);

export const updateDriverAvatar = ({ body }: { body: any }): Promise<any> =>
  httpClient.formData.put(endpoints.user.avatar, body);

export const removeDriverAvatar = ({ body }: { body: any }): Promise<any> =>
  httpClient.json.put(endpoints.user.avatar, body);

export const updateDriverDoc = ({
  id,
  body,
}: {
  id: string;
  body: any;
}): Promise<any> => httpClient.formData.put(endpoints.driver.doc(id), body);

export const removeDriverDoc = ({
  id,
  body,
}: {
  id: string;
  body: any;
}): Promise<any> => httpClient.json.put(endpoints.driver.doc(id), body);

export const useGetDriversListQuery = (
  {
    params,
    enabled,
    onSuccess,
    onError,
    refetchInterval,
  }: {
    params?: { [key: string]: any };
    enabled?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
    refetchInterval?: any;
  } = {
    enabled: true,
    refetchInterval: false,
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useQuery({
    queryKey: [queryKeys.driver.getDriverList, params],
    queryFn: () => getDriverList(params),
    initialData: () =>
      queryClient.getQueryData([queryKeys.driver.getDriverList, params]),
    select: (data) => convertUsers(data),
    onSuccess,
    onError,
    enabled,
    keepPreviousData: true,
    cacheTime: 1 * 10 * 1000,
    refetchInterval,
  });

export const useGetUserQuery = ({ id }) =>
  useQuery({
    queryKey: [queryKeys.driver.getDriverDetail, id],
    queryFn: () => getDriver({ id }),
    enabled: !!id,
    initialData: {} as IDriver,
    select: (data) => convertDriver(data),
  });

export const useGetDriverLogQuery = ({ id }) =>
  useQuery({
    queryKey: [queryKeys.driver.getDriverLog, id],
    queryFn: () => getDriverLog({ id }),
    initialData: [],
    enabled: !!id,
  });

const getDriverDocKey = (data: IDriver): string[] =>
  DRIVER_DOCS.reduce((result, { id, isMultiple }) => {
    if (isMultiple && data[id]?.length) {
      return [...result, ...data[id]];
    }
    if (!isMultiple && typeof data[id] === 'string') {
      return [...result, data[id]];
    }
    return result;
  }, []);

export const useGetDriverDocQuery = (data: IDriver) =>
  useQuery({
    queryKey: [queryKeys.driver.getDriverDoc, ...getDriverDocKey(data)],
    queryFn: () => getDriverDoc(data),
    enabled: !!data?.id,
  });

export const useUpdateDriverMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (res: any, variables: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: updateDriver,
    onSuccess: (res, variables) => onSuccess(res, variables),
    onError,
  });

export const useUpdateDriverDoc = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateDriverDoc,
    onSuccess,
    onError,
  });
};

export const useUpdateDriverAvatar = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateDriverAvatar,
    onSuccess,
    onError,
  });
};

export const useRemoveDriverAvatar = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: removeDriverAvatar,
    onSuccess,
    onError,
  });
};

export const useRemoveDriverDoc = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: removeDriverDoc,
    onSuccess: (res: IDriver) => onSuccess(res),
    onError,
  });
};
