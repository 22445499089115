import { URLS } from '~/config';
import { IOptionItem } from '~/models/common';

export const COUNTRY_CODES: IOptionItem[] = [
  {
    value: 'CA',
    code: '1',
    label: 'CA',
  },
  {
    value: 'US',
    code: '1',
    label: 'US',
  },
  {
    value: 'VN',
    code: '84',
    label: 'VN',
  },
  {
    value: 'AU',
    code: '61',
    label: 'AU',
  },
];

export const ONGOING_STATUS_OPTIONS = [
  { label: 'Unassigned', value: 'unassigned' },
  { label: 'Pickup', value: 'pending_pickup' },
  { label: 'Dropoff', value: 'delivering' },
  { label: 'Return', value: 'returning' },
];

export const COMPLETED_STATUS_OPTIONS = [
  { label: 'Completed', value: 'completed' },
  { label: 'Returned', value: 'returned' },
  { label: 'Cancelled', value: 'cancelled' },
];

const urls = URLS ? JSON.parse(URLS) : {};

export const importFileUrl = {
  file: urls.importFileUrl,
  instruction: urls.importFileInstructions,
};

export const agreeTermsURL = urls.term;
