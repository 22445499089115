import httpClient from '~/lib/client/httpClient';
import { cleanDeep, toQueryString } from '~/utils/queryString';
import {
  MutationFunction,
  QueryFunction,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from '~/lib/react-query';
import {
  IAddressResponse,
  IBulkCreateAddressBook,
  ICreateAddressBookRequest,
  IGetAddressListRequest,
} from './types';

export enum AddressBookKeys {
  GET_ADDRESS_BOOK_LIST = 'AddressBook.GET_ADDRESS_BOOK_LIST',
}

export const addressBookQueryKeys = {
  getAddressBookList: (params?: any) =>
    [AddressBookKeys.GET_ADDRESS_BOOK_LIST, params].filter(Boolean),
};

export const fetchAddressBookList: QueryFunction<IAddressResponse> = async ({
  meta,
}) => {
  const params = meta as IGetAddressListRequest;
  cleanDeep(params);
  return httpClient.json.get(
    `address_books?${toQueryString(params, {
      arrayFormat: 'none',
    })}`,
  );
};

export const createAddress: MutationFunction<
  IAddressResponse,
  ICreateAddressBookRequest
> = (params) => httpClient.json.post('address_books', params);

export const updateAddress: MutationFunction<
  IAddressResponse,
  ICreateAddressBookRequest
> = (params) => httpClient.json.put(`address_books/${params.id}`, params);

export const bulkCreateAddress: MutationFunction<
  unknown,
  IBulkCreateAddressBook
> = (params) => httpClient.json.post('bulk_create_address_book', params);

export const useGetAddressQuery = (
  params: IGetAddressListRequest,
  options?: UseQueryOptions<IAddressResponse>,
) =>
  useQuery({
    queryKey: addressBookQueryKeys.getAddressBookList(params),
    queryFn: fetchAddressBookList,
    initialData: () =>
      queryClient.getQueryData(
        addressBookQueryKeys.getAddressBookList(params),
      ) as IAddressResponse,
    meta: params,
    keepPreviousData: true,
    cacheTime: 1 * 10 * 1000,
    ...options,
  });

export const useCreateAddressBook = (
  options?: UseMutationOptions<
    IAddressResponse,
    unknown,
    ICreateAddressBookRequest
  >,
) =>
  useMutation({
    mutationFn: createAddress,
    ...options,
  });

export const useUpdateAddressBook = (
  options?: UseMutationOptions<
    IAddressResponse,
    unknown,
    ICreateAddressBookRequest
  >,
) =>
  useMutation({
    mutationFn: updateAddress,
    ...options,
  });

export const useBulkCreateAddressBook = (
  options?: UseMutationOptions<unknown, unknown, IBulkCreateAddressBook>,
) =>
  useMutation({
    mutationFn: bulkCreateAddress,
    ...options,
  });
