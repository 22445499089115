import { Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { useCallback, useMemo, useState } from 'react';
import { FastField, Field } from '~/components/common/Formik';
import { Formik } from 'formik';
import {
  StoreAddressSchema,
  StoreURLSchema,
  WooStoreInformationSchema,
} from '~/utils/schema/stores';
import {
  ConnectionStoreStatus,
  CreateStoreStatus,
  IntegrationType,
} from '~/models/stores';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { useCheckConnection } from '~/services/api/stores';
import { TextLink } from '~/components/common/TextLink';
import { CanFleetPluginURL } from '~/constants/stores';
import { StyledHeaderTitle, StyledStoreForm } from '../style';
import { StoreAddressForm } from './StoreAddressForm';
import { StoreConnection } from './StoreConnection';
import { AddStoreLayout } from '../layout/AddStoreLayout';

type WooCommerceStoreProps = {
  step: number;
  setStep: (v: number) => void;
  setPlatform: (p: IntegrationType) => void;
  onClose: () => void;
  totalStep: number;
  createIntegrationMutation: any;
  isLoadingCreateIntegration: boolean;
};

export const WooCommerceStore: React.FC<WooCommerceStoreProps> = ({
  step,
  totalStep,
  onClose,
  setStep,
  setPlatform,
  createIntegrationMutation,
  isLoadingCreateIntegration,
}) => {
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStoreStatus>(null);

  const {
    mutate: checkConnectionMutation,
    isLoading: isLoadingCheckConnection,
  } = useCheckConnection({
    onSuccess: (resp) => {
      if (resp?.connected) {
        setConnectionStatus(ConnectionStoreStatus.Connected);
      } else {
        setConnectionStatus(ConnectionStoreStatus.NotConnected);
      }
    },
    onError: () => setConnectionStatus(ConnectionStoreStatus.NotConnected),
  });

  const handleSubmitAddStore = useCallback(
    (values, { setTouched }) => {
      if (step < 4) {
        if (step === 2) {
          setTouched({});
        }
        setStep(step + 1);
      } else {
        values.status =
          connectionStatus === ConnectionStoreStatus.Connected
            ? CreateStoreStatus?.Active
            : CreateStoreStatus?.Deactivated;
        createIntegrationMutation({
          integration_type: IntegrationType.Woo,
          store_url: values?.store_url,
          status: values.status,
          display_name: values?.display_name,
          address_1: values?.address_1,
          address_2: values?.address_2,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          postcode: values?.postcode,
          phone: parsePhoneNumber(
            values.phone,
            values.country_code as CountryCode,
          ).number,
        });
      }
    },
    [step, connectionStatus],
  );

  const validationSchema = useMemo(() => {
    if (step === 2) {
      return WooStoreInformationSchema;
    }
    if (step === 3) {
      return StoreAddressSchema;
    }
    if (step === 4) {
      return StoreURLSchema;
    }
    return null;
  }, [step]);

  const contentComponent = useMemo(() => {
    if (step === 2) {
      return (
        <>
          <StyledHeaderTitle>Store Information</StyledHeaderTitle>
          <Typography>Enter your store details.</Typography>
          <Stack spacing={2} width='100%' mt={9} mb={15}>
            <Field.TextInput legend='Store Name' name='display_name' />
            <Field.TextInput
              legend='Phone Number'
              name='phone'
              type='tel'
              prefix={<FastField.CountryCodeDropdown name='country_code' />}
            />
          </Stack>
        </>
      );
    }
    if (step === 3) {
      return (
        <>
          <StyledHeaderTitle>Store Address</StyledHeaderTitle>
          <Typography>
            Enter the address, where orders will be picked up
          </Typography>
          <StoreAddressForm />
        </>
      );
    }
    if (step === 4) {
      return (
        <>
          <StyledHeaderTitle>
            {connectionStatus === ConnectionStoreStatus.Connected
              ? 'Setup Completed!'
              : 'Connect with WooCommerce'}
          </StyledHeaderTitle>
          <Typography mb={10}>
            {connectionStatus === ConnectionStoreStatus.Connected
              ? 'Your store is set up successfully'
              : 'Enter the URL of your eCommerce store and check the connection'}
          </Typography>
          <Field.TextInput
            name='store_url'
            legend='Store URL'
            disabled={connectionStatus === ConnectionStoreStatus.Connected}
          />

          <StoreConnection status={connectionStatus} />
          {connectionStatus !== ConnectionStoreStatus.Connected && (
            <Typography sx={{ width: '100%', mb: 15 }}>
              Make sure to{' '}
              <TextLink
                style={{ fontWeight: 'bold' }}
                to={CanFleetPluginURL.Woo}
                data-testid='btn-install-plugin'
              >
                install Breadstack Delivery plugin
              </TextLink>
              .
            </Typography>
          )}
        </>
      );
    }
    return '';
  }, [step, connectionStatus]);

  const connectButtons = (values, hSubmit) => {
    if (connectionStatus === ConnectionStoreStatus.Connected)
      return (
        <Button
          type='submit'
          loading={isLoadingCreateIntegration}
          sx={{
            width: 150,
            mt: 12,
          }}
        >
          Done
        </Button>
      );
    return (
      <Stack
        direction='row'
        spacing={2}
        sx={{
          '& > button': {
            width: 180,
          },
        }}
      >
        <Button
          buttonType='default'
          loading={isLoadingCheckConnection || isLoadingCreateIntegration}
          type='submit'
        >
          Do It Later
        </Button>
        <Button
          onClick={() => {
            if (values.store_url) {
              setConnectionStatus(ConnectionStoreStatus.Checking);
              checkConnectionMutation({
                integration_type: IntegrationType.Woo,
                store_url: values?.store_url,
                store_id: values?.store_id,
              });
            } else {
              hSubmit();
            }
          }}
          loading={isLoadingCheckConnection}
        >
          Check Connection
        </Button>
      </Stack>
    );
  };

  return (
    <Formik
      initialValues={{
        store_id: '',
        store_url: '',
        status: CreateStoreStatus?.Deactivated,
        integration_type: IntegrationType.Breadstack,
        display_name: '',
        phone: '',
        address_1: '',
        address_2: '',
        country: '',
        city: '',
        state: '',
        postcode: '',
        country_code: 'CA',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmitAddStore}
    >
      {({ values, handleSubmit }) => (
        <AddStoreLayout
          isEnabledCloseAndUpdateStore={step === 4 && values.store_url}
          onClose={onClose}
          step={step}
          totalStep={totalStep}
          onCloseAndUpdateStore={() => {
            onClose();
            createIntegrationMutation({
              status: CreateStoreStatus?.Deactivated,
              integration_type: IntegrationType.Woo,
              store_url: values?.store_url,
              display_name: values?.display_name,
              address_1: values?.address_1,
              address_2: values?.address_2,
              country: values?.country,
              state: values?.state,
              city: values?.city,
              postcode: values?.postcode,
              phone: parsePhoneNumber(
                values.phone,
                values.country_code as CountryCode,
              ).number,
            });
          }}
        >
          <StyledStoreForm>
            {contentComponent}
            {step === 4 ? (
              connectButtons(values, handleSubmit)
            ) : (
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  '& > button': {
                    width: 150,
                  },
                }}
              >
                <Button
                  buttonType='default'
                  onClick={() => {
                    if (step === 2) {
                      setPlatform(null);
                    }
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
                <Button type='submit'>Next</Button>
              </Stack>
            )}
          </StyledStoreForm>
        </AddStoreLayout>
      )}
    </Formik>
  );
};
