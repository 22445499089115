import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Grid, Stack } from '@mui/material';
import Button from '~/components/common/Button';
import { Field } from '~/components/common/Formik';
import { fromQueryString } from '~/utils/queryString';
import { styled } from '~/themes';

interface AddressFilterFormProps {
  onSubmit?: (values: any) => void;
  onClear?: () => void;
  onClearAll?: () => void;
  stateCities: {
    city?: string[];
    state_info?: {
      state?: string;
      state_name?: string;
    };
  }[];
}

export const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const AddressFilterForm: React.FC<AddressFilterFormProps> = ({
  stateCities,
  onSubmit,
  onClear,
  onClearAll,
}) => {
  const location = useLocation();

  const { search, city, state } = fromQueryString(location.search);

  const initialValues = {
    search: search || '',
    city: city || '',
    state: state || '',
  };

  const statesOptions = useMemo(
    () =>
      stateCities.map(({ state_info }) => ({
        label: state_info.state_name,
        value: state_info.state,
      })),
    [stateCities],
  );

  const renderState = useCallback(
    (setFieldValue) => (
      <Field.Select
        key='state'
        placeholder='Province / State'
        name='state'
        options={statesOptions}
        onChange={() => setFieldValue('city', '')}
      />
    ),
    [statesOptions],
  );

  const renderCity = useCallback(
    (values) => {
      let citiesOptions = [];
      const cities = stateCities.find(
        (e) => e.state_info?.state === values.state,
      )?.city;
      if (cities?.length)
        citiesOptions = cities.map((e) => ({
          label: e,
          value: e,
        }));

      return (
        <Field.Select placeholder='City' name='city' options={citiesOptions} />
      );
    },
    [stateCities],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <StyledForm>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} xl={4} sx={{ minWidth: 300 }}>
              <Field.SearchInput
                name='search'
                placeholder='Search Address'
                onClear={() => {
                  onClear();
                  setFieldValue('search', '');
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              lg={6}
              xl={4}
              sx={{ minWidth: 360, display: { xs: 'none', md: 'block' } }}
            >
              <Stack direction='row' spacing={1}>
                {renderState(setFieldValue)}
                {renderCity(values)}
              </Stack>
            </Grid>

            <Grid
              item
              xs={4}
              lg={4}
              xl={3}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Stack direction='row' spacing={1}>
                <Button type='submit' buttonType='default'>
                  Apply
                </Button>
                <Button
                  buttonType='default'
                  onClick={() => {
                    resetForm();
                    onClearAll();
                  }}
                >
                  Clear All
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </StyledForm>
      )}
    </Formik>
  );
};
