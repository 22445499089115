import { useEffect } from 'react';

export const useKeyDow = ({
  targetKey,
  disabled = false,
  callback,
}: {
  targetKey: string;
  disabled?: boolean;
  callback: () => void;
}) => {
  const downHandler = ({ key }) => {
    if (key === targetKey) callback();
  };

  useEffect(() => {
    if (disabled) return () => {};
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [disabled]);
};
