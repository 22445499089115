import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Modal from '~/components/common/Modal';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.8),
    },
    modal: {
      width: 350,
    },
    link: {
      color: theme.color.primaryOrange,
    },
  });

interface IProps extends WithStyles<typeof style> {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const DialogVerifyCode2FA = ({ classes, open, onClose, children }: IProps) => (
  <Modal
    open={open}
    onClose={onClose}
    className={classes.modal}
    disableCloseOutside
  >
    {children}
  </Modal>
);

export default withStyles(style)(DialogVerifyCode2FA);
