import { createStyles } from '@mui/styles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    root: {
      width: 80,
      height: 32,
      padding: 0,
    },
    rootNoText: {
      width: 80,
    },
    switchBase: {
      'padding': 0,
      'top': 3,
      'left': 3,
      '&$checked': {
        'transform': 'translateX(48px)',
        '& + $track': {
          'backgroundColor': theme.color.green,
          'opacity': 1,
          'border': 'none',
          '&::after': {
            content: '"ON"',
            position: 'absolute',
            top: 8,
            left: 20,
            fontSize: 14,
            color: theme.color.white,
          },
        },
      },
      '& input': {
        left: '-200%',
        width: 130,
        height: 32,
        top: -3,
      },
    },

    switchBaseNoText: {
      '&$checked': {
        transform: 'translateX(48px)',
      },
    },

    thumb: {
      color: theme.color.white,
      width: 26,
      height: 26,
    },
    track: {
      'borderRadius': 30,
      'backgroundColor': theme.color.violet600,
      'opacity': 1,
      '&::after': {
        content: '"OFF"',
        position: 'absolute',
        fontSize: 14,
        top: 8,
        left: 36,
        color: theme.color.white,
      },
    },
    disabled: {
      '&$checked + $track': {
        opacity: '0.5 !important',
      },
    },
    checked: {},
    trackNoText: {
      '&::before, &::after': {
        display: 'none',
      },
    },
    formRoot: {
      margin: 0,
    },
    formLabel: {
      marginLeft: theme.spacing(1.5),
    },
    legend: {
      color: theme.palette.text.primary,
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  });

export default style;
