import { FormLabel } from '@mui/material';
import { createStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React from 'react';
import { Theme } from '~/themes';
import SwitchButton, { ISwitchButton } from './SwitchButton';

const themeStyle = (theme: Theme) =>
  createStyles({
    container: {
      background: theme.color.lightViolet,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
      padding: theme.spacing(0.5, 0.5, 0.5, 2),
      borderRadius: 21,
      fontSize: 14,
      fontWeight: 400,
    },
    formLabel: {
      color: theme.color.black,
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  });

export interface ISwitchItem extends Omit<ISwitchButton, 'classes'> {
  className?: string;
  label?: string;
  legend?: string;
  style?: React.CSSProperties;
}

const SwitchItem: React.FC<ISwitchItem & WithStyles<typeof themeStyle>> = ({
  classes,
  label = '',
  legend = '',
  style = {},
  className = '',
  ...props
}) => (
  <div style={style} className={clsx({ [className]: Boolean(className) })}>
    {legend && (
      <FormLabel className={classes.formLabel} component='legend'>
        {legend}
      </FormLabel>
    )}
    <div className={classes.container}>
      <div>{label}</div>
      <SwitchButton {...props} />
    </div>
  </div>
);

export default withStyles(themeStyle)(SwitchItem);
