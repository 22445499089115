import React from 'react';
import {
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  SxProps,
} from '@mui/material';
import { WithStyles, createStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import clsx from 'clsx';

const stepperStyle = () =>
  createStyles({
    container: {
      marginLeft: -58,
    },
  });

export interface IProps extends WithStyles<typeof stepperStyle> {
  activeStep: number;
  className?: string;
  sx?: SxProps;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#fa8c73',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#fa8c73',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#160E4D',
    borderWidth: '2px 0 0 0',
    borderStyle: 'dashed',
  },
}));

const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => ({
  background: 'white',
  display: 'flex',
  height: 5,
  width: 5,
  border: '5px solid #160E4D',
  borderRadius: '50%',
  alignItems: 'center',
  ...((ownerState.active || ownerState.completed) && {
    borderColor: '#FA8C73',
  }),
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div />
    </QontoStepIconRoot>
  );
}

const steps = ['Verify Email', 'Business Info', 'Payment Info'];

const RegisterStepper: React.FC<IProps> = ({
  classes,
  activeStep,
  className = '',
  sx,
}) => (
  <Stepper
    alternativeLabel
    activeStep={activeStep}
    connector={<QontoConnector />}
    className={clsx(classes.container, className)}
    sx={sx}
  >
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={QontoStepIcon} />
      </Step>
    ))}
  </Stepper>
);

export default withStyles(stepperStyle)(RegisterStepper);
