import React, { useMemo } from 'react';
import themes, { styled } from '~/themes';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import NoImage from '~/assets/images/icons/no-avatar.svg';
import NotFoundImg from '~/assets/images/background/setup-your-store-background.png';
import { useGetTrackQuery } from '~/services/api/track';
import { useParams } from 'react-router-dom';
import { TrackStatus } from '~/models/track';
import { Stack } from '@mui/system';
import { IPlanTier } from '~/models/plan';
import moment from 'moment';
import TrackProgress from './TrackProgress';
import TrackRate from './TrackRate';
import {
  formatDateTimeLocal,
  TrackVerticalProgress,
} from './TrackVerticalProgress';

export const StyledTextDesc = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  fontSize: 14,
  fontWeight: 500,
  marginBottom: '8px !important',
}));

export const StyledDriverName = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.color.gray900,
  fontSize: 14,
  background: theme.bg.gray300,
  padding: theme.spacing(0.6, 1.2),
  borderRadius: theme.spacing(0.5),
}));

export const StyledDriverAvatar = styled('img')(({ theme }) => ({
  width: theme.spacing(5.2),
  height: theme.spacing(5.2),
  borderRadius: theme.spacing(2.6),
}));

export const StyledDriverInfo = styled(Box)(({ theme }) => ({
  'textAlign': 'right',
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '4px',
  '& > div': {
    '&:first-child': {
      color: theme.color.black,
      fontWeight: 'bold',
      fontSize: 18,
    },
    '&:last-child': {
      color: theme.color.gray900,
      fontSize: 14,
    },
  },
}));

export const StyledTrackPhoneButton = styled('a')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(3.5),
}));

export const StyledEstTimeTitle = styled(Typography)(({ theme }) => ({
  color: theme.color.gray900,
  fontSize: 16,
  marginTop: theme.spacing(2),
}));

interface ITrack {}

export const Track: React.FC<ITrack> = () => {
  const { code } = useParams();
  const calendarFormats = {
    sameDay: 'h:mm a [| today]',
    nextDay: 'h:mm a [| tomorrow]',
    lastDay: 'h:mm a [| yesterday]',
    nextWeek: 'h:mm a | MM/DD/YYYY',
    lastWeek: 'h:mm a | MM/DD/YYYY',
    sameElse: 'h:mm a | MM/DD/YYYY',
  };

  const { data, isLoading: isFetching } = useGetTrackQuery({
    params: { code },
  });

  const isRating =
    data?.progress_bar === 1 && data.org_tier === IPlanTier.Advance;

  const isDelivered =
    data?.message === TrackStatus.Delivered &&
    [IPlanTier.Standard, IPlanTier.Standard].includes(data.org_tier);

  const orderMessage = useMemo(() => {
    if (isDelivered) return 'Order is Delivered';
    if (data?.progress_bar < 0.75) return data?.message || '';
    if (data?.progress_bar === 0.75)
      return data?.estimated_delivery_time > 0
        ? `Our driver is on their way to you. Estimated arrival time is around ${moment()
            .local()
            .add(data?.estimated_delivery_time, 'm')
            .format('h:mm a')}.`
        : 'Our driver is on their way to you.';
    if (data?.progress_bar === 0.9)
      return `Your order is almost at your doorstep. Estimated arrival time is around ${moment()
        .local()
        .add(data?.estimated_delivery_time, 'm')
        .format('h:mm a')}.`;
    return '';
  }, [data?.message, isDelivered]);

  const orderNumber = useMemo(() => {
    if (!data?.order_number) return '';
    return data?.order_number?.includes('-')
      ? data?.order_number
      : `#${data?.order_number}`;
  }, [data?.order_number]);

  const title = useMemo(() => {
    switch (data?.progress_bar) {
      case 0.25:
        return 'Order is being processed';
      case 0.5:
        return 'In Transit';
      case 0.75:
        return 'Out for Delivery';
      case 0.9:
        return 'Arriving soon';
      default:
        return '';
    }
  }, [data?.progress_bar]);

  const renderContent = () => {
    if (!data?.order_number && !isFetching) {
      return (
        <Stack
          sx={{
            padding: themes.spacing(3, 1.5),
            justifyContent: 'center',
            alignItems: 'center',
            mt: '25%',
          }}
          spacing={2}
        >
          <img src={NotFoundImg} alt='404.png' width={350} height={230} />
          <Typography variant='h1' fontSize={40}>
            404
          </Typography>
          <Typography variant='body1' color={themes.color.gray900}>
            Something went wrong
          </Typography>
        </Stack>
      );
    }

    if (isFetching) {
      return (
        <Stack spacing={1.5} py={3} px={1.5}>
          <Skeleton height={40} width='50%' />
          <Skeleton width='90%' />
          <Skeleton width='30%' />
          <Stack spacing={1}>
            <Skeleton width='20%' />
            <Skeleton width='22%' />
            <Skeleton width='30%' />
            <Skeleton width='15%' />
          </Stack>
          <Divider />
          <Skeleton width='25%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
        </Stack>
      );
    }
    if (isRating) {
      return <TrackRate data={data} />;
    }

    return (
      <Stack p={1.6}>
        <Typography variant='h1' fontSize={18} mb={1.8}>
          {title}
        </Typography>
        <TrackProgress progress={(data?.progress_bar || 0) * 100} />
        <Typography fontSize={14} mt={1.6}>
          {orderMessage}
        </Typography>
        {Boolean(
          data?.driver_name &&
            data?.message &&
            !isDelivered &&
            data?.message !== TrackStatus.OrderIsConfirmed,
        ) && (
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: themes.spacing(3),
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <StyledDriverAvatar
                src={
                  data?.driver_avatar && data?.driver_avatar_type
                    ? `data:${data.driver_avatar_type};base64,${data.driver_avatar}`
                    : NoImage
                }
                alt='avatar'
              />
            </Box>
            <StyledDriverInfo>
              <div>{data?.plate_number || ''}</div>
              <div>{data?.vehicle_body || ''}</div>
            </StyledDriverInfo>
          </Stack>
        )}
        {Boolean(data?.driver_name) && !isDelivered && (
          <StyledDriverName>{`${data?.driver_name} is taking care of your package`}</StyledDriverName>
        )}
        <TrackVerticalProgress value={data.progress_bar} data={data} />
        <Divider sx={{ my: 1.6 }} />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          {data?.pickup && (
            <Stack
              spacing={1}
              fontSize={14}
              color={themes.color.black}
              flex={1}
            >
              <StyledTextDesc>Pickup Address</StyledTextDesc>
              <span>{data?.pickup?.name || ''}</span>
              <span>{data?.pickup?.address_1 || ''}</span>
              {data?.pickup?.address_2 && (
                <span>{data?.pickup?.address_2}</span>
              )}
              <span>{`${data?.pickup?.city}, ${data?.pickup?.state} ${data?.pickup?.postcode}`}</span>
            </Stack>
          )}

          <Stack spacing={1} fontSize={14} color={themes.color.black} flex={1}>
            <StyledTextDesc>Delivery Address</StyledTextDesc>
            <span>{data?.delivery_name || ''}</span>
            <span>{data?.address_1 || ''}</span>
            {data?.address_2 && <span>{data?.address_2}</span>}
            <span>{data?.address_body || ''}</span>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container
      sx={{
        p: '0px !important',
        maxWidth: '600px !important',
        [themes.breakpoints.down('lg')]: {
          maxWidth: '100% !important',
        },
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        sx={{
          background: '#ED6808',
          height: 55,
          px: 1.6,
        }}
      >
        <Typography fontSize={18} fontWeight='600' color={themes.color.white}>
          Order {orderNumber}
        </Typography>
        <Typography fontSize={14} color={themes.color.white}>
          {formatDateTimeLocal(data?.created_at, calendarFormats)}
        </Typography>
      </Stack>
      {renderContent()}
    </Container>
  );
};
