import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchFormik } from '~/components/common/Search';
import { useGetUsersQuery } from '~/services/api/userManagement';
import Button from '~/components/common/Button';
import { USER_ROLE } from '~/constants/userManagement';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { fromQueryString } from '~/utils/queryString';
import { Stack } from '@mui/material';
import DialogInviteUser from './DialogInviteUser';
import UserTable from './UserTable';
import { StyledFilterContainer } from '../style';

interface IProps {}

export const UserList: React.FC<IProps> = () => {
  const location = useLocation();

  const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false);
  const { isStarterPlan, setIsOpenUpSellDialog } = usePaymentInfo();
  const { search, sort, sort_by } = fromQueryString(location.search);

  const { data: users, isFetching } = useGetUsersQuery({
    params: {
      sort,
      sort_by,
      search,
      roles: [USER_ROLE.OWNER, USER_ROLE.ADMIN, USER_ROLE.DISPATCHER],
    },
  });

  const filterComponent = useMemo(
    () => (
      <StyledFilterContainer>
        <SearchFormik placeholder='Search' />
        <Button
          onClick={() => {
            if (isStarterPlan) {
              return setIsOpenUpSellDialog(true);
            }
            return setOpenInviteUserDialog(true);
          }}
        >
          Add User
        </Button>
      </StyledFilterContainer>
    ),
    [search, location, isStarterPlan],
  );

  return (
    <Stack flex={1} width='100%' overflow='hidden'>
      {filterComponent}
      <Stack className='customized-scrollbar' flex={1} overflow='auto'>
        <UserTable isLoading={isFetching} data={users || []} />
      </Stack>
      <DialogInviteUser
        open={openInviteUserDialog}
        onClose={() => setOpenInviteUserDialog(false)}
      />
    </Stack>
  );
};
