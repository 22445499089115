import {
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG, VAPID_KEY } from '~/config';

export class NotificationsService {
  protected workerRegistration: ServiceWorkerRegistration;

  protected app: FirebaseApp;

  protected messaging: Messaging;

  constructor() {
    this.app = initializeApp(JSON.parse(FIREBASE_CONFIG));
    this.messaging = getMessaging(this.app);
  }

  async registerWorker() {
    const registration = await navigator.serviceWorker.register(
      '../firebase-messaging-sw.js',
      // {
      //   type: 'module',
      // },
    );
    this.workerRegistration = registration;
  }

  async updateWorker() {
    this.workerRegistration?.update();
  }

  async getWebToken() {
    return getToken(this.messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration: this.workerRegistration,
    });
  }

  onNotifications(cb) {
    onMessage(this.messaging, (payload) => {
      cb(payload);
    });
  }
}
