import { getIn } from 'formik';
import { Select, SelectProps } from '~/components/common/Select';
import { IFormProps } from '~/models/common';

export interface IFormSelectProps
  extends IFormProps,
    Omit<SelectProps, 'name' | 'form'> {}

const FormSelect = ({
  field,
  form,
  error,
  options,
  inputProps,
  ...props
}: IFormSelectProps) => (
  <Select
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      getIn(form.touched, field.name) && getIn(form.errors, field.name)
    }
    options={options}
    value={field.value ?? ''}
    id={field.name}
    inputProps={{
      ...field,
      ...inputProps,
      value: field?.value || '',
    }}
    {...props}
  />
);

export default FormSelect;
