import { Stack, Switch, Typography } from '@mui/material';
import { IBillingFrequency } from '~/models/plan';
import themes, { styled } from '~/themes';

type ChangeBilledProps = {
  onChange?: (e: any) => void;
  billingFrequency?: IBillingFrequency;
  isShowHighlight?: boolean;
};
const PlanSwitch = styled(Switch)(({ theme }) => ({
  'width': 64,
  'height': 32,
  'padding': 0,
  'display': 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 26,
    },
  },
  '& .MuiSwitch-switchBase': {
    'padding': 3,
    '&.Mui-checked': {
      'transform': 'translateX(32px)',
      'color': theme.color.primaryOrange,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#F8F8FC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    color: theme.color.primaryOrange,
    width: 26,
    height: 26,
    borderRadius: 26 / 2,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 30,
    opacity: 1,
    backgroundColor: '#F8F8FC',
    boxSizing: 'border-box',
  },
}));
export const ChangeBilled: React.FC<ChangeBilledProps> = ({
  onChange,
  billingFrequency,
  isShowHighlight = false,
}) => (
  <Stack
    direction='row'
    justifyContent='center'
    spacing={1}
    alignItems='center'
  >
    <Typography>Billed monthly</Typography>
    <PlanSwitch
      onChange={onChange}
      checked={billingFrequency === IBillingFrequency.Year}
    />
    <Typography>Billed annually</Typography>
    <Typography
      padding={0.5}
      sx={{
        borderRadius: '5px',
        minWidth: 50,
        marginLeft: '0 !important',
        ...(isShowHighlight && {
          marginLeft: '10px !important',
          background: themes.bg.darkPurple,
          color: themes.color.white,
          fontSize: '12px',
        }),
      }}
    >
      {isShowHighlight ? '20% OFF' : '(20% off)'}
    </Typography>
  </Stack>
);
