/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import themes from '~/themes';
import style from './style';
import { ILogData } from './type';
import { DriverAvatar } from '../AssignTask/DriverAvatar';

export interface ChangeLogItemProps extends WithStyles<typeof style> {
  data: ILogData;
  loading?: boolean;
  isShowAvatar?: boolean;
  isHiddenLeftPoint?: boolean;
  className?: string;
}

const LogItem: React.FC<ChangeLogItemProps> = ({
  classes,
  data,
  loading,
  isShowAvatar = true,
  isHiddenLeftPoint = false,
  className = '',
}) => (
  <div
    className={clsx(
      classes.changeLogContent,
      {
        [classes.hiddenLeftPoint]: isHiddenLeftPoint,
      },
      className,
    )}
  >
    {!isHiddenLeftPoint && <div className={clsx(classes.point, {})} />}

    <div className={clsx(classes.log)}>
      {isShowAvatar && <DriverAvatar size={40} avatar={data?.avatar} />}
      <div className={classes.logContent}>
        {!loading && (
          <>
            <Typography variant='body2'>{data.title}</Typography>
            <Typography
              variant='h5'
              sx={{
                whiteSpace: 'pre-wrap',
              }}
              color={themes.color.black}
            >
              {data.body}
            </Typography>
          </>
        )}
      </div>
    </div>
  </div>
);

export default withStyles(style)(LogItem);
