import { useMutation } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { IHub } from '~/models/shared';
import endpoints from './endpoints';

export const createHub = (params): Promise<IHub> =>
  httpClient.json.post(`${endpoints.share.hubs}`, params);

export const editHub = (params): Promise<IHub> =>
  httpClient.json.put(`${endpoints.share.hubs}/${params.id}`, params);

export const deleteHub = (id): Promise<IHub> =>
  httpClient.json.delete(`${endpoints.share.hubs}/${id}`);

export const useCreateHubMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: createHub,
    onSuccess,
    onError,
  });

export const useEditHubMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: editHub,
    onSuccess,
    onError,
  });

export const useDeleteHubMutation = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: deleteHub,
    onSuccess,
    onError,
  });
