import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { Theme } from '~/themes';
import { IOptionItem } from '~/models/common';

const style = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      height: '3px !important',
      backgroundColor: `${theme.color.primaryOrange} !important`,
    },
    tab: {
      minWidth: 0,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      borderRadius: 3,
      padding: theme.spacing(0, 2),
    },
    divBefore: {
      'overflow': 'visible',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -4,
        width: 1,
        height: `calc(100% - ${theme.spacing(1)})`,
        background: theme.bg.gray600,
      },
    },
    label: {
      color: theme.palette.text.primary,
    },
    count: {
      padding: '3px 10px',
      background: theme.color.violet300,
      color: theme.palette.text.primary,
      borderRadius: 5,
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      tab: {
        padding: theme.spacing(0, 0.5),
        fontSize: 13,
        fontWeight: 600,
      },
      count: {
        padding: 5,
        marginLeft: 5,
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  className?: string;
  children?: React.ReactNode;
  tabs: IOptionItem[];
  dividerBefore?: Array<number>;
  value: string | number;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  indicatorClassName?: string;
  labelClassName?: string;
  tabClassName?: string;
  flexContainerClassName?: string;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  allowScrollButtonsMobile?: boolean;
}

const ThemeTabs: React.FC<IProps> = ({
  classes,
  className,
  indicatorClassName,
  labelClassName,
  tabClassName = '',
  flexContainerClassName,
  tabs = [],
  children = '',
  dividerBefore = [],
  value,
  onChange,
  variant = 'standard',
  ...rest
}) => {
  const renderTab = () =>
    tabs.map((tab, index) => (
      <Tab
        key={tab.value}
        data-testid={`tab-${tab.value}`}
        className={clsx(classes.tab, {
          [classes.divBefore]: dividerBefore.includes(index),
          [tabClassName]: Boolean(tabClassName),
        })}
        value={tab.value}
        label={
          <div>
            <span className={clsx(classes.label, labelClassName)}>
              {tab.label || ''}
            </span>
            {typeof tab.count === 'number' && (
              <span className={classes.count}>{tab.count || 0}</span>
            )}
          </div>
        }
      />
    ));

  return (
    <Tabs
      classes={{
        root: classes.root,
        indicator: clsx(classes.indicator, indicatorClassName),
        flexContainer: clsx(flexContainerClassName),
      }}
      value={value}
      className={className}
      onChange={(_, v) => onChange(v)}
      variant={variant}
      scrollButtons={false}
      {...rest}
    >
      {children || renderTab()}
    </Tabs>
  );
};

export default withStyles(style)(ThemeTabs);
