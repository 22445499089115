import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { Typography } from '@mui/material';
import BackgroundImage from '~/assets/images/background/setup-team.png';
import Button from '~/components/common/Button';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { usePaymentInfo } from '~/providers/PaymentProvider';

const style = (theme: Theme) =>
  createStyles({
    containerClass: {
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      'padding': theme.spacing(2),
      'marginTop': theme.spacing(2),
      '& > img:first-child': {
        maxHeight: 200,
      },
    },
    title: {
      marginTop: theme.spacing(3),
      fontSize: 22,
      paddingTop: 0,
    },
    desc: {
      color: theme.color.violet900,
      textAlign: 'center',
      marginTop: theme.spacing(1),
      whiteSpace: 'break-spaces',
    },
    button: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
      containerClass: {
        'marginTop': theme.spacing(1),
        'maxWidth': '100%',
        '& > img:first-child': {
          maxWidth: '100%',
        },
      },
    },
  });

interface IProps extends WithStyles<typeof style> {
  setOpen: (value: boolean) => void;
}

const SetupYourTeams: React.FC<IProps> = ({ classes, setOpen }) => {
  const { isStarterOrStandardPlan, setIsOpenUpSellDialog } = usePaymentInfo();
  return (
    <div className={classes.containerClass}>
      <img src={BackgroundImage} alt='setup-your-store' />
      <Typography variant='h1' className={classes.title}>
        Set up driver teams
      </Typography>
      <Typography className={classes.desc}>
        Group your drivers into teams - for example, based on shared geographic
        range.
        <br />
        Teams make for easier task management and smarter auto-assignment.
      </Typography>
      <Button
        className={classes.button}
        buttonType='primary'
        onClick={() => {
          if (isStarterOrStandardPlan) {
            return setIsOpenUpSellDialog(true);
          }
          return setOpen(true);
        }}
      >
        Add Team
      </Button>
    </div>
  );
};

export default withStyles(style)(SetupYourTeams);
