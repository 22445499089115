import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { ITrackData, ITrackRating } from '~/models/track';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getTrack = ({ code }: { code: string }): Promise<ITrackData> =>
  httpClient.json.get(`${endpoints.track.track}/${code}`);

export const useGetTrackQuery = (
  {
    params,
    onSuccess,
    onError,
  }: {
    params: any;
    onSuccess?: () => void;
    onError?: () => void;
  } = {
    params: {},
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useQuery({
    queryKey: [queryKeys.track.getTrack, params.code],
    queryFn: () => getTrack(params),
    onSuccess,
    onError,
    refetchInterval: 1 * 7 * 1000,
  });

interface ITrackParams extends ITrackRating {
  id: string;
}

export const updateTrackRating = ({ id, ...params }: ITrackParams) =>
  httpClient.json.put(endpoints.track.trackRating(id), params);

export const useUpdateTrackRating = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: updateTrackRating,
    onSuccess,
    onError,
  });
};
