import React from 'react';
import clsx from 'clsx';
import { OutlinedInput as MuiInput } from '@mui/material';
import { InputProps as _InputProps } from '@mui/material/Input';

export interface InputProps extends _InputProps {
  className?: string;
  success?: boolean;
  error?: boolean;
}

export const Input = React.forwardRef(
  (props: InputProps, ref: React.Ref<HTMLInputElement | any>) => {
    const { className = '', success = false, error = false, ...rest } = props;
    return (
      <MuiInput
        ref={ref}
        error={error}
        className={clsx({ success }, className)}
        {...rest}
        notched={false}
      />
    );
  },
);
