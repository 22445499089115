import {
  Autocomplete,
  Box,
  CircularProgress,
  FilledInputProps,
  FormControl,
  FormLabel,
  InputBaseComponentProps,
  InputProps as InputPropsMui,
  OutlinedInputProps,
  TextField,
} from '@mui/material';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

type TOption = {
  label?: string;
  customLabel?: React.ReactNode | string;
  value?: string;
  defaultOptionFullText?: string;
};

export interface AsyncSelectProps {
  transformOptions?: (data: any) => Array<any>;
  fetchOptions?: (searchText: string) => Promise<any>;
  onChange: (event: React.SyntheticEvent, value: TOption) => void;
  defaultOptions?: TOption[];
  id?: string;
  legend?: string;
  helperText?: string;
  error?: boolean;
  value?: any;
  inputProps?: InputBaseComponentProps;
  onBlur?: (e: any) => void;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputPropsMui>;
  uniqueValue?: boolean;
}

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
  id,
  legend,
  value,
  helperText,
  error,
  inputProps,
  onChange,
  transformOptions,
  fetchOptions,
  defaultOptions = [],
  onBlur,
  InputProps,
  uniqueValue = false,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  const [debouncedSearchText] = useDebounce(inputValue, 300);

  const [options, setOptions] = useState<TOption[]>(defaultOptions);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      return;
    }

    if (debouncedSearchText.trim() === '') {
      setOptions(defaultOptions);
      return;
    }

    let isSubscribed = true;

    const request = async () => {
      setLoading(true);
      setOptions([]);
      try {
        const data = await fetchOptions(debouncedSearchText);

        if (isSubscribed) {
          const newOptions = defaultOptions
            .filter((e) =>
              e.defaultOptionFullText
                ?.toLowerCase()
                .includes(debouncedSearchText?.toLowerCase()),
            )
            .concat(transformOptions(data));
          if (uniqueValue) {
            const uniqOptions = uniqBy(newOptions, (option) => option?.value);
            setOptions(uniqOptions);
          } else setOptions(newOptions);
        }
      } catch (e) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    };

    request();

    // eslint-disable-next-line consistent-return
    return () => {
      isSubscribed = false;
    };
  }, [debouncedSearchText, open]);

  return (
    <FormControl fullWidth>
      {legend && (
        <FormLabel>{typeof legend === 'string' ? legend : legend}</FormLabel>
      )}
      <Autocomplete
        onBlur={() => onBlur(inputValue)}
        disablePortal
        id={id}
        data-testid={`txt-${id}`}
        options={options}
        open={open}
        value={value}
        inputValue={inputValue}
        forcePopupIcon={false}
        disableClearable
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onInputChange={(_, val, reason) => {
          if (reason === 'input') setInputValue(val);
        }}
        onChange={(_, val) => {
          if (val?.inputValue) setInputValue(val?.inputValue);
          else if (val?.place_id) setInputValue(val?.value);
          else setInputValue(val?.address?.address_1);
          onChange(_, val);
        }}
        getOptionLabel={(option: TOption) =>
          typeof option === 'string' ? option : option?.label || ''
        }
        loadingText='loading...'
        noOptionsText='No result'
        filterOptions={(o) => o}
        isOptionEqualToValue={(option: TOption, _value) =>
          option?.value === _value
        }
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            error={error}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              inputProps: {
                ...inputProps,
                ...params.inputProps,
                'aria-label':
                  typeof legend === 'string' ? legend : 'input-label',
                'autoComplete': 'new-password',
                'data-testid': `txt-${id}`,
              },
              endAdornment: loading ? (
                <CircularProgress
                  color='inherit'
                  size={18}
                  style={{ marginRight: 12 }}
                />
              ) : null,
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component='li'
            data-testid={`ddl-${option.value || ''}`}
            data-value={`${option.value}-async-select`}
            sx={{ display: 'block !important' }}
            {...props}
            key={option.value}
          >
            {option.customLabel ? option.customLabel : option?.label || ''}
          </Box>
        )}
        {...rest}
      />
    </FormControl>
  );
};
