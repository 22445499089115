import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { getIn, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { FastField, Field } from '~/components/common/Formik';
import { CountryListItem } from '~/models/common';
import {
  getSuggestionAddress,
  getSuggestionAddressDetail,
  getAddressCustomer,
} from '~/pages/LiveTracking/apis';
import {
  IAddTaskData,
  IAllAddressBook,
  ITaskAddress,
} from '~/pages/LiveTracking/types';
import { getCountryCodePhone } from '~/pages/LiveTracking/utils';
import themes, { styled } from '~/themes';
import {
  convertGoogleLocationDetail,
  formatSuggestionsAddress,
} from '~/utils/formatter';

const StyledAsyncSelectPhone = styled(Field.AsyncSelect)(() => ({
  '.MuiOutlinedInput-root': {
    'padding': 0,
    'paddingLeft': 14,
    '.MuiOutlinedInput-root': {
      paddingLeft: 0,
    },

    'input': {
      paddingTop: '0px !important',
      paddingLeft: '8px !important',
      paddingBottom: '0px !important',
      height: '100%',
    },
  },
}));

type AddressFormProps = {
  countries: CountryListItem[];
  loading: boolean;
  parent?: 'pickup' | 'delivery';
  label: {
    [key: string]: string;
  };
  allAddressBook: IAllAddressBook[];
  allAddressCustomer: IAllAddressBook[];
};

export const AddressForm: React.FC<AddressFormProps> = ({
  countries,
  loading,
  parent = '',
  label,
  allAddressBook,
  allAddressCustomer,
}) => {
  const { values, setFieldValue, setValues } = useFormikContext<IAddTaskData>();

  const currentCountry = useMemo(
    () => getIn(values, `${parent ? `${parent}.` : ''}country`),
    [values],
  );

  const states = useMemo(
    () => countries.find(({ value }) => value === currentCountry)?.states || [],
    [countries, currentCountry],
  );

  const formatLongAddress = (data: ITaskAddress) => {
    const { address_1, country, city, state, postcode } = data;
    return `${address_1}, ${city}, ${state} ${postcode}, ${country}`;
  };

  const onChangeAddress = useCallback(
    async (_, val) => {
      try {
        let d;
        if (val.place_id) {
          const res = await getSuggestionAddressDetail(val.place_id);
          const googleAddress = convertGoogleLocationDetail(res.result);
          d = googleAddress;
          d.postcode = googleAddress.postal_code;
        } else {
          d = val.address;
        }

        setValues({
          ...values,
          [parent]: {
            ...(values as { [key: string]: any })[parent],
            address_1: val.place_id ? val.value : d.address_1,
            country: d.country,
            state: d.state,
            city: d.city,
            save_to_address_book: false,
            postcode: d.postcode,
            address_book: {},
            ...(!val.place_id && {
              name: d.name,
              phone: d?.phone?.replace(/\+1|\+84|\+61/g, '')?.trim() || '',
              country_code: getCountryCodePhone(d?.phone),
              address_2: d?.address_2 || '',
              address_book: d,
            }),
            ...(!val.place_id &&
              val.address.store_type === 'woocommerce' && {
                address_book: {
                  ...d,
                  name: d.store_name,
                },
                name: d.store_name,
              }),
            ...(parent === 'delivery' &&
              !val.place_id && {
                email: d?.email || '',
              }),
          },
        });
      } catch (err) {
        setValues({
          ...values,
          [parent]: {
            name: '',
            phone: '',
            address_1: '',
            address_2: '',
            country: '',
            state: '',
            city: '',
            postcode: '',
            country_code: 'CA',
            email: '',
          },
        });
      }
    },
    [values, parent],
  );

  const onChangeAddressCustomer = useCallback(
    (_, val) => {
      const { address } = val;
      setValues({
        ...values,
        [parent]: {
          ...(values as { [key: string]: any })[parent],
          address_1: address?.address_1,
          country: address?.country,
          state: address?.state,
          city: address?.city,
          save_to_address_book: false,
          postcode: address?.postcode,
          email: address?.email,
          name: address?.name,
          phone: address?.phone?.replace(/\+1|\+84|\+61/g, '')?.trim() || '',
          country_code: getCountryCodePhone(address?.phone),
          address_2: address?.address_2 || '',
          address_book: {},
        },
      });
    },
    [values, parent],
  );

  const onBlurAddress = useCallback(
    async (value) => {
      setTimeout(() => {
        setFieldValue(`${parent}.address_1`, value || '');
      }, 50);
    },
    [setFieldValue, parent],
  );

  const onBlurName = useCallback(
    async (value) => {
      setTimeout(() => {
        setFieldValue(`${parent}.name`, value || '');
      }, 50);
    },
    [setFieldValue, parent],
  );

  const onBlurPhone = useCallback(
    async (value) => {
      setTimeout(() => {
        setFieldValue(`${parent}.phone`, value || '');
      }, 50);
    },
    [setFieldValue, parent],
  );

  const renderAddressLabelItem = ({ name, address }) => (
    <Stack direction='row' spacing={0.5}>
      {name && (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            color: themes.color.violet900,
            fontWeight: 'bold',
            mr: 0.5,
          }}
        >
          {name} -
        </Typography>
      )}

      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {address}
      </Typography>
    </Stack>
  );

  const renderCustomerLabelItem = ({ name, address }) => (
    <Stack direction='row' spacing={0.5}>
      {name && (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            color: themes.color.violet900,
            fontWeight: 'bold',
            mr: 0.5,
          }}
        >
          {name} -
        </Typography>
      )}

      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {address}
      </Typography>
    </Stack>
  );

  const renderName = () => {
    if (parent === 'delivery')
      return (
        <Field.AsyncSelect
          onBlur={onBlurName}
          name={`${parent ? `${parent}.` : ''}name`}
          legend={label?.name}
          uniqueValue
          fetchOptions={(debouncedSearchText) =>
            getAddressCustomer(debouncedSearchText)
          }
          defaultOptions={allAddressCustomer?.map((address) => ({
            value: address.id,
            inputValue: address?.name,
            customLabel: renderCustomerLabelItem({
              name: address?.name,
              address: formatLongAddress(address),
            }),
            address,
            label: '',
            defaultOptionFullText: `${address.name} ${formatLongAddress(
              address,
            )}`,
          }))}
          transformOptions={(data: any) => {
            let ops = [];
            if (data?.length) {
              ops = [
                ...(data || []).map((item: any) => ({
                  customLabel: renderCustomerLabelItem({
                    name: item?.name,
                    address: formatLongAddress(item),
                  }),
                  value: item?.id,
                  inputValue: item?.name,
                  label: '',
                  address: item,
                })),
              ];
            }

            return ops;
          }}
          onChange={onChangeAddressCustomer}
        />
      );

    return (
      <FastField.TextInput
        legend={label?.name}
        name={`${parent ? `${parent}.` : ''}name`}
      />
    );
  };

  const renderPhone = () => {
    if (parent === 'delivery')
      return (
        <StyledAsyncSelectPhone
          onBlur={onBlurPhone}
          name={`${parent ? `${parent}.` : ''}phone`}
          legend='Phone Number'
          uniqueValue
          fetchOptions={(debouncedSearchText) =>
            getAddressCustomer(debouncedSearchText)
          }
          defaultOptions={allAddressCustomer?.map((address) => ({
            value: address.id,
            inputValue:
              address?.phone?.replace(/\+1|\+84|\+61/g, '')?.trim() || '',
            customLabel: renderCustomerLabelItem({
              name: address?.phone,
              address: formatLongAddress(address),
            }),
            address,
            label: '',
            defaultOptionFullText: `${address.phone} ${formatLongAddress(
              address,
            )}`,
          }))}
          transformOptions={(data: any) => {
            let ops = [];
            if (data?.length) {
              ops = [
                ...(data || []).map((item: any) => ({
                  customLabel: renderCustomerLabelItem({
                    name: item?.phone,
                    address: formatLongAddress(item),
                  }),
                  value: item?.id,
                  inputValue:
                    item?.phone?.replace(/\+1|\+84|\+61/g, '')?.trim() || '',
                  label: '',
                  address: item,
                })),
              ];
            }

            return ops;
          }}
          onChange={onChangeAddressCustomer}
          InputProps={{
            startAdornment: (
              <FastField.CountryCodeDropdown
                name={`${parent ? `${parent}.` : ''}country_code`}
              />
            ),
          }}
        />
      );

    return (
      <Field.TextInput
        legend='Phone Number'
        name={`${parent ? `${parent}.` : ''}phone`}
        type='tel'
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value);
        }}
        prefix={
          <FastField.CountryCodeDropdown
            name={`${parent ? `${parent}.` : ''}country_code`}
          />
        }
      />
    );
  };

  return (
    <>
      <Grid item xs={12} key={`${parent ? `${parent}.` : ''}address_1`}>
        <Field.AsyncSelect
          onBlur={onBlurAddress}
          inputProps={{
            placeholder: 'Search address, store location, or existing contacts',
          }}
          name={`${parent ? `${parent}.` : ''}address_1`}
          legend='Address Line 1'
          fetchOptions={(debouncedSearchText) =>
            getSuggestionAddress(
              formatSuggestionsAddress({
                query: debouncedSearchText,
                country: values[parent].country,
                state: values[parent].state,
              }),
              false,
            )
          }
          defaultOptions={allAddressBook?.map((address) => ({
            value: address.id,
            customLabel: renderAddressLabelItem({
              name: address?.metadata?.bs_warehouse_id
                ? `${address.store_name}: ${address.name}`
                : address.store_name || address.name,
              address: formatLongAddress(address),
            }),
            address,
            place_id: '',
            label: '',
            defaultOptionFullText: `${
              address?.store_name || address.name
            } ${formatLongAddress(address)}`,
          }))}
          transformOptions={(data: any) => {
            let ops = [];
            if (data.results.length) {
              ops = [
                ...ops,
                ...(data?.results || []).map((item: any) => ({
                  customLabel: renderAddressLabelItem({
                    name: '',
                    address: item.formatted_address,
                  }),
                  value: item?.formatted_address
                    ? item.formatted_address.split(',')[0]
                    : item.name,
                  place_id: item.place_id,
                  label: '',
                })),
              ];
            }

            return ops;
          }}
          onChange={onChangeAddress}
        />
      </Grid>
      <Grid item xs={12} key={`${parent ? `${parent}.` : ''}address_2`}>
        <FastField.TextInput
          legend='Address Line 2 (Optional)'
          name={`${parent ? `${parent}.` : ''}address_2`}
        />
      </Grid>
      <Grid item xs={12} md={6} key={`${parent ? `${parent}.` : ''}country`}>
        <Field.Select
          options={countries}
          legend='Country'
          name={`${parent ? `${parent}.` : ''}country`}
          disabled={loading}
          onChange={() => {
            setFieldValue('state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} key={`${parent ? `${parent}.` : ''}state`}>
        <Field.Select
          legend='Province / State'
          name={`${parent ? `${parent}.` : ''}state`}
          options={states}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={6} key={`${parent ? `${parent}.` : ''}city`}>
        <FastField.TextInput
          legend='City'
          name={`${parent ? `${parent}.` : ''}city`}
        />
      </Grid>
      <Grid item xs={12} md={6} key={`${parent ? `${parent}.` : ''}postcode`}>
        <FastField.TextInput
          legend='Postal Code'
          name={`${parent ? `${parent}.` : ''}postcode`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {renderName()}
      </Grid>
      <Grid item xs={12} md={6} key={`${parent ? `${parent}.` : ''}phone`}>
        {renderPhone()}
      </Grid>
      {parent === 'delivery' && (
        <Grid item xs={12} md={6}>
          <FastField.TextInput
            name='delivery.email'
            legend='Email (Optional)'
          />
        </Grid>
      )}
    </>
  );
};
