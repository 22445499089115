import React from 'react';
import { createStyles, WithStyles } from '@mui/styles';
import { Theme } from '~/themes';
import withStyles from '@mui/styles/withStyles';
import { TeamList } from './List/TeamList';

const style = (theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up('xl')]: {
        minWidth: 1270,
      },
    },
  });

interface IProps extends WithStyles<typeof style> {}

const Teams: React.FC<IProps> = () => <TeamList />;

export default withStyles(style)(Teams);
