/* eslint-disable no-nested-ternary */
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import {
  CountryCode,
  getCountryCallingCode,
  isValidNumberForRegion,
  parsePhoneNumber,
} from 'libphonenumber-js';
import lodashGet from 'lodash/get';
import { IOptionItem, TFunc } from '~/models/common';
import { COUNTRY_CODES } from '~/constants/task';
import { USER_ROLE } from '~/constants/userManagement';
import { IUserTooltip } from '~/models/user';

export const USER_DEVICE_ZONE = moment.tz.guess();

export const formatSimpleDateTime = (
  date?: string | Date | Moment,
  format: string = 'hh:mm a - MM/DD/YYYY',
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(
        format,
      )
    : moment().format(format);

export const formatSimpleDate = (
  date?: string | Date | Moment,
  format: string = 'MM/DD/YYYY',
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(
        format,
      )
    : moment().format(format);

export const formatSimpleTime = (
  date?: string | Date | Moment,
  format: string = 'hh:mm a',
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(
        format,
      )
    : moment().format(format);

const getTimeRangeItem = (hour: number, format: string = 'hh:mma') =>
  moment(`${moment().format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    .set({
      hour,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format(format);

export const getTimeRange = (
  startHour: number,
  endHour: number,
  separator: string = '-',
  format: string = 'hh:mma',
) =>
  `${getTimeRangeItem(startHour, format)} ${separator} ${getTimeRangeItem(
    endHour,
    format,
  )}`;

export const formatTaskDateTime = (date?: string | Date | Moment) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).calendar(
        null,
        {
          sameDay: 'hh:mm a [- Today]',
          nextDay: 'hh:mm a [- Tomorrow]',
          lastDay: 'hh:mm a [- Yesterday]',
          nextWeek: 'hh:mm a - MM/DD/YYYY',
          lastWeek: 'hh:mm a - MM/DD/YYYY',
          sameElse: 'hh:mm a - MM/DD/YYYY',
        },
      )
    : '-';

export const formatTaskTime = (time: string | Date | Moment) =>
  moment(`${moment(time).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format('ha');

export const formatTaskDate = (date?: string | Date | Moment) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).calendar(
        null,
        {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastDay: '[Yesterday]',
          nextWeek: 'MM/DD/YYYY',
          lastWeek: 'MM/DD/YYYY',
          sameElse: 'MM/DD/YYYY',
        },
      )
    : '-';

export const formatSimpleTaskDateTime = (
  date?: string | Date | Moment,
  disableToday: boolean = false,
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).calendar(
        null,
        {
          sameDay: `hh:mm a ${disableToday ? '' : '[| Today]'}`,
          nextDay: 'hh:mm a [| Tomorrow]',
          lastDay: 'hh:mm a [| Yesterday]',
          nextWeek: 'hh:mm a | MM/DD/YYYY',
          lastWeek: 'hh:mm a | MM/DD/YYYY',
          sameElse: 'hh:mm a | MM/DD/YYYY',
        },
      )
    : '-';

export const convertedSortParams = (
  sort: string | any,
  sortBy?: string | any,
) => {
  // if (sort) {
  //   const sortByKey: string | any = sort.replace(/^-/, '')
  //   const sortByValue = sort === sortByKey ? 1 : -1
  //   return {
  //     [sortByKey]: sortByValue,
  //   }
  // }
  // return {}
  if (Number(sort)) {
    return { [sortBy]: Number(sort) };
  }
  return {};
};

export const convertedArrayToString = (value: []) => value.toString();

export const mapCountingToOptions = (
  options: Array<IOptionItem>,
  counting: { [key: string]: number },
): Array<IOptionItem> =>
  options.map((x: IOptionItem) => ({
    ...x,
    count: counting[x.value] || 0,
  }));

export const convertNumberWithSISymbol = (
  num: number = 0,
  digits: number = 2,
) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits) + si[i].symbol;
};

export const searchText = (str: string, keyword: string) =>
  (str?.toLowerCase() || '').includes(keyword?.toLowerCase() || '');

export const compareText = (text1: string, text2: string) =>
  (text1?.toLowerCase() || '') === (text2?.toLowerCase() || '');

export const convertSortParams = (sort: any) =>
  sort && sort.sort
    ? `${sort.sort}=${
        sort.desc === 1 || sort.desc === '1' || sort.desc === true
          ? 'desc'
          : 'asc'
      }`
    : '';

export const goToTop = (id: string) => {
  document.querySelector(`#${id}`)?.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollTo = (id: string, offset: number) => {
  document
    .querySelector(`#${id}`)
    ?.scrollTo({ top: offset, behavior: 'smooth' });
};

// Two display types:  mins, hours
export const convertTimeFromSeconds = (second: number) => {
  const mins = second / 60;
  const hours = mins / 60;
  if (second < 60) {
    return '1 min';
  }
  if (mins < 60) {
    return `${mins.toFixed(1)} mins`;
  }
  if (Number(hours.toFixed(1)) === 1) {
    return '1 hour';
  }
  return `${hours.toFixed(1)} hours`;
};

export const isNullOrUndefined = (data: any) =>
  data === null || data === undefined;
export const isNullOrUndefinedOrEmptyString = (data: string) =>
  isNullOrUndefined(data) || data === '';

export const fixedOneDecimal = (number?: number | string) =>
  typeof number === 'string'
    ? number
      ? parseFloat(number).toFixed(1)
      : '0'
    : number
    ? number.toFixed(1)
    : '0';

export const fixedTwoDecimal = (
  number?: number | string,
  decimal: number = 2,
) =>
  typeof number === 'string'
    ? number
      ? parseFloat(number).toFixed(decimal)
      : '0'
    : number
    ? number.toFixed(decimal)
    : '0';

export const convertMilSecondToMin = (milSecond?: number) =>
  milSecond ? Math.ceil(milSecond / 60) : 0;

export const convertPercentage = (percent?: number) =>
  percent ? `${fixedTwoDecimal(percent)}%` : '';

const getIn = (obj: any, sort: string, type: string = 'string') => {
  const item = lodashGet(obj, sort.replaceAll('-', '').replaceAll('__', '.'));
  if (type === 'number') {
    return parseFloat(item);
  }
  return `${item}`.toLowerCase();
};

export const convertSortList = (
  list: any[],
  sortString: string,
  type: string = 'string',
) =>
  [...(list || [])].sort(
    (a, b) =>
      (getIn(a, sortString, type) === getIn(b, sortString, type)
        ? 0
        : getIn(a, sortString, type) > getIn(b, sortString, type)
        ? 1
        : -1) * (sortString[0] === '-' ? -1 : 1),
  );

export const onGoBack = (history: any, newUrl: string) => {
  if (history.action !== 'POP') {
    history.goBack();
  } else {
    history(newUrl);
  }
};

export const transformTypeToTitle = (text: string, separator: string = '-') => {
  const str = text.split(separator);
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length);
  }
  return str.join(' ').replace('_', '-');
};

export const transformTypeOptions = (
  list: { [key: string]: string },
  extraProps: { [key: string]: string | number | null } | TFunc = {},
): IOptionItem[] =>
  (Object.values(list) || []).map((value) => ({
    value,
    label: transformTypeToTitle(value),
    ...(typeof extraProps === 'function' ? extraProps(value) : extraProps),
  }));

export const formatRadiusValue = (value: number) => {
  if (value && parseInt(value.toFixed(2), 10) > 0) {
    return value.toFixed(2);
  }
  return '0';
};

export const isArrayEqual = (x: any[], y: any[]) =>
  _(x).differenceWith(y, _.isEqual).isEmpty();

export const isObjectEqual = (objA: any = {}, objB: any = {}) => {
  // compare a to b (b may have redundancies)
  let rs = true;
  Object.keys(objA).forEach((keyA) => {
    if (
      !isNullOrUndefined(objA[keyA]) &&
      !isNullOrUndefined(objB[keyA]) &&
      JSON.stringify(objA[keyA]) !== JSON.stringify(objB[keyA])
    ) {
      rs = false;
    }
  });

  return rs;
};

export const numberWithCommas = (x: number | string) =>
  x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const convertTimeToMin = (time: string) => {
  const timeArray = time.split(':');
  return parseInt(timeArray[0], 10) * 60 + parseInt(timeArray[1], 10);
};

export const getDisplayPhone = (
  phone: string,
  defaultCountry?: CountryCode,
) => {
  // +11231231234 => +1 123-123-1234
  const phoneNumber = parsePhoneNumber(phone, defaultCountry || 'CA');
  return phoneNumber.formatInternational();
};

export const getCountryCodeFromPhone = (
  phone: string,
  defaultCountry?: CountryCode,
) => {
  if (!phone) return defaultCountry || 'CA';
  const country =
    parsePhoneNumber(phone, defaultCountry || 'CA')?.country || '';
  if (!['VN', 'US', 'CA', 'AU'].includes(country)) return 'CA';
  return country;
};

export const getCountryCode = (country?: string): string =>
  country ? COUNTRY_CODES.find((e) => e.value === country)?.code || '' : '';

export const getNewSort = (sortId: string, nextArrange: number) => {
  // if (nextArrange === 1) {
  //   return sortId
  // }
  // if (nextArrange === -1) {
  //   return `-${sortId}`
  // }
  // return null
  if ([-1, 1].includes(nextArrange)) {
    return { sort: nextArrange, sort_by: sortId };
  }
  return { sort: null, sort_by: null };
};

export const isAdminOrOwner = (role?: string) =>
  role === USER_ROLE.OWNER || role === USER_ROLE.ADMIN;

export const isDispatcher = (role?: string) => role === USER_ROLE.DISPATCHER;

export const convertTooltipData = (tooltip: IUserTooltip): IUserTooltip => ({
  task_list: typeof tooltip.task_list === 'boolean' ? tooltip.task_list : true,
  create_task:
    typeof tooltip.create_task === 'boolean' ? tooltip.create_task : true,
  invite_driver:
    typeof tooltip.invite_driver === 'boolean' ? tooltip.invite_driver : true,
  add_store: typeof tooltip.add_store === 'boolean' ? tooltip.add_store : true,
  online_driver:
    typeof tooltip.online_driver === 'boolean' ? tooltip.online_driver : true,
  view_specific_info:
    typeof tooltip.view_specific_info === 'boolean'
      ? tooltip.view_specific_info
      : true,
  assign_task:
    typeof tooltip.assign_task === 'boolean' ? tooltip.assign_task : true,
  check_ideal_route:
    typeof tooltip.check_ideal_route === 'boolean'
      ? tooltip.check_ideal_route
      : true,
  enter_task_details:
    typeof tooltip.enter_task_details === 'boolean'
      ? tooltip.enter_task_details
      : true,
  assign_task_create:
    typeof tooltip.assign_task_create === 'boolean'
      ? tooltip.assign_task_create
      : true,
  organization:
    typeof tooltip.organization === 'boolean' ? tooltip.organization : true,
  stores: typeof tooltip.stores === 'boolean' ? tooltip.stores : true,
  optimize_store:
    typeof tooltip.optimize_store === 'boolean' ? tooltip.optimize_store : true,
  drivers: typeof tooltip.drivers === 'boolean' ? tooltip.drivers : true,
  manage_driver:
    typeof tooltip.manage_driver === 'boolean' ? tooltip.manage_driver : true,
  teams: typeof tooltip.teams === 'boolean' ? tooltip.teams : true,
  users: typeof tooltip.users === 'boolean' ? tooltip.users : true,
  set_task_routes:
    typeof tooltip.set_task_routes === 'boolean'
      ? tooltip.set_task_routes
      : true,
});

export const getHourAndMin = (second: number) => {
  const hour = Math.floor(second / 3600);
  const min = Math.floor((second - hour * 3600) / 60);
  const sec = Math.round(second - hour * 3600 - min * 60);
  return {
    hour,
    min,
    sec,
  };
};

export const getHourAndMinLabel = (second: number) => {
  const { hour, min } = getHourAndMin(second);
  return `${hour}h ${min || '00'}m`;
};

export const getHourMinSecLabel = (second: number) => {
  const { hour, min, sec } = getHourAndMin(second);
  return `${hour ? `${hour}h ` : ''}${min || '00'}m ${
    sec ? `${sec}s` : hour || sec ? '' : `00s`
  }`;
};

export const hashId = (str: string) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  // eslint-disable-next-line
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line
    hash |= 0;
  }
  return hash;
};

export const getPhoneWithCountryCode = (
  phone: string,
  countryCode?: CountryCode,
) => {
  let formatPhone = phone?.trim()?.replace(/[()\s]/g, '');
  if (!formatPhone) return '';
  if (formatPhone.startsWith('+')) return formatPhone;
  try {
    if (
      formatPhone &&
      countryCode &&
      isValidNumberForRegion(
        formatPhone,
        countryCode?.toUpperCase() as CountryCode,
      )
    )
      formatPhone = parsePhoneNumber(formatPhone, countryCode)?.number;
    else
      formatPhone = `+${
        getCountryCallingCode(countryCode as CountryCode) || ''
      }${formatPhone}`;
  } catch (error) {
    formatPhone = `+${
      getCountryCallingCode(countryCode as CountryCode) || ''
    }${formatPhone}`;
  }

  return formatPhone;
};
