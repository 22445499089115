import { Typography } from '@mui/material';
import { styled } from '~/themes';
import { Button } from 'bs-unified-ui';
import { TextLink } from '~/components/common/TextLink';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useRef, useState } from 'react';
import { isNaN, isNumber, isString } from 'lodash';

type Props = {
  phone: string;
  onClickReSend?: () => void;
  onComplete: (code: string) => void;
  loading: boolean;
  error?: string;
  clearError: () => void;
  hasAlertReLogin?: boolean;
};

export const StyledContainer = styled('div')(() => ({
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
}));

const MuiOtpInputStyled = styled(MuiOtpInput)`
  margin-top: 8px;
  gap: 8px;

  .MuiInputBase-root {
    height: 48px;
    max-height: unset;
    border-radius: 5px !important;
    input {
      padding: 4px 8px;
    }
  }
`;

const VerifyCode2FA = ({
  phone,
  onClickReSend,
  onComplete,
  loading,
  error,
  clearError,
  hasAlertReLogin = false,
}: Props) => {
  const [otp, setOtp] = useState('');
  const [counter, setCounter] = useState<number>(0);
  const refInterval = useRef<any>();

  const reSendOtp = async () => {
    onClickReSend();
    setCounter(60);
    refInterval.current = setInterval(() => {
      setCounter((prevState) => prevState - 1);
    }, 1000);
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
    clearError();
  };

  const matchIsNumeric = (text: string) =>
    (isNumber(text) || (isString(text) && text !== '')) && isNaN(Number(text));

  const validateChar = (value) => !matchIsNumeric(value);

  useEffect(() => {
    if (counter === 0) clearInterval(refInterval.current);
  }, [counter]);

  useEffect(() => {
    setCounter(60);
    refInterval.current = setInterval(() => {
      setCounter((prevState) => prevState - 1);
    }, 1000);
    return () => clearInterval(refInterval.current);
  }, []);

  return (
    <StyledContainer>
      <Typography
        variant='h1'
        sx={{
          marginTop: '56px',
        }}
      >
        Enter verification code
      </Typography>
      <Typography variant='body2' mt={2}>
        {`We've sent a code to ${phone}. Please enter it below to verify your
      phone number.`}
      </Typography>
      {hasAlertReLogin && (
        <Typography variant='body2' mt={1}>
          Note: You will be prompted to re-login.
        </Typography>
      )}

      {counter ? (
        <p
          style={{
            marginTop: '16px',
            lineHeight: '24px',
            color: 'rgba(51, 54, 61, 0.40)',
          }}
        >
          Resend code{' '}
          <span
            style={{
              color: 'black',
            }}
          >
            ({counter})
          </span>
        </p>
      ) : (
        <TextLink
          to=''
          style={{
            marginTop: '16px',
            lineHeight: '24px',
            color: '#2D91FC',
          }}
          onClick={() => reSendOtp()}
        >
          Resend code
        </TextLink>
      )}

      <Typography variant='body2' mt={3}>
        Enter the 6-digit code
      </Typography>
      <MuiOtpInputStyled
        value={otp}
        onChange={handleChange}
        length={6}
        validateChar={validateChar}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && otp?.length === 6) {
            onComplete(otp);
          }
        }}
      />
      {error && (
        <Typography
          sx={{
            lineHeight: 1.66,
            color: '#F24822',
            mt: '10px !important',
            fontSize: 14,
          }}
        >
          {error}
        </Typography>
      )}
      <Button
        type='submit'
        sx={{ mt: 2 }}
        disabled={otp?.length !== 6}
        onClick={() => onComplete(otp)}
        loading={loading}
      >
        Next
      </Button>
    </StyledContainer>
  );
};

export default VerifyCode2FA;
