import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
} from '@mui/material';
import themes from '~/themes';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type AccordionItemProps = {
  id: string;
  hiddenExpandIcon?: boolean;
  isDisabledExpandIcon?: boolean;
  styles?: {
    container?: SxProps;
    summary?: SxProps;
    detail?: SxProps;
  };
  summaryContent?: React.ReactNode | string;
  expanded: (id: string) => boolean;
  handleChangeExpandedIds: (
    id: string,
  ) => (event?: React.SyntheticEvent, expanded?: boolean) => void;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  id,
  children,
  hiddenExpandIcon = false,
  isDisabledExpandIcon = false,
  styles = {
    summary: {},
    container: {},
    detail: {},
  },
  summaryContent = '',
  expanded,
  handleChangeExpandedIds,
}) => (
  <Accordion
    disableGutters
    expanded={expanded(id)}
    key={id}
    data-testid={`${id}-container`}
    onChange={handleChangeExpandedIds(id)}
    sx={{
      '&::before': {
        display: 'none',
      },
      ...styles.container,
    }}
  >
    <AccordionSummary
      aria-controls={`${id}-content`}
      id={`${id}-summary`}
      data-testid={`${id}-summary`}
      expandIcon={
        hiddenExpandIcon ? null : (
          <PlayArrowIcon
            sx={{
              color: themes.color.violet900,
              fontSize: '20px',
              rotate: '90deg',
              ...(isDisabledExpandIcon && {
                opacity: 0.5,
              }),
            }}
          />
        )
      }
      sx={{
        padding: themes.spacing(1),
        color: themes.color.black,
        fontSize: 14,
        ...styles.summary,
      }}
    >
      {summaryContent}
    </AccordionSummary>
    <AccordionDetails data-testid={`${id}-details`} sx={{ ...styles.detail }}>
      {children}
    </AccordionDetails>
  </Accordion>
);
