export enum IPlanTier {
  Free = 0,
  Standard = 1,
  Advance = 2,
}

export interface IPlan {
  name?: string;
  type_?: 'plan' | 'graduated';
  price_id?: string;
  unit_amount?: number;
  recurring?: Recurring;
  tier?: IPlanTier;
  id?: string;
  frequency?: IBillingFrequency;
  metadata?: IPlanMetadata;
  country?: string;
  subscription_id?: string;
}

export interface Recurring {
  interval?: IBillingFrequency;
  interval_count?: number;
}

export enum IBillingFrequency {
  Month = 'month',
  Year = 'year',
}

export interface IPlanMetadata {
  tier?: IPlanTier;
  price?: string;
  title?: string;
  key_features?: string[];
  checks?: string[];
  price_num?: number;
  numbers_of_task?: string;
  priceId?: string;
}

export enum SetupPlanStep {
  AddOrg = 'add-org',
  PreviewSummary = 'preview',
  AddCard = 'add-card',
}

export const PlanTierWithNumberOfTask = {
  '100': IPlanTier.Free,
  '500': IPlanTier.Standard,
  '1000': IPlanTier.Standard,
  '6000': IPlanTier.Advance,
  'more-than-6000': IPlanTier.Advance,
};

export interface ICreateSubscriptionParams {
  payment_account_id?: string;
  price_id?: string;
  quantity?: number;
}

export interface IUpdateSubscriptionParams {
  subId?: string;
  price_id?: string;
  driver_id?: string;
}
