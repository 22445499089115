import Modal from '~/components/common/Modal';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { CircularProgress, Stack } from '@mui/material';
import { useGetPreviewTimeSlot } from '~/services/api/stores';
import React, { useEffect, useMemo, useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import themes, { Theme } from '~/themes';
import { WithStyles, withStyles } from '@mui/styles';
import { isArray, isEmpty } from 'lodash';
import clsx from 'clsx';

const style = (theme: Theme) =>
  createStyles({
    container: {
      '& .MuiDialogContent-root': {
        backgroundColor: '#F8F8FC !important',
        marginTop: '-20px',
      },
    },
    disableBackMonth: {
      '& .rdtPicker th.rdtPrev': {
        pointerEvents: 'none !important',
        opacity: '0.5',
      },
    },
    dateTimeContainer: {
      [themes.breakpoints.up('md')]: {
        '& table': {
          aspectRatio: '1/1 !important',
          borderSpacing: '0px 0px',
        },
      },
      [themes.breakpoints.down('md')]: {
        '& table': {
          aspectRatio: 'unset',
          borderSpacing: '0px 0px',
        },
      },
      'width': '100%',
      '& .rdtPicker td.rdtToday:before': {
        borderBottom: `7px solid ${theme.bg.darkPurple}`,
      },
      '& .rdtDays tr .dow': {
        color: '#000000',
        borderWidth: 0,
        letterSpacing: 0,
        fontSize: 13,
        fontWeight: 500,
        paddingBottom: 3,
      },
      '& .rdtPicker th': {
        borderBottom: 'none',
      },
      '& .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev': {
        'fontSize': theme.spacing(3),
        'fontWeight': '400',
        '&:hover': {
          background: 'none',
        },
      },
      '& th.rdtSwitch': {
        'cursor': 'auto !important',
        '&:hover': {
          backgroundColor: 'white !important',
        },
      },
      '& .rdtPicker th.rdtSwitch': {
        fontFamily: 'DM Sans',
        fontWeight: '500',
      },
      '& .rdtPicker td.rdtActive': {
        backgroundColor: `${theme.bg.darkPurple} !important`,
        color: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: `${theme.color.violet900} !important`,
      },
      '& .rdtPicker': {
        border: 'none',
        padding: '12px',
        minWidth: 'unset !important',
      },
    },
    hideActiveDate: {
      '& .rdtPicker td.rdtActive': {
        backgroundColor: `white !important`,
        color: '#000000',
      },
    },
  });

type Props = {
  open: boolean;
  onClose: () => void;
  data: any;
  timezone?: string;
};

const Preview = ({
  open,
  onClose,
  data: { delivery, id },
  classes,
  timezone,
}: Props & WithStyles<typeof style>) => {
  const { asap_option } = delivery;
  const [dateSelected, setDateSelected] = useState(moment().tz(timezone));
  const [monthSelected, setMonthSelected] = useState(moment().tz(timezone));
  const [dateTimeSlots, setDateTimeSlots] = useState<any>({});

  const isCurrentMonth = monthSelected.isSame(moment(), 'months');

  const disableDate = (current) => {
    if (dateTimeSlots[current.format('YYYY-MM-DD')]?.length === 0) {
      return false;
    }
    const yesterday = moment().tz(timezone).subtract(1, 'day');
    return moment(current).isAfter(yesterday);
  };

  const selectedTimeSlots = useMemo(
    () =>
      dateSelected ? dateTimeSlots[dateSelected.format('YYYY-MM-DD')] : '',
    [dateTimeSlots, dateSelected],
  );

  const { data: resData, isFetching: isLoading } = useGetPreviewTimeSlot({
    params: {
      delivery,
      id,
      date_from: monthSelected?.clone().startOf('month').format('YYYY-MM-DD'),
      offset: 60,
    },
    enabled: !dateTimeSlots[monthSelected.format('YYYY-MM-DD')],
  });

  const handleBeforeNavigate = (nextView, currentView) => {
    if (nextView === 'years' || nextView === 'months') {
      return currentView;
    }
    return nextView;
  };

  const handleDateChange = (date) => {
    setMonthSelected((prevState) =>
      prevState?.clone()?.month(date.month())?.year(date.year()),
    );
    setDateSelected(date);
  };

  useEffect(() => {
    if (!isEmpty(resData)) {
      setDateTimeSlots((prevState) => ({
        ...prevState,
        ...resData.time_slots,
      }));
    }
  }, [resData]);

  useEffect(() => {
    if (
      dateSelected &&
      dateTimeSlots[dateSelected?.format('YYYY-MM-DD')]?.length === 0
    ) {
      setDateSelected(null);
    }
  }, [dateTimeSlots]);

  const renderSlots = (slots, index) => {
    if (!isArray(slots)) {
      if (slots === 'ASAP' && asap_option === 'asap') {
        return (
          <Stack
            sx={{
              padding: '8px',
              color: 'white',
              backgroundColor: '#121142',
            }}
          >
            {slots}
          </Stack>
        );
      }
      if (slots === 'ASAP') return <Stack>{slots}</Stack>;
      return (
        <Stack
          flex={1}
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {slots}
        </Stack>
      );
    }
    return (
      <Stack
        sx={{
          padding: '8px',
          color: index === 1 && asap_option === 'first' ? 'white' : '#33363D',
          backgroundColor:
            index === 1 && asap_option === 'first' ? '#121142' : '',
        }}
      >{`${moment(slots[0], 'HH:mm').format('hh:mm a')} - ${moment(
        slots[1],
        'HH:mm',
      ).format('hh:mm a')}`}</Stack>
    );
  };

  const renderTimeSlots = () => {
    if (isLoading)
      return (
        <Stack
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          flex={1}
        >
          <CircularProgress size={30} />
        </Stack>
      );
    if (!dateSelected) return null;

    const sufixKey = dateSelected.format('YYYY-MM-DD');

    if (selectedTimeSlots?.length === 0) {
      return (
        <Stack flexDirection='column' textAlign='center' gap='4px' flex={1}>
          No time slot
        </Stack>
      );
    }

    return (
      <Stack flexDirection='column' textAlign='center' gap='4px' flex={1}>
        {selectedTimeSlots?.map((slots, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${sufixKey}-${index}`}>
            {renderSlots(slots, index)}
          </React.Fragment>
        ))}
      </Stack>
    );
  };

  return (
    <Modal
      title='Time Picker Preview'
      open={open}
      onClose={onClose}
      disableCloseOutside
      PaperProps={{
        sx: {
          width: 700,
        },
      }}
      maxWidth='lg'
      actions={[]}
      className={classes.container}
      customContentModal='content'
    >
      <Stack pt={1.6} px={2.5}>
        <Stack
          flexDirection='row'
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            [themes.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Stack
            sx={{
              width: '66.67%',
              [themes.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Datetime
              className={clsx(classes.dateTimeContainer, {
                [classes.disableBackMonth]: isCurrentMonth,
                [classes.hideActiveDate]: !dateSelected,
              })}
              {...(dateSelected ? { value: dateSelected } : {})}
              isValidDate={disableDate}
              input={false}
              open={open}
              timeFormat={false}
              onChange={handleDateChange}
              onBeforeNavigate={handleBeforeNavigate}
              onNavigateForward={(amount) => {
                setMonthSelected((prevState) =>
                  prevState.clone().add(amount, 'months'),
                );
              }}
              onNavigateBack={(amount) => {
                setMonthSelected((prevState) => {
                  const isBackDisabled = prevState
                    .clone()
                    .subtract(1, 'months')
                    .isBefore(moment().tz(timezone), 'months');
                  if (!isBackDisabled)
                    return prevState.clone().subtract(amount, 'months');
                  return prevState;
                });
              }}
            />
          </Stack>
          <Stack
            flex={1}
            sx={{
              aspectRatio: '1/2',
              [themes.breakpoints.down('md')]: {
                aspectRatio: '1/1',
                overflow: 'hidden',
              },
            }}
            p={1}
            pt={2.4}
          >
            <Stack
              sx={{
                height: '100%',
                overflowY: 'auto',
              }}
              className='customized-scrollbar'
            >
              {renderTimeSlots()}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default withStyles(style)(Preview);
