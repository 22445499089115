import FieldWrapper from '~/utils/formik/FieldWrapper';
import FastFieldWrapper from '~/utils/formik/FastFieldWrapper';
import TextInput, { IFieldTextInput } from './TextInput';
import CheckBox, { IFieldCheckBox } from './CheckBox';
import GroupRadioButton, { IFieldGroupRadioButton } from './GroupRadioButton';
import SwitchButton, { IFieldSwitchButton } from './SwitchButton';
import SwitchItem, { IFieldSwitchItem } from './SwitchItem';

import AsyncSelect, { IAsyncSelect } from './AsyncSelect';
import FormSelect, { IFormSelectProps } from './Select';
import CountryCodeDropdown, {
  IFieldCountryCodeDropdown,
} from './CountryCodeDropdown';
import FormElementSelect, { IFormElementSelectProps } from './ElementSelect';
import FormRadioButton, { IFieldRadioButton } from './RadioButton';
import FormTimeSelector, { IFormTimeSelector } from './TimeSelector';
import DateSelector, { IFieldDateSelector } from './DateSelector';
import SearchInput, { IFieldSearchInput } from './SearchInput';
import UnifiedTextField, { IUnifiedTextField } from './UnifiedTextField';

export const Field = {
  TextInput: FieldWrapper<IFieldTextInput>(TextInput),
  CheckBox: FieldWrapper<IFieldCheckBox>(CheckBox),
  GroupRadioButton: FieldWrapper<IFieldGroupRadioButton>(GroupRadioButton),
  AsyncSelect: FieldWrapper<IAsyncSelect>(AsyncSelect),
  CountryCodeDropdown:
    FieldWrapper<IFieldCountryCodeDropdown>(CountryCodeDropdown),
  SwitchButton: FieldWrapper<IFieldSwitchButton>(SwitchButton),
  Select: FieldWrapper<IFormSelectProps>(FormSelect),
  ElementSelect: FieldWrapper<IFormElementSelectProps>(FormElementSelect),
  RadioButton: FieldWrapper<IFieldRadioButton>(FormRadioButton),
  TimeSelector: FieldWrapper<IFormTimeSelector>(FormTimeSelector),
  DateSelector: FieldWrapper<IFieldDateSelector>(DateSelector),
  SearchInput: FieldWrapper<IFieldSearchInput>(SearchInput),
  UnifiedTextField: FieldWrapper<IUnifiedTextField>(UnifiedTextField),
};

export const FastField = {
  TextInput: FastFieldWrapper<IFieldTextInput>(TextInput),
  CheckBox: FastFieldWrapper<IFieldCheckBox>(CheckBox),
  SwitchButton: FastFieldWrapper<IFieldSwitchButton>(SwitchButton),
  SwitchItem: FastFieldWrapper<IFieldSwitchItem>(SwitchItem),
  CountryCodeDropdown:
    FastFieldWrapper<IFieldCountryCodeDropdown>(CountryCodeDropdown),
  Select: FastFieldWrapper<IFormSelectProps>(FormSelect),
  ElementSelect: FastFieldWrapper<IFormElementSelectProps>(FormElementSelect),
  RadioButton: FastFieldWrapper<IFieldRadioButton>(FormRadioButton),
  TimeSelector: FastFieldWrapper<IFormTimeSelector>(FormTimeSelector),
  DateSelector: FastFieldWrapper<IFieldDateSelector>(DateSelector),
  SearchInput: FastFieldWrapper<IFieldSearchInput>(SearchInput),
  UnifiedTextField: FastFieldWrapper<IUnifiedTextField>(UnifiedTextField),
};

export default {
  Field,
  FastField,
};
