import React from 'react';
import { getIn } from 'formik';
import { IFormProps } from '~/models/common';
import {
  UnifiedTextField as TextField,
  UnifiedTextFieldProps,
} from '~/components/unified/UnifiedTextField';

export interface IUnifiedTextField
  extends IFormProps,
    Omit<UnifiedTextFieldProps, 'name'> {
  disableErrorText?: boolean;
}

const UnifiedTextField: React.FC<IUnifiedTextField> = ({
  field,
  form,
  error,
  helperText,
  inputProps = {},
  disableErrorText,
  ...props
}) => (
  <TextField
    id={field.name}
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      disableErrorText
        ? ''
        : helperText ||
          (Boolean(getIn(form.touched, field.name)) &&
            getIn(form.errors, field.name))
    }
    inputProps={{
      'data-testid': `${field.name}-input`,
      ...field,
      ...inputProps,
      'value': field.value ?? '',
    }}
    {...props}
  />
);

export default UnifiedTextField;
