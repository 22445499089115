/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useMemo } from 'react';
import {
  Table as ThemeTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  Paper,
  SxProps,
  CircularProgress,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import style from './style';
import CheckBox from '../CheckBox';

export type TColumn = {
  id: string;
  title?: string | React.ReactNode;
  cellProps?: any;
  cellStyle?: any;
  sortable?: boolean;
  // eslint-disable-next-line no-unused-vars
  renderComponent?: (
    item: any,
    column?: any,
    index?: number,
  ) => React.ReactNode;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  colSpan?: number;
  customId?: string;
  emptyDash?: boolean;
  [key: string]: any;
};

export interface ITableProps extends WithStyles<typeof style> {
  className?: string;
  dataProps?: any;
  customClassName?: string;
  loading?: boolean;
  columns: TColumn[];
  sortBy?: any;
  footerData?: any[];
  footerColumns?: Array<TColumn>;
  striped?: boolean;
  noHoverInteraction?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSort?: (sortId: string, nextArrange: number) => void;
  // eslint-disable-next-line no-unused-vars
  onRowClick?: (item: any, index: number) => void;
  // eslint-disable-next-line no-unused-vars
  onCheckboxClick?: (item: any, index?: number) => void;
  clickableRowId?: number | null;
  customTableHead?: string;
  borderRadiusHeader?: boolean;
  noResultText?: string;
  selected?: (number | string)[];
  // eslint-disable-next-line no-unused-vars
  renderExpand?: (item: any, column?: any, index?: number) => React.ReactNode;
  customEmptyState?: ReactNode;
  headerCellSxProps?: SxProps;
  cellSxProps?: SxProps;
  containerSxProps?: SxProps;
  sxNoData?: SxProps;
  name?: string;
}

const Table: React.FC<ITableProps> = ({
  classes,
  className,
  customClassName,
  customTableHead,
  headerCellSxProps,
  cellSxProps,
  containerSxProps,
  loading,
  dataProps,
  columns,
  sortBy = {},
  footerData = null,
  footerColumns,
  striped = false,
  noHoverInteraction = false,
  borderRadiusHeader,
  clickableRowId = null,
  noResultText = 'No results found.',
  selected,
  customEmptyState,
  onSort = () => {},
  renderExpand,
  onRowClick = null,
  onCheckboxClick = null,
  sxNoData = {},
  name,
}) => {
  const dataTransform = useMemo(() => {
    if (
      dataProps &&
      ((!customEmptyState &&
        dataProps.find((item: any) => Object.values(item).length > 0)) ||
        (!!customEmptyState && dataProps?.length))
    ) {
      return dataProps;
    }
    return [{ id: '_noData' }];
  }, [dataProps, sortBy]);

  const renderHeader = (
    id: string,
    title: string | React.ReactNode,
    cellProps: any,
    cellStyle?: React.CSSProperties,
    sortable?: boolean,
  ) => {
    switch (id) {
      case '_no':
        return (
          <TableCell
            key={id}
            sx={headerCellSxProps}
            {...(cellProps || {})}
            style={cellStyle}
            className={classes.textCenter}
          >
            {title}
          </TableCell>
        );
      default:
        return (
          <TableCell
            className={clsx(classes.tableHeadCell, className)}
            key={id}
            style={cellStyle}
            sx={headerCellSxProps}
            {...(cellProps || {})}
          >
            {sortable ? (
              <span
                role='presentation'
                className={classes.sortLabel}
                onClick={() => {
                  if (onSort) {
                    // eslint-disable-next-line no-nested-ternary
                    onSort(id, sortBy[id] ? (sortBy[id] === 1 ? -1 : 0) : 1);
                  }
                }}
              >
                {title}
                {!sortBy[id] ? (
                  <span className={classes.sortableIcon} />
                ) : (
                  <ArrowDropUpIcon
                    className={clsx(classes.sortIcon, {
                      [classes.sortIconDesc]: sortBy[id] === -1,
                    })}
                  />
                )}
              </span>
            ) : (
              title
            )}
          </TableCell>
        );
    }
  };

  const renderColumn = (column: TColumn, item: any, index: number) => {
    const emptyDashStr = column.emptyDash ? '-' : '';
    switch (column.id) {
      case '_checkbox':
        return (
          <TableCell
            key={`cell_${index}_${column.id}`}
            data-testid={`tbl-cell-${column.id}`}
            style={column.cellStyle}
            className={classes.tableCell}
            sx={cellSxProps}
          >
            {Boolean(column.id) && (
              <CheckBox
                checked={
                  selected?.indexOf(item?.[column?.customId || 'id']) !== -1
                }
                onChange={(e) => {
                  if (onCheckboxClick) {
                    onCheckboxClick(item, index);
                  } else if (onRowClick) {
                    onRowClick(item, index);
                  }
                  e.stopPropagation();
                }}
              />
            )}
          </TableCell>
        );
      case 'image':
        return (
          <TableCell
            key={`cell_${index}_${column.id}`}
            data-testid={`tbl-cell-${column.id}`}
            style={column.cellStyle}
            className={classes.tableCell}
          >
            <Avatar
              alt={item.driver}
              src={item.image}
              className={classes.avatar}
            />
          </TableCell>
        );
      case '_no':
        return (
          <TableCell
            key={`cell_${index}_${column.id}`}
            data-testid={`tbl-cell-${column.id}`}
            style={column.cellStyle}
            className={classes.tableCell}
            sx={cellSxProps}
          >
            {Object.keys(item).length > 0 ? (
              index + 1
            ) : (
              <div style={{ minHeight: 30 }} />
            )}
          </TableCell>
        );
      case '_empty':
      case '_empty_tail':
        return (
          <TableCell
            key={`cell_${index}_${column.id}`}
            style={column.cellStyle}
            className={classes.tableCell}
            sx={cellSxProps}
          >
            &nbsp;
          </TableCell>
        );

      case '_more':
        return (
          <TableCell
            className={classes.moreItem}
            style={column.cellStyle}
            data-testid='tbl-cell-more'
            key={`cell_${index}_${column.id}`}
            sx={cellSxProps}
            onClick={() => onRowClick && onRowClick(item, index)}
          >
            {selected?.indexOf(item.id || '') !== -1 ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </TableCell>
        );
      default:
        if (column.renderComponent) {
          return (
            <TableCell
              key={`cell_${index}_${column.id}`}
              data-testid={`tbl-cell-${column.id}`}
              style={column.cellStyle}
              className={clsx(classes.tableCell, {
                noBorder: !!customEmptyState,
              })}
              sx={cellSxProps}
            >
              <>
                {column.prefix || ''}
                {column.renderComponent(item, column, index)}
                {column.suffix || ''}
              </>
            </TableCell>
          );
        }
        return (
          <TableCell
            key={`cell_${index}_${column.id}`}
            data-testid={`tbl-cell-${column.id}`}
            style={column.cellStyle}
            className={classes.tableCell}
            colSpan={column.colSpan}
            sx={cellSxProps}
          >
            {item[column.id] !== undefined ? column.prefix : ''}
            {item[column.id] !== undefined ? item[column.id] : emptyDashStr}
            {item[column.id] !== undefined ? column.suffix : ''}
          </TableCell>
        );
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={containerSxProps}
      className={clsx(
        classes.tableContainer,
        customClassName,
        'customized-scrollbar',
      )}
      data-testid='tbl-container'
    >
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={30} />
        </div>
      )}
      <ThemeTable
        className={clsx(classes.table, {
          [classes.striped]: striped,
          [classes.customHeader]: borderRadiusHeader,
        })}
        data-testid={`tbl-${name || ''}`}
      >
        <TableHead className={customTableHead} data-testid='tbl-head'>
          <TableRow key='header' data-testid='tbl-row'>
            {columns.map(({ id, title, cellProps, cellStyle, sortable }) =>
              renderHeader(id, title || '', cellProps, cellStyle, sortable),
            )}
          </TableRow>
        </TableHead>
        <TableBody data-testid='tbl-body'>
          {dataTransform.map((item: TColumn, index: number) => {
            if (renderExpand && selected?.indexOf(item?.id) !== -1) {
              return (
                <>
                  <TableRow
                    className={clsx({
                      [classes.expandRowPointer]: renderExpand,
                      selected: selected?.indexOf(item?.id) !== -1,
                    })}
                    hover
                    key={item.id}
                    onClick={() => onRowClick && onRowClick(item, index)}
                  >
                    {columns.map((column) => renderColumn(column, item, index))}
                  </TableRow>
                  {renderExpand(renderColumn, item, index)}
                </>
              );
            }
            if (item.id === '_noData') {
              return (
                <TableRow
                  key={item.id}
                  className={clsx(classes.tableRow, {
                    noHoverInteraction: true,
                    striped,
                  })}
                >
                  <TableCell
                    key='noData'
                    id='noData'
                    colSpan={columns.length}
                    className={clsx(classes.noData, className)}
                    sx={sxNoData}
                  >
                    {!loading && noResultText}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow
                className={clsx(classes.tableRow, {
                  selected: index === clickableRowId,
                  clickable: !!onRowClick,
                  noHoverInteraction,
                  [classes.noBorder]: !!customEmptyState,
                  striped,
                })}
                key={item.id || `row_${index}`}
                hover
                onClick={() => onRowClick && onRowClick(item, index)}
              >
                {columns.map((column) => renderColumn(column, item, index))}
              </TableRow>
            );
          })}
        </TableBody>
        {!!footerData?.length && (
          <TableFooter>
            {footerData.map((item, index) => (
              <TableRow key={`footer_${item.id || index}`}>
                {footerColumns &&
                  footerColumns.map((column) =>
                    renderColumn(column, item, dataTransform.length),
                  )}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </ThemeTable>
      {customEmptyState || null}
    </TableContainer>
  );
};

export default withStyles(style)(Table);
