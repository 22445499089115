import { createStyles } from '@mui/styles';
import { Theme } from '~/themes/index';

const style = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      display: 'flex',
    },
    changeLogContainer: {
      overflow: 'auto',
      flex: 1,
    },
    containerTitle: {
      marginBottom: theme.spacing(1),
    },
    changeLogContent: {
      'display': 'flex',
      'alignItems': 'center',
      'marginBottom': theme.spacing(2),
      'position': 'relative',
      '&:before': {
        content: '""',
        width: 2,
        height: 'calc(50% + 20px)',
        backgroundColor: theme.bg.purpleOutline,
        position: 'absolute',
        left: 5,
        bottom: '50%',
        zIndex: 0,
      },
      '&:after': {
        content: '""',
        width: 2,
        height: 'calc(50% + 20px)',
        backgroundColor: theme.bg.purpleOutline,
        position: 'absolute',
        left: 5,
        top: '50%',
        zIndex: 0,
      },
      '&:last-child': {
        'marginBottom': 0,
        '&::after': {
          display: 'none',
        },
      },
      '&:first-child': {
        '&::before': {
          display: 'none',
        },
      },
    },
    hiddenLeftPoint: {
      '&::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
    updated: {
      '&:after': {
        backgroundColor: theme.bg.purpleOutline,
      },
    },
    latest: {
      '&:after': {
        backgroundColor: theme.bg.midPurple,
      },
    },
    log: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      borderRadius: 10,
      backgroundColor: theme.bg.lightPurple,
      flex: 1,
    },
    pendingLog: {
      backgroundColor: 'unset',
    },
    noAvatar: {
      padding: '10px 20px',
    },
    point: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: theme.bg.purpleOutline,
      marginRight: theme.spacing(1),
      zIndex: 1,
    },
    pendingPoint: {
      backgroundColor: theme.bg.midPurple,
    },
    avatar: {
      border: `2px solid ${theme.bg.purpleOutline}`,
      marginRight: theme.spacing(1),
    },
    logContent: {
      'width': '100%',
      '&>*:first-child': {
        marginBottom: theme.spacing(0.5),
      },
    },
    noResultFound: {
      textAlign: 'center',
      color: theme.color.violet600,
      width: '100%',
      height: 45,
    },
    title: {
      fontWeight: 500,
      whiteSpace: 'pre-wrap',
    },
  });

export default style;
