import React from 'react';
import { getIn } from 'formik';
import { TextInput as TextInputRaw } from '~/components/common/TextInput';
import { IFormProps } from '~/models/common';
import { TextInputProps } from '../TextInput/TextInput';

export interface IFieldTextInput
  extends IFormProps,
    Omit<TextInputProps, 'name'> {
  disableErrorText?: boolean;
}

const TextInput: React.FC<IFieldTextInput> = ({
  field,
  form,
  error,
  helperText,
  inputProps = {},
  disableErrorText,
  onChange,
  ...props
}) => (
  <TextInputRaw
    id={field.name}
    error={
      !!error ||
      (!!getIn(form.touched, field.name) && !!getIn(form.errors, field.name))
    }
    helperText={
      disableErrorText
        ? ''
        : helperText ||
          (Boolean(getIn(form.touched, field.name)) &&
            getIn(form.errors, field.name))
    }
    inputProps={{
      ...field,
      ...inputProps,
      ...(onChange ? { onChange } : {}),
      value: field.value ?? '',
    }}
    {...props}
  />
);

export default TextInput;
