import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { Field } from '~/components/common/Formik';
import { Stack, Typography } from '@mui/material';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { alertParams, showAlert } from '~/components/common/Alert';
import { SendAlertSchema } from '../schema';
import { useSendAlertMutation } from '../apis';
import { useLiveMapContext } from '../hooks/useLiveMapContext';

type DialogSendAlertProps = {
  open: boolean;
  onClose: () => void;
};

export const DialogSendAlert: React.FC<DialogSendAlertProps> = ({
  open,
  onClose,
}) => {
  const { driverId } = useLiveMapContext();
  const { mutate: sendAlertMutation, isLoading } = useSendAlertMutation({
    onSuccess: () => {
      onClose();
      showAlert({
        ...alertParams.successDark,
        title: 'Alert Sent Successfully',
        description: 'Alert has been sent to designated driver.',
      });
    },
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth='sm'
      title='Send Alert'
      sx={{}}
      actions={[
        {
          title: 'Cancel',
          onClick: onClose,
          buttonType: 'default',
        },
        {
          title: 'Send',
          type: 'submit',
          buttonType: 'primary',
          loading: isLoading,
          form: 'send-alert',
        },
      ]}
    >
      <Formik
        initialValues={{
          message: '',
          requestPhoneCall: false,
          phone: '',
          country_code: 'CA',
        }}
        onSubmit={(values) => {
          const phone = values.phone
            ? parsePhoneNumber(values.phone, values.country_code as CountryCode)
                .number
            : '';

          sendAlertMutation({
            id: driverId,
            body: {
              message: values.message,
              ...(phone ? { phone } : {}),
            },
          });
        }}
        enableReinitialize
        validationSchema={SendAlertSchema}
      >
        {({ values, setFieldValue, setTouched }) => (
          <Form id='send-alert'>
            <Field.TextInput
              name='message'
              fullWidth
              rows={12}
              multiline
              placeholder='Type a message here.'
            />
            <Typography
              sx={{ textAlign: 'end', mt: 0.5, mb: 2 }}
            >{`${values.message.length}/150`}</Typography>

            <Field.CheckBox
              label='Request a phone call'
              name='requestPhoneCall'
              onChange={(e) => {
                if (!e.target.checked) {
                  setTouched({ phone: false });
                }
                setFieldValue('requestPhoneCall', e.target.checked);
                setFieldValue('phone', '');
              }}
            />
            <Stack mt={1}>
              <Field.TextInput
                sx={{ width: 'fit-content' }}
                legend='Phone Number'
                name='phone'
                type='tel'
                disabled={!values.requestPhoneCall}
                prefix={
                  <Field.CountryCodeDropdown
                    name='country_code'
                    disabled={!values.requestPhoneCall}
                  />
                }
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
