import { BroadcastEvents, channel } from '~/lib/broadcast';
import { queryClient } from '~/lib/react-query';
import { IAccount } from '~/models/adminOptions';
import { queryKeys } from '~/services/api/queryKeys';

export const setAccountQueryData = (
  updateData: IAccount,
  isSyncMultiTab: boolean = true,
) => {
  if (isSyncMultiTab) {
    channel.postMessage({
      type: BroadcastEvents.SyncAccountData,
      payload: {
        data: updateData,
      },
    });
  }

  queryClient.setQueriesData(
    [queryKeys.settings.account],
    (oldData: { users: IAccount[] }) => ({
      ...oldData,
      users: oldData.users.map((data) => {
        if (data.org_id === updateData.org_id) {
          return {
            ...data,
            ...updateData,
          };
        }
        return data;
      }),
    }),
  );
};
