/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/system';
import StarIcon from '~/assets/images/icons/star.svg';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { styled } from '@mui/styles';
import { Theme } from '~/themes';
import { Grid, Skeleton } from '@mui/material';
import { ITabValue } from '~/models/shared';
import { Link } from 'react-router-dom';
import { StyledTitle } from '../styles';
import ChartContainer from '../ChartContainer';

export interface IRatingItem {
  feedback: string;
  date: string;
  code: string;
  id: string;
  rating: number;
  searchQuery: string;
}

interface IRating {
  averageRating: number;
  items: IRatingItem[];
  isLoading: boolean;
}

export const StyledRating = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 160,
  height: '100%',
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    width: 'auto',
    padding: theme.spacing(2),
  },
}));
export const StyledReviewItem = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(0.5),
  backgroundColor: theme.bg.lightPurple,
  borderRadius: theme.spacing(1),
  flex: 1,
}));
export const StyledList = styled(Box)(({ theme }: { theme: Theme }) => ({
  overflowX: 'auto',
  maxHeight: 230,
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    maxHeight: 210,
  },
}));
const Rating: React.FC<IRating> = ({ averageRating, items, isLoading }) => {
  const renderItem = (list: IRatingItem[]) =>
    list.map((item) => (
      <StyledReviewItem key={item.id}>
        {item.feedback && <span>{item.feedback}</span>}
        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
          <Link
            to={`/tasks?tab=${ITabValue.Completed}&id=${item.id}&search=${item.searchQuery}`}
            rel='noreferrer'
            style={{ color: '#2D42E8' }}
          >
            {item.code}
          </Link>
          <Box fontSize={14} color='#8D8D8D'>
            {item.date}
          </Box>
          <img
            alt={`star-${item.code}`}
            src={StarIcon}
            style={{ marginLeft: 'auto', marginRight: -5, height: 13 }}
          />
          {item.rating}
        </Box>
      </StyledReviewItem>
    ));
  const renderLoadingItem = (list: number[]) =>
    list.map((item) => (
      <StyledReviewItem key={(Math.random() + 1).toString(36).substring(7)}>
        {Array(item)
          .fill(0)
          .map((_, idx) => (
            <Skeleton
              key={(Math.random() + 1).toString(36).substring(7)}
              width={idx === 0 ? '100%' : '60%'}
              animation={false}
            />
          ))}
        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
          <Skeleton width={86} animation={false} />
          <img
            alt='star'
            src={StarIcon}
            style={{ marginLeft: 'auto', marginRight: -5, height: 13 }}
          />
          0
        </Box>
      </StyledReviewItem>
    ));

  return (
    <ChartContainer
      sxContent={{
        gap: 1,
      }}
    >
      <StyledRating>
        <StyledTitle mb={1}>Rating</StyledTitle>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          flex={1}
          gap={0.8}
        >
          <img src={StarIcon} height={27} alt='avg-star' />
          <Box fontSize={30} fontWeight='bold' color='black'>
            {averageRating}
          </Box>
          <Box fontSize={12} color='#9B9B9B'>
            Average Rating
          </Box>
        </Box>
      </StyledRating>
      <Box display='flex' flexDirection='column' flex={1} height='100%'>
        <StyledTitle mb={1}>Low Reviews</StyledTitle>
        {isLoading ? (
          <StyledList className='customized-scrollbar'>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  {renderLoadingItem([1, 2, 1])}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  {renderLoadingItem([2, 1])}
                </Box>
              </Grid>
            </Grid>
          </StyledList>
        ) : items?.length ? (
          <StyledList className='customized-scrollbar'>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  {renderItem(items.slice(0, 3))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  {renderItem(items.slice(3, 5))}
                </Box>
              </Grid>
            </Grid>
          </StyledList>
        ) : (
          'No data'
        )}
      </Box>
    </ChartContainer>
  );
};

export default Rating;
