import { useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import themes from '~/themes';
import { Zone } from '~/models/stores';
import {
  useGetListHubZone,
  useUpdateHubZonesMutation,
} from '~/services/api/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import Table, { TColumn } from '~/components/common/Table/Table';
import Button from '~/components/common/Button';
import SwitchButton from '~/components/common/SwitchButton';
import Tooltip from '~/components/common/Tooltip';
import { getNumberWithCurrency } from '~/utils/formatter';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import { alertParams, showAlert } from '~/components/common/Alert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DeliveryZoneSets } from '../../types';
import { DialogAddDistanceRadius } from './DialogAddDistanceRadius';

type DistanceRadiusProps = {};

export const DistanceRadius: React.FC<DistanceRadiusProps> = () => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const [openDistanceRadiusDialog, setOpenDistanceRadiusDialog] =
    useState(false);
  const [zoneSelected, setZoneSelected] = useState<Zone>(null);

  const { data: zones, isFetching } = useGetListHubZone({
    action: 'list_all',
    parameters: {
      area_type: DeliveryZoneSets.DistanceRadius,
      integration_id: search.id,
    },
  });
  const { mutate: updateHubZonesMutation } = useUpdateHubZonesMutation({
    onSuccess: () => {},
  });

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'max_delivery_distance',
        title: 'Delivery Radius',
        cellStyle: { textAlign: 'left' },
        renderComponent: (item) => (
          <Typography>
            <SwitchButton
              name='update-postcode'
              checked={item.enable}
              noText
              onChange={(e) => {
                updateHubZonesMutation({
                  action: 'update',
                  parameters: {
                    integration_id: search.id,
                    enable: e.target.checked,
                    _id: item.id,
                  },
                });
              }}
            />
            {`${item.max_delivery_distance} ${
              item.max_delivery_distance_type === 'kilometer' ? 'Km' : 'Mile'
            }`}
          </Typography>
        ),
      },
      {
        id: 'delivery_fee',
        title: 'Delivery Fee',
        renderComponent: (item) =>
          item.delivery_fee_type === 'percentage' ? (
            `${Number(item.delivery_fee || 0).toFixed(2)}%`
          ) : (
            <Tooltip
              title={getNumberWithCurrency(item.delivery_fee || 0).tooltip}
            >
              <span>
                {getNumberWithCurrency(item.delivery_fee || 0).display}
              </span>
            </Tooltip>
          ),
      },
      {
        id: 'delivery_minimum',
        title: 'Order Minimum',
        renderComponent: (item) => (
          <Tooltip
            style={{ maxWidth: 120 }}
            title={getNumberWithCurrency(item.delivery_minimum || 0).tooltip}
          >
            <span>
              {getNumberWithCurrency(item.delivery_minimum || 0).display}
            </span>
          </Tooltip>
        ),
        cellStyle: { textAlign: 'left' },
      },
      {
        id: 'actions',
        title: '',
        cellStyle: { textAlign: 'left' },
        renderComponent: (item) => (
          <ElementSelect
            paperProps={{
              sx: {
                width: 160,
              },
            }}
            elementSelect={() => (
              <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
            )}
            onChange={(o) => {
              if (o.value === 'edit') {
                setOpenDistanceRadiusDialog(true);
                setZoneSelected(item);
              }
              if (o.value === 'delete') {
                showAlert({
                  ...alertParams.warning,
                  description:
                    'Once deleted, this zone cannot be recovered.\nAre you sure you want to continue?',
                  okText: 'Yes, Delete',
                  cancelText: 'Cancel',
                  onOk: () => {
                    updateHubZonesMutation({
                      action: 'delete',
                      parameters: {
                        _id: item.id,
                      },
                    });
                  },
                });
              }
            }}
            options={[
              {
                label: 'Edit',
                value: 'edit',
              },
              {
                label: 'Delete',
                value: 'delete',
              },
            ]}
          />
        ),
      },
    ],
    [search],
  );

  return (
    <Stack spacing={0.5}>
      {zones.length ? (
        <Table
          columns={columns}
          dataProps={zones}
          loading={isFetching}
          borderRadiusHeader={false}
        />
      ) : (
        <Typography py={themes.spacing(1)}>
          Added delivery fee will appear here.
        </Typography>
      )}
      <Stack
        direction='row'
        spacing={1}
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '10px 0',
        }}
      >
        <Button
          buttonType='default'
          noRounder
          onClick={() => setOpenDistanceRadiusDialog(true)}
        >
          Add Delivery Radius
        </Button>
      </Stack>
      {openDistanceRadiusDialog && (
        <DialogAddDistanceRadius
          open={openDistanceRadiusDialog}
          zoneSelected={zoneSelected}
          onClose={() => {
            setOpenDistanceRadiusDialog(false);
            setZoneSelected(null);
          }}
          zones={zones}
        />
      )}
    </Stack>
  );
};
