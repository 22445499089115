import { Box } from '@mui/system';
import React from 'react';
import { Skeleton } from '@mui/material';
import { StyledContainer } from '../styles';

export interface ISummaryItem {
  title: string;
  count: number;
}

interface ISummary {
  items: ISummaryItem[];
  isLoading: boolean;
}

const Summary: React.FC<ISummary> = ({ items, isLoading }) => (
  <StyledContainer
    sx={{
      justifyContent: 'space-around',
      flexDirection: 'row',
    }}
  >
    {items.map((item) =>
      isLoading ? (
        <Skeleton height={47} width={85} />
      ) : (
        <Box
          key={item.title}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Box fontSize={24} fontWeight='bold'>
            {item.count}
          </Box>
          <Box fontSize={12} color='#9B9B9B'>
            {item.title}
          </Box>
        </Box>
      ),
    )}
  </StyledContainer>
);

export default Summary;
