import { Formik, Form } from 'formik';
import { Box, Stack, Typography } from '@mui/material';
import { Field } from '~/components/common/Formik';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useLogout,
  useSetNewPassword,
  useVerifyAdminChangePassword,
} from '~/services/api/auth';
import { fromQueryString } from '~/utils/queryString';
import { firebaseGetToken } from '~/services/firebase';
import { showAlert, alertParams } from '~/components/common/Alert';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import {
  CookieOptions,
  RF_CUSTOM_TOKEN_COOKIES_KEY,
  CUSTOM_TOKEN_COOKIES_KEY,
} from '~/constants/auth';
import { UnifiedPublicLayout } from '~/layout/UnifiedPublicLayout';
import { Button, theme as unifiedTheme } from 'bs-unified-ui';
import { TextLink } from '~/components/common/TextLink';
import { styled } from '~/themes';
import { useEffect, useState } from 'react';
import { UnifiedResetPasswordSchema } from './schema';

type NewPasswordProps = {};
export const StyledForm = styled(Form)(() => ({
  width: '100%',
}));
export const UnifiedNewPassword: React.FC<NewPasswordProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fixedSearch = location.search.replace(/\+/g, '%2B');
  const searchQueries = fromQueryString(fixedSearch, {
    arrayFormat: 'none',
  });

  const [errorMsg, setErrorMsg] = useState<string>('');

  const { mutate: logoutMutation } = useLogout({
    onSuccess: () => {},
  });

  useVerifyAdminChangePassword({
    data: searchQueries,
    onError: () => {
      showAlert({
        ...alertParams.warning,
        title: 'Error',
        description: 'Your change request already done.',
        okText: 'OK',
        onOk: () => navigate('/login'),
        onCancel: () => navigate('/login'),
      });
    },
  });

  const { mutate: setNewPasswordMutation, isLoading: isSetNewPasswordLoading } =
    useSetNewPassword({
      onSuccess: async () => {
        const idToken = await firebaseGetToken();

        const isLogged = !!idToken;
        if (isLogged) {
          const idTkDecoded = jwtDecode<{ email: string }>(idToken);
          if (idTkDecoded?.email === searchQueries.email) {
            Cookies.remove(RF_CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
            Cookies.remove(CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
          } else {
            logoutMutation();
          }
        }
        navigate('/new-password', { replace: true });
      },
      onError: (error) => {
        setErrorMsg(error?.response?.data?.message);
      },
    });

  useEffect(() => {
    let layoutTimer;
    if (!searchQueries?.email && !searchQueries?.code) {
      layoutTimer = setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
    return () => {
      clearTimeout(layoutTimer);
    };
  }, [searchQueries]);

  const renderContent = () => {
    if (searchQueries?.email && searchQueries?.code) {
      return (
        <>
          <Box className='headerContainer'>
            <Typography variant='h1'>New password</Typography>
            <Typography
              color={unifiedTheme.foreground.black}
              sx={{ opacity: 0.4, fontSize: 14, mt: 0.5, lineHeight: '24px' }}
            >
              Password must be more than 8 characters and contains uppercase
              letters, lowercase letters, numbers, and symbols.
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Field.UnifiedTextField
              fullWidth
              name='password'
              label='New Password'
              type='password'
              onChange={() => setErrorMsg('')}
            />
            <Field.UnifiedTextField
              fullWidth
              name='confirmPassword'
              label='Confirm password'
              type='password'
              onChange={() => setErrorMsg('')}
            />
            {errorMsg && (
              <Typography
                sx={{
                  lineHeight: 1.66,
                  color: '#F24822',
                  mt: '19px !important',
                  fontSize: 14,
                }}
              >
                {errorMsg}
              </Typography>
            )}
          </Stack>
          <Button
            fullWidth
            sx={{ mt: 3 }}
            type='submit'
            loading={isSetNewPasswordLoading}
          >
            Continue
          </Button>
        </>
      );
    }

    return (
      <Box className='headerContainer'>
        <Typography variant='h1'>Reset Password Success</Typography>
        <Typography mt={2.4} mb={4.4} fontSize={14} sx={{ opacity: 0.9 }}>
          You will be directed to the login page...
        </Typography>
        <Typography variant='subtitle2'>
          *If your browser does not refresh, &nbsp;
          <TextLink color='#2933C5' to='/login'>
            click here
          </TextLink>
          .
        </Typography>
      </Box>
    );
  };

  return (
    <UnifiedPublicLayout>
      <Formik
        initialValues={{
          emailQuery: searchQueries?.email || '',
          password: '',
          confirmPassword: '',
        }}
        enableReinitialize
        validationSchema={UnifiedResetPasswordSchema}
        onSubmit={({ emailQuery, password }) => {
          setErrorMsg('');
          setNewPasswordMutation({
            email: emailQuery,
            code: searchQueries?.code || '',
            password,
          });
        }}
      >
        {() => <StyledForm>{renderContent()}</StyledForm>}
      </Formik>
    </UnifiedPublicLayout>
  );
};
