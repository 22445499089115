import { Stack, Typography } from '@mui/material';
import themes from '~/themes';
import Button from '~/components/common/Button';
import { useCallback, useMemo } from 'react';
import { FastField, Field } from '~/components/common/Formik';
import { Formik } from 'formik';
import {
  ShopifyStoreInformationSchema,
  StoreAddressSchema,
} from '~/utils/schema/stores';
import { CreateStoreStatus, IntegrationType } from '~/models/stores';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Icon } from '~/components/common/Icon';
import { StyledHeaderTitle, StyledStoreForm } from '../style';
import { StoreAddressForm } from './StoreAddressForm';
import { AddStoreLayout } from '../layout/AddStoreLayout';

type ShopifyStoreProps = {
  step: number;
  setStep: (v: number) => void;
  setPlatform: (p: IntegrationType) => void;
  onClose: () => void;
  totalStep: number;
  createIntegrationMutation: any;
  isLoadingCreateIntegration: boolean;
};

export const ShopifyStore: React.FC<ShopifyStoreProps> = ({
  step,
  totalStep,
  onClose,
  setStep,
  setPlatform,
  createIntegrationMutation,
  isLoadingCreateIntegration,
}) => {
  const handleSubmit = useCallback(
    (_, { setTouched }) => {
      if (step < 4) {
        if (step === 2) {
          setTouched({});
        }
        setStep(step + 1);
      }
    },
    [step],
  );

  const handleCreateIntegration = useCallback((values) => {
    createIntegrationMutation({
      integration_type: IntegrationType.Shopify,
      store_url: values?.store_url,
      status: CreateStoreStatus.Deactivated,
      display_name: values?.display_name,
      address_1: values?.address_1,
      address_2: values?.address_2,
      country: values?.country,
      state: values?.state,
      city: values?.city,
      postcode: values?.postcode,
      phone: parsePhoneNumber(values.phone, values.country_code as CountryCode)
        .number,
    });
  }, []);

  const validationSchema = useMemo(() => {
    if (step === 2) {
      return ShopifyStoreInformationSchema;
    }
    if (step === 3) {
      return StoreAddressSchema;
    }
    return null;
  }, [step]);

  const contentComponent = () => {
    if (step === 2) {
      return (
        <>
          <StyledHeaderTitle>Store Information</StyledHeaderTitle>
          <Typography>Enter your store details.</Typography>
          <Stack spacing={2} width='100%' mt={9} mb={15}>
            <Field.TextInput legend='Store Name' name='display_name' />
            <Field.TextInput legend='Store URL' name='store_url' />
            <Field.TextInput
              legend='Phone Number'
              name='phone'
              type='tel'
              prefix={<FastField.CountryCodeDropdown name='country_code' />}
            />
          </Stack>
        </>
      );
    }
    if (step === 3) {
      return (
        <>
          <StyledHeaderTitle>Store Address</StyledHeaderTitle>
          <Typography>
            Enter the address, where orders will be picked up
          </Typography>
          <StoreAddressForm />
        </>
      );
    }
    if (step === 4) {
      return (
        <>
          <StyledHeaderTitle>Connect with Shopify</StyledHeaderTitle>
          <Typography>
            Make sure you install Breadstack Delivery on Shopify
          </Typography>
          <Stack
            sx={{
              background: themes.bg.lightPurple,
              borderRadius: '50%',
              width: 200,
              height: 200,
              justifyContent: 'center',
              alignItems: 'center',
              mt: 3.5,
            }}
          >
            <Icon name='link' size={80} useBackgroundImg />
          </Stack>
        </>
      );
    }
    return '';
  };

  return (
    <Formik
      initialValues={{
        store_id: '',
        store_url: '',
        integration_type: IntegrationType.Breadstack,
        display_name: '',
        phone: '',
        address_1: '',
        address_2: '',
        country: '',
        city: '',
        state: '',
        postcode: '',
        country_code: 'CA',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <AddStoreLayout
          isEnabledCloseAndUpdateStore={false}
          onClose={onClose}
          step={step}
          totalStep={totalStep}
        >
          <StyledStoreForm>
            {contentComponent()}
            {step === 4 ? (
              <Button
                sx={{ width: 190, mt: 12.5 }}
                loading={isLoadingCreateIntegration}
                onClick={() => handleCreateIntegration(values)}
              >
                Go to Shopify
              </Button>
            ) : (
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  '& > button': {
                    width: 150,
                  },
                }}
              >
                <Button
                  buttonType='default'
                  onClick={() => {
                    if (step === 2) {
                      setPlatform(null);
                    }
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
                <Button type='submit'>Next</Button>
              </Stack>
            )}
          </StyledStoreForm>
        </AddStoreLayout>
      )}
    </Formik>
  );
};
