import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Form, Formik } from 'formik';
import { Theme } from '~/themes';
import { ORGANIZATION_DEFAULT } from '~/constants/adminOptions';
import Button from '~/components/common/Button';
import { alertParams, showAlert } from '~/components/common/Alert';
import { IOrg } from '~/models/org';
import { useUpdateOrgMutation } from '~/services/api/org';
import { OrgSchema } from '~/utils/schema/org';
import { isObjectEqual } from '~/utils/common';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { FastField, Field } from '~/components/common/Formik';
import { ORG_CATEGORY_OPTIONS } from '~/constants/common';
import { useAuth } from '~/providers/AuthProvider';

const style = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      boxShadow: '0px 1px 6px #160E4D1A',
      borderRadius: theme.spacing(2),
      background: theme.color.white,
    },
    form: {
      padding: theme.spacing(5, 5, 5, 5),
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(3, 3, 3, 3),
      },
    },
    saveButton: {
      width: 150,
      marginTop: theme.spacing(3),
    },
    title: {
      color: theme.color.violet900,
      fontSize: 16,
      fontWeight: 500,
      marginBottom: theme.spacing(0.5),
    },
    listDesc: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(3),
    },
  });

interface IProps extends WithStyles<typeof style> {}

const Organization: React.FC<IProps> = ({ classes }) => {
  const { account } = useAuth();

  const { mutate: updateOrg, isLoading: loadingOrgUpdate } =
    useUpdateOrgMutation({
      onSuccess: () => {
        showAlert(alertParams.success);
      },
    });

  const onSubmit = (values: IOrg) => {
    updateOrg({
      name: values.name,
      category: values.category,
      auto_assign: values.auto_assign,
    });
  };

  return (
    <Stack className={classes.container}>
      <Formik
        initialValues={account?.org || ORGANIZATION_DEFAULT}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={OrgSchema}
      >
        {({ values }) => (
          <Form className={classes.form}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.title}>
                  General Information
                </Typography>
                <Field.TextInput name='name' legend='Name' />
              </Grid>
              <Grid item xs={0} md={8} />

              <Grid item xs={12} md={4}>
                <Field.Select
                  name='category'
                  legend='Business Category'
                  options={ORG_CATEGORY_OPTIONS}
                />
              </Grid>
              <Grid item xs={0} md={8} />

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.title}>
                  Auto-Assignment
                </Typography>
                <div className={classes.listDesc}>
                  Choose to let Breadstack Delivery automatically assign
                  incoming delivery tasks to drivers based on team capacity and
                  store locations.
                </div>
                <FastField.SwitchButton name='auto_assign' legend='' />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <Button
              buttonType='primary'
              type='submit'
              loading={loadingOrgUpdate}
              className={classes.saveButton}
              disabled={isObjectEqual(
                values,
                account?.org || ORGANIZATION_DEFAULT,
              )}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default withStyles(style)(Organization);
