export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
  Deactivated = 'deactivated',
}

export interface IUsers {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_photo: any | null;
}

export interface IUsersResponse {
  active: number;
  deactivated: number;
  pending: number;
  results: IUsers[];
}

export interface IUserDetail extends IUsers {
  status?: UserStatus;
  active_date: string | null;
  deactivated_date: string | null;
  pending_date: string | null;
}

export interface IUser {
  id?: string;
  created_at?: string;
  updated_at?: string;
  // org_id: string
  first_name?: string;
  last_name?: string;
  display_name?: string;
  username?: string;
  phone?: string;
  email?: string;
  role?: string;
  status?: string;
  items_per_page?: number;
  avatar?: string;
  avatar_type?: string;
  teams?: any[];
  tooltip?: IUserTooltip;
  timezone?: string;
  profile_photo?: string;
  timezoneType?: 'auto' | 'manual' | '';
}

export interface IUserTooltip {
  task_list?: boolean;
  create_task?: boolean;
  invite_driver?: boolean;
  online_driver?: boolean;
  view_specific_info?: boolean;
  assign_task?: boolean;
  check_ideal_route?: boolean;
  enter_task_details?: boolean;
  assign_task_create?: boolean;
  organization?: boolean;
  stores?: boolean;
  optimize_store?: boolean;
  drivers?: boolean;
  manage_driver?: boolean;
  teams?: boolean;
  users?: boolean;
  set_task_routes?: boolean;
  add_store?: boolean;
}
