export interface IInvoice {
  lines: any;
  id: string;
  created: number;
  invoice_pdf: string;
  number: string;
  status: InvoiceStatus;
  total: number;
}

export enum InvoiceStatus {
  Draft = 'draft', // The starting status for all invoices. You can still edit the invoice at this point
  Open = 'open', //	The invoice has been finalized, and is awaiting customer payment. You can no longer edit the invoice, but you can revise it.
  Paid = 'paid', // This invoice was paid.
  Void = 'void', // This invoice was a mistake, and must be canceled.
  UnCollectible = 'uncollectible', // The customer is unlikely to pay this invoice (treat it as bad debt in your accounting process).
}
