/* eslint-disable no-restricted-syntax */
import { isEmpty } from 'lodash';
import moment from 'moment';
import { object, string, number, boolean, array } from 'yup';
import { TIME_SLOT_TYPE } from '~/constants/stores';
import { Zone } from '~/models/stores';

export const AddZoneTypeSchema = object().shape({
  area_type: string(),
});

export const AddZoneSchema = object().shape({
  name: string().required('Please enter a Zone Name'),
  delivery_fee: number()
    .required('Please enter a Delivery Fee')
    .test('delivery_fee', 'Please enter a Delivery Fee', (value) => value >= 0),
  delivery_minimum: number()
    .required('Please enter a Order Minimum')
    .test(
      'delivery_minimum',
      'Please enter a Order Minimum',
      (value) => value >= 0,
    ),
});

export const AddPostalCodeSchema = object().shape({
  postal_code: string().required('Please enter a Postal Code'),
  delivery_fee: number()
    .required('Please enter a Delivery Fee')
    .test('delivery_fee', 'Please enter a Delivery Fee', (value) => value >= 0),
  delivery_minimum: number()
    .required('Please enter a Order Minimum')
    .test(
      'delivery_minimum',
      'Please enter a Order Minimum',
      (value) => value >= 0,
    ),
});

export const AddDistanceSchema = (zones: Zone[]) =>
  object().shape({
    max_delivery_distance: number()
      .required('Please enter Max Delivery Distance')
      .test(
        'max_delivery_distance',
        'Please enter a Max Delivery Distance',
        (value) => value > 0,
      ),
    delivery_fee: number()
      .default(0)
      .test(
        'delivery_fee',
        'Please enter a Delivery Fee',
        (value) => !value || value >= 0,
      ),
    delivery_minimum: number()
      .default(0)
      .test('delivery_minimum', function (value, ctx) {
        if (value < 0)
          return ctx.createError({ message: 'Please enter a Order Minimum' });
        const hasExistZone = zones?.some(
          (zone) =>
            zone?.max_delivery_distance_type ===
              this.parent.max_delivery_distance_type &&
            zone.max_delivery_distance === this.parent.max_delivery_distance &&
            zone.delivery_minimum === value,
        );
        if (hasExistZone)
          return ctx.createError({
            message:
              'Enter a different value, this delivery minimum already exists under the same radius.',
          });

        return true;
      }),
  });

const TimeSlotSchema = object().shape({
  from_time: string()
    .required('Please select a Start Time value')
    .test(
      'is-before-end',
      'Start time must be before End time',
      function (from_time) {
        const { to_time } = this.parent;
        if (
          (from_time === '12:00' && to_time === '12:00') ||
          moment(to_time, 'hh:mm').isSameOrBefore(moment(from_time, 'hh:mm'))
        ) {
          return false;
        }
        return true;
      },
    ),
  to_time: string().required('Please select an End Time value'),
  lead_time: number(),
});

export const AddSpecialHours = object().shape({
  available: boolean().required(),
  time_slot_generation: string().when('available', {
    is: true,
    then: string().required('Please select Time Slot Option'),
    otherwise: string().notRequired(),
  }),
  name: string().trim().required('Please enter a Name'),
  start_date: string()
    .required('Please select a Start Date value')
    .test(
      'start_date',
      'Start date must be before end date.',
      // eslint-disable-next-line func-names
      function (value) {
        return moment(this.parent.end_date).isSameOrAfter(moment(value));
      },
    ),
  end_date: string()
    .required('Please select an End Date value')
    // eslint-disable-next-line func-names
    .test('end_date', 'End date must be after start date.', function (value) {
      return moment(this.parent.start_date).isSameOrBefore(moment(value));
    }),

  // when time_slot_generation = AUTO
  from_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select a Start Time value'),
    otherwise: string().notRequired(),
  }),
  to_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select an End Time value'),
    otherwise: string().notRequired(),
  }),
  delivery_window: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select an Each Time Slot Duration value'),
    otherwise: string().notRequired(),
  }),
  next_available_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select a Next Available Time value'),
    otherwise: string().notRequired(),
  }),
  lead_time: number(),

  // when time_slot_generation = NONE
  last_order_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.NONE && available === true,
    then: string().required('Please select a Last Order Time value'),
    otherwise: string().notRequired(),
  }),

  // when time_slot_generation = CUSTOM
  time_slot: array().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.CUSTOM && available === true,
    then: array()
      .of(TimeSlotSchema)
      .min(1, 'Please add at least one Time Slot')
      .test(
        'unique-time-slot',
        'Time slots must be unique',
        function (time_slots) {
          if (!time_slots) return true;

          // Map to track duplicates
          const seen = new Map();
          const errors = {};

          // Iterate through each time slot and check for duplicates
          time_slots.forEach((slot, index) => {
            const key = `${slot.from_time}-${slot.to_time}`;
            if (seen.has(key)) {
              // Mark the current and original duplicate entries with an error
              errors[index] = {
                from_time: 'This time slot is duplicated',
                to_time: '  ',
              };
            } else {
              seen.set(key, index);
            }
          });

          // If errors exist, return them in the expected format
          if (!isEmpty(errors)) {
            return this.createError({
              path: 'time_slot',
              message: errors,
            });
          }
          return true;
        },
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const AddHours = object().shape({
  available: boolean().required(),
  time_slot_generation: string().when('available', {
    is: true,
    then: string().required('Please select Time Slot Option'),
    otherwise: string().notRequired(),
  }),

  // when time_slot_generation = AUTO
  from_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select a Start Time value'),
    otherwise: string().notRequired(),
  }),
  to_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select an End Time value'),
    otherwise: string().notRequired(),
  }),
  delivery_window: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select an Each Time Slot Duration value'),
    otherwise: string().notRequired(),
  }),
  next_available_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.AUTO && available === true,
    then: string().required('Please select a Next Available Time value'),
    otherwise: string().notRequired(),
  }),
  lead_time: number(),

  // when time_slot_generation = NONE
  last_order_time: string().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.NONE && available === true,
    then: string().required('Please select a Last Order Time value'),
    otherwise: string().notRequired(),
  }),

  // when time_slot_generation = CUSTOM
  time_slot: array().when(['time_slot_generation', 'available'], {
    is: (time_slot_generation, available) =>
      time_slot_generation === TIME_SLOT_TYPE.CUSTOM && available === true,
    then: array()
      .of(TimeSlotSchema)
      .min(1, 'Please add at least one Time Slot')
      .test(
        'unique-time-slot',
        'Time slots must be unique',
        function (time_slots) {
          if (!time_slots) return true;

          // Map to track duplicates
          const seen = new Map();
          const errors = {};

          // Iterate through each time slot and check for duplicates
          time_slots.forEach((slot, index) => {
            const key = `${slot.from_time}-${slot.to_time}`;
            if (seen.has(key)) {
              // Mark the current and original duplicate entries with an error
              errors[index] = {
                from_time: 'This time slot is duplicated',
                to_time: '  ',
              };
            } else {
              seen.set(key, index);
            }
          });

          // If errors exist, return them in the expected format
          if (!isEmpty(errors)) {
            return this.createError({
              path: 'time_slot',
              message: errors,
            });
          }
          return true;
        },
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const AddHoursDisableDelivery = object().shape({
  available: boolean().required(),
  last_order_time: string().when('available', {
    is: true,
    then: string().required('Please select a Last Order Time value'),
    otherwise: string().notRequired(),
  }),
});

export const AddSpecialHoursDisableDelivery = object().shape({
  available: boolean().required(),
  name: string().trim().required('Please enter a Name'),
  start_date: string()
    .required('Please select a Start Date value')
    .test(
      'start_date',
      'Start date must be before end date.',
      // eslint-disable-next-line func-names
      function (value) {
        return moment(this.parent.end_date).isSameOrAfter(moment(value));
      },
    ),
  end_date: string()
    .required('Please select an End Date value')
    // eslint-disable-next-line func-names
    .test('end_date', 'End date must be after start date.', function (value) {
      return moment(this.parent.start_date).isSameOrBefore(moment(value));
    }),
  last_order_time: string().when('available', {
    is: true,
    then: string().required('Please select a Last Order Time value'),
    otherwise: string().notRequired(),
  }),
});
