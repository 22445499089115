import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { showAlert } from '~/components/common/Alert';
import CheckBox from '~/components/common/CheckBox';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { Icon } from '~/components/common/Icon';
import Modal from '~/components/common/Modal';
import { SearchInput } from '~/components/common/Search';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import { queryClient } from '~/lib/react-query';
import { IPlan } from '~/models/plan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { useUpdateSubscriptions } from '~/services/api/plan';
import { queryKeys } from '~/services/api/queryKeys';
import themes from '~/themes';
import { useGetDriverListQuery } from '../../Drivers/apis';
import { UserRole, UserStatus } from '../../Users/types';

type DialogDowngradePlanProps = {
  open: boolean;
  onClose: () => void;
  downgradePriceId?: string;
  currentPlan?: IPlan;
};

export const DialogDowngradePlan: React.FC<DialogDowngradePlanProps> = ({
  open,
  onClose,
  downgradePriceId,
}) => {
  const [isAccepted, setIsAccepted] = useState(true);
  const [driverSelect, setDriverSelect] = useState('');
  const [searchDriverQuery, setSearchDriverQuery] = useState('');
  const [isShowDriverSelect, setIsShowDriverSelect] = useState(false);
  const { org } = usePaymentInfo();
  const { data: drivers = [] } = useGetDriverListQuery(
    {
      roles: UserRole.Driver,
      status: UserStatus.Active,
    },
    { enabled: true },
  );

  const {
    mutate: updateSubscriptionsMutation,
    isLoading: isLoadingUpdateSubscriptions,
  } = useUpdateSubscriptions({
    onSuccess: (resp) => {
      onClose();
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
      showAlert({
        icon: 'success',
        title: 'Updated Successfully',
        description: `Your new plan will take effect on ${moment
          .unix(resp?.current_period_end)
          .format('MM/DD/YYYY')}`,
        okText: 'Ok',
      });
    },
  });

  const warningCheckList = useMemo(() => {
    // free tier
    if (!downgradePriceId) {
      return [
        'You will be restricted to 1 user (owner) and 1 driver',
        'Your task limit will decrease to 50 tasks/mo',
        'You will lose access to the following features:',
        'Additional tasks',
        'Task scheduling',
        'Realtime ETA notifications',
        'Geo-fencing and delivery zone management',
        'Advanced driver management tools',
      ];
    }
    return [
      'Your task limit will decrease to 2000 tasks/mo',
      'You will lose access to advanced driver management tools',
    ];
  }, []);

  const renderWarningCheckList = () => (
    <>
      <Typography
        variant='h3'
        mt={2}
        whiteSpace='break-spaces'
        lineHeight='22px'
      >
        {'Are you sure you want to change your plan? \nIf you continue:'}
      </Typography>

      <ul
        style={{
          paddingLeft: 17,
          marginTop: 30,
        }}
      >
        {warningCheckList.map((str, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li
            key={str}
            style={{ marginTop: 10, ...(idx >= 3 && { marginLeft: 30 }) }}
          >
            {str}
          </li>
        ))}
      </ul>
      <p style={{ marginTop: 30 }}>
        Your new plan will take effect after the current billing cycle.
      </p>
      <CheckBox
        sx={{ ml: 0.5 }}
        checked={isAccepted}
        label='I acknowledge the above.'
        onChange={(e) => setIsAccepted(e.target.checked)}
      />
    </>
  );

  const renderKeepDriver = () => (
    <>
      <Typography variant='h3' my={2}>
        Which driver would you like to keep?
      </Typography>
      <SearchInput
        value={searchDriverQuery}
        placeholder='Search'
        onChange={(e) => setSearchDriverQuery(e.target.value)}
        onClear={() => setSearchDriverQuery('')}
      />

      <GroupRadioButton
        fullWidth
        sx={{
          gap: '0px !important',
          mt: 2,
        }}
        value={driverSelect}
        onChange={(e) => setDriverSelect(e.target.value)}
        radioOptionSxProps={{
          'alignItems': 'center !important',
          '&.radio-option-selected': {
            background: themes.bg.midPurple,
          },
          'borderBottom': '1px solid #E9E7F6',
        }}
        options={drivers
          .filter((e) =>
            e.display_name
              .toLowerCase()
              .includes(searchDriverQuery?.toLowerCase()),
          )
          .map((driver) => ({
            value: driver.id,
            label: (
              <Stack direction='row' spacing={1} alignItems='center'>
                <DriverAvatar
                  status={driver.driver_status}
                  avatar={driver.avatar}
                  name={driver.display_name}
                />
                <span>{driver.display_name}</span>
              </Stack>
            ),
          }))}
      />
    </>
  );

  return (
    <Modal
      maxWidth={false}
      open={open}
      onClose={onClose}
      disableCloseOutside
      PaperProps={{
        sx: {
          'maxWidth': 480,
          'pt': 3,
          '& .MuiDialogContent-root': {
            px: 3,
          },
        },
      }}
      actions={[
        {
          title: 'Cancel',
          onClick: onClose,
          buttonType: 'default',
        },
        {
          title: 'Continue',
          buttonType: 'primary',
          disabled: !isAccepted,
          loading: isLoadingUpdateSubscriptions,
          onClick: () => {
            if (!downgradePriceId) {
              if (isShowDriverSelect) {
                updateSubscriptionsMutation({
                  subId: org.subscription_id,
                  price_id: downgradePriceId,
                  driver_id: driverSelect,
                });
              } else {
                setIsShowDriverSelect(true);
              }
              setIsShowDriverSelect(true);
            } else {
              updateSubscriptionsMutation({
                subId: org.subscription_id,
                price_id: downgradePriceId,
              });
            }
          },
        },
      ]}
    >
      <Icon name='exclamation' useBackgroundImg size={40} />
      {isShowDriverSelect ? renderKeepDriver() : renderWarningCheckList()}
    </Modal>
  );
};
