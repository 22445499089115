/* eslint-disable object-shorthand */
import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import Button from '~/components/common/Button';
import themes from '~/themes';
import { Form, Formik } from 'formik';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { IStore, StorePlatform } from '~/models/stores';
import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { isNullOrUndefined } from '~/utils/common';
import { alertParams, showAlert } from '~/components/common/Alert';
import { Select } from '~/components/common/Select';
import { AddZoneTypeSchema } from '~/utils/schema/integration';
import { DrawnZones } from './components/DrawnZone/DrawnZones';
import { DeliveryZoneSets, IDefaultCenter } from './types';
import { PostalCode } from './components/PostalCode/PostalCode';
import { DistanceRadius } from './components/DistanceRadius/DistanceRadius';

type ZoneAndFreeProps = { data: IStore };

export const ZoneAndFree: React.FC<ZoneAndFreeProps> = ({ data }) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;

  const { mutate: updateConnectionMutation, isLoading: isUpdateLoading } =
    useUpdateConnectionMutation();

  const defaultCenter: IDefaultCenter =
    !isNullOrUndefined(data.hubs?.[0]?.lng) &&
    !isNullOrUndefined(data.hubs?.[0]?.lng)
      ? {
          lat: data.hubs[0].lat,
          lng: data.hubs[0].lng,
        }
      : undefined;

  const renderDeliverySetting = useCallback(
    (type: DeliveryZoneSets, defaultCenterItem?: IDefaultCenter) => {
      switch (type) {
        case DeliveryZoneSets.DrawnZones:
          return <DrawnZones defaultCenter={defaultCenterItem} />;
        case DeliveryZoneSets.PostalCode:
          return <PostalCode />;
        case DeliveryZoneSets.DistanceRadius:
          return <DistanceRadius />;
        default:
          return <div />;
      }
    },
    [],
  );

  const onSubmit = (values) => {
    const { area_type } = values;
    updateConnectionMutation({
      id: data.id,
      area_type: area_type,
    });
  };

  if (platform === StorePlatform.Breadstack) {
    return (
      <Stack spacing={2}>
        <Typography
          sx={{
            borderRadius: '5px',
            background: themes.bg.lightPurple,
            padding: 1,
          }}
        >
          You can create delivery zones and add a different delivery fee for
          each zone. Delivery Zone can be defined by drawn zone or postal codes
          or distance radius.
        </Typography>

        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(data.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Formik
        initialValues={{
          area_type: data.area_type || DeliveryZoneSets.DrawnZones,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={AddZoneTypeSchema}
      >
        {({ isValid, dirty, values, setFieldValue, resetForm }) => (
          <Form
            id='update-distance'
            onKeyPress={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <Typography
              sx={{
                borderRadius: '5px',
                background: themes.bg.lightPurple,
                padding: 1,
              }}
            >
              Create delivery zones with their own delivery fees. To create a
              zone, you can either draw it on a map, add postal codes to
              include, or set a distance radius from your linked hub.
            </Typography>

            <Stack mt={2} mb={1} gap={2}>
              <Typography
                variant='h5'
                color={themes.color.black}
                fontWeight='bold'
              >
                Set delivery area by
              </Typography>
              <Select
                name='area_type'
                id='area_type'
                options={[
                  {
                    value: DeliveryZoneSets.DrawnZones,
                    label: 'Draw Zones',
                  },
                  { value: DeliveryZoneSets.PostalCode, label: 'Postal Code' },
                  {
                    value: DeliveryZoneSets.DistanceRadius,
                    label: 'Distance Radius',
                  },
                ]}
                fullWidth={false}
                value={values?.area_type}
                onChange={(e: any) => {
                  showAlert({
                    ...alertParams.warnDark,
                    title: 'Are You Sure To Change The Delivery Area Type?',
                    description:
                      'Are you sure to change the delivery area type?',
                    onOk: () => {
                      resetForm();
                      setFieldValue('area_type', e.target.value);
                    },
                    cancelText: 'Cancel',
                    sx: { '& .MuiPaper-root': { maxWidth: 498 } },
                    sxDescription: { color: '#160E4D' },
                    sxButton: {
                      borderRadius: '20px !important',
                      width: '125px',
                    },
                  });
                }}
                paperMaxHeight={400}
                sx={{ width: 260 }}
              />
            </Stack>
            {renderDeliverySetting(values.area_type, defaultCenter)}
            <Button
              className='save-change-btn'
              id='update-distance'
              type='submit'
              loading={isUpdateLoading}
              fullWidth
              disabled={!isValid || !dirty}
            >
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
