import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Form, Formik } from 'formik';
import Button from '~/components/common/Button';
import { FastField } from '~/components/common/Formik';
import SwitchButton from '~/components/common/SwitchButton';
import { IStore } from '~/models/stores';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import themes from '~/themes';

type TaskSettingsProps = {
  data: IStore;
};

export const TaskSettings: React.FC<TaskSettingsProps> = ({ data }) => {
  const { mutate: updateStoreMutation, isLoading: isUpdateLoading } =
    useUpdateConnectionMutation();

  const handleSubmit = (values) => {
    if (values.skip_pickup) {
      delete values.store_require_pickup_proofs;
    }
    updateStoreMutation({
      id: data.id,
      ...values,
    });
  };
  return (
    <Formik
      initialValues={{
        skip_pickup: !!data?.skip_pickup,
        store_require_pickup_proofs: !!data?.store_require_pickup_proofs,
        store_require_delivery_capture: !!data?.store_require_delivery_capture,
        store_require_delivery_signature:
          !!data?.store_require_delivery_signature,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, isValid, dirty, setFieldValue }) => (
        <Form>
          <Stack spacing={2}>
            <Typography
              sx={{
                background: themes.bg.gray300,
                borderRadius: '5px',
                p: 1,
              }}
            >
              These settings will automatically apply to all your new tasks.
              However, you can adjust them for each task individually.
            </Typography>
            <Typography fontWeight='bold'>Pickup Settings</Typography>

            <Stack
              sx={{ background: themes.bg.gray300, borderRadius: '5px', p: 1 }}
            >
              <Typography fontWeight='bold'>Include Pickup Task</Typography>
              <Typography my={1}>
                When a delivery order is placed, always create a pickup task.
                Drivers will receive the pickup task on the Driver app, which
                they must complete before starting the corresponding dropoff
                task.
              </Typography>
              <SwitchButton
                name='skip_pickup'
                noText
                checked={!values.skip_pickup}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('skip_pickup', !e.target.checked)
                }
              />
            </Stack>
            {!values.skip_pickup && (
              <Stack spacing={1}>
                <FastField.CheckBox
                  name='store_require_pickup_proofs'
                  label='Require drivers to scan barcode or capture an image of the package upon pickup.'
                />

                <Divider />
              </Stack>
            )}

            <Stack spacing={1}>
              <Typography fontWeight='bold'>Dropoff Settings</Typography>
              <FastField.CheckBox
                name='store_require_delivery_capture'
                label='Require drivers to capture an image of the package upon dropoff.'
              />
              <FastField.CheckBox
                name='store_require_delivery_signature'
                label='Require drivers to collect the customer’s signature upon dropoff.'
              />
            </Stack>
          </Stack>
          <Button
            className='save-change-btn'
            type='submit'
            loading={isUpdateLoading}
            fullWidth
            disabled={!isValid || !dirty}
          >
            Save Changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};
