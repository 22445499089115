import {
  DriverMarkerContent,
  DropOffMarkerContent,
  StoreMarkerContent,
} from '../components/Markers/MarkerContents';
import { Driver, MarkerType } from '../types';

export class TaskRoutes {
  private map: google.maps.Map | null;

  private directionsService = new google.maps.DirectionsService();

  private directionsRenderer: google.maps.DirectionsRenderer =
    new google.maps.DirectionsRenderer();

  private dropOffMarker: google.maps.marker.AdvancedMarkerElement;

  private storeMarker: google.maps.marker.AdvancedMarkerElement;

  private driverMarker: google.maps.InfoWindow;

  constructor(map: google.maps.Map | null) {
    this.map = map;
  }

  addStoreMarker(position: google.maps.LatLngLiteral) {
    if (position) {
      this.storeMarker = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: StoreMarkerContent({}),
      });
      this.storeMarker.map = this.map;
    }
  }

  addDropOffMarker(position: google.maps.LatLngLiteral) {
    if (position) {
      this.dropOffMarker = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: DropOffMarkerContent({}),
      });
      this.dropOffMarker.map = this.map;
    }
  }

  addDriverMarker(driver: Driver) {
    const content = DriverMarkerContent({
      avatar: driver.avatar,
      driverStatus: driver.driver_status,
      name: driver.display_name,
    });
    const position = { lat: driver.lat, lng: driver.lng };

    if (this.driverMarker) {
      this.driverMarker.setOptions({
        disableAutoPan: true,
        position,
        content,
      });
    } else {
      this.driverMarker = new google.maps.InfoWindow({
        position,
        content,
      });
    }
    this.driverMarker.open(this.map);
  }

  drawRoute(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral) {
    const { directionsRenderer } = this;
    directionsRenderer.setOptions({
      map: this.map,
      suppressMarkers: true,
      markerOptions: {
        visible: false,
      },
      preserveViewport: true,
      polylineOptions: {
        visible: true,
        strokeWeight: 5,
        strokeColor: '#0897FF',
      },
    });

    const request: google.maps.DirectionsRequest = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: [],
    };

    this.directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      } else {
        this.directionsRenderer.setMap(null);
      }
    });
  }

  clearRoute() {
    if (this.directionsRenderer) {
      this.directionsRenderer.setOptions({
        polylineOptions: {
          visible: false,
        },
      });
      this.directionsRenderer.setMap(null);
    }
  }

  hideMarkers(
    markerTypes: MarkerType[] = [
      MarkerType.DropOff,
      MarkerType.Store,
      MarkerType.Driver,
    ],
  ) {
    const markers = {
      [MarkerType.DropOff]: this.dropOffMarker,
      [MarkerType.Store]: this.storeMarker,
      [MarkerType.Driver]: this.driverMarker,
    };

    markerTypes.forEach((type) => {
      const marker = markers[type];
      if (marker) {
        if (type === MarkerType.Driver) {
          marker.close();
        } else {
          marker.map = null;
        }
      }
    });
  }

  showMarkers(
    markerTypes: MarkerType[] = [
      MarkerType.DropOff,
      MarkerType.Store,
      MarkerType.Driver,
    ],
  ) {
    const markers = {
      [MarkerType.DropOff]: this.dropOffMarker,
      [MarkerType.Store]: this.storeMarker,
      [MarkerType.Driver]: this.driverMarker,
    };

    markerTypes.forEach((type) => {
      const marker = markers[type];
      if (marker) {
        if (type === MarkerType.Driver) {
          marker.open(this.map);
        } else {
          marker.map = this.map;
        }
      }
    });
  }
}
