import { Dialog, IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useRef, useState, useCallback, useMemo } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';
import { canvasPreview } from '~/utils/imageCrop/canvasPreview';
import { Theme } from '~/themes';
import CropIcon from '~/assets/images/icons/crop.svg';
import Revert from '~/assets/images/icons/revert.svg';
import Upload from '~/assets/images/icons/upload.svg';
import Remove from '~/assets/images/icons/delete.svg';
import { showAlert } from '~/components/common/Alert';
import { rotateImage } from '~/utils/imageCrop/rotateImage';
import { errorAlert } from '../Alert/alertParams';
// eslint-disable-next-line import/no-cycle
import useImageUploadContext from './useImageUploadContext';
import Button from '../Button';

const scale = 1;

const style = (theme: Theme) =>
  createStyles({
    paper: {
      boxSizing: 'border-box',
      width: 600,
      maxWidth: '100%',
      background: theme.bg.image,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
      gap: theme.spacing(3),
    },
    header: {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      '& button:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      color: 'white',
    },
    closeButton: {
      width: 20,
      height: 20,
      background: 'white',
      color: 'black',
      fontSize: 14,
      padding: 0,
    },
    buttonContainer: {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'space-between',
      'gap': theme.spacing(1),
      '&>div:first-child': {
        width: 125,
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      '& button:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    buttonGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    button: {
      width: 40,
      height: 40,
      background: theme.bg.gray900,
      color: 'white',
      fontSize: 25,
    },
    disableButton: {
      border: `1px solid ${theme.bg.gray300}`,
    },
    saveButton: {
      background: theme.bg.gray900,
      color: 'white',
      height: 40,
      borderRadius: 20,
      width: 125,
    },
    uploadButton: {
      'cursor': 'pointer',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'borderRadius': 40,
      'boxSizing': 'border-box',
      '&:hover': {
        background: theme.bg.primaryOrange,
      },
    },
    fileInput: {
      display: 'none',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: 540,
      background: 'black',
    },
    image: {
      maxHeight: '540px !important',
      maxWidth: '540px !important',
      width: 'auto',
      height: 'auto',
      objectFit: 'contain',
    },
    popup: {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'background': 'rgba(0, 0, 0, .8)',
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'justifyContent': 'center',
      'color': 'white',
      '& > div': {
        '&:first-child': {
          fontSize: 18,
          fontWeight: 600,
          marginBottom: theme.spacing(1),
        },
        '&:last-child': {
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(2),
          justifyContent: 'center',
          marginTop: theme.spacing(3),
        },
      },
    },
    customUploadButton: {
      background: '#FA8C73',
      borderRadius: 19,
      maxHeight: 35,
      padding: '9px 36px',
      marginTop: theme.spacing(3),
      color: 'white',
      fontWeight: 600,
      cursor: 'pointer',
    },
  });

interface IProps extends WithStyles<typeof style> {
  title?: string;
}

const ImageUpload: React.FC<IProps> = ({
  classes,
  title = 'Profile Image',
}) => {
  const {
    open,
    setOpen,
    onSave,
    src,
    imgSrc,
    setImgSrc,
    disableSquare,
    optional,
    readonly,
  } = useImageUploadContext();

  const disableEdit = useMemo(
    () => imgSrc.includes('firebasestorage.googleapis.com'),
    [imgSrc],
  );

  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<PixelCrop | undefined>();
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [rotate, setRotate] = useState(0);

  const onCloseCrop = () => {
    setIsCrop(false);
    setCrop(undefined);
    setRotate(0);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onCloseCrop();
      if (e.target.files[0].size > 5 * 1024 * 1024) {
        showAlert(errorAlert('File size must be less than 5 MB.'));
      } else {
        setCrop(undefined);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setImgSrc(reader?.result?.toString() || '');
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!disableEdit) {
      imgRef.current = e.currentTarget;
      imgRef.current.setAttribute('crossorigin', 'anonymous');
      const { width, height } = imgRef.current;
      const newCrop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width,
          },
          disableSquare ? width / height : 1,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop({
        ...newCrop,
        unit: 'px',
      });
    }
  };

  const onCrop = () => {
    setIsCrop(true);
  };

  const handleSaveData = (formData: FormData | null, blob?: Blob) => {
    onCloseCrop();
    setOpen(false);
    onSave(formData, blob);
  };

  const handleSave = async () => {
    if (isCrop && crop?.width && crop?.height && imgRef.current) {
      // Update Crop Image
      // We use canvasPreview as it's much faster than imgPreview.
      const url = canvasPreview(imgRef.current, crop, scale, 0);
      const blob = await (await fetch(url)).blob();
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob, `avatar.${blob.type.split('/')[1]}`);
        handleSaveData(formData, blob);
      } else {
        showAlert(errorAlert('Invalid file'));
      }
    } else if (imgSrc) {
      // For rotate
      fetch(imgSrc)
        .then((res) => res.blob())
        .then(async (res) => {
          const formData = new FormData();
          formData.append('file', res, `avatar.${res.type.split('/')[1]}`);
          onCloseCrop();
          handleSaveData(formData, res);
        });
    } else {
      handleSaveData(null);
    }
  };

  const onRotate = async (newValue?: number) => {
    const newRotate = ((newValue || rotate) - 90) % 360;
    setRotate(newRotate);
    if (imgRef.current) {
      const type = imgSrc?.substring(
        'data:'.length,
        imgSrc?.indexOf(';base64'),
      );
      const data = await rotateImage(imgRef.current, type);
      setImgSrc(data);
    }
  };

  const onClose = useCallback(() => {
    setOpen(false);
    onCloseCrop();
    setImgSrc(src || '');
  }, [src]);

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
        >
          <Close fontSize='inherit' />
        </IconButton>
      </div>
      <div className={classes.content}>
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={isCrop ? crop : undefined}
            onChange={(pixelCrop) => setCrop(pixelCrop)}
            disabled={!isCrop}
            style={{ background: 'white' }}
          >
            <img
              className={classes.image}
              alt='img'
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {deletePopup && (
          <div className={classes.popup}>
            <div>Delete this image?</div>
            <div>
              You won’t be able to recover it in the future, unless you upload
              it again.
            </div>
            <div>
              <Button
                buttonType='default'
                onClick={() => setDeletePopup(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setImgSrc('');
                  onCloseCrop();
                  setDeletePopup(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        {!imgSrc && (
          <div className={classes.popup}>
            <div>Upload New Image</div>
            {!optional && (
              <div>
                You can not leave this empty. Please upload a new image.
              </div>
            )}
            <label htmlFor='file-upload'>
              <div className={classes.customUploadButton}>Upload</div>
              <input
                id='file-upload'
                className={classes.fileInput}
                type='file'
                accept='image/*'
                onChange={onSelectFile}
              />
            </label>
          </div>
        )}
      </div>
      {!readonly && (
        <div className={classes.buttonContainer}>
          <div />
          <div className={classes.buttonGroup}>
            <label htmlFor='file-upload'>
              <div className={clsx(classes.button, classes.uploadButton)}>
                <img alt='upload' src={Upload} />
              </div>
              <input
                id='file-upload'
                className={classes.fileInput}
                type='file'
                accept='image/*'
                onChange={onSelectFile}
              />
            </label>
            <IconButton
              className={classes.button}
              onClick={() => Boolean(imgSrc) && setDeletePopup(true)}
              size='large'
            >
              <img alt='remove' src={Remove} />
            </IconButton>
            <IconButton
              className={clsx(classes.button, {
                [classes.disableButton]: disableEdit,
              })}
              onClick={() => Boolean(imgSrc) && onCrop()}
              size='large'
              disabled={disableEdit}
            >
              <img alt='crop' src={CropIcon} />
            </IconButton>
            <IconButton
              className={clsx(classes.button, {
                [classes.disableButton]: disableEdit,
              })}
              onClick={() => onRotate()}
              size='large'
              disabled={disableEdit}
            >
              <img alt='rotate' src={Revert} />
            </IconButton>
          </div>
          <Button
            className={classes.saveButton}
            onClick={() => handleSave()}
            disabled={
              (!optional && !imgSrc) ||
              (imgSrc === src && rotate === 0 && !isCrop)
            }
          >
            Save
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default withStyles(style)(ImageUpload);
