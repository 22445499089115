import { Typography } from '@mui/material';
import { styled } from '~/themes';

export const StyledAuthTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 'bold',
  color: theme.color.violet900,
  marginBottom: `${theme.spacing(1)} !important`,
  textAlign: 'center',
}));
