import { useRoutes } from 'react-router-dom';
import { useAuth } from '~/providers/AuthProvider';
import PageLoading from '~/components/common/PageLoading';
import { useUnifiedAuthContext } from '~/providers/UnifiedAuthProvider';
import {
  RF_CUSTOM_TOKEN_COOKIES_KEY,
  CUSTOM_TOKEN_COOKIES_KEY,
} from '~/constants/auth';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useEffect, useMemo } from 'react';
import { clearItem, LOCAL_STORAGE_KEYS } from '~/utils/localStorage';
import { authRoutes } from './auth';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const { accounts, isFetchingAccounts } = useAuth();
  const { isVerifyingSSO } = useUnifiedAuthContext();
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );

  useEffect(() => {
    const handler = () => {
      clearItem(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);
    };
    // eslint-disable-next-line no-restricted-globals
    addEventListener('storage', handler);

    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('storage', handler);
    };
  }, []);

  const routes = useMemo(() => {
    if (
      accounts.length &&
      (isNewSSO ||
        (!isNewSSO &&
          Cookies.get(RF_CUSTOM_TOKEN_COOKIES_KEY) &&
          Cookies.get(CUSTOM_TOKEN_COOKIES_KEY)))
    )
      return protectedRoutes();
    return authRoutes;
  }, [isNewSSO, accounts]);

  const element = useRoutes([...publicRoutes, ...routes]);
  const isPageLoading = isFetchingAccounts || isVerifyingSSO;
  if (isPageLoading) return <PageLoading loading />;
  return element;
};
