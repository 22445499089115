import { Stack } from '@mui/material';
import themes from '~/themes';
import { CloseButton } from '~/components/common/Button';
import { Stepper } from '../Stepper';

type AddStoreLayoutProps = {
  step: number;
  totalStep: number;
  isEnabledCloseAndUpdateStore?: boolean;
  onClose: () => void;
  onCloseAndUpdateStore?: () => void;
};

export const AddStoreLayout: React.FC<AddStoreLayoutProps> = ({
  step,
  totalStep,
  isEnabledCloseAndUpdateStore = false,
  onClose,
  onCloseAndUpdateStore = () => {},
  children,
}) => (
  <Stack
    sx={{
      p: themes.spacing(4, 3),
      position: 'relative',
      alignItems: 'center',
    }}
  >
    <Stepper activeStep={step} totalStep={totalStep} />
    <CloseButton
      sx={{ position: 'absolute', right: 30, top: 30 }}
      onClick={isEnabledCloseAndUpdateStore ? onCloseAndUpdateStore : onClose}
    />
    <Stack alignItems='center' width='100%' maxWidth={450}>
      {children}
    </Stack>
  </Stack>
);
