import React from 'react';
import RadioButton from '~/components/common/RadioButton';
import { IFormProps } from '~/models/common';
import { getIn } from 'formik';
import { IRadioButton } from '../RadioButton/RadioButton';

export interface IFieldRadioButton
  extends IFormProps,
    Omit<IRadioButton, 'name' | 'classes'> {}

const FormRadioButton: React.FC<IFieldRadioButton> = ({
  field,
  form,
  onChange,
  ...props
}) => (
  <RadioButton
    {...field}
    error={
      !!getIn(form.touched, field.name) && !!getIn(form.errors, field.name)
    }
    helperText={
      Boolean(getIn(form.touched, field.name)) && getIn(form.errors, field.name)
    }
    onChange={(_, newValue) => {
      form.setFieldValue(field.name, newValue);
      if (onChange) {
        onChange(_, newValue);
      }
    }}
    {...props}
  />
);

export default FormRadioButton;
