import { object, string } from 'yup';

// Driver Verification

const OrgSchema = object().shape({
  name: string()
    .required('This field is required')
    .trim()
    .max(50, 'Max 50 characters'),
});

export { OrgSchema };
