import React from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@mui/material';
import { createStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      zIndex: theme.zIndex.drawer + 1,
      background: 'rgba(255, 255, 255, .5)',
    },
    loading: {
      width: '100%',
    },
  });

export interface IProps extends WithStyles<typeof style> {
  className?: string;
  loading?: boolean;
  color?: any;
}

const PageLoading: React.FC<IProps> = ({
  className,
  classes,
  loading = false,
  color,
}) =>
  loading ? (
    <div className={clsx(classes.container, className)}>
      <LinearProgress color={color} className={classes.loading} />
    </div>
  ) : null;

export default withStyles(style)(PageLoading);
