import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useFormikContext } from 'formik';
import { Theme } from '~/themes';
import { Field, FastField } from '~/components/common/Formik';
import { CountryListItem } from '~/models/common';
import { IDriver } from '~/models/driver';
import { useAuth } from '~/providers/AuthProvider';

const style = (theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
      marginTop: theme.spacing(0.5),
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
    phone: {
      '& .prefix': {
        marginLeft: -14,
      },
      '& .counter': {
        display: 'none',
      },
    },
  });

interface IProps extends WithStyles<typeof style> {}

const Vehicles: React.FC<IProps> = () => {
  const { values, setFieldValue } = useFormikContext<IDriver>();
  const { countries, isFetchingCountry } = useAuth();

  const getStatesByCountry = useCallback(
    (currentCountry) =>
      (countries || []).find(
        ({ value }: CountryListItem) => value === currentCountry,
      )?.states || [],
    [countries],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Make / Brand' name='vehicle.make_model' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Color' name='vehicle.color' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FastField.TextInput legend='Plate No.' name='vehicle.licence_plate' />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field.Select
          options={countries || []}
          legend='Plate - Country of Issuance'
          name='vehicle.insurance_country'
          disabled={isFetchingCountry}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('vehicle.insurance_country', event.target.value);
            setFieldValue('vehicle.insurance_state', '');
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field.Select
          legend='Plate - Prov. / State of Issuance'
          name='vehicle.insurance_state'
          options={getStatesByCountry(values?.vehicle?.insurance_country)}
          disabled={isFetchingCountry}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Vehicles);
