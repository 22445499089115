import { Stack, Typography } from '@mui/material';
import { Icon } from '~/components/common/Icon';
import { useNavigate } from 'react-router-dom';
import Modal from '~/components/common/Modal';
import Button from '~/components/common/Button';
import { useAuth } from '~/providers/AuthProvider';
import { USER_ROLE } from '~/constants/userManagement';
import themes from '~/themes';

type DialogFailPaymentProps = {
  open?: boolean;
  onClose?: () => void;
};

export const DialogFailPayment: React.FC<DialogFailPaymentProps> = ({
  open,
  onClose,
}) => {
  const { account } = useAuth();
  const navigate = useNavigate();
  return (
    <Modal
      disableCloseOutside
      open={open}
      onClose={onClose}
      maxWidth='xs'
      PaperProps={{
        sx: {
          'borderRadius': '10px !important',
          '& .MuiDialogContent-root': {
            padding: 4,
          },
        },
      }}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            width: 113,
            height: 113,
            background: '#FFE6EC',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon name='exclamation-red' useBackgroundImg size={50} />
        </Stack>
        <Typography variant='h3' mt={3}>
          Subscription Payment Failed
        </Typography>
        <Typography
          sx={{
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            mt: 1,
            mb: 2,
            color: themes.color.violet900,
          }}
        >
          {account.role === USER_ROLE.DISPATCHER
            ? 'Please contact your organization’s admin to update the\n payment method.'
            : 'Please update the payment method\n to avoid service disruption.'}
        </Typography>

        <Button
          onClick={() => {
            onClose();
            if (account.role === USER_ROLE.DISPATCHER) return false;
            return navigate('/setting/billing');
          }}
        >
          Ok
        </Button>
      </Stack>
    </Modal>
  );
};
