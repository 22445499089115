import { Formik, Form } from 'formik';
import { Box, Typography } from '@mui/material';
import { Field } from '~/components/common/Formik';
import { styled } from '~/themes';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendResetPasswordEmail } from '~/services/api/auth';
import { fromQueryString, toQueryString } from '~/utils/queryString';
import { UnifiedPublicLayout } from '~/layout/UnifiedPublicLayout';
import { Button, theme as unifiedTheme } from 'bs-unified-ui';
import { UnifiedSentEmailResetPasswordSchema } from './schema';

type UnifiedForgotPasswordProps = {};

export const StyledForm = styled(Form)(() => ({
  width: '100%',
}));

export const UnifiedForgotPassword: React.FC<
  UnifiedForgotPasswordProps
> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQueries = fromQueryString(location.search);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const emailSent = searchQueries?.email || '';

  const {
    mutate: sendResetPasswordEmailMutation,
    isLoading: isSendEmailLoading,
  } = useSendResetPasswordEmail({
    onSuccess: ({ email }) => {
      navigate({
        pathname: '/reset-password',
        search: toQueryString({ email }),
      });
    },
    onError: () => {
      setErrorMsg('Incorrect email');
    },
  });

  const renderContent = (values, errors, touched) => {
    if (emailSent) {
      return (
        <Box className='headerContainer'>
          <Typography variant='h1'>Email sent</Typography>
          <Typography fontSize={14} mt={2.4} lineHeight='24px'>
            An email with a reset link has been sent to{' '}
            <a
              href={`mailto: ${emailSent}`}
              style={{
                color: '-webkit-link',
              }}
            >
              {emailSent}
            </a>
            . Please check and follow the instructions.
          </Typography>
        </Box>
      );
    }
    return (
      <>
        <Box className='headerContainer'>
          <Typography variant='h1'>Reset Password</Typography>
          <Typography
            color={unifiedTheme.foreground.black}
            sx={{ opacity: 0.4, fontSize: 14, mt: 0.5, lineHeight: '24px' }}
          >
            Please enter your email address below and we’ll send you a link to
            reset your password.
          </Typography>
        </Box>

        <Field.UnifiedTextField
          name='email'
          fullWidth
          label='Email'
          disableErrorText
          onChange={() => {
            if (!errors?.email) {
              setErrorMsg('');
            }
          }}
        />
        {(errors?.email || errorMsg) && touched?.email && (
          <Typography
            sx={{
              lineHeight: 1.66,
              color: '#F24822',
              mt: '19px !important',
              fontSize: 14,
            }}
          >
            {errors?.email || errorMsg}
          </Typography>
        )}

        <Button
          fullWidth
          type='submit'
          sx={{ mt: 3 }}
          loading={isSendEmailLoading}
        >
          Send email
        </Button>
      </>
    );
  };

  return (
    <UnifiedPublicLayout>
      <Formik
        initialValues={{
          email: '',
        }}
        enableReinitialize
        validationSchema={UnifiedSentEmailResetPasswordSchema}
        onSubmit={({ email }) => {
          setErrorMsg('');
          sendResetPasswordEmailMutation({ email });
        }}
      >
        {({ values, errors, touched }) => (
          <StyledForm>{renderContent(values, errors, touched)}</StyledForm>
        )}
      </Formik>
    </UnifiedPublicLayout>
  );
};
