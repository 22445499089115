import { object, string, number } from 'yup';
import { validatePhoneNumber } from '~/pages/LiveTracking/utils';

export const UpdateStoreSchema = object().shape({
  display_name: string().trim().required('Name is required'),
  phone: string().required('Phone is required'),
  address_1: string().trim().required('Address is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  city: string().required('City is required'),
  postcode: string().trim().required('Postcode is required'),
});

export const ShopifyStoreInformationSchema = object().shape({
  display_name: string().trim().required('Store Name is required'),
  phone: string()
    .required('Phone is required')
    .test('phone', 'Invalid phone number', validatePhoneNumber),
  store_url: string()
    .trim()
    .required('Store URL is required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    ),
});

export const WooStoreInformationSchema = object().shape({
  display_name: string().trim().required('Store Name is required'),
  phone: string()
    .required('Phone is required')
    .test('phone', 'Invalid phone number', validatePhoneNumber),
});

export const StoreAddressSchema = object().shape({
  address_1: string().trim().required('Address Line 1 is required'),
  country: string().required('Country is required'),
  state: string().required('State is required'),
  city: string().required('City is required'),
  postcode: string().trim().required('Postcode is required'),
});

export const StoreURLSchema = object().shape({
  store_url: string()
    .trim()
    .required('Store URL is required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    ),
});

export const BreadstackLoginSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required'),
  password: string()
    .min(8, 'Password must be more than 8 characters long')
    .required('Password is required'),
});

export const DeliveryHoursSchema = object().shape({
  limit_all_slot: number()
    .integer('Enter an integer')
    .min(1, 'Enter a number greater than 0')
    .max(1000000, 'Enter a number less than or equal to 1,000,000')
    .nullable(true),
});

export const IndividualOrderLimitSchema = object().shape({
  limit: number()
    .integer('Enter an integer')
    .min(1, 'Enter a number greater than 0')
    .max(1000000, 'Enter a number less than or equal to 1,000,000')
    .nullable(true),
});
