import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Logs from '~/components/shared/Logs/Logs';
import { fromQueryString } from '~/utils/queryString';
import { formatDateTimeUTC } from '~/utils/formatter';
import { useGetDriverLogQuery } from '../../apis';

type TimelineProps = {};

export const Timeline: React.FC<TimelineProps> = () => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const driverId = search.id as string;

  const { data: driverLog, isFetching } = useGetDriverLogQuery({
    id: driverId,
  });
  if (isFetching)
    return (
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <CircularProgress size={18} />
      </Box>
    );

  return (
    <Box width='100%'>
      <Logs
        isShowAvatar={false}
        data={driverLog.map(({ log, created_at, created_by }) => ({
          title: `Note: ${formatDateTimeUTC(
            created_at,
            'hh:mm a - MM/DD/YYYY',
          )} by ${created_by.display_name}`,
          body: log,
        }))}
      />
    </Box>
  );
};
