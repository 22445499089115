export enum DeliveryZoneSets {
  DrawnZones = 'polygon',
  DistanceRadius = 'radius',
  PostalCode = 'postal_code',
}

export interface IDefaultCenter {
  lat: number;
  lng: number;
}
