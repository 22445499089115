import { CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import themes from '~/themes';

type OptimizeRouteLoadingProps = {};

export const OptimizeRouteLoading: React.FC<OptimizeRouteLoadingProps> = () => (
  <Dialog
    open
    maxWidth='xs'
    fullWidth
    disableEscapeKeyDown
    PaperProps={{
      sx: {
        borderRadius: '10px',
      },
    }}
  >
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: themes.spacing(3),
        padding: themes.spacing(4),
      }}
    >
      <CircularProgress
        size={60}
        sx={{ color: themes.color.violet900 }}
        thickness={5}
      />
      <Typography variant='h2'>Optimizing… Do Not Reload</Typography>
    </Stack>
  </Dialog>
);
