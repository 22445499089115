import { IconButton } from '@mui/material';
import { styled } from '~/themes';
import CloseIcon from '~/assets/images/icons/close.svg';
import SearchIcon from '~/assets/images/icons/search.svg';

type SearchPrefixProps = {
  onClearClick: () => void;
  isSearching?: boolean;
};

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  'width': theme.spacing(2.2),
  'height': theme.spacing(2.2),
  'marginRight': theme.spacing(1),
  '& > img': {
    objectFit: 'scale-down',
  },
}));

export const SearchInputPrefix: React.FC<SearchPrefixProps> = ({
  isSearching,
  onClearClick,
}) => (
  <StyledIconButton onClick={onClearClick} size='large'>
    <img
      alt='search-icon'
      src={isSearching ? CloseIcon : SearchIcon}
      width={22}
      height={22}
    />
  </StyledIconButton>
);
