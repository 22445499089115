import React from 'react';
import { createStyles, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Close } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { tags } from './tags';

const tagStyle = () =>
  createStyles({
    root: {
      fontSize: 14,
    },
  });

interface IProps
  extends Omit<ChipProps, 'classes'>,
    WithStyles<typeof tagStyle> {
  tag: string;
  fixedWidth?: boolean;
  label?: string;
  noBackground?: boolean;
  revertColor?: boolean;
}

const Tag: React.FC<IProps> = ({
  classes,
  size = 'medium',
  tag = 'delivered',
  label = '',
  fixedWidth = false,
  style = {},
  noBackground,
  revertColor,
  ...props
}) => (
  <Chip
    data-value={label || tags[tag]?.label || ''}
    data-testid='tag'
    classes={classes}
    style={{
      color: revertColor ? tags[tag]?.bgColor : tags[tag]?.color,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: noBackground
        ? 'unset'
        : revertColor
        ? tags[tag]?.color
        : tags[tag]?.bgColor,
      minWidth: fixedWidth ? 105 : undefined,
      ...(size === 'small' ? { borderRadius: 5 } : {}),
      ...style,
    }}
    label={label || tags[tag]?.label || ''}
    size={size}
    deleteIcon={<Close fontSize='inherit' />}
    {...props}
  />
);

export default withStyles(tagStyle)(Tag);
